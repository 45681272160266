import apiClient from "@/services/axios";
import vuexStore from "@/store";

class Service {
  constructor() {
    this.$api = apiClient;
  }

  getEmployee(id) {
    return this.$api.get(`/employee/${id}`);
  }

  payPrecheck(payload) {
    return this.$api.post("/review", payload);
  }

  precheckConfirm(id, payload) {
    return this.$api.post(`/review-confirm/${id}`, payload);
  }

  payTips(id, payload) {
    return this.$api.post(`/review-tips/${id}`, payload);
  }

  reviewStore(storeId, orderId, payload) {
    return this.$api.post(`/review-store/${storeId}/${orderId}`, payload);
  }

  fastPay(storeId, orderId, payload) {
    return this.$api.post(`/payment/${storeId}/${orderId}`, payload);
  }

  fastPayInstantOrder(storeId, orderId, payload) {
    return this.$api.post(`/payment/instant/${storeId}/order/${orderId}`, payload);
  }

  fastPayInstant(storeId, payload) {
    return this.$api.post(`/payment/instant/${storeId}`, payload);
  }

  fastPayWithoutOrder(storeId, payload) {
    return this.$api.post(`/payment/without-order/terminal/${storeId}`, payload);
  }

  fastPayConfirm(orderId, payload) {
    return this.$api.post(`/payment-confirm/${orderId}`, payload);
  }

  getReview(employeeId, reviewId) {
    return this.$api.get(`/employee/${employeeId}/review/${reviewId}`);
  }

  getReviewUser(uuid) {
    return this.$api.get(`/user/${uuid}`);
  }

  reviewCopy(uuid) {
    return this.$api.post(`/review-copy/${uuid}`);
  }

  employeePos(storeId, employeeId) {
    if (!employeeId) {
      employeeId = 0
    }
    return this.$api.get(`/employee/pos/${storeId}/${employeeId}`);
  }

  getTerminalData(storeId) {
    return this.$api.get(`/terminal/${storeId}`);
  }

  invoiceDetails(storeId, cashdeskId, orderGuid, employeeUuid, details) {
    const payload = {
      employee_uuid: employeeUuid,
      cashdesk_id: cashdeskId,
      order_guid: orderGuid,
      details: details ? 1 : 0,
    }
    const params = new URLSearchParams({
      ...payload,
    })
    if (!employeeUuid || employeeUuid == 0) {
      params.delete('employee_uuid')
    }
    return this.$api.get(`/store/${storeId}/order-details`, { params });
  }

  storeEmployees(storeId) {
    return this.$api.get(`/store/employees?store=${storeId}`, { headers: {'X-Locale': vuexStore.getters.language}});
  }

  paymentStatus(id, system) {
    return this.$api.get(`/review/status?id=${id}&system=${system}`);
  }

  orderWithDetails(storeId, orderId) {
    return this.$api.get(`/store/${storeId}/order-with-details/${orderId}`);
  }

  order(terminal, order) {
    return this.$api.get(`/store/terminal/${terminal}/order/${order}`);
  }
}
export default new Service();
