<template>
  <div id="market" class="relative" :style="{ marginTop: adModal ? '0' : 0 }">
<!--    <div v-if="!this.$route.path.includes('market-org')" class="market-overlay"></div>-->
    <loading-block
      class="loading-block-hidden"
      :class="{ 'loading-block-visible': menuLoading }"
    />
    <div :class="{'move-bottom2': adModal}" class="address-action text-center pt-15">
      <a class="address-link" @click="onShowAddress">
        <div>
          <span v-if="!userAddress">{{ $t('market.show-address') }}</span>
          <span v-else style="font-size: 14px; font-weight: 400">{{$t('market.address')}}</span>
          <img class="ml-5" src="/arrow-address.svg" alt="icon-arrow">
        </div>
        <div v-if="userAddress" style="color: #000000; font-weight: 600;">{{ userAddress }}</div>
      </a>
      <div class="header-marketplace__search">
        <input id="searchInput" type="text" v-model.trim="searching" :placeholder="$t('marketplace.search-food')"/>
        <div style="position: absolute; width: 100%; left: 0; bottom: 1px"></div>
        <img src="/svg/search.svg" width="22" height="22" alt=""/>
        <i
          v-if="searching"
          @click="searching = ''"
          class="van-icon van-icon-cross"
          style="color: rgb(124, 124, 124); top: 14px"
        >
        </i>
      </div>
    </div>
    <header-block
      v-if="false"
      ref="headerBlock"
      :search="search"
      :search-key="searchKey"
      :is-main-page="true"
      @show-delivery-address="showDeliveryAddress"
      @search-update="searchUpdate"
    />
    <adv-block
      v-if="advertisement.length"
      :advertisement="advertisement"
      @move-adv="onMoveStory($event, 'adv')"
    />
    {{ searching }}
    <sales-block
      v-if="sales.length"
      :sales="sales"
      @move-sale="onMoveStory($event, 'sale')"
    />
    <div style="padding-top: 140px;"></div>
    <template v-if="!searching">
      <brands-block
        v-if="brands.length"
        :brands="brands"
        @moveBrand="onMoveBrand"
        @filter-brand="onFilter(false)"
      />
    </template>
    <restaurant-block
      :filters="filters"
      :restaurant-cards="stores"
      :search="search"
      :searching="searching"
      :end="end"
      :current-filter="currentFilter"
      @show-filter="showFilter"
      @move-restaurant="onMoveRestaurant"
      @filter-restaurant="onFilter(true)"
      @reset-filter="reset"
      id="restaurant-block"
    />
    <filter-popup
      ref="filterBlock"
      :filter-modal="filterModal"
      :filters="filters"
      :bill-min="billMin"
      :bill-max="billMax"
      :histogram-data="histogramData"
      @on-closed="filterModal = false"
      @confirm-filter="confirmFilter"
    />
    <cart-info
      :auth-modal="false"
      :cart-info-modal="cartModal"
      :delivery-price="0"
      :checkout="checkoutModal"
      @close-auth-modal="() => false"
      @close-cart-info-modal="closeCartModal"
      @close-checkout-modal="checkoutModal = false"
      @remove-item="removeItemFromCart"
      @show-delivery-address="showDeliveryAddress"
      @finish-order="finishOrder"
      @show-checkout-modal="showCheckoutModal"
    />
    <delivery-address :modal="deliveryAddress" @close="hideDeliveryAddress"/>
    <swipe-modal class="float-menu-modal" :modal="menuModal" @close="menuModal = false">
      <template v-slot:body>
        <call-modal />
      </template>
    </swipe-modal>
    <swipe-modal v-if="false" class="float-menu-modal" v-model="categoryModal">
      <template v-slot:body>
        <ul class="float-menu" v-if="products.length && showMenu">
          <li v-for="(category, index) in products" :key="index">
            <a href="#" @click.prevent="goToCategory(category)" class="link">{{
                category.name
              }}</a>
          </li>
        </ul>
      </template>
    </swipe-modal>
    <div v-if="false" class="float-buttons-wrap right">
      <div class="float-button right pointer" @click="categoryModal = true">
        <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 -960 960 960" width="28">
          <path d="M141-182.804q-16.103 0-27.149-11.017-11.047-11.017-11.047-27.119 0-16.203 10.966-27.23 10.965-11.026 26.991-11.026H820q15.229 0 26.332 10.898 11.103 10.897 11.103 27.562 0 16.239-11.034 27.085-11.034 10.847-27.162 10.847H141Zm0-174q-16.103 0-27.149-11.08-11.047-11.079-11.047-26.274 0-15.464 11.047-26.87 11.046-11.407 27.149-11.407h679q15.229 0 26.332 11.357 11.103 11.357 11.103 27.134 0 15.352-11.103 26.246-11.103 10.894-26.332 10.894H141Zm0-171.761q-16.103 0-27.149-11.266-11.047-11.267-11.047-26.909 0-15.643 11.047-26.549 11.046-10.907 27.149-10.907h679q15.229 0 26.332 11.097 11.103 11.097 11.103 26.59 0 15.762-11.103 26.853-11.103 11.091-26.332 11.091H141Zm0-173.239q-16.103 0-27.149-11.287-11.047-11.288-11.047-26.79 0-15.502 11.047-26.528 11.046-11.026 27.149-11.026h679q15.229 0 26.332 11.151 11.103 11.152 11.103 26.725 0 15.573-11.103 26.664-11.103 11.091-26.332 11.091H141Z"/>
        </svg>
      </div>
      <div
        class="orders-button"
        @click="showCartModal"
        v-if="cart && cart.length && !order.length"
      >
        <span class="price">{{ cartTotalPrice | money($i18n.locale) }}</span>
        <span class="remaining">30 минут</span>
      </div>
      <div
        v-if="order.length"
        class="orders-button"
        @click="showOrderInfoModal"
      >
        <span class="price">Заказ на кассе</span>
        <span class="remaining">30 минут</span>
      </div>
    </div>
    <div class="float-buttons-wrap left">
      <div
        v-if="showMenu"
        class="float-button pointer"
        @click="menuModal = true"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 -960 960 960" width="28">
          <path d="M181.957-182.804q-17.196 0-28.174-10.979-10.979-10.978-10.979-28.174 0-15.956 10.979-27.054 10.978-11.098 28.174-11.098h38.695v-298.543q0-88.957 51.5-160.413 51.5-71.457 138.935-91.696v-17.761q0-27.956 20.098-48.554 20.098-20.598 48.815-20.598t48.815 20.598q20.098 20.598 20.098 48.554v17.761Q636.109-791 688.348-719.304q52.239 71.695 52.239 160.652v298.543h38.456q15.957 0 27.674 11.098 11.718 11.098 11.718 27.054 0 17.196-11.718 28.174-11.717 10.979-27.674 10.979H181.957ZM480.239-56.326q-36.478 0-61.478-25.12-25-25.119-25-61.358h172.717q0 36.239-25.119 61.358-25.12 25.12-61.12 25.12ZM125.37-560.652q-16.24 0-27.338-10.978-11.097-10.979-8.858-26.457 6-75 40.239-139.978 34.239-64.978 87.239-112.978 12.239-11.24 28.457-10.24 16.217 1 25.456 14.479 9.239 12.478 6.12 28.956-3.12 16.478-14.598 26.957-39.761 38.239-65.022 86.88-25.261 48.641-31.5 105.402-1.761 16.479-13.239 27.218-11.478 10.739-26.956 10.739Zm710.26 0q-15.717 0-27.195-10.739t-13.239-27.218q-6.239-56.761-31.5-105.402t-65.022-86.88q-12.239-10.479-15.359-26.957-3.119-16.478 7.12-28.956 9-13.479 25.217-14.479 16.218-1 28.457 10.24 53.239 48 86.858 112.978 33.62 64.978 40.62 139.978 1.478 15.478-9.62 26.457-11.097 10.978-26.337 10.978Z"/>
        </svg>
      </div>
    </div>
    <navigation-market @change-zindex="changeZindex"/>
    <van-popup
      class="sales-modal h-100 market-sales-modal"
      v-model="storyModal"
      position="bottom"
      @opened="storyModal = true"
      :style="{ maxHeight: '100%' }"
    >
      <Stories
        :key="storyKey"
        :stories-from="items"
        :showInnerContent="true"
        :showOuterContent="true"
        :duration="3000"
        :currentIndex="currentStoryIndex"
        :viewed="false"
        :modal="storyModal"
        @closeStories="closeStories"
        @сurrentImageEnd="currentImgEnd"
        @сurrentAllImagesEnd="currentAllImagesEnd"
        @allStoriesEnd="storyKey++"
      >
        <template v-slot:innerContent="{ story }">
          <div class="property" :style="{backgroundColor: '#fff'}" v-if="story">
            <div class="property__description">
              <div>
                <h2 class="property__title">{{ story.images.find(el => !el.viewed)?.comment }}</h2>
                <p class="property__text">{{ story.images.find(el => !el.viewed)?.text }}</p>
                <div v-if="story.images.find(el => !el.viewed)?.promo_code"
                     class="property__promo_code d-flex flex-column ">
                  <div>по промокоду</div>
                  <span>{{ story.images.find(el => !el.viewed)?.promo_code }}</span>
                </div>
              </div>
              <button
                class="story-btn"
                @click="applyOrder(story.images.find(el => !el.viewed).url, story.images.find(el => !el.viewed)?.promo_code ?? null)"
              >
                {{
                  story.images.find(el => !el.viewed)?.order ?
                    'Заказать' : 'За покупками'
                }}
              </button>
            </div>
          </div>
        </template>
      </Stories>
    </van-popup>
    <van-popup
      v-if="false"
      v-model="locationModal"
      position="top"
      class="location-modal"
      @close="closeLocationModal()"
    >
      <template v-if="chosenAddress">
        <div class="text-center">
          <h3 class="m-0 p-0">{{$t('market.show-address')}}</h3>
        </div>
        <div class="d-flex">
          <button @click="showLocation(false)" class="location-modal-btn btn-light">{{$t('market.later')}}</button>
          <van-button
            :loading="loadingBtn"
            @click="showLocation(true)"
            class="location-modal-btn d-flex align-items-center justify-content-center">
            Указать
          </van-button>
        </div>
      </template>
      <template v-else>
        <div class="text-center">
          <p class="mb-0 mt-0">{{$t('market.is-show-address')}}</p>
          <h4 class="mb-10 mt-10">{{ userAddress }}</h4>
        </div>
        <div class="d-flex">
          <button @click="confirmAddress(false)" class="location-modal-btn btn-light">{{ $t('cancel') }}</button>
          <van-button
            :loading="loadingBtn"
            @click="confirmAddress(true)"
            class="location-modal-btn d-flex align-items-center justify-content-center">{{ $t('ok') }}
          </van-button>
        </div>
      </template>
    </van-popup>
    <van-popup
      v-model="mapModal"
      class="map-popup map-popup--branch"
      position="bottom"
      :style="{ height: '100%' }"
      closeable
      close-icon-position="top-right"
      @close="setChosenCoords"
    >
      <yandex-map
        :settings="mapSettings"
        :coords="center"
        :zoom="15"
        :controls="['zoomControl', 'geolocationControl']"
        @click="onClickMap"
        style="
          height: 100%;
          width: 100%;
          position: fixed;
          top: 0;
          z-index: 5;
          left: unset;
          max-width: 768px;
          margin: 0 auto;
        "
      >
        <ymap-marker
          :marker-id="markerId"
          :coords="center"
          :marker-type="'placemark'"
          :icon="{ content: iconAddress }"
        />
      </yandex-map>
      <div class="d-flex justify-content-center im-here">
        <button @click="setChosenCoords">{{ $t('market.im-here') }}</button>
      </div>
    </van-popup>
    <van-popup
      v-if="false"
      v-model="checkModal"
      class="checks-modal "
      position="center"
      :close-on-click-overlay="false"
      round
    >
      <div class="checks d-flex flex-column align-items-center justify-content-center">
        <h2 class="checks-title">Что Вы хотите оплатить?</h2>
        <button @click="handleCheckType('bill_and_tip')" class="checks-btn active">Счёт и чаевые</button>
        <button @click="handleCheckType('only_bill')" class="checks-btn">Счёт</button>
        <button @click="handleCheckType('only_tip')" class="checks-btn">Чаевые</button>
        <label for="click" class="d-flex align-items-center">
          <input v-model="dontAskAgain" type="checkbox" id="click"> <span>Больше не спрашивать</span>
        </label>
      </div>
    </van-popup>
    <van-popup
      v-model="noticeModal"
      position="top"
      class="location-modal"
      @close="closeNoticeModal"
    >
        <div class="text-center">
          <p class="mb-10 mt-0">Ты здесь?</p>
        </div>
        <div class="d-flex">
          <button @click="confirmNotice('cancel')" class="location-modal-btn btn-light">Нет</button>
          <van-button
            :loading="loadingBtn"
            @click="confirmNotice('confirm')"
            class="location-modal-btn d-flex align-items-center justify-content-center">Да
          </van-button>
        </div>
    </van-popup>
    <van-popup
      v-model="notifyModal"
      position="center"
      style="padding: 16px; border-radius: 16px; max-width: 768px; width: 80%; color: #0faeae"
      @close="notifyModal = false"
    >
        <div class="text-center d-flex flex-column align-items-center">
          <img src="/img/marketplace/warning.svg" alt="Warning"/>
          <p class="mb-10 mt-0">{{$t('market.search-test')}}</p>
        </div>
    </van-popup>
    <div
      v-if="alertModal && !this.$route.path.includes('market-org')"
      class="alert-modal pt-40 pb-40"
    >
        <div class="text-center">
          <h1 class="mt-0">{{ $t("marketplace.soon")}}</h1>
          <span>{{ $t("marketplace.tasty") }}</span>
        </div>
    </div>
<!--    <language-switcher v-if="useragent !== 'multicard'" :is-market="true" @switch-language="switchLanguage" />-->
  </div>
</template>
<script>
import SwipeModal from "@/components/menu/SwipeModal.vue";
import LoadingBlock from "@/components/menu/Loading.vue";
import NavigationMarket from "@/components/menu/NavigationMarket.vue";
import {mapActions, mapGetters} from "vuex";
import {DeviceUUID} from "device-uuid";
import "swiper/swiper-bundle.css";
import AdvBlock from "@/components/marketplace/AdvBlock";
import HeaderBlock from "@/components/marketplace/HeaderBlock";
import RestaurantBlock from "@/components/marketplace/RestaurantBlock";
import FilterPopup from "@/components/marketplace/FilterPopup";
import SalesBlock from "@/components/marketplace/SalesBlock";
import DeliveryAddress from "@/components/menu/DeliveryAddress";
import Marketplace from "@/services/marketplace/service";
import BrandsBlock from "@/components/marketplace/BrandsBlock";
import CartInfo from "@/components/menu/CartInfo";
import {Button, Popup} from "vant";
import Stories from "@/components/marketplace/Stories";
import Filter from "@/models/Filter";
import {yandexMap, ymapMarker} from "vue-yandex-maps";
import apiClient from "@/services/axios";
import CallModal from "@/components/menu/CallModal.vue";
import {useUserAgent} from "@/helpers/useUserAgent";
// import MarqueeText from "@/components/menu/MarqueeText";

export default {
  title: "RAHMAT - Каталог",
  name: "MarketplacePage",
  provide() {
    return {
      services: this.services,
    };
  },
  data() {
    return {
      page: 1,
      'per-page': 20,
      total: 0,
      loadMore: false,
      end: false,
      billMin: 0,
      billMax: 0,
      histogramData: [],
      timeoutMinute: 15,
      items: [],
      isSwipeModal: false,
      locationModal: false,
      checkModal: false,
      noticeModal: false,
      alertModal: false,
      dontAskAgain: true,
      loadingBtn: false,
      mapModal: false,
      notifyModal: false,
      showAddress: true,
      mapSettings: {
        apiKey: "e9fe7cab-eb17-4480-976c-a9bd1756a5c1",
        lang: this.$i18n.locale,
        coordorder: "latlong",
        enterprise: true,
        version: "2.1",
      },
      center: [],
      coords: [],
      iconAddress: "...",
      markerId: 0,
      branch: 0,
      hasCurrentClientLocation: false,
      services: [
        {id: 1, text: "delivery", imgUrl: "delivery-courier.svg"},
        {id: 2, text: "takeaway", imgUrl: "takeaway.svg"},
        {id: 3, text: "on_table", imgUrl: "table.svg"}
      ],
      advertisement: [],
      sales: [],
      brands: [],
      stores: [],
      filters: [],
      currentStoryIndex: 0,
      logo: "/img/nologo.png",
      search: "",
      storyModal: false,
      cartModal: false,
      checkoutModal: false,
      orderInfoModal: false,
      menuModal: false,
      categoryModal: false,
      filterModal: false,
      storyKey: 0,
      searchKey: 0,
      showMenu: false,
      showMenuMarket: 2,
      deliveryPrice: 20000,
      deliveryAddress: false,
      typeOfStory: "adv",
      currentFilter: "all",
      store: {},
      searchTimer: undefined,
      allowedUserLocation: false,
      chosenAddress: true,
      currentAddress: "",
      userCoords: [],
      timeIsUp: false,
      switcherActive: false,
      timestamp: this.getStoredTimestamp(),
    };
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll)
    const coords = localStorage.getItem('coords');
    if (
        coords === null ||
        !coords ||
        coords === 'NaN' ||
        coords.includes('NaN') ||
        /^[0-9,.]*$/.test(coords) === false
    ) {
      localStorage.removeItem('timeIsUp');
      localStorage.removeItem('coords');
    }

    // this.getData()
  },
  methods: {
    ...mapActions([
      "addToCart",
      "removeFromCart",
      "cartFlush",
      "makeOrder",
      "showMenuLoading",
      "fetchProducts",
      "setCanOrder",
      "setDeviceUuid",
      "setCoords",
      "setAddress",
      "setLanguage",
      "setBtnColor"
    ]),
    async getData() {
      let uuid = new DeviceUUID().get();
      this.setDeviceUuid(uuid);
      this.showMenuLoading(true);

      if (this.useragent === 'multicard') {
        window.flutter_inappwebview.callHandler('getLocation').then(res => {
          if (!res?.latitude || !res?.longitude) {
            this.fetchStore();
            return;
          }
          this.center = [res?.latitude, res?.longitude];
          this.coords = [res?.latitude, res?.longitude];
          localStorage.setItem("coords", this.coords);
          this.fetchStore(false, res?.latitude + ',' + res?.longitude).then(() => {
            this.getMapAddress();
          })
        })
      } else {
        if (this.checkTimeout()) {
          this.getCurrentPositionFunc();
          localStorage.setItem("timeIsUp", 0);
        }
        if (localStorage.getItem("coords") != null) {
          await this.fetchStore().then(res => {
            if (res.length) {
              this.checkUpUserLocation();
            }
          });
        } else {
          navigator.geolocation.getCurrentPosition((position) => {
            this.center = [position.coords.latitude, position.coords.longitude];
            if (this.checkTimeout()) {
              localStorage.setItem('coords', this.center);
              const date = new Date();
              const getTime = date.getTime();
              const timeout = getTime + this.timeoutMinute * 60 * 1000;
              localStorage.setItem('timeIsUp', timeout);
              this.fetchStore();
            }
          });
        }
      }
    },
    handleScroll() {
      const loadMore = document.getElementById('load-more-check')
      if (loadMore) {
        const rect = loadMore.getBoundingClientRect();
        this.loadMore = (window.innerHeight - rect.top) > 0;
      }
    },
    confirmNotice(status) {
      if (status === "cancel") {
        this.noticeModal  = false;
        this.onShowAddress();
      } else {
        this.noticeModal  = false;
      }
    },
    closeNoticeModal() {
      this.noticeModal  = false;
    },
    handleCheckType(type) {
      switch (type) {
        case "bill_and_tip":
          break;
        case "only_bill":
          break;
        case "only_tip":
          break;
      }
    },
    onShowAddress() {
      this.mapModal = true;
      this.$nextTick(() => {
        this.coords = localStorage.getItem("coords").split(',');
        this.center = localStorage.getItem("coords").split(',');
        this.updateAddress(this.userAddress);
      });
      // this.deliveryAddress = true;
    },
    checkTimeout() {
      const date = new Date();
      let timeout = date.getTime() + this.timeoutMinute * 60 * 1000;
      if (localStorage.getItem("timeIsUp") === null)
        localStorage.setItem("timeIsUp", 0);

      if (localStorage.getItem("timeIsUp") != 0) {
        timeout = localStorage.getItem("timeIsUp");
        const date = new Date();
        if (date.getTime() > timeout) {
          return true;
        }
      } else {
        return true;
      }
      return false;
    },
    setTimeStamp() {
      this.timeoutMinute = 15;
      let timeout;
      if (localStorage.getItem("timeIsUp") == 0) {
        const date = new Date();
        timeout = date.getTime() + this.timeoutMinute * 60 * 1000;
        localStorage.setItem("timeIsUp", timeout);
      } else {
        timeout = localStorage.getItem("timeIsUp");
      }
      const date = new Date();
      if (date.getTime() > timeout) {
        localStorage.setItem("timeIsUp", 0);
      }
    },
    setChosenCoords() {
      localStorage.setItem("coords", this.center);
      this.noticeModal = false;
      this.locationModal = false;
      this.mapModal = false;
      this.setTimeStamp();
      this.fetchStore();
    },
    closeLocationModal() {
      if (this.chosenAddress) {
        this.showLocation(false);
        this.center = [41.311153, 69.279729];
        localStorage.setItem('coords', this.center);
        this.updateAddress("сквер Амира Темура");
      } else {
        // todo na etot adres
      }
    },
    async confirmAddress(status) {
      if (status) {
        await this.fetchStore();
        this.locationModal = false;
        localStorage.setItem("coords", this.coords);
        this.setTimeStamp();
      } else {
        this.showLocation(true);
        this.center = [41.311153, 69.279729];
        localStorage.setItem('coords', this.center);
        this.updateAddress("сквер Амира Темура");
      }
    },
    async onClickMap(e) {
      this.iconAddress = "...";
      this.center = e.get("coords");
      this.setCoords(e.get("coords"));
      this.coords = e.get("coords");
      await this.getMapAddress();
      localStorage.setItem("coords", this.coords);
    },
    async getMapAddress() {
      try {
        const response = await apiClient.get(
          `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${this.mapSettings.apiKey}&geocode=${this.coords[1] + ',' + this.coords[0]}`
        );
        const address = response.data.response.GeoObjectCollection["featureMember"][0].GeoObject.name;
        if (address)
          this.updateAddress(address);
      } catch (error) {
        console.log("err: ", error.message);
      }
    },
    updateAddress: function (address) {
      this.iconAddress = address;
      this.$store.commit("UPDATE_USER_ADDRESS", address);
    },
    changeZindex(increase) {
      if (this.$refs.headerBlock) {
        this.$refs.headerBlock.changeZIndex(increase);
      }
    },
    closeStories() {
      this.storyModal = false;
    },
    currentImgEnd(index) {
      this.currentStoryIndex = index;
    },
    currentAllImagesEnd(index) {
      this.items[index].images[this.items[index].images.length - 1].viewed = false;
    },
    applyOrder() {
      this.$router.push({name: "MarketplaceDetail"});
    },
    onFilter() {
      this.fetchStore();
    },
    async showFilter() {
      await this.fetchStore();
      this.filterModal = true;
    },
    fetchStore(add = false, coords = null) {
      this.showMenuLoading(true);
      let params = this.$route.query;
      if (coords == null) {
        const userCoords = localStorage.getItem("coords") ? localStorage.getItem("coords") : this.coords;
        params = { ...params, coordinates: userCoords };
      } else {
        params = { ...params, coordinates: coords };
      }

      if (!params['page'])
        params['page'] = this.page
      if (!params['per-page'])
        params['per-page'] = this['per-page']
      return Marketplace.getStoresList(params ? params : null).then(({data}) => {
        if (data.data) {
          if (add) {
            data.data.stores.forEach((item) => {
              this.stores.push(item)
            })
          } else {
            this.stores = data.data.stores;
          }
          this.showMenuLoading(false);
          this.advertisement = data.data?.advertisement;
          this.sales = data.data.sales;
          this.brands = data.data.brands;
          this.filters = data.data.filters;
          this.total = data.data.total;
          this.end = data.data.end;
          this.billMin = parseInt(data.data['bill_min']);
          this.billMax = parseInt(data.data['bill_max']);
          this.histogramData = data.data['histogram_data'];
          return this.stores;
        }
      }).finally(() => {
        this.showMenuLoading(false);
        this.loadingBtn = false;
        this.$store.commit("SET_STORES_LIST", this.stores);
        this.$store.commit("SET_BRANDS_LIST", this.brands);
        this.$store.commit("SET_FILTERS_LIST", this.filters);
      });
    },
    confirmFilter() {
      this.fetchStore();
    },
    reset() {
      if (this.$refs.filterBlock) {
        this.$refs.filterBlock.reset();
        this.$refs.filterBlock.onApply();
      }
    },
    onScroll(searchValue) {
      const element = document.querySelector(".restaurant__cards");
      const headerHeight = document.querySelector('.header-marketplace__search').offsetHeight;
      const positionY = element.getBoundingClientRect().top + window.scrollY - headerHeight;
      if (searchValue || this.$store.getters["queryParams"].kitchens && this.$store.getters["queryParams"].kitchens.length) {
        window.scroll({
          top: positionY,
          behavior: "smooth"
        });
      } else {
        window.scroll({
          top: 0,
          behavior: "smooth"
        });
      }
    },
    showCartModal: function () {
      this.cartModal = true;
    },
    closeCartModal: function () {
      this.cartModal = false;
    },
    showOrderInfoModal: function () {
      this.orderInfoModal = true;
    },
    finishOrder() {
      this.checkoutModal = false;
      this.cartModal = false;
      this.makeOrder({
        store_id: this.$route.params.store,
        items: this.cart,
        price: this.cartTotalPrice,
        deliveryPrice: this.deliveryPrice,
        type: "on_table"
      });
    },
    removeItemFromCart(item, all = false) {
      this.removeFromCart({item, all});
    },
    async goToCategory(category) {
      this.activeTab = await category.name;
      this.categoryModal = false;
      let block = await document.getElementById(category.id);
      await block.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    },
    onMoveRestaurant(restaurant) {
      this.$router.push(`/market/${restaurant.path}`);
    },
    onMoveStory(index, type) {
      this.storyKey++;
      this.items = [];
      this.currentStoryIndex = index;
      if (type === "sale") {
        this.items = [...this.sales];
      } else {
        this.items = [...this.advertisement];
      }
      this.items = [
        {
          type: "stories",
          images: [
            {
              url: "https://eats.yandex.com/images/3595381/35971a574b60b0508d00087a4ae6a88d-800x800.jpg",
              viewed: false,
              comment: 'Скидка в магазине',
              text: 'Заказ будет на 15000 тисяч дешевле, если в корзине от 150000 тисяч',
              promo_code: 'r555',
              order: false
            },
            {
              url: "https://eats.yandex.com/images/3702558/e713e77f251886b27bc81de9100fdc0a-800x800.jpg",
              viewed: false,
              comment: 'Скидка в магазине',
              text: 'Заказ будет на 18000 тисяч дешевле, если в корзине от 180000 тисяч',
              promo_code: 'mgn180',
              button: {
                type: 'order'
              }
            },
          ],
        },
        {
          images: [
            {
              url: "https://halaldastarkhan.com/wp-content/uploads/2020/06/Website-214.jpg",
              viewed: false,
              comment: 'Доставка в Рамадан',
              text: 'Работает днем и ночю. Заказывайте любимую еду из ресторана',
              promo_code: false,
              order: true
            },
          ],
        },
      ];
      this.storyModal = true;
    },
    onMoveBrand(brand) {
      const query = this.$route.query;
      this.$router.push({path: this.$route.path, query: {...query, brand: `${brand.name}`}});
    },
    showDeliveryAddress() {
      this.deliveryAddress = true;
    },
    showCheckoutModal: function () {
      this.checkoutModal = true;
    },
    hideDeliveryAddress() {
      this.deliveryAddress = false;
    },
    searchUpdate(value) {
      const query = this.$route.query;
      this.search = value;
      this.searching = value;
      this.$router.replace({query: {...query, name: this.search}}).catch(() => {});
      clearTimeout(this.searchTimer);
      this.showMenuLoading(true);
      this.scrollToElement();
      this.searchTimer = setTimeout(() => {
        this.fetchStore();
      }, 500);
    },
    scrollToElement() {
      window.scroll({
        top: 0,
        behavior: "smooth"
      });
    },
    async successCallback(position) {
      navigator.permissions.query({name: 'geolocation'}).then(async (permissionStatus) => {
        this.allowedUserLocation = permissionStatus.state === "granted"
        this.chosenAddress = !localStorage.getItem("coords");
        this.userCoords = [position.coords.latitude, position.coords.longitude];
        this.center = [position.coords.latitude, position.coords.longitude];
        this.coords = [position.coords.latitude, position.coords.longitude];
        await this.getMapAddress();
        if (localStorage.getItem("timeIsUp")) {
          this.locationModal = true;
        }
      });
    },
    errorCallback(error) {
      console.log(error)
      this.allowedUserLocation = false;
      if (!localStorage.getItem("coords")) {
        this.chosenAddress = true;
      } else {
        this.chosenAddress = false;
        this.coords = [...localStorage.getItem("coords").split(',')];
        this.getMapAddress();
      }
      if (localStorage.getItem("timeIsUp") == 0) {
        this.locationModal = true;
      }
    },
    getCurrentPositionFunc() {
      this.locationModal = false;
      navigator.geolocation.getCurrentPosition(
        this.successCallback,
        this.errorCallback,
      );
    },
    showLocation(status) {
      this.loadingBtn = status;
      if (status) {
        this.mapModal = true;
        this.$nextTick(() => {
          this.center = [41.311153, 69.279729];
          localStorage.setItem('coords', this.center);
          this.updateAddress("сквер Амира Темура");
          this.loadingBtn = false;
        });
      } else {
        this.locationModal = false;
        this.fetchStore();
      }
    },
    checkUpUserLocation() {
      this.$nextTick(() => {
        if (this.stores.length) {
          const { distance } = this.stores[0];
          if (Number(distance).toFixed(1) >= 50) {
            this.noticeModal = true;
          }
        }
      });
    },
    handleLanguage() {
      let lang = "";
      if (this.language && this.language !== "") {
        lang = this.language;
      } else {
        let browserLocale = navigator.language;
        if (browserLocale.substring(0, 2) === "uz") {
          lang = browserLocale.substring(0, 2);
        } else {
          this.langModal = true;
        }
      }
      this.$i18n.locale = lang.substring(0, 2);
      this.setLanguage(lang.substring(0, 2));
    },
    notifyModalCheck() {
      const isLocalStorageEnabled = () => {
        try {
          const key = '__storage__test';
          window.localStorage.setItem(key, null);
          window.localStorage.removeItem(key);
          return true;
        } catch (e) {
          return false;
        }
      };
      const execute = isLocalStorageEnabled();
      if (execute) {
        const now = new Date().getTime();
        const time = localStorage.getItem('timeIsUp');
        return now > parseInt(time);
      } else {
        return false;
      }
    },
    getStoredTimestamp() {
      const storedTimestamp = localStorage.getItem('timestamp');
      return storedTimestamp ? parseInt(storedTimestamp) : null;
    },
    getCurrentTimestamp() {
      return Math.floor(Date.now() / 1000);
    },
    updateTimestamp() {
      this.timestamp = this.getCurrentTimestamp();
      localStorage.setItem('timestamp', this.timestamp.toString());
      setTimeout(this.updateTimestamp, 1000);

      this.notifyModal = !!(this.isFirstVisit() || this.is10SecondsPassed());
    },
    isFirstVisit() {
      return this.timestamp === null;
    },
    is10SecondsPassed() {
      const now = this.getCurrentTimestamp();
      const storedTimestamp = this.getStoredTimestamp();

      return storedTimestamp !== null && now - storedTimestamp > 24 * 60 * 60;
    },
    switchLanguage() {
      this.fetchStore();
    },
  },
  computed: {
    ...mapGetters(["cart", "order", "menuLoading", "products", "token", "address", "userAddress", "language", "employee", "adModal"]),
    useragent() {
      return useUserAgent().getUserAgent()
    },
    currentLocale() {
      return this.$i18n.locale.toUpperCase();
    },
    cartTotalPrice() {
      if (!this.cart || !this.cart.length) return 0;
      let price = 0;
      this.cart.forEach((cartItem) => {
        if (cartItem.item) {
          price += cartItem.item.price * cartItem.count;
        }
      });
      return price;
    },
    searching: {
      get() {
        return this.$store.getters["queryParams"].name;
      },
      set(value) {
        return this.$store.commit("SET_MARKET_SEARCH", value);
      }
    },
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.notifyModal = this.notifyModalCheck();
    this.updateTimestamp();
    this.$i18n.locale = (this.language && this.language.length) ? this.language : 'ru'
    this.$store.commit("SET_NAVIGATION_MENU_HOME_ROUTE", this.$route.fullPath);
    this.setBtnColor("#0faeae");
    let lastScroll = 0;
    let isScrolled = false;
    window.addEventListener("scroll", function () {
      let topHeader = document.querySelector(".address-action");
      let currentScroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      let scrollDirection = currentScroll < lastScroll;
      let shouldToggle = isScrolled && scrollDirection;
      isScrolled = currentScroll > 100;
      if (topHeader) {
        if (topHeader.classList.contains("fixed") && !scrollDirection) {
          topHeader.classList.remove("fixed");
        } else {
          topHeader.classList.toggle("fixed", shouldToggle);
        }
      }
      lastScroll = currentScroll;
    });
    // this.handleLanguage();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'CatalogPage') {
        vm.stores = vm.$store.getters.storesList
        vm.brands = vm.$store.getters.brandsList
        vm.filters = vm.$store.getters.filtersList
      } else {
        vm.getData()
      }
      // vm.canUpdate = from.name !== 'CatalogPage'
    })
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SET_NAVIGATION_MENU_HOME_ROUTE", from.fullPath);
    next();
  },
  beforeCreate() {
    let query = this.$route.query;
    const clone = {...query};
    this.$store.commit("UPDATE_ROUTE_QUERY_PARAMS", {...clone});
    this.$store.commit("CHECK_QUERY_COLLECT");

    if ("sweets" in query) {
      if (typeof query.sweets === "string") {
        this.$route.query.sweets = this.$route.query.sweets.split();
      }
      if (typeof this.$store.state.queryParams.sweets === "string") {
        this.$store.state.queryParams.sweets = this.$store.state.queryParams.sweets.split();
      }
    }
    if ("name" in clone) {
      delete clone.name;
      query = {...clone};
      this.$router.push({query}).catch(() => {
      });
    }
  },
  watch: {
    "$route.query": {
      handler: function (rQuery) {
        const clone = {...rQuery};
        Object.keys(clone).forEach((key) => {
          if (!clone['kitchens']?.length) {
            delete clone['kitchens'];
          }
          if (Object.keys(new Filter({})).some(someKey => someKey === key)) {
            //   todo
          } else {
            delete clone[key];
            this.$router.push({...clone}).catch(() => {
            });
          }
        });
        this.$store.commit("UPDATE_ROUTE_QUERY_PARAMS", Object.keys(clone).length ? clone : 0);
        this.$store.commit("CHECK_QUERY_COLLECT");
      },
      deep: true,
      immediate: true
    },
    loadMore(value) {
      if (value) {
        this.page++
        this.fetchStore(true);
      }
    },
    token(value) {
      if (value) {
        this.checkoutModal = true;
      }
    },
    search() {
      // this.onScroll(value);
    },
    searching(value) {
      this.searchUpdate(value);
    },
    timeIsUp(value) {
      if (value) {
        localStorage.removeItem("coords");
      }
    },
    locationModal(value) {
      if (value) this.filterModal = false;
    }
  },
  components: {
    // MarqueeText,
    CallModal,
    BrandsBlock,
    SalesBlock,
    FilterPopup,
    RestaurantBlock,
    HeaderBlock,
    AdvBlock,
    NavigationMarket,
    LoadingBlock,
    SwipeModal,
    Stories,
    "delivery-address": DeliveryAddress,
    "cart-info": CartInfo,
    "van-popup": Popup,
    "van-button": Button,
    yandexMap,
    ymapMarker,
  },
};
</script>

<style scoped lang="scss">
.alert-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 90%;
  border-radius: 16px;
  background-color: #FFFFFF;
  max-width: 768px;
  z-index: 6;
  h1 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 4px;
    text-transform: uppercase;
  }
  span {
    font-size: 18px;
    font-weight: 700;
  }
}
.lang-modal {
  width: 90%;
  max-width: 768px;
  padding: 10px 30px 10px;

  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 30px;
  }
}

.lang-modal-content {
  text-align: center;

  li {
    flex: 0 0 33.333%;
    margin-bottom: 25px;

    a {
      position: relative;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #000000;

      &:after {
        content: '';
        position: absolute;
        width: 25px;
        height: 0;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -4px;
        border: .5px solid rgba(0, 0, 0, 0.4);
        border-radius: 20px;
      }
    }
  }
}

.checks-title {
  font-size: 22px;
  line-height: 27px;
}

.checks-modal {
  width: 90%;
  max-width: 768px;
  padding-bottom: 20px;

  button {
    background: #F5F5F5;
    border: 1px solid #D1D1D1;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    margin-bottom: 15px;
    height: 40px;
    width: 180px;

    &.active {
      color: #FFFFFF;
      background: linear-gradient(95.73deg, #29BF9B -14.53%, #0BABAB 51.05%);
      border-color: transparent;
    }
  }

  label {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000000;

    span {
      margin-left: 3px;
      margin-top: 2px;
    }
  }
}

.im-here {
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  width: 80%;

  button {
    width: 80%;
    color: #FFFFFF;
    background: linear-gradient(95.73deg, #29BF9B -14.53%, #0BABAB 51.05%);
    min-width: 68px;
    height: 40px;
    border-radius: 16px;
    padding: 0 14px;
    font-size: 16px;
    font-weight: 500;
    max-width: 700px;
    border: 1px solid transparent;
  }
}

.location-modal {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 768px;
  margin: auto;
  left: auto;

  &-btn {
    color: #FFFFFF;
    background: linear-gradient(95.73deg, #29BF9B -14.53%, #0BABAB 51.05%);
    min-width: 68px;
    height: 40px;
    border-radius: 16px;
    padding: 0 12px;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid transparent;
  }

  .btn-light {
    margin-right: 8px;
    transition: all .25s;
    color: #21201F;
    background: #F6F6F6;
    border-color: transparent;
  }
}
</style>
