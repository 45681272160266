<template>
  <div class="home">
    <LoadingBlock v-if="loading"></LoadingBlock>
    <HeaderBlock :key="Date.now()" :logo="logo"></HeaderBlock>
    <NavigationBill />
    <SuccessBlock
        v-if="transferInfo"
        :transferInfo="transferInfo"
    ></SuccessBlock>
  </div>
</template>

<script>
import LoadingBlock from "@/components/Loading";
import SuccessBlock from "@/components/SuccessBlock";
import HeaderBlock from "@/components/HeaderBlock";
import { mapActions } from "vuex";
import NavigationBill from "@/components/NavigationBill";
import Service from "@/services/api/service";

export default {
  title: "Оплата чаевых",
  name: "ResultView",
  components: {
    NavigationBill,
    LoadingBlock,
    SuccessBlock,
    HeaderBlock,
  },
  data() {
    return {
      transferInfo: null,
      logo: ""
    };
  },
  mounted: function () {
    let id = this.$route.query.id;
    let system = this.$route.query.system;
    this.showLoading(true)
    Service.paymentStatus(id, system).then((res) => {
      if (res) {
        this.transferInfo = res.data.data.payment_info
        this.logo = res.data.data.store_logo
        this.setEmployee(res.data.data.employee)
      }
    }).finally(() => {
      this.showLoading(false)
    });
    this.$store.commit("SET_NAVIGATION_BILL_HOME_ROUTE", this.$route.fullPath)
  },
  beforeRouteLeave(to, from, next){
    this.$store.commit("SET_NAVIGATION_BILL_HOME_ROUTE", from.fullPath)
    next()
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    ...mapActions(["showLoading", "setEmployee"]),
  },
};
</script>
