<template>
  <div
    style="
      height: 100vh;
      left: 0;
      top: 0;
      width: 100%;
      background-color: #ffffff;
      position: fixed;
      z-index: 99;
      opacity: 0.9;
    "
    class="container flex-column bg-green d-flex align-items-center justify-content-center"
  >
    <div class="text-center mt-auto mb-auto white">
      <van-loading type="spinner" size="80" color="#61c89c" />
      <div class="f-18 mt-10 text-black">Получение геопозиции...</div>
    </div>
  </div>
</template>

<script>
import { Loading } from "vant";
export default {
  name: "AddressLoadingBlock",
  components: {
    "van-loading": Loading,
  },
};
</script>

<style>
.text-black {
  color: #000;
}
</style>
