var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stories-wrapper"},[_c('div',{staticClass:"close",on:{"click":_vm.closeStories}}),_c('div',{staticClass:"stories"},_vm._l((_vm.stories),function(story,index){return _c('div',{key:index,staticClass:"story",style:(index === _vm.indexSelected
          ? `transform: translate(0px)`
          : `transform: translate(${_vm.calculateTransform(index)}px) scale(0.3);cursor:pointer;`),on:{"click":function($event){index !== _vm.indexSelected ? _vm.selectSlide(index) : ''}}},[_c('div',{staticClass:"story__source",on:{"click":function($event){_vm.isPaused ? _vm.playStory($event) : _vm.pauseStory($event)}}},[(_vm.getSrc(story, index).type === 'video')?_c('video',{attrs:{"id":_vm.getSrc(story, index).url,"src":_vm.getSrc(story, index).url,"autoplay":""}}):_c('img',{attrs:{"src":_vm.getSrc(story, index).url,"alt":""}}),(index === _vm.indexSelected)?_c('div',{staticClass:"story__header"},[_c('div',{staticClass:"time"},_vm._l((story.images.length),function(elm,index){return _c('div',{key:index,staticClass:"time__item"},[_c('div',{staticClass:"time__fill",style:(index === _vm.key
                    ? `width: ${_vm.percent}%`
                    : _vm.key > index
                    ? `width:100%`
                    : `width:0%`)})])}),0),_c('div',{staticClass:"story__top"},[_c('div',{staticClass:"user"},[_c('div',{staticClass:"user__image"},[_c('img',{attrs:{"src":story.picture,"alt":""}})]),_c('div',{staticClass:"user__name"},[_vm._v(" "+_vm._s(story.username)+" ")])]),_c('div',{staticClass:"story__time"},[_vm._v(_vm._s(story.time))])])]):_vm._e(),_c('div',{staticClass:"story__body"},[(index !== _vm.indexSelected)?_c('div',{staticClass:"user"},[_c('div',{staticClass:"user__image",style:(_vm.getNotViewedIndex(story) === -1 ? `background: #FFFFFF` : '')},[_c('img',{attrs:{"src":story.picture,"alt":""}})]),_c('div',{staticClass:"user__name"},[_vm._v(" "+_vm._s(story.username)+" ")])]):_vm._e(),(_vm.showInnerContent && index === _vm.indexSelected)?_vm._t("innerContent",null,{"story":story}):_vm._e()],2)]),(index === _vm.indexSelected)?_c('div',{staticClass:"story__icon story__icon--prev",on:{"click":function($event){return _vm.prev(index)}}}):_vm._e(),(index === _vm.indexSelected)?_c('div',{staticClass:"story__icon story__icon--next",on:{"click":function($event){return _vm.next(index)}}}):_vm._e(),(_vm.showOuterContent && index === _vm.indexSelected)?_vm._t("outerContent",null,{"story":story}):_vm._e()],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }