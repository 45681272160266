<template>
  <div>
    <loading-block v-if="menuLoading"/>
    <menu-header
      ref="menuHeader"
      @delivery-modal="showDeliveryAddress"
      :logo="logo"
      :show-address="true"
      :store-name="storeName"
      :key="menuHeaderKey"
      :search-value="search"
      :is-marketplace="true"
      :current-store="currentStore"
    />
    <div class="fixed-menu-content">
      <header-bottom ref="headerBottom" :detail-page="true" :is-main-page="false" @search-update="searchUpdate"/>
      <stories-block v-if="1 === 0"/>
      <tasty-block v-if="1 === 0"/>
      <products-block :show-menu="showMenu" :search="search" :is-marketplace="true"/>
    </div>
    <delivery-address :modal="deliveryAddress" @close="hideDeliveryAddress"/>
    <cart-info
      :auth-modal="authModal"
      @close-auth-modal="closeAuthModal"
      :cart-info-modal="cartModal"
      @close-cart-info-modal="closeCartModal"
      @close-checkout-modal="checkoutModal = false"
      :checkout="checkoutModal"
      @remove-item="removeItemFromCart"
      :delivery-price="deliveryPrice"
      @finish-order="finishOrder"
      @show-delivery-address="showDeliveryAddress"
      @show-checkout-modal="showCheckoutModal"
    />
    <order-info
      @close-order-info-modal="closeOrderInfoModal"
      :order-info-modal="orderInfoModal"
    />
    <swipe-modal class="float-menu-modal" :modal="menuModal" @close="menuModal = false">
      <template v-slot:body>
        <call-modal :show-bell-list="showBellList" />
      </template>
    </swipe-modal>
    <swipe-modal class="float-menu-modal" :modal="categoryModal" @close="categoryModal = false">
      <template v-slot:body>
        <ul class="float-menu" v-if="products.length && showMenu">
          <li v-for="category in products" :key="category.id">
            <a href="#" @click.prevent="goToCategory(category)" class="link">{{
                category.name
              }}</a>
          </li>
        </ul>
      </template>
    </swipe-modal>
    <div v-if="products.length" class="float-buttons-wrap right">
      <div class="float-button right pointer" @click="categoryModal = true">
        <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 -960 960 960" width="28">
          <path d="M141-182.804q-16.103 0-27.149-11.017-11.047-11.017-11.047-27.119 0-16.203 10.966-27.23 10.965-11.026 26.991-11.026H820q15.229 0 26.332 10.898 11.103 10.897 11.103 27.562 0 16.239-11.034 27.085-11.034 10.847-27.162 10.847H141Zm0-174q-16.103 0-27.149-11.08-11.047-11.079-11.047-26.274 0-15.464 11.047-26.87 11.046-11.407 27.149-11.407h679q15.229 0 26.332 11.357 11.103 11.357 11.103 27.134 0 15.352-11.103 26.246-11.103 10.894-26.332 10.894H141Zm0-171.761q-16.103 0-27.149-11.266-11.047-11.267-11.047-26.909 0-15.643 11.047-26.549 11.046-10.907 27.149-10.907h679q15.229 0 26.332 11.097 11.103 11.097 11.103 26.59 0 15.762-11.103 26.853-11.103 11.091-26.332 11.091H141Zm0-173.239q-16.103 0-27.149-11.287-11.047-11.288-11.047-26.79 0-15.502 11.047-26.528 11.046-11.026 27.149-11.026h679q15.229 0 26.332 11.151 11.103 11.152 11.103 26.725 0 15.573-11.103 26.664-11.103 11.091-26.332 11.091H141Z"/>
        </svg>
      </div>
      <div
        v-if="false && cart && cart.length && !order.length"
        class="orders-button"
        @click="showCartModal"
      >
        <span class="price">{{ cartTotalPrice | money($i18n.locale) }}</span>
        <span class="remaining">30 минут</span>
      </div>
      <div
        v-if="order.length"
        class="orders-button"
        @click="showOrderInfoModal"
      >
        <span class="price">Заказ на кассе</span>
        <span class="remaining">30 минут</span>
      </div>
    </div>
    <div class="float-buttons-wrap left">
      <div
        v-if="false && Object.keys(showBellList).some(k => !!showBellList[k])"
        class="float-button pointer"
        @click="menuModal = true"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 -960 960 960" width="28">
          <path d="M181.957-182.804q-17.196 0-28.174-10.979-10.979-10.978-10.979-28.174 0-15.956 10.979-27.054 10.978-11.098 28.174-11.098h38.695v-298.543q0-88.957 51.5-160.413 51.5-71.457 138.935-91.696v-17.761q0-27.956 20.098-48.554 20.098-20.598 48.815-20.598t48.815 20.598q20.098 20.598 20.098 48.554v17.761Q636.109-791 688.348-719.304q52.239 71.695 52.239 160.652v298.543h38.456q15.957 0 27.674 11.098 11.718 11.098 11.718 27.054 0 17.196-11.718 28.174-11.717 10.979-27.674 10.979H181.957ZM480.239-56.326q-36.478 0-61.478-25.12-25-25.119-25-61.358h172.717q0 36.239-25.119 61.358-25.12 25.12-61.12 25.12ZM125.37-560.652q-16.24 0-27.338-10.978-11.097-10.979-8.858-26.457 6-75 40.239-139.978 34.239-64.978 87.239-112.978 12.239-11.24 28.457-10.24 16.217 1 25.456 14.479 9.239 12.478 6.12 28.956-3.12 16.478-14.598 26.957-39.761 38.239-65.022 86.88-25.261 48.641-31.5 105.402-1.761 16.479-13.239 27.218-11.478 10.739-26.956 10.739Zm710.26 0q-15.717 0-27.195-10.739t-13.239-27.218q-6.239-56.761-31.5-105.402t-65.022-86.88q-12.239-10.479-15.359-26.957-3.119-16.478 7.12-28.956 9-13.479 25.217-14.479 16.218-1 28.457 10.24 53.239 48 86.858 112.978 33.62 64.978 40.62 139.978 1.478 15.478-9.62 26.457-11.097 10.978-26.337 10.978Z"/>
        </svg>
      </div>
    </div>
    <navigation-menu :market="true" @change-zindex="changeZindex"/>
  </div>
</template>

<script>
import CallModal from "@/components/menu/CallModal.vue";
import SwipeModal from "@/components/menu/SwipeModal.vue";
import CartInfo from "@/components/menu/CartInfo.vue";
import OrderInfo from "@/components/menu/OrderInfo.vue";
import LoadingBlock from "@/components/menu/Loading.vue";
import TastyBlock from "@/components/menu/TastyBlock.vue";
import MenuHeader from "@/components/menu/MenuHeader.vue";
import StoriesBlock from "@/components/menu/StoriesBlock.vue";
import DeliveryAddress from "@/components/menu/DeliveryAddress.vue";
import ProductsBlock from "@/components/menu/ProductsBlock.vue";
import NavigationMenu from "@/components/menu/NavigationMenu.vue";
import {mapActions, mapGetters} from "vuex";
import {DeviceUUID} from "device-uuid";
import HeaderBottom from "@/components/marketplace/header/HeaderBottom";

export default {
  title: "QR Меню",
  name: "MarketplaceOrderPage",
  provide() {
    return {
      services: this.services
    }
  },
  components: {
    HeaderBottom,
    NavigationMenu,
    CartInfo,
    LoadingBlock,
    MenuHeader,
    DeliveryAddress,
    StoriesBlock,
    TastyBlock,
    ProductsBlock,
    SwipeModal,
    OrderInfo,
    CallModal,
  },
  data() {
    return {
      storeName: "",
      currentStore: {},
      logo: "/img/nologo.png",
      search: "",
      searchFood: "",
      cartModal: false,
      deliveryAddress: false,
      checkoutModal: false,
      authModal: false,
      orderInfoModal: false,
      menuModal: false,
      categoryModal: false,
      menuHeaderKey: 0,
      showMenu: false,
      showBellList: {
        call_bill: 0,
        call_hookah: 0,
        call_waitress: 0
      },
      deliveryPrice: 20000,
      storeSelected: false,
      orderType: "delivery",
      services: [
        {id: 1, text: "delivery", imgUrl: "delivery-courier.svg"},
        {id: 2, text: "takeaway", imgUrl: "takeaway.svg"},
        {id: 3, text: "on_table", imgUrl: "table.svg"},
      ],
    };
  },
  created() {
    let uuid = new DeviceUUID().get();
    this.setDeviceUuid(uuid);
  },
  mounted() {
    this.getProducts();
    this.$store.commit("SET_NAVIGATION_MENU_HOME_ROUTE", this.$route.fullPath);
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SET_NAVIGATION_MENU_HOME_ROUTE", from.fullPath);
    next()
  },
  methods: {
    ...mapActions([
      "addToCart",
      "removeFromCart",
      "makeOrder",
      "showMenuLoading",
      "fetchProducts",
      "setCanOrder",
      "setDeviceUuid",
    ]),
    showDeliveryAddress: function () {
      this.deliveryAddress = true;
    },
    finishOrder() {
      if (!this.storeSelected) {
        this.showDeliveryAddress();
        return;
      }
      this.checkoutModal = false;
      this.cartModal = false;
      this.makeOrder({
        store_id: this.$route.query.s,
        items: this.cart,
        price: this.cartTotalPrice,
        deliveryPrice: this.deliveryPrice,
        type: "delivery",
      });
    },
    removeItemFromCart(item, all = false) {
      this.removeFromCart({item, all});
    },
    getImage(item, size) {
      if (!item) return;
      let image = "";
      if (size === "big") image = item.bigImageUrl;
      if (size === "small") image = item.imageUrl;
      if (image) image = image.replace("http:", "https:");
      return image;
    },
    getBackground(image) {
      return `background-image: url('${image}')`;
    },
    async getProducts() {
      let storeId = "";
      if (this.$route.params.store) {
        this.storeSelected = true;
        storeId = this.$route.params.store;
      }
      this.fetchProducts({
        brand: this.$route.params.brand,
        store: storeId,
      }).then((res) => {
        if (res) {
          this.currentStore = res;
          this.storeName = res.name;
          this.showMenu = res.menu === 1;
          this.showBellList = {
            call_bill: res.call_bill,
            call_hookah: res.call_hookah,
            call_waitress: res.call_waitress
          };
          this.logo = res.logo;
          this.setCanOrder(res.order === 1);
        }
      });
    },
    showCheckoutModal: function () {
      if (this.token) {
        this.checkoutModal = true;
        return true;
      }
      this.showAuthModal();
    },
    showAuthModal: function () {
      this.authModal = true;
    },
    closeAuthModal: function () {
      this.authModal = false;
    },
    showCartModal: function () {
      this.cartModal = true;
    },
    closeCartModal: function () {
      this.cartModal = false;
    },
    hideDeliveryAddress: function () {
      this.deliveryAddress = false;
      this.menuHeaderKey++;
    },
    closeOrderInfoModal: function () {
      this.orderInfoModal = false;
    },
    showOrderInfoModal: function () {
      this.orderInfoModal = true;
    },
    async goToCategory(category) {
      this.activeTab = await category.name;
      this.categoryModal = false;
      let block = await document.getElementById(category.id);
      await block.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    searchUpdate(value) {
      this.search = value;
      const element = document.querySelector(".foods-list");
      if (element) {
        /* eslint-disable */
        // debugger
        const positionY = element.getBoundingClientRect().top + window.scrollY;
        if (value && positionY) {
          window.scroll({
            top: positionY - 235,
            behavior: "smooth"
          });
        }
      }
    },
    clearSearch() {
      this.search = "";
    },
    changeZindex(increase) {
      if (this.$refs.menuHeader) {
        this.$refs.menuHeader.changeZIndex(increase);
      }
      if (this.$refs.headerBottom) {
        console.log(this.$refs.headerBottom)
        this.$refs.headerBottom.changeZIndex(increase);
      }
    }
  },
  computed: {
    ...mapGetters([
      "cart",
      "order",
      "menuLoading",
      "products",
      "token",
      "address",
      "canOrder",
      "deviceUuid",
    ]),
    cartTotalPrice() {
      if (!this.cart || !this.cart.length) return 0;
      let price = 0;
      this.cart.forEach((cartItem) => {
        if (cartItem.item) {
          price += cartItem.item.price * cartItem.count;
        }
      });
      return price;
    },
  },
  watch: {
    token(value) {
      if (value) {
        this.authModal = false;
        this.checkoutModal = true;
      }
    },
  },
};
</script>

<style scoped></style>
