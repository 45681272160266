import axios from "axios";
import { Notify } from "vant";
import store from "@/store";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
});

// axios.defaults.withCredentials = true
apiClient.interceptors.request.use((request) => {
  return request;
});

apiClient.interceptors.response.use(undefined, (error) => {
  const { response } = error;
  const { data } = response;
  if (response.status === 401 || response.status === 403) {
    const refreshToken = store.getters.refreshToken
    if (typeof refreshToken === 'string' && refreshToken.length) {
      apiClient.post(`/menu/auth/refresh?token=${refreshToken}`).then(({data}) => {
        if (data.success) {
          store.dispatch("saveRefreshToken", data.data.refresh)
          store.dispatch("saveToken", data.data.token)
        }
      }).finally(() => {
        apiClient.delete(`/menu/auth/refresh?token=${refreshToken}`)
      })
    }
  } else {
    Notify({
      type: "warning",
      message: data.error.details || "Системная ошибка, попробуйте позже",
    });
    store.dispatch("showLoading", false).then((r) => console.log(r));
  }
  return Promise.reject(data)
});

export default apiClient;
