<template>
  <div class="account-page">
    <h2 class="mt-0 account-title">{{ $t('account') }}</h2>
    <div :style="{'--menu-item-color': btnColor}">
      <language-switcher :button="false" :modal="languageModal" @close="languageModal = false" />
      <authorization-block
          @close="closeAuthModal"
          @open-auth-model="openAuthModel"
          @open-modal="openModal"
          @language-modal="languageModal = true"
      />
      <auth-modal
          :auth-modal="authModal"
          @close="closeAuthModal"
          :current-country="currentCountry"
          @change-country="changeCountry"
          :phone="phone"
      />
      <van-popup
          class="account-modal p-10"
          v-model="ticketModal"
          position="bottom"
          closeable
          close-icon="close"
          close-icon-position="top-right"
          @closed="closedCardCheckModal"
          :style="{ maxHeight: '90%' }"
      >
        <div class="modal">
          <div class="modal__top d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center" style="height: 35px">
              <h2 class="modal__title">{{ $t("my-checks") }}</h2>
            </div>
            <div v-if="false" class="modal-filter"><img src="/img/account/filter-outline.svg" alt=""></div>
          </div>
          <div v-if="!loading" class="modal__tickets">
            <template v-if="payments.length">
              <a
                  v-for="ticket in payments"
                  :key="ticket.id"
                  :href="ticket.type === 'tips' ? '#' : ticket['qr']"
                  :target="ticket.type === 'tips' ? '' : '_blank'"
                  class="ticket d-flex align-items-center justify-content-between"
                  @click.prevent="openCheck(ticket)"
              >
                <div class="ticket__body d-flex align-items-center">
                  <div class="ticket__image"><img :src="getImage(ticket)" alt=""></div>
                  <div class="ticket__desc ml-15">
                    <span>{{ ticket.title }}</span>
                    <h3 class="name">{{ ticket['employee_name'] }}</h3>
                    <p class="type">{{ ticket['store_name'] }}</p>
                  </div>
                </div>
                <div class="ticket__items">
                  <p class="summa">
                    {{ ticket.amount / 100 | money($i18n.locale) }}
                    <span v-if="ticket['cash']" class="percent">( {{ ticket['cash'] }} %)</span></p>
                  <p class="date"><span>{{ ticket.added_on }}</span></p>
                  <div class="status" v-if="ticket.type === 'tips'">{{ $t('tips') }}</div>
                  <div class="status" v-if="ticket.type === 'bill'">{{ $t('check') }}</div>
                </div>
              </a>
              <p v-if="!endCheckLimit" ref="loadMoreCheck" class="load-more-check w-100 text-center">{{$t('loading')}}</p>
            </template>
            <p v-else class="d-flex justify-content-center mt-0 mb-40 pt-50 f-22">{{ $t("not-found") }}</p>
          </div>
          <loading v-else class="mt-130" :account-class="'payment-loading'"/>
        </div>
      </van-popup>
      <van-popup
          class="account-modal p-10"
          v-model="cardModal"
          position="bottom"
          :style="{ maxHeight: '90%' }"
          closeable
          close-icon="close"
          close-icon-position="top-right"
          @closed="closedCardCheckModal"
      >
        <div class="modal">
          <div class="modal__top d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center" style="height: 35px">
              <h2 class="modal__title">{{ $t("my-cards") }}</h2>
            </div>
          </div>
          <div v-if="!loading" class="modal__cards">
            <template v-if="cards.length">
              <div
                  v-for="card in cards"
                  :key="card.id"
                  class="card-item card-item-green"
              >
                <div @click="cardDelete(card.id)" class="card-delete">{{ $t("delete") }}</div>
                <div class="f-24 font-bold">{{ card['bank'] }}</div>
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <div class="f-16 font-semibold">{{ card.name }}</div>
                    <div class="f-18 font-semibold mt-5">{{ card.pan }}</div>
                  </div>
                  <div v-if="card['ps']"><img :src="`/svg/${card['ps']}.svg`" alt="" width="30" height="44">
                  </div>
                </div>
              </div>
            </template>
            <p v-else class="d-flex justify-content-center mt-0 mb-40 pt-50 f-22">{{ $t("no-card") }}</p>
          </div>
          <loading v-else class="mt-130" :account-class="'payment-loading'"/>
        </div>
      </van-popup>
      <van-popup
          class="account-modal p-10"
          v-model="booksModal"
          position="bottom"
          :style="{ maxHeight: '90%' }"
          closeable
          close-icon="close"
          close-icon-position="top-right"
          @closed="closedCardCheckModal"
      >
        <div class="modal">
          <div class="modal__top d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center" style="height: 35px">
              <h2 class="modal__title">{{ $t("my-books") }}</h2>
            </div>
          </div>
          <template v-if="books.length">
            <div
                v-for="book in books"
                :key="book.id"
                class="book"
                @click="openBook(book.number)"
            >
              <h3 class="mb-10 mt-0">{{ book['username'] }}</h3>
              <div class="d-flex justify-content-between book-content">
                <div>
                  <div class="d-flex align-items-center mb-5">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                      <path d="M180.109-63.326q-30.746 0-54.025-23.447-23.28-23.447-23.28-53.857v-620.022q0-30.844 23.28-54.194 23.279-23.35 54.025-23.35H231.5v-58.717h78.239v58.717h340.522v-58.717H728.5v58.717h51.391q30.845 0 54.194 23.35 23.35 23.35 23.35 54.194v297.87q-18.126-9.243-37.618-14.698-19.491-5.455-39.926-7.356v-92.294H180.109v436.5h323.617q7.187 21.434 18.144 41.01 10.956 19.577 26.391 36.294H180.109Zm566.208 33.26q-83.611 0-141.268-57.785-57.658-57.787-57.658-141.158 0-83.372 57.667-141.149 57.666-57.777 141.277-57.777 83.372 0 141.149 57.786 57.777 57.787 57.777 141.158 0 83.372-57.786 141.149-57.787 57.777-141.158 57.777Zm58.492-94.977 30.713-31.435-76-75.048v-121.322h-43.913v137.019l89.2 90.786Z"/>
                    </svg>
                    <div class="ml-5">{{ book.date }}</div>
                  </div>
                  <div class="d-flex align-items-center mb-5">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                      <path d="M520.478-495.587v-161.261q0-15.186-10.663-25.952-10.662-10.765-26.219-10.765-15.556 0-26.554 10.765-10.999 10.766-10.999 25.952v176.131q0 8.543 2.62 15.461 2.62 6.917 7.859 11.973l139.087 143.087q10.611 12 26.903 12 16.292 0 28.412-12 10.119-10 10.619-26.598.5-16.597-10.739-27.836L520.478-495.587ZM480.309-62.804q-85.188 0-161.857-32.988t-133.165-89.476q-56.496-56.487-89.49-133.144-32.993-76.656-32.993-161.837 0-85.438 33.1-161.463 33.098-76.026 89.66-132.609 56.563-56.583 133.095-89.848 76.533-33.266 161.617-33.266 85.446 0 161.452 33.262 76.005 33.261 132.595 89.85 56.589 56.59 89.85 132.622 33.262 76.032 33.262 161.509 0 85.476-33.266 161.771-33.265 76.294-89.848 132.857-56.583 56.562-132.576 89.66-75.993 33.1-161.436 33.1Z"/>
                    </svg>
                    <div class="ml-5">{{ book.time.slice(0, -3) }}</div>
                  </div>
                </div>
                <div>
                  <div class="d-flex align-items-center mb-5">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                      <path d="M12.109-240.812q0-39.677 19.098-70.846 19.097-31.168 55.098-46.668 74.956-32.739 136.418-47.739t125.821-15q65.177 0 125.969 15 60.791 15 135.791 47.761 35.384 14.934 55.268 46.174 19.885 31.239 19.885 71.239v33.587q0 32.674-22.435 54.989Q640.587-130 607.913-130h-518.5q-32.435 0-54.87-22.315Q12.11-174.63 12.11-207.304v-33.508ZM724.304-130q7.718-17.957 14.435-37.033 6.718-19.076 6.718-40.271v-29.083q0-63.722-29-109.602-29-45.881-93.957-76.837 74.174 8.239 140.38 23.478 66.207 15.239 108.772 37.044 35.761 20.521 56.5 51.597Q948.891-279.63 948.891-241v33.696q0 32.674-22.935 54.989Q903.022-130 871.431-130H724.304ZM348.783-487.152q-72.761 0-117.783-45.022-45.022-45.022-45.022-117.283 0-72.26 45.022-117.782 45.022-45.522 117.283-45.522 72.021 0 117.782 45.409 45.761 45.41 45.761 117.395 0 72.761-45.648 117.783-45.649 45.022-117.395 45.022Zm398.174-162.805q0 72.522-45.519 117.663-45.518 45.142-117.251 45.142-13.796 0-29.622-1.881-15.826-1.88-29.065-6.88 25.239-28.173 37.739-67.16 12.5-38.987 12.5-86.589 0-47.364-12.5-84.59-12.5-37.226-37.739-69.748 13.033-3.75 29.207-6.255 16.173-2.506 29.445-2.506 71.747 0 117.276 45.529 45.529 45.529 45.529 117.275Z"/>
                    </svg>
                    <div class="ml-5">{{ book.guests }}</div>
                  </div>
                  <div class="d-flex align-items-center mb-5">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                      <path d="M619.152-519.152v-250h192.696q30.745 0 54.025 23.279 23.279 23.28 23.279 54.025v172.696h-270Zm-470 327.304q-31.383 0-54.344-22.96-22.96-22.961-22.96-54.344v-422.696q0-30.745 22.96-54.025 22.961-23.279 54.344-23.279h392.696v577.304H149.152Zm157.738-230q24.414 0 41.262-16.931Q365-455.711 365-480.042q0-25.331-16.89-42.221-16.89-16.889-41.304-16.889-25.331 0-42.221 17.181-16.889 17.182-16.889 42.013 0 24.331 16.931 41.221 16.932 16.889 42.263 16.889Zm312.262 230v-250h270v172.696q0 31.383-23.279 54.344-23.28 22.96-54.025 22.96H619.152Z"/>
                    </svg>
                    <div class="ml-5">{{ book.hall_name }}</div>
                  </div>
                </div>
              </div>
              <div class="mb-10">{{ book['comment'] }}</div>
              <a href="" @click.prevent="openBook(book.number)">{{ $t('more-detail') }}</a>
            </div>
            <p v-if="!endCheckLimit" ref="loadMoreCheck" class="load-more-check w-100 text-center">{{$t('loading')}}</p>
          </template>
          <p v-else class="d-flex justify-content-center mt-0 mb-40 pt-50 f-22">{{ $t("not-found") }}</p>
        </div>
      </van-popup>
      <van-popup v-model="alertModal" :style="{ height: '15%', width: '75%', borderRadius: '16px',  padding: '12px' }">
        <h3 class="text-center mt-10 mb-5">{{ $t("is-delete") }}</h3>
        <div :style="{display: 'flex', alignItems: 'flex-end', height: '60%'}">
          <my-auth-btn class="auth-btn auth-save mr-5" :style="{width: '50%', height: '35px'}" @button="cancelDelete">
            {{ $t("cancel") }}
          </my-auth-btn>
          <my-auth-btn class="auth-btn auth-logout ml-5" :style="{width: '50%', height: '35px'}" @button="deleteCard">
            {{ $t("ok") }}
          </my-auth-btn>
        </div>
      </van-popup>
      <check-modal
          :modal="checkModal"
          :check="currentCheck"
          @closed="closedCheck"
      />
      <book-modal
          :modal="bookModal"
          :book-reserve="bookReserve"
          @closed="closedBook"
      />
    </div>
  </div>
</template>

<script>
import Loading from "@/components/menu/Loading.vue";
import AuthModal from "@/components/menu/auth/AuthModal.vue";
import AuthorizationBlock from "@/components/auth/Authorization.vue";
import {Notify, Popup} from "vant";
import Profile from "@/services/profile/service";
import {mapGetters} from "vuex";
import MyAuthBtn from "@/components/auth/UI/MyAuthBtn.vue";
import Check from "@/components/auth/features/Check";
import Book from "@/components/auth/features/Book";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  name: "AccountBlock",
  components: {
    LanguageSwitcher,
    MyAuthBtn,
    Loading,
    AuthModal,
    AuthorizationBlock,
    "van-popup": Popup,
    'check-modal': Check,
    'book-modal': Book,
  },
  data() {
    return {
      phone: "",
      authModal: false,
      cardModal: false,
      ticketModal: false,
      booksModal: false,
      bookModal: false,
      checkModal: false,
      languageModal: false,
      loading: false,
      alertModal: false,
      deleteCardId: null,
      currentCountry: "uz",
      payments: [],
      cards: [],
      books: [],
      keyBooks: 1,
      skip: 0,
      offset: 20,
      total: 0,
      calledObserver: false,
      endCheckLimit: false,
      currentCheck: {},
      bookReserve: null,
    };
  },
  methods: {
    authenticated(data) {
      console.log('data parent', data);
    },
    openCheck(value) {
      this.currentCheck = value;
      this.checkModal = true;
    },
    openBook(number) {
      this.bookModal = true;
      this.bookReserve = number;
    },
    closedCheck() {
      this.checkModal = false;
      this.currentCheck = {}
    },
    closedBook() {
      this.bookModal = false;
      this.bookReserve = null;
    },
    closedCardCheckModal() {
      this.cardModal = false;
      this.ticketModal = false;
      this.booksModal = false;
      this.endCheckLimit = false;
      this.calledObserver = false;
      this.total = 0;
      this.skip = 0;
      this.$nextTick(() => {
        this.cards = [];
        this.payments = [];
        this.books = [];
      });
    },
    closeAuthModal() {
      this.authModal = false;
      if (!this.token) {
        this.$router.go(-1);
      }
    },
    changeCountry(country) {
      this.currentCountry = country;
    },
    openAuthModel() {
      this.authModal = true;
    },
    async openModal(type) {
      this.loading = true;
      switch (type) {
        case "cards":
          this.cardModal = true;
          await this.fetchCards();
          break;
        case "checks":
          this.ticketModal = true;
          await this.fetchPayments();
          break;
        case "books":
          this.booksModal = true;
          await this.fetchReservations();
          break;
      }
      this.loading = false;
    },
    getImage(ticket) {
      if (ticket.type === 'tips') {
        let photo = ticket['employee_photo'];
        if (photo && photo.length) {
          return photo;
        }
        return '/img/superman.png';
      }
      if (ticket.type === 'bill') {
        let logo = ticket['store_logo'];
        if (logo && logo.length) {
          return logo;
        }
        return '/img/nologo.png';
      }
    },
    async fetchPayments() {
      return Profile.getPayments(this.token, this.deviceUuid, {skip: this.skip, offset: this.offset}).then(({data}) => {
        if (data?.data) {
          this.payments.push(...data.data.payments);
          this.total = data.data.total;
          this.loading = false;
          this.endCheckLimit = data.data.end;
          this.calledObserver = false;
          this.$nextTick(() => {
            this.loadMoreChecks();
          });
        }
      })
          .catch(e => console.error(e))
          .finally(() => {
            this.loading = false;
          });
    },
    loadMoreChecks() {
      const callback = (entries) => {
        if (entries[0].isIntersecting) {
          if (!this.calledObserver && !this.endCheckLimit) {
            this.skip += 20;
            if (this.booksModal) this.fetchReservations();
            if (this.ticketModal) this.fetchPayments();
            this.calledObserver = true;
          }
        }
      };

      const observer = new IntersectionObserver(callback, {rootMargin: "0px", threshold: 1.0});
      if (this.$refs.loadMoreCheck) observer.observe(this.$refs.loadMoreCheck);
    },
    fetchCards() {
      return Profile.getCards(this.token, this.deviceUuid).then(({data}) => {
        if (data.data) {
          this.cards = data.data;
          this.total = data.data.total;
          this.loading = false;
        }
      })
          .catch(e => console.error(e))
          .finally(() => {
            this.loading = false;
            this.alertModal = false;
          })
    },
    fetchReservations() {
      return Profile.getReservations(this.token, this.deviceUuid, {skip: this.skip, offset: this.offset}).then(({data}) => {
        if (data.data) {
          this.books.push(...data.data.reservations);
          this.total = data.data.total;
          this.endCheckLimit = data.data.end;
          this.calledObserver = false;
          this.keyBooks++;
          this.$nextTick(() => {
            this.loadMoreChecks();
          });
        }
      })
          .catch(e => console.error(e))
          .finally(() => {
            this.loading = false;
            this.alertModal = false;
          })
    },
    cardDelete(id) {
      this.deleteCardId = id;
      this.alertModal = true;
    },
    cancelDelete() {
      this.deleteCardId = null;
      this.alertModal = false;
    },
    deleteCard() {
      Profile.deleteCard(this.deleteCardId, this.token, this.deviceUuid).then(({data}) => {
        if (data.success) {
          Notify({
            type: "success",
            message: this.$t("deleted")
          });
          this.fetchCards();
        }
      }).catch(e => Notify({
        type: "success",
        message: e
      })).finally(() => {
        this.alertModal = false;
      })
    },
  },
  computed: {
    ...mapGetters(["token", "deviceUuid", "btnColor"])
  }
}
</script>
<style scoped lang="scss">

.load-more-check {
  font-weight: 600;

  &-2 {
    span {
      font-weight: 600;
    }
  }
}

.card-item {
  padding: 22px;
  width: auto;
  height: 165px;
  margin: 10px;
  background: linear-gradient(to left, rgb(41, 191, 155), rgb(15, 174, 174));
  position: relative;

  .card-delete {
    position: absolute;
    right: 6px;
    top: 6px;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    background: linear-gradient(95.73deg, #ea858c -14.53%, #ED5E68 51.05%);
    border-radius: 6px;
    padding: 4px 6px 3.7px;
    text-transform: uppercase;
    color: #FFFFFF;
    width: -moz-max-content;
    width: max-content;
    cursor: pointer;
  }
}

.modal__cards {
  display: flex;
  flex-direction: column;
}

.modal {
  &-back {
    width: 19px;
    height: 19px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-title {
    font-weight: 600;
    line-height: 100%;
    color: #000000;
  }

  &__title {
    font-weight: 600;
  }

  &__top {
    padding: 5px 7px 8px;
  }

  &-filter {
    width: 24px;
    height: 24px;
  }
}

.ticket {
  background: #F5F5F5;
  border-radius: 20px;
  padding: 12px;
  margin-bottom: 8px;

  &__image {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__desc {
    .name {
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #000000;
      margin: 0 0 2px 0;
    }

    .type {
      font-weight: 400;
      font-size: 10px;
      line-height: 100%;
      color: #8E8E93;
      margin: 0;
    }
  }

  &__items {
    text-align: right;

    .summa {
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      color: #000000;
      margin: 0 0 8px 0;

      .percent {
        color: #8E8E93;
      }
    }

    .date {
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      color: #8E8E93;
      margin: 0 0 7px 0;

    }

    .status {
      background: var(--menu-item-color);
      border-radius: 6px;
      padding: 3.5px 6px;
      text-transform: uppercase;
      color: #FFFFFF;
      font-size: 11px;
      text-align: center;
      width: max-content;
      margin-left: auto;
      font-weight: 600;
    }
  }
}

.book {
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;
  &-content {
    max-width: 90%;
  }
}
</style>
