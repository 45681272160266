<template>
  <div v-if="isAllowBrowser">
    <span
      v-if="!recording"
      class="gif-mic"
      style="filter: grayscale(1)"
      :class="hasAudio ? 'cursor-not-allowed' : ''"
      @click="record"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="32" viewBox="0 0 26 25" fill="none">
          <path d="M10.2512 12.1628C8.3014 12.1628 6.71628 10.5777 6.71628 8.62791V4.53488C6.71628 2.58512 8.3014 1 10.2512 1C12.2009 1 13.786 2.58512 13.786 4.53488V8.62791C13.786 10.5777 12.2009 12.1628 10.2512 12.1628ZM10.2512 2.11628C8.91907 2.11628 7.83256 3.20279 7.83256 4.53488V8.62791C7.83256 9.96 8.91907 11.0465 10.2512 11.0465C11.5833 11.0465 12.6698 9.96 12.6698 8.62791V4.53488C12.6698 3.20279 11.5833 2.11628 10.2512 2.11628Z" :fill="getColor"/>
          <path d="M10.2512 14.7674C6.80558 14.7674 4 11.9619 4 8.51628V7.25116C4 6.94605 4.25302 6.69302 4.55814 6.69302C4.86326 6.69302 5.11628 6.94605 5.11628 7.25116V8.51628C5.11628 11.3442 7.42326 13.6512 10.2512 13.6512C13.0791 13.6512 15.386 11.3442 15.386 8.51628V7.25116C15.386 6.94605 15.6391 6.69302 15.9442 6.69302C16.2493 6.69302 16.5023 6.94605 16.5023 7.25116V8.51628C16.5023 11.9619 13.6967 14.7674 10.2512 14.7674Z" :fill="getColor"/>
          <path d="M10.2512 17C9.94605 17 9.69302 16.747 9.69302 16.4419V14.2093C9.69302 13.9042 9.94605 13.6512 10.2512 13.6512C10.5563 13.6512 10.8093 13.9042 10.8093 14.2093V16.4419C10.8093 16.747 10.5563 17 10.2512 17Z" :fill="getColor"/>
      </svg>
    </span>
    <span
      v-else
      class="gif-mic"
      @click="stopRecording"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="32" viewBox="0 0 26 25" fill="none">
          <path d="M10.2512 12.1628C8.3014 12.1628 6.71628 10.5777 6.71628 8.62791V4.53488C6.71628 2.58512 8.3014 1 10.2512 1C12.2009 1 13.786 2.58512 13.786 4.53488V8.62791C13.786 10.5777 12.2009 12.1628 10.2512 12.1628ZM10.2512 2.11628C8.91907 2.11628 7.83256 3.20279 7.83256 4.53488V8.62791C7.83256 9.96 8.91907 11.0465 10.2512 11.0465C11.5833 11.0465 12.6698 9.96 12.6698 8.62791V4.53488C12.6698 3.20279 11.5833 2.11628 10.2512 2.11628Z" :fill="getColor" />
          <path d="M10.2512 14.7674C6.80558 14.7674 4 11.9619 4 8.51628V7.25116C4 6.94605 4.25302 6.69302 4.55814 6.69302C4.86326 6.69302 5.11628 6.94605 5.11628 7.25116V8.51628C5.11628 11.3442 7.42326 13.6512 10.2512 13.6512C13.0791 13.6512 15.386 11.3442 15.386 8.51628V7.25116C15.386 6.94605 15.6391 6.69302 15.9442 6.69302C16.2493 6.69302 16.5023 6.94605 16.5023 7.25116V8.51628C16.5023 11.9619 13.6967 14.7674 10.2512 14.7674Z" :fill="getColor"/>
          <path d="M11.2856 5.41302C11.226 5.41302 11.1591 5.40558 11.0921 5.38326C10.5488 5.18233 9.95349 5.18233 9.41023 5.38326C9.12 5.48744 8.8 5.3386 8.69581 5.04837C8.59163 4.75814 8.74047 4.43814 9.0307 4.33395C9.81953 4.05116 10.6902 4.05116 11.4791 4.33395C11.7693 4.43814 11.9181 4.75814 11.814 5.04837C11.7247 5.27163 11.5088 5.41302 11.2856 5.41302Z" :fill="getColor"/>
          <path d="M10.8465 6.9907C10.7944 6.9907 10.7498 6.98326 10.6977 6.96837C10.4 6.88651 10.0949 6.88651 9.79721 6.96837C9.49954 7.05023 9.19442 6.87163 9.11256 6.57395C9.0307 6.28372 9.2093 5.9786 9.50698 5.89674C9.9907 5.76279 10.5116 5.76279 10.9953 5.89674C11.293 5.9786 11.4716 6.28372 11.3898 6.5814C11.3228 6.82698 11.0921 6.9907 10.8465 6.9907Z" :fill="getColor"/>
          <path d="M10.2512 17C9.94605 17 9.69302 16.747 9.69302 16.4419V14.2093C9.69302 13.9042 9.94605 13.6512 10.2512 13.6512C10.5563 13.6512 10.8093 13.9042 10.8093 14.2093V16.4419C10.8093 16.747 10.5563 17 10.2512 17Z" :fill="getColor"/>
      </svg>
    </span>
  </div>
  <div v-else>
    <svg class="gif-mic" xmlns="http://www.w3.org/2000/svg" width="36" height="32" viewBox="0 0 26 25" fill="none">
      <path d="M10.2512 12.1628C8.3014 12.1628 6.71628 10.5777 6.71628 8.62791V4.53488C6.71628 2.58512 8.3014 1 10.2512 1C12.2009 1 13.786 2.58512 13.786 4.53488V8.62791C13.786 10.5777 12.2009 12.1628 10.2512 12.1628ZM10.2512 2.11628C8.91907 2.11628 7.83256 3.20279 7.83256 4.53488V8.62791C7.83256 9.96 8.91907 11.0465 10.2512 11.0465C11.5833 11.0465 12.6698 9.96 12.6698 8.62791V4.53488C12.6698 3.20279 11.5833 2.11628 10.2512 2.11628Z" :fill="getColor" />
      <path d="M10.2512 14.7674C6.80558 14.7674 4 11.9619 4 8.51628V7.25116C4 6.94605 4.25302 6.69302 4.55814 6.69302C4.86326 6.69302 5.11628 6.94605 5.11628 7.25116V8.51628C5.11628 11.3442 7.42326 13.6512 10.2512 13.6512C13.0791 13.6512 15.386 11.3442 15.386 8.51628V7.25116C15.386 6.94605 15.6391 6.69302 15.9442 6.69302C16.2493 6.69302 16.5023 6.94605 16.5023 7.25116V8.51628C16.5023 11.9619 13.6967 14.7674 10.2512 14.7674Z" :fill="getColor"/>
      <path d="M11.2856 5.41302C11.226 5.41302 11.1591 5.40558 11.0921 5.38326C10.5488 5.18233 9.95349 5.18233 9.41023 5.38326C9.12 5.48744 8.8 5.3386 8.69581 5.04837C8.59163 4.75814 8.74047 4.43814 9.0307 4.33395C9.81953 4.05116 10.6902 4.05116 11.4791 4.33395C11.7693 4.43814 11.9181 4.75814 11.814 5.04837C11.7247 5.27163 11.5088 5.41302 11.2856 5.41302Z" :fill="getColor"/>
      <path d="M10.8465 6.9907C10.7944 6.9907 10.7498 6.98326 10.6977 6.96837C10.4 6.88651 10.0949 6.88651 9.79721 6.96837C9.49954 7.05023 9.19442 6.87163 9.11256 6.57395C9.0307 6.28372 9.2093 5.9786 9.50698 5.89674C9.9907 5.76279 10.5116 5.76279 10.9953 5.89674C11.293 5.9786 11.4716 6.28372 11.3898 6.5814C11.3228 6.82698 11.0921 6.9907 10.8465 6.9907Z" :fill="getColor"/>
      <path d="M10.2512 17C9.94605 17 9.69302 16.747 9.69302 16.4419V14.2093C9.69302 13.9042 9.94605 13.6512 10.2512 13.6512C10.5563 13.6512 10.8093 13.9042 10.8093 14.2093V16.4419C10.8093 16.747 10.5563 17 10.2512 17Z" :fill="getColor"/>
    </svg>
  </div>
</template>

<script>
import {Toast} from 'vant';

export default {
  name: "AudioRecorder",
  props: {
    installedAudio: {
      type: Boolean,
      default: false
    },
    timeExpired: {
      type: Boolean,
      default: false
    },
    getColor: {
      type: String,
      default: '#1fb7a4'
    },
  },
  data() {
    return {
      hasAudio: false,
      recording: false,
      state: ['Initial', 'Record'],
      stateIndex: 0,
      chunks: [],
      audioUrl: '',
      mediaRecorder: null,
      expiredTime: false,
      isAllowBrowser: true,
    }
  },
  methods: {
    clickImg() {
      Toast('Разрешение браузера отклонено');
    },
    record(e) {
      if (this.hasAudio) {
        e.stopPropagation();
        return;
      }
      this.stateIndex = 1
      this.mediaHandle()
    },
    stopRecording() {
      this.stateIndex = 2
      this.mediaRecorder.stop();
      this.$emit("stop")
      this.recording = false;
    },
    mediaHandle() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        console.log('mediaDevices supported..')

        navigator.mediaDevices.getUserMedia({
          audio: true
        }).then(stream => {
          this.mediaRecorder = new MediaRecorder(stream)
          this.mediaRecorder.ondataavailable = (e) => {
            this.chunks.push(e.data)
          }

          this.mediaRecorder.start()
          this.$emit("start")
          this.recording = true;
          this.mediaRecorder.onstop = () => {
            const blob = new Blob(this.chunks, {'type': 'audio/mp3'})
            this.chunks = []
            this.audioURL = window.URL.createObjectURL(blob)
            this.$emit("audio-url", this.audioURL)
            this.$emit("send-audio", blob)
            // console.log('ul', this.audioURL)
            stream.getTracks().forEach(tr => tr.stop())
          }
        }).catch(error => {
          this.isAllowBrowser = false;
          console.log('Following error has occured : ', error)
        })
      }
    }
  },
  mounted() {
    // this.mediaHandle();
  },
  watch: {
    installedAudio(value) {
      this.hasAudio = value;
    },
    timeExpired(value) {
      if (value) {
        this.stopRecording();
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
