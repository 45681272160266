<template>
  <div
    ref="text"
    class="truncate"
    :style="styles"
    @mouseenter="onEnter"
    @mouseleave="onLeave"
    v-html="displayText"
  />
</template>

<script>
export default {
  props: {
    text: {
      type: [String, Array],
      required: true,
    },
    speed: {
      type: Number,
      default: 200,
    },
    cursor: {
      type: String,
      default: "help",
    },
    color: {
      type: String,
      default: "#000000",
    },
  },
  data: () => ({
    displayText: "",
    interval: null,
  }),
  computed: {
    styles() {
      return {
        cursor: this.interval ? this.cursor : "default",
        color: this.color,
      };
    },
  },
  watch: {
    text: {
      immediate: true,
      handler: function () {
        if (!this.interval) this.resetText();
      },
    },
  },
  mounted() {
    this.onEnter();
  },
  methods: {
    onEnter() {
      if (this.interval || !this.isTruncated()) return;
      this.interval = setInterval(() => {
        if (!this.isTruncated()) return clearInterval(this.interval);
        this.displayText = this.displayText.slice(1);
        while (this.displayText.charAt() === " ") {
          this.displayText = this.displayText.slice(1);
        }
      }, this.speed);
    },
    onLeave() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = false;
      }
      this.resetText();
    },
    isTruncated() {
      return this.getTruncatedDistance() > 0;
    },
    getTruncatedDistance() {
      if (!this.$refs.text) return 0;
      return this.$refs.text.scrollWidth - this.$refs.text.clientWidth;
    },
    resetText() {
      this.displayText = this.text;
    },
  },
};
</script>

<style scoped>
.truncate {
  width: 100%;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
