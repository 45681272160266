<template>
  <div class="success-wrap">
    <div v-if="reviewSuccess" class="white">
      <div class="label text-center">{{ $t("thank-you") }}</div>
      <svg
          class="bgsvg-success payment"
          width="375"
          height="375"
          viewBox="0 0 375 404"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M442.401 -30L187.692 224.709L75.6015 112.619L-14 202.223L187.692 403.91L532 59.6015L442.401 -30Z"
            fill="#F0F0F0"
        />
      </svg>
    </div>
    <div v-else>
      <div class="white" v-if="payment['status'] === 'success'">
        <div class="label text-center">{{ $t("thank-you") }}</div>
      </div>
      <div v-if="payment['status'] === 'success'">
        <svg
            class="bgsvg-success payment"
            width="375"
            height="375"
            viewBox="0 0 375 404"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M442.401 -30L187.692 224.709L75.6015 112.619L-14 202.223L187.692 403.91L532 59.6015L442.401 -30Z"
              fill="#F0F0F0"
          />
        </svg>
      </div>
      <div
        v-if="payment['status'] === 'success'"
        class="receipts-wrapper text-center font-semibold mt-auto mb-30 z-index-2"
      >
        <a v-if="payment['status'] === 'success' && payment['receipt_url']" class="f-14 black" href="#" @click="openWindow(payment['receipt_url'])"
          ><img src="/svg/download1.svg" alt="" /><br />{{
            $t("download-check")
          }}</a
        >
        <a
          class="f-14 black"
          target="_blank"
          @click="openWindow(payment['tax_receipt_url'])"
          v-if="payment['tax_receipt_url']"
          href="#"
          ><img src="/svg/download1.svg" alt="" /><br />{{
            $t("download-fiscal")
          }}</a
        >
      </div>
      <div v-else-if="(payment['status'] === 'new' || payment['status'] === 'draft')">
        <div v-if="paymentProcessing" class="payment-processing">
          <div class="payment-processing-icon">
            <van-loading size="80" color="#006867" vertical>{{ $t('draft-payment-title') }}</van-loading>
            <van-icon name="underway" color="#006867" size="40" />
          </div>
          <div class="payment-processing-title">{{ $t('draft-payment-text') }}</div>
        </div>
        <div v-else class="payment-processing">
          <div class="payment-processing-icon">
            <van-icon v-if="payment['status'] === 'success'" name="success" color="#006867" size="80" />
            <van-icon v-else-if="payment['status'] === 'error' || payment['status'] === 'revert'" name="cross" color="#6F6F6F" size="80" />
            <div v-else>
              <van-loading v-if="statusLoading" color="#006867" size="90" />
              <div class="fail-icon" v-else>
                <van-icon name="fail" color="#6F6F6F" size="50" />
                <van-icon name="circle" color="#6F6F6F" size="90" />
              </div>
            </div>
          </div>
          <div class="payment-processing-title" v-if="payment['status'] === 'success'">{{ $t('success-payment-title') }}</div>
          <div class="payment-processing-title" v-else-if="payment['status'] === 'error' || payment['status'] === 'revert'">{{ $t('error-payment-title') }}</div>
          <div class="payment-processing-title" v-else>
            <p v-if="!statusLoading">{{ $t('retry-payment-title') }}</p>
            <div v-if="!statusLoading">
              <van-button
                  @click="reloadStatus"
                  type="primary"
                  icon="replay"
                  class="payment-reload-button"
              >{{ $t('reload-payment-status') }}
              </van-button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mb-50">
        <van-empty
          class="px-0 mb-50 text-center"
          image="error"
          :description="$t('error-payment-text')"
        />
      </div>
    </div>
    <p class="f-12 text-center copyright-text-z-index mt-50">
      © ООО "Multicard Payment", {{ year }}
    </p>
  </div>
</template>

<script>
import {useUserAgent} from "@/helpers/useUserAgent";
import {Empty, Icon, Loading, Button} from "vant";
import Service from "@/services/api/service";
export default {
  name: "SuccessBlock",
  components: {
    "van-loading": Loading,
    "van-button": Button,
    "van-empty": Empty,
    "van-icon": Icon,
  },
  props: {
    transferInfo: {
      type: Object,
      default: () => ({}),
    },
    reviewSuccess: {
      type: Boolean,
      default: false,
    },
    returnUrl: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      statusLoading: false,
      paymentProcessing: false,
      payment: {}
    }
  },
  mounted() {
    this.payment = this.transferInfo
    if (this.returnUrl) {
      setTimeout(() => {
        window.location.href = this.returnUrl
      }, 5000)
    }
    if (this.payment?.status !== 'success'
        || this.payment?.status !== 'error'
        || this.payment?.status !== 'revert') {
      this.paymentProcessing = true
      setTimeout(() => {
        Service.paymentStatus(this.payment?.id, this.payment?.payment_system).then((res) => {
          this.payment = res.data?.data?.['payment_info']
        })
        .finally(() => {
          this.paymentProcessing = false;
        })
      }, 5000)
    }
  },
  methods: {
    openWindow(url) {
      if (this.useragent === 'multicard')
        window.flutter_inappwebview.callHandler('browser', { url })
      else
        window.open(url, '_blank')
    },
    reloadStatus() {
      this.statusLoading = true;
      Service.paymentStatus(this.payment?.id, this.payment?.payment_system).then((res) => {
        this.payment = res.data?.data?.['payment_info']
      })
      .finally(() => {
        this.statusLoading = false;
      })
    }
  },
  computed: {
    year() {
      return (new Date()).getFullYear()
    },
    useragent() {
      return useUserAgent().getUserAgent()
    },
  },
  watch: {
    payment(value) {
      if (value.status === 'success' && value['return_url']) {
        setTimeout(() => {
          window.location.href = value['return_url']
        }, 5000)
      }
    }
  }
};
</script>

<style scoped>
.payment-processing {
  text-align: center;
  position: relative;
  padding: 20px;
}
.payment-processing .van-icon-underway{
  position: absolute;
  top: 40px;
  left: 50%;
  margin-left: -20px;
}
.payment-processing .van-loading__text{
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}
.payment-processing-title {
  max-width: 70%;
  margin: 0 auto 100px;
  text-align: center;
  font-size: 14px;
  color: #6F6F6F;
}
.payment-reload-button {
  border: 1px solid #ccc;
  padding: 6px 18px;
  border-radius: 6px;
  background: none;
  color: #666;
}
.fail-icon {
  position: relative;
}
.fail-icon .van-icon-fail {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}
</style>
