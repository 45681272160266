<template>
  <div class="header-marketplace__search">
    <input id="searchInput" type="text" v-model.trim="searching" :placeholder="$t('marketplace.search-food')"/>
    <img src="/svg/search.svg" width="22" height="22" alt=""/>
    <i v-if="searching" @click="searching = ''" class="van-icon van-icon-cross"
       style="color: rgb(124, 124, 124); top: 14px"></i>
  </div>
</template>

<script>
export default {
  name: "SearchItem",
  data: function () {
    return {
    }
  },
  computed: {
    searching: {
      get() {
        return this.$store.getters["queryParams"].name;
      },
      set(value) {
        return this.$store.commit("SET_MARKET_SEARCH", value);
      }
    }
  },
  watch: {
    searching(value) {
      this.$emit("search-update", value);
    },
  }
}
</script>

<style scoped>

</style>
