<template>
  <header class="header-marketplace">
    <header-top
      v-if="false"
      :search-food-same="searchFood"
      @delivery-modal="$emit('show-delivery-address')"
      @search-update="$emit('search-update', $event)"
    />
    <header-bottom
      v-if="true"
      :key="searchKey"
      :detail-page="false"
      :is-main-page="mainPage"
      :search-food-same="searchFood"
      @search-update="$emit('search-update', $event)"
      :class="zIndexValue === 2 ? 'd-none' : ''"
    />
  </header>
</template>

<script>
import HeaderTop from "@/components/marketplace/header/HeaderTop";
import HeaderBottom from "@/components/marketplace/header/HeaderBottom";

export default {
  name: "HeaderBlock",
  components: {HeaderBottom, HeaderTop},
  props: {
    search: {
      type: String,
      default: "",
    },
    searchKey: {
      type: Number,
      default: 0
    },
    isMainPage: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      searchFood: "",
      action: false,
      mainPage: false,
      zIndexValue: 4
    };
  },
  methods: {
    changeZIndex(value) {
      this.zIndexValue = value ? 2 : 4;
    }
  },
  created() {
    this.mainPage = this.isMainPage;
  },
  watch: {
    search(value) {
      this.searchFood = value;
    },
  },
};
</script>

<style scoped></style>
