<template>
  <div class="order" :style="{'--menu-item-color': btnColor}">
    <h2 class="mt-0 order-title">{{ $t('marketplace.order') }}</h2>
    <loading-block v-if="orderLoading"></loading-block>
    <div v-else-if="order[0]?.data?.items?.length && currentDesk">
      <div v-for="item in order" :key="item.id" class="cart-list">
        <li v-for="(product, index) in item.data.items" :key="index">
          <div class="cart-item-image">
            <i :style="getBackground(getImage(product, 'small'))"></i>
          </div>
          <div class="cart-item-description">
            <div v-if="item" class="cart-description-left">
              <div class="cart-item-title">{{ product.name }}</div>
              <div class="cart-item-title-hint">
                {{ $t('menu.quantity') }}: {{ product.count }}
              </div>
              <div class="cart-item-title-hint" v-if="product.weight">
                {{ product.weight }} {{ product.unit }}
              </div>
            </div>
            <div class="cart-description-right">
              <div v-if="product.price" class="price">
                {{ product.price * product.count | money($i18n.locale) }}
              </div>
            </div>
          </div>
        </li>
        <li class="flex-column justify-content-end">
          <div v-if="item.data?.discounts?.length">
            <div v-for="(discount, index) in item.data.discounts" :key="index">
              <div class="w-100 text-right">{{ discount.name }}: <b>{{ Math.abs(discount.price) | money($i18n.locale) }}</b></div>
            </div>
          </div>
          <div class="w-100 text-right">
            <p><b>{{ $t('cheque-details-total') }} {{ item.data['orderSum'] | money($i18n.locale) }}</b></p>
          </div>
        </li>
      </div>
      <div class="order-buttons d-flex flex-row justify-content-between pt-25 pb-50">
        <van-button
            type="info"
            @click="addProducts"
            class="add-dishes-btn"
        >{{ $t('menu.add-dishes') }}</van-button>
        <van-button
            :loading="deleteLoading"
            type="warning"
            @click="deleteOrder"
            class="delete-order-btn"
        >{{ $t('menu.delete-order') }}</van-button>
        <van-button
            :loading="closeLoading"
            type="primary"
            @click="closeOrder"
            class="create-order-btn"
        >{{ $t('menu.close-bill') }}</van-button>
      </div>
    </div>
    <div v-else class="text-center d-flex flex-column align-items-center justify-content-center" style="height: 80vh">
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4_47)">
          <mask id="mask0_4_47" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="1" y="0" width="64" height="64">
            <path d="M65 0H1V64H65V0Z" fill="white"/>
          </mask>
          <g mask="url(#mask0_4_47)">
            <path d="M55.6017 59.1977L60.9813 53.8436L59.3558 52.214L55.6017 55.8999L54.0924 54.3867L52.4669 56.0551L55.6017 59.1977ZM37.6442 40.5357H56.221V38.2078H37.6442V40.5357ZM56.7047 63C54.7052 63 52.9958 62.2822 51.5768 60.8467C50.1577 59.4111 49.4482 57.7105 49.4482 55.7447C49.4482 53.7159 50.1576 51.9866 51.5764 50.5567C52.9952 49.1267 54.7111 48.4118 56.7241 48.4118C58.7108 48.4118 60.4201 49.1267 61.8521 50.5567C63.284 51.9866 64 53.7159 64 55.7447C64 57.7105 63.284 59.4111 61.8521 60.8467C60.4201 62.2822 58.7043 63 56.7047 63ZM33 61.4481V34.3279C33 33.6877 33.2274 33.1397 33.6821 32.6838C34.1369 32.2279 34.6835 32 35.3221 32H58.5431C59.1816 32 59.7283 32.2279 60.1831 32.6838C60.6378 33.1397 60.8652 33.6877 60.8652 34.3279V47.015C60.4996 46.8377 60.1244 46.6898 59.7395 46.5716C59.3547 46.4534 58.9559 46.3554 58.5431 46.2778V34.3279H35.3221V57.5682H47.3196C47.394 58.0382 47.5094 58.4855 47.6657 58.9101C47.822 59.3347 48.0033 59.7539 48.2097 60.1677L46.9326 61.4481L44.6105 59.1202L42.2884 61.4481L39.9663 59.1202L37.6442 61.4481L35.3221 59.1202L33 61.4481ZM37.6442 53.7272H47.4075C47.5037 53.3133 47.6099 52.9124 47.726 52.5244C47.8421 52.1364 48.0033 51.7614 48.2097 51.3992H37.6442V53.7272ZM37.6442 47.1314H52.5056C53.0732 46.8469 53.6667 46.6206 54.2859 46.4524C54.9051 46.2843 55.5501 46.1744 56.221 46.1227V44.8035H37.6442V47.1314Z" :fill="btnColor"/>
            <path d="M23.6667 48H5C2.79467 48 1 46.2053 1 44C1 43.7707 1.024 43.568 1.056 43.344L9.07733 3.20533C9.44533 1.34933 11.0933 0 13 0H42.3333C44.24 0 45.888 1.34933 46.256 3.208L50.8933 26.4053C51.0373 27.128 50.5707 27.8293 49.8453 27.9733C49.1547 28.12 48.424 27.6507 48.2773 26.928L43.64 3.728C43.5173 3.112 42.968 2.66667 42.3333 2.66667H13C12.3653 2.66667 11.816 3.112 11.6933 3.72267L3.69333 43.7253C3.68267 43.8133 3.66667 43.9013 3.66667 44C3.66667 44.736 4.26667 45.3333 5 45.3333H23.6667C24.4027 45.3333 25 45.9307 25 46.6667C25 47.4027 24.4027 48 23.6667 48Z" fill="#BABAC0"/>
            <path d="M60.2507 28C59.6293 28 59.0747 27.5627 58.9467 26.928L54.3093 3.728C54.184 3.112 53.6347 2.66667 53 2.66667H42.3333C41.5973 2.66667 41 2.06933 41 1.33333C41 0.597333 41.5973 0 42.3333 0H53C54.9067 0 56.5547 1.34933 56.9227 3.208L61.56 26.4053C61.704 27.128 61.2373 27.8293 60.512 27.9733C60.4267 27.992 60.3387 28 60.2507 28Z" fill="#BABAC0"/>
            <path d="M27.6666 23.9999C21.784 23.9999 17 19.2159 17 13.3333V6.66659C17 5.93059 17.5973 5.33325 18.3333 5.33325C19.0693 5.33325 19.6667 5.93059 19.6667 6.66659V13.3333C19.6667 17.7439 23.256 21.3333 27.6666 21.3333C32.0773 21.3333 35.6666 17.7439 35.6666 13.3333V6.66659C35.6666 5.93059 36.264 5.33325 37 5.33325C37.736 5.33325 38.3333 5.93059 38.3333 6.66659V13.3333C38.3333 19.2159 33.5493 23.9999 27.6666 23.9999Z" fill="#BABAC0"/>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_4_47">
            <rect width="64" height="64" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <p :style="{fontSize: '18px', marginBottom: '35px'}">{{ $t('menu.order-is-empty') }}</p>
    </div>
    <navigation-menu />
  </div>
</template>

<script>

import NavigationMenu from "@/components/menu/NavigationMenu.vue";
import {mapActions, mapGetters} from "vuex";
import {Button, Notify} from 'vant';
import Menu from '@/services/menu/service'
import LoadingBlock from "@/components/Loading.vue";

export default {
  name: "OrderPage",
  data() {
    return {
      orderLoading: false,
      closeLoading: false,
      deleteLoading: false
    }
  },
  components: {
    LoadingBlock,
    "van-button": Button,
    NavigationMenu
  },
  mounted() {
    this.orderLoading = true
    this.getOrder(this.$route.params.desk).finally(() => {
      this.orderLoading = false
    })
  },
  methods: {
    ...mapActions(["setAddDishes", "flushOrder", "getOrder"]),
    addProducts() {
      this.setAddDishes(true);
      this.$router.push(this.$store.state.navigationMenuHomeRoute)
    },
    deleteOrder() {
      this.deleteLoading = true
      Menu.deleteOrder(this.$route.params.desk, this.$store.state.token, this.$store.state.deviceUuid).then((res) => {
        if (res.data.data.deleted) {
          Notify({
            type: 'success',
            message: 'Заказ удален',
          })
        } else {
          Notify({
            type: 'success',
            message: 'Не удалось удалить заказ',
          })
        }
      }).finally(() => {
        this.deleteLoading = false
        this.orderLoading = true
        this.getOrder(this.$route.params.desk).finally(() => {
          this.orderLoading = false
        })
      })
    },
    closeOrder() {
      this.closeLoading = true
      Menu.closeBill(this.$route.params.desk, this.$store.state.token, this.$store.state.deviceUuid).then((res) => {
        if (res.data.data.closed) {
          Notify({
            type: 'success',
            message: 'Запрос выполнен успешно',
          })
          window.location.href = res.data.data.url
        } else {
          Notify({
            type: 'success',
            message: 'Не удалось закрыть счет',
          })
        }
        // this.flushOrder()
        this.setAddDishes(false)
      }).finally(() => {
        this.closeLoading = false
      })
    },
    getBackground(image) {
      return `background-image: url('${image}')`;
    },
    getImage(item, size) {
      let image = "";
      if (!item.image) return image = '/img/dummy.png';
      if (size === "big") image = item['bigImageUrl'];
      if (size === "small") image = item['imageUrl'];
      if (image) image = image.replace("http:", "https:");
      return image;
    },
  },
  computed: {
    ...mapGetters(["btnColor", "order"]),
    currentDesk() {
      return this.order[0].desk === this.$route.params.desk
    }
  }
};
</script>

<style scoped lang="scss">
.order {
  padding: 16px 16px 20px;
}

.order-title {
  position: relative;
  font-weight: 600;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -15px;
    width: 100%;
    height: 1px;
    background-color: #ececec;
  }
}
.cart-list {

  li {
    padding: 12px 0;
    overflow: hidden;
    border-bottom: 1px solid #F5F5F5;
    display: flex;
    gap: 12px;
  }

  .cart-item-image {

    i {
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      display: block;
      width: 80px;
      height: 80px;
      border-radius: 12px;
    }
  }

  .cart-item-description {
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    width: 100%;
    gap: 10px;

    .cart-description-left {
      display: flex;
      flex-direction: column;
      //justify-content: space-between;
      align-items: flex-start;
    }

    .cart-description-right {
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .cart-item-delete {
      float: right;
      color: #cccccc;
      margin: 2px;
    }

    .cart-item-title {
      font-family: "Gilroy", sans-serif;
      font-style: normal;
      font-size: 13px;
      line-height: 14px;
      float: left;
      color: #000000;
      font-weight: 600;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 5px;

      &-hint {
        font-family: "Gilroy", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        color: #949494;
        clear: both;
        margin-bottom: 5px;
      }
    }

    .cart-stepper {
      clear: both;
      background: #F5F5F5;
      float: left;
      border-radius: 26px;
      padding: 5px 8px;

      .van-stepper__input,
      .van-stepper__minus,
      .van-stepper__plus,
      .van-stepper__minus--disabled,
      .van-stepper__plus--disabled {
        background: #F5F5F5;
      }
    }

    .price {
      font-family: "Gilroy", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      text-align: right;
      color: #000000;
      white-space: nowrap;
    }

    .cart-modifiers {
      span {
        font-size: 12px;
        color: #949494;
        font-weight: 500;
      }
    }
  }
}
</style>

