import Vue from "vue";

Vue.filter("money", (value, locale) => {
  if (value === null) value = 0;
  if (!value) value = 0;
  let amount = "";
  if (locale === "ru") amount = " сум";
  if (locale === "uz") amount = " so'm";
  if (locale === "en") amount = " sum";

  return (
    parseInt(value)
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + amount
  );
});

Vue.filter("weight", (value, locale) => {
  if (value === null) value = 0;
  if (!value) value = 0;
  let suffix = "";
  if (locale === "ru") suffix = " г";
  if (locale === "uz") suffix = " gr";
  if (locale === "en") suffix = " gr";
  return value + suffix;
});

Vue.filter("cardFormatter", (pan) => {
  if (pan && typeof pan === "string" && pan.length === 16) {
    const match = pan.match(/.{2}/g);
    return (
      match[0] + match[1] + " " + match[2] + "** **** " + match[6] + match[7]
    );
  }

  return pan;
});

Vue.filter("photo", function (value) {
  if (value === null || value === undefined || value === "") {
    return "/img/superman.png";
  } else {
    return value;
  }
});

Vue.filter("logo", function (value) {
  if (value === null || value === undefined || value === "") {
    return "/img/nologo.png";
  } else {
    return value;
  }
});

Vue.filter("phone", function (value, country) {
  if (value === null || value === undefined || value === "") {
    return "";
  } else {
    if (country === "uz") {
      let cleaned = ("" + value).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/);
      if (match) {
        return (
          "+(" +
          match[1] +
          " " +
          match[2] +
          ") " +
          match[3] +
          "-" +
          match[4] +
          "-" +
          match[5]
        );
      }
      return value;
    }
    return value;
  }
});
