<template>
  <div class="bill-header-wrapper" >
    <div class="relative grey_bg" :style="{
      background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 14.87%, rgba(0, 0, 0, 0.01) 99.78%),
      linear-gradient(180deg, rgba(0, 0, 0, 0.00) 12.98%, rgba(0, 0, 0, 0.50) 83.52%, rgba(0, 0, 0, 0.50) 99.79%),
      url(${store.background_url}) center / contain no-repeat,
      lightgray -27.255px -1.304px / 113.337% 100.573% no-repeat`,
      backgroundSize: 'cover'
    }">
      <div class="brand">
        <svg xmlns="http://www.w3.org/2000/svg" width="68" height="69" viewBox="0 0 68 69" fill="none">
          <g filter="url(#filter0_bdd_355_3458)">
            <rect x="19" y="16.6094" width="30" height="30" rx="8" fill="url(#paint0_linear_355_3458)"
                  shape-rendering="crispEdges"/>
            <path
                d="M30.2589 30.1096L29.4489 28.9396H28.5549V30.1096H27.5829V25.9096H29.4009C29.7729 25.9096 30.0949 25.9716 30.3669 26.0956C30.6429 26.2196 30.8549 26.3956 31.0029 26.6236C31.1509 26.8516 31.2249 27.1216 31.2249 27.4336C31.2249 27.7456 31.1489 28.0156 30.9969 28.2436C30.8489 28.4676 30.6369 28.6396 30.3609 28.7596L31.3029 30.1096H30.2589ZM30.2409 27.4336C30.2409 27.1976 30.1649 27.0176 30.0129 26.8936C29.8609 26.7656 29.6389 26.7016 29.3469 26.7016H28.5549V28.1656H29.3469C29.6389 28.1656 29.8609 28.1016 30.0129 27.9736C30.1649 27.8456 30.2409 27.6656 30.2409 27.4336Z"
                fill="white"/>
            <path
                d="M34.761 29.2096H32.811L32.439 30.1096H31.443L33.315 25.9096H34.275L36.153 30.1096H35.133L34.761 29.2096ZM34.455 28.4716L33.789 26.8636L33.123 28.4716H34.455Z"
                fill="white"/>
            <path
                d="M40.4408 25.9096V30.1096H39.4688V28.3876H37.5608V30.1096H36.5888V25.9096H37.5608V27.5656H39.4688V25.9096H40.4408Z"
                fill="white"/>
            <path
                d="M31.4196 37.4168L31.4136 34.8968L30.1776 36.9728H29.7396L28.5096 34.9508V37.4168H27.5976V33.2168H28.4016L29.9736 35.8268L31.5216 33.2168H32.3196L32.3316 37.4168H31.4196Z"
                fill="white"/>
            <path
                d="M36.094 36.5168H34.144L33.772 37.4168H32.776L34.648 33.2168H35.608L37.486 37.4168H36.466L36.094 36.5168ZM35.788 35.7788L35.122 34.1708L34.456 35.7788H35.788Z"
                fill="white"/>
            <path d="M38.5808 34.0088H37.2368V33.2168H40.8968V34.0088H39.5528V37.4168H38.5808V34.0088Z" fill="white"/>
          </g>
          <defs>
            <filter id="filter0_bdd_355_3458" x="0" y="0.609375" width="68" height="68" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5"/>
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_355_3458"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                             result="hardAlpha"/>
              <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect2_dropShadow_355_3458"/>
              <feOffset dy="3"/>
              <feGaussianBlur stdDeviation="10"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.941667 0 0 0 0 0.941667 0 0 0 0 0.941667 0 0 0 0.1 0"/>
              <feBlend mode="normal" in2="effect1_backgroundBlur_355_3458" result="effect2_dropShadow_355_3458"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                             result="hardAlpha"/>
              <feOffset dy="1"/>
              <feGaussianBlur stdDeviation="2.5"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
              <feBlend mode="normal" in2="effect2_dropShadow_355_3458" result="effect3_dropShadow_355_3458"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_355_3458" result="shape"/>
            </filter>
            <linearGradient id="paint0_linear_355_3458" x1="17.2119" y1="-13.3906" x2="43.16" y2="-10.7874"
                            gradientUnits="userSpaceOnUse">
              <stop stop-color="#29BF9B"/>
              <stop offset="0.826058" stop-color="#0BABAB"/>
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="d-flex align-items-center">
        <div class="vendor-logo">
          <div
              v-if="logo"
              :style="getLogo"
              class="store-logo"
          ></div>
          <div
              v-else-if="store && store.logo_url"
              :style="getLogoUrl"
              class="store-logo"
          ></div>
        </div>
        <div class="info">
          <div class="title" :class="{'mb-0': !store?.name}">{{ name ?? store.name }}</div>
          <span v-if="description" class="address d-flex align-items-center">
            {{ description }}
          </span>
          <span v-else-if="store?.address" class="address d-flex align-items-center">
            <svg data-v-28dcf1f8="" xmlns="http://www.w3.org/2000/svg" fill="#fff" height="18" viewBox="0 -960 960 960"
                 width="18" style="min-width: 18px; margin-right: 5px;">
              <path data-v-28dcf1f8=""
                    d="M479.693-86.043q-11.693 0-24.334-4t-22.598-13q-46.478-39.522-97.601-90.748-51.123-51.227-93.739-109.5-42.617-58.274-70.617-121.69-28-63.416-28-129.258 0-151.564 100.398-247.88Q343.599-898.435 480-898.435t236.918 96.316q100.517 96.316 100.517 247.88 0 65.842-28.12 129.258-28.119 63.416-70.736 121.69-42.616 58.273-93.739 109.5-51.123 51.226-96.362 90.748-11.228 9-24.16 13t-24.625 4Zm.44-398.718q31.106 0 53.226-22.133 22.119-22.132 22.119-53.239 0-31.106-22.252-53.226-22.252-22.119-53.359-22.119-31.106 0-53.106 22.252t-22 53.359q0 31.106 22.133 53.106 22.132 22 53.239 22Z"></path></svg>
            {{ store.address }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BillHeaderBlock",
  props: {
    logo: {
      type: String,
      default: "",
    },
    address: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  components: {
  },
  computed: {
    ...mapState(["store"]),
    hideLogo() {
      return this.store?.['hide_logo'] ?? false;
    },
    getColor() {
      return this.store?.['button_color'] ?? "#1fb7a4";
    },
    getLogoUrl() {
      return this.getBackground(this.store?.logo_url)
    },
    getLogo() {
      return this.getBackground(this.logo)
    }
  },
  methods: {
    getBackground(logo) {
      if (logo === null || logo === undefined || logo === "") {
        logo = "/img/nologo.png";
      }
      return 'background-image: url("' + logo + '");'
    }
  }
};
</script>

<style scoped>
.logos {
  margin-bottom: 35px;
}
.user .logo-bg-img {
  margin-top: -10px;
  width: 120px;
  height: 120px;
  border-radius: 120px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff;
}
</style>
