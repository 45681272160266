<template>
  <div class="d-flex w-100 p20">
    <div class="d-flex flex-column w-100">
      <table v-if="(serviceCommissionPrice || tips || delivery)" class="table details-table mb-20 w-100">
        <tbody>
          <tr v-if="total">
            <td class="align-left border-bottom">{{ $t("check") }}</td>
            <td class="align-right border-bottom font-bold">
              <span>{{ total | money($i18n.locale) }}</span>
            </td>
          </tr>
          <tr v-if="tips">
            <td class="align-left border-bottom">{{ $t("tips") }}</td>
            <td class="align-right border-bottom font-bold">
              <span>{{ tips | money($i18n.locale) }}</span>
            </td>
          </tr>
          <tr v-if="serviceCommissionPrice">
            <td class="align-left border-bottom">{{ $t("service-commission") }}</td>
            <td class="align-right border-bottom font-bold">
              <span>{{ serviceCommissionPrice | money($i18n.locale) }}</span>
            </td>
          </tr>
          <tr v-if="delivery">
            <td class="align-left border-bottom">{{ $t("delivery-payment") }}</td>
            <td class="align-right border-bottom font-bold">
              {{ delivery | money($i18n.locale) }}
            </td>
          </tr>
        </tbody>
      </table>
      <p class="label-total">{{ $t("total-payment") }}</p>
      <p :style="{ color }" class="price-total">
        <span>{{ total + tips + serviceCommissionPrice + delivery | money($i18n.locale) }}</span>
      </p>
      <div v-if="!otp">
        <div class="label text-center mt-10 mb-20">
          {{ $t("enter-card-data") }}
        </div>
        <the-mask
          v-model="cardNumber"
          name="cc-number"
          autocomplete="cc-number"
          x-autocompletetype="cc-number"
          type="tel"
          class="form-control mb-20"
          inputmode="number"
          mask="#### #### #### ####"
          :placeholder="$t('card-number')"
        />
        <the-mask
          v-model="cardDate"
          type="tel"
          name="cc-date"
          autocomplete="cc-date"
          x-autocompletetype="cc-date"
          class="form-control"
          inputmode="number"
          :placeholder="$t('card-expiry-date')"
          mask="##/##"
        />
        <div class="text-center mt-30 pb-30">
          <van-button
            :loading="loadingCard"
            :disabled="cardNumber.length < 16 || cardDate.length < 4"
            @click="sendOtp"
            style="width: 200px"
            :color="color"
            type="primary"
            >{{ $t("next") }}</van-button
          >
        </div>
      </div>
      <div v-else>
        <div class="label text-center mb-25">
          {{ $t("enter-code-from-sms") }}
        </div>
        <input
            class="otp"
            ref="otpInput"
            type="number"
            v-model="otpCode"
            :style="{ textIndent: otpCode.length ? '20px' : '0' }"
            @input="handleOnChange"
        />
        <div class="buttons-with-padding">
          <van-button
            @click="otp = false"
            class="back-button"
            type="primary"
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 13 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.70711 0.292893C9.09763 0.683417 9.09763 1.31658 8.70711 1.70711L2.41421 8L8.70711 14.2929C9.09763 14.6834 9.09763 15.3166 8.70711 15.7071C8.31658 16.0976 7.68342 16.0976 7.29289 15.7071L0.292893 8.70711C-0.0976311 8.31658 -0.0976311 7.68342 0.292893 7.29289L7.29289 0.292893C7.68342 -0.0976311 8.31658 -0.0976311 8.70711 0.292893Z"
                fill="white"
              />
            </svg>
          </van-button>
          <van-button
            v-if="mode === 'fastpay'"
            :loading="loadingOtp"
            :disabled="otpCode.length < 4"
            @click="confirmFastpay"
            style="width: 200px; float: right"
            :color="color"
            type="primary"
          >{{ $t("pay") }}</van-button>
          <van-button
            v-else-if="mode === 'precheck'"
            :loading="loadingOtp"
            :disabled="otpCode.length < 4"
            @click="confirmPrecheck"
            style="width: 200px; float: right"
            :color="color"
            type="primary"
          >{{ $t("pay") }}</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "vant";
import { TheMask } from "vue-the-mask";
import {mapActions, mapState} from "vuex";
import Service from "@/services/api/service";

export default {
  name: "AddCard",
  props: {
    mode: {
      type: String,
      default: "fastpay"
    },
    total: {
      type: Number,
    },
    delivery: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#1fb7a4'
    },
    serviceCommissionPrice: {
      type: Number,
      default: 0
    },
    tips: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      cardNumber: "",
      cardDate: "",
      otpCode: "",
      otp: false,
      paydata: {},
      loadingCard: false,
      loadingOtp: false,
      transfer: {},
    };
  },
  components: {
    "the-mask": TheMask,
    "van-button": Button,
  },
  computed: {
    ...mapState(["store"]),
    transferFullAmount() {
      return this.$store.getters.transferFullAmount;
    },
    response() {
      return this.$store.getters.response;
    },
  },
  methods: {
    ...mapActions(["showLoading"]),
    handleOnChange(e) {
      this.otpCode = e.target.value;
    },
    async sendOtp() {
      this.loadingCard = true;
      const card = {
        pan: this.cardNumber,
        expiry: this.cardDate,
      }
      this.$emit('otp', card, (result) => {
        // this.$refs.otpInput.focus();
        if (result) {
          this.loadingCard = false;
          this.paydata = result?.data?.data;
          this.otp = true;
        }
      })
    },
    async confirmFastpay() {
      this.loadingOtp = true;
      await Service.fastPayConfirm(this.paydata.id, {
        otp: this.otpCode,
      }).then((data) => {
        console.log(data)
        this.loadingOtp = false;
        //check if not card push this card
        this.$store.commit("SET_CARD", data.data.data.card);
        this.otp = false;
        this.success = true;
        this.$emit("closeModal");
        if (data?.data?.data?.transfer) {
          this.transfer = data.data.data.transfer;
          this.$emit("transfer", this.transfer);
        }
      }).catch((error) => {
        console.log(error);
        this.loadingOtp = false;
      });
    },
    async confirmPrecheck() {
      this.loadingOtp = true;
      await Service.precheckConfirm(this.paydata.id, {
        otp: this.otpCode,
        tg: false
      }).then((data) => {
        console.log(data)
        this.loadingOtp = false;
        //check if not card push this card
        this.$store.commit("SET_CARD", data.data.data.card);
        this.otp = false;
        this.success = true;
        this.$emit("closeModal");
        if (data?.data?.data?.transfer) {
          this.transfer = data.data.data.transfer;
          this.$emit("transfer", this.transfer);
        }
      }).catch((error) => {
        console.log(error);
        this.loadingOtp = false;
      });
    }
  }
}
</script>

<style scoped>
.details-table tr:last-child td {
  border: none;
}
</style>