<template>
  <div>
    <LoadingBlock v-if="loading"></LoadingBlock>
    <store-view-item
      status="store-view-pay"
    />
    <navigation-menu/>
  </div>
</template>

<script>
import LoadingBlock from "@/components/Loading";
import StoreViewItem from "@/views/StoreViewItem";
import NavigationMenu from "@/components/menu/NavigationMenu";

export default {
  title: "Список сотрудников",
  name: "StoreViewPay",
  components: {
    NavigationMenu,
    StoreViewItem,
    LoadingBlock,
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
};
</script>
