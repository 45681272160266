<template>
  <van-popup
      v-model="modal"
      class="cart-popup"
      position="bottom"
      :style="{ maxHeight: '100%' }"
      closeable
      @closed="$emit('close-cart-info-modal')"
      close-icon-position="top-right"
      close-icon="close"
  >
    <div class="cart-modal-content" :style="{'--menu-item-color': btnColor}">
      <div class="cart-modal-content-inner">
        <div class="top">
          <van-button v-if="cart && cart.length" @click="flushCart" class="delete-btn">
            <van-icon name="delete-o"/>
          </van-button>
          <h3 class="title">Корзина</h3>
          <ul class="cart-list" v-if="cart && cart.length">
            <template v-for="(cartItem, index) in reduceCart">
              <li :key="index">
                <div class="cart-item-image">
                  <i :style="getBackground(getImage(cartItem, 'small'))"></i>
                </div>
                <div class="cart-item-description">
                  <div v-if="cartItem" class="cart-description-left">
                    <div class="cart-item-title">{{ cartItem.name }}</div>
                    <div class="cart-item-title-hint">
                      {{ cartItem.weight }} {{ cartItem.unit }}
                    </div>
                    <van-stepper
                        v-if="!cartItem.modifiers.length"
                        class="cart-stepper"
                        :value="inCart(cartItem)"
                        button-size="22"
                        disable-input
                        @change="onChangeStep($event)"
                        @minus="handleStepper(cartItem, 'minus')"
                        @plus="handleStepper(cartItem, 'plus')"
                    />
                    <div v-else class="cart-modifiers d-flex flex-column">
                      <span
                          v-for="(mod, index) in cartItem.modifiers"
                          :key="mod.idx"
                      >
                        {{ mod.name }}{{ index === (cartItem.modifiers.length - 1) ? '' : ', ' }}
                      </span>
                    </div>
                  </div>
                  <div class="cart-description-right">
                    <van-icon
                        @click="$emit('remove-item', cartItem)"
                        class="cart-item-delete"
                        name="cross"
                    />
                    <div v-if="cartItem.price" class="price">
                      {{
                        calcPrice(cartItem)
                            | money($i18n.locale)
                      }}
                    </div>
                  </div>
                </div>
              </li>
            </template>
          </ul>
          <template v-if="cart && cart.length">
            <div class="cart-total">
              <div class="cart-total-text">Сумма заказа</div>
              <div class="cart-total-price">
                {{ cartTotalPrice | money($i18n.locale) }}
              </div>
            </div>
            <input
                class="cart-comments form-control"
                placeholder="Комментарий к заказу"
            />
          </template>
          <div v-else class="text-center d-flex flex-column align-items-center justify-content-center">
            <img src="/cart-empty.svg" alt="">
            <p :style="{fontSize: '18px', marginBottom: '35px'}">Корзина пуста</p>
          </div>
          <auth-modal
              :auth-modal="authModal"
              :current-country="currentCountry"
              :phone="phone"
              @change-country="changeCountry"
              @close="closeAuthModal"
          />
          <van-popup
              class="checkout-popup"
              v-model="checkoutModal"
              closeable
              close-icon-position="top-left"
              position="bottom"
              @close="closeCheckoutPopup"
              :style="{ maxHeight: '100%' }"
          >
            <div class="checkout-popup-content">
              <div class="checkout-popup-inner">
                <div class="top">
                  <h2 class="title">Оформить заказ</h2>
                  <div class="ml-20 mr-20">
                    <div
                        v-if="$route.name === 'DeliveryPage'"
                        class="checkout-address checkout-card"
                        @click="$emit('show-delivery-address')"
                    >
                      <h6>{{ $t("marketplace.address-delivery") }}</h6>
                      <p v-if="address">
                        <marquee-text :text="address"/>
                      </p>
                      <p v-else>{{ $t("marketplace.show-address-delivery") }}</p>
                      <p>
                        <span class="hint">Филиал</span>
                        <span class="ml-15">B&B Максим Горького</span>
                      </p>6
                    </div>
                    <div
                        v-if="$route.name === 'TakeawayPage'"
                        class="checkout-address checkout-card"
                        @click="$emit('show-delivery-address')"
                    >
                      <h6>Адрес самовывоза</h6>
                      <p>
                        <span class="hint">Филиал</span>
                        <span class="ml-15">B&B Максим Горького</span>
                      </p>
                    </div>
                    <div
                        class="checkout-time checkout-card"
                        @click="showTimeModal"
                    >
                      <h6>Время приготовления</h6>
                      <transition-group name="_delivery">
                        <p key="item-1" v-if="!selectedDeliveryTime">Как можно скорее</p>
                        <p key="item-2" v-else>Доставка {{
                            `${selectedDeliveryTime[0]}: ${selectedDeliveryTime[1]}`
                          }}</p>
                      </transition-group>
                    </div>
                    <van-popup
                        v-model="timeModal"
                        class="time-popup"
                        position="bottom"
                        :style="{ height: '270px' }"
                    >
                      <van-picker
                          ref="timePicker"
                          show-toolbar
                          position="bottom"
                          :columns="timeTypes"
                          :item-height="34"
                          :default-index="defaultTimeIndex"
                          @confirm="onSelectTimeType"
                          @change="onChangeTimeType"
                          @cancel="timeModal = false"
                          @close="onCloseTimeType"
                      >
                        <template v-slot:default>
                          <van-button @click="confirmDeliveryTime" class="time-button">
                            <!--                            {{ `${deliveryTime[0]}: ${deliveryTime[1]}` }}-->
                            {{ deliveryTime ? `${deliveryTime[0]} в ${deliveryTime[1]}` : '' }}
                          </van-button>
                        </template>
                      </van-picker>
                    </van-popup>
                    <div
                        class="checkout-payment checkout-card"
                        @click="paymentTypeModal = true"
                    >
                      <h6>Способ оплаты</h6>
                      <p>
                        <span>Онлайн оплата</span>
                        <span class="float-right">8600 **** **** 4247</span>
                      </p>
                    </div>
                    <van-popup v-model="paymentTypeModal" position="bottom">
                      <van-picker
                          show-toolbar
                          title="Date"
                          position="bottom"
                          :columns="paymentTypes"
                          @confirm="onSelectPaymentType"
                          @cancel="paymentTypeModal = false"
                      />
                    </van-popup>
                    <div class="checkout-promo checkout-card d-none">
                      <h6>Промокод</h6>
                      <p>Бургер 2022</p>
                    </div>
                  </div>
                </div>
                <div class="checkout-total">
                  <h6>Итого к оплате</h6>
                  <ul class="total-list">
                    <li>
                      <p class="left">Заказ</p>
                      <p class="right">
                        {{ cartTotalPrice | money($i18n.locale) }}
                      </p>
                    </li>
                    <li v-if="deliveryPrice">
                      <p class="left">Доставка</p>
                      <p class="right">
                        {{ deliveryPrice | money($i18n.locale) }}
                      </p>
                    </li>
                    <li class="d-none">
                      <p class="left">
                        Промокод<span class="hint">Отменить</span>
                      </p>
                      <p class="right text-red">-10 000 сум</p>
                    </li>
                  </ul>
                  <div class="pay-wrapper">
                    <div class="float-left">
                      <van-button
                          @click="$emit('finish-order')"
                          class="pay-button"
                      >Оплатить
                      </van-button
                      >
                    </div>
                    <div class="float-right">
                      <div class="price">
                        <u class="d-none">320 000 сум</u>
                        <i>&nbsp;</i>
                        <b>{{
                            (cartTotalPrice + deliveryPrice) | money($i18n.locale)
                          }}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-popup>
        </div>
        <div class="footer">
          <div v-if="cart && cart.length">
            <van-button v-if="addDishesMode" @click="$emit('update-order')"
            >Добавить блюдо</van-button>
            <van-button v-else @click="createOrder">Оформить заказ</van-button>
          </div>
          <div v-else>
            <van-button @click="$emit('close-cart-info-modal')"
            >Закрыть</van-button>
          </div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {Button, Icon, Picker, Popup, Stepper} from "vant";
import AuthModal from "@/components/menu/auth/AuthModal.vue";
import MarqueeText from "@/components/menu/MarqueeText.vue";

export default {
  name: "CartInfo",
  props: {
    cartInfoModal: {
      type: Boolean,
      default: false,
    },
    checkout: {
      type: Boolean,
      default: false,
    },
    deliveryPrice: {
      type: Number,
      default: 0,
    },
  },
  components: {
    "marquee-text": MarqueeText,
    "auth-modal": AuthModal,
    "van-stepper": Stepper,
    "van-picker": Picker,
    "van-button": Button,
    "van-popup": Popup,
    "van-icon": Icon,
  },
  data() {
    return {
      phone: "",
      authModal: false,
      modal: false,
      timeModal: false,
      defaultTimeIndex: 0,
      deliveryTime: null,
      selectedDeliveryTime: null,
      currentCountry: "uz",
      checkoutModal: false,
      paymentTypeModal: false,
      paymentTypes: ["test", "test2"],
      timeAfterTomorrowTypes: [],
      timeTomorrowTypes: [],
      timeTodayTypes: [],
      deliveryTimeFromBackend: [
        "Завтра", "20:40 ( ±10 )"
      ],
      afterTomorrowDate: {
        day: null,
        month: null,
        weekday: null,
      },
    };
  },
  methods: {
    ...mapActions(["cartFlush", "addToCartWithoutMod"]),
    ...mapMutations(["CART_REMOVE_ONE"]),
    inCart(item) {
      return this.cart.filter((el) => el.id === item.id).length;
    },
    onChangeStep(event) {
      this.stepCount = event;
    },
    handleStepper(item, type) {
      if (type === "plus") {
        this.addToCartWithoutMod(item);
      } else {
        this.CART_REMOVE_ONE(item);
      }
    },
    calcPrice(item) {
      let price = item.price;
      if (item.modifiers?.length) {
        const calc = item.modifiers.reduce((sum, mod) => sum + (mod.price * mod.quantity), 0);
        price += calc;
      } else {
        price *= this.inCart(item) || 1;
      }
      return price;
    },
    flushCart() {
      this.cartFlush();
      this.cartModal = false;
    },
    showTimeModal: function () {
      this.handleDeliveryTime();
      this.timeModal = true;
      this.$nextTick(() => {
        if (!this.deliveryTimeFromBackend.length) {
          this.$refs.timePicker.setValues(
              [
                "Сегодня",
                this.timeTodayTypes[5]?.text
              ]
          );
          this.deliveryTime = [
            "Сегодня",
            this.timeTodayTypes[5]?.text
          ]
        } else {
          this.$refs.timePicker.setValues(
              [
                "Сегодня",
                this.timeTodayTypes[0]?.text
              ]
          );
          this.deliveryTime = [
            "Сегодня",
            this.timeTodayTypes[0]?.text
          ]
        }
      })
    },
    onSelectPaymentType: function (type) {
      console.log(type);
    },
    onSelectTimeType: function (value) {
      this.deliveryTime = value;
      console.log(value)
      this.timeModal = false;
    },
    onChangeTimeType(picker, value) {
      this.deliveryTime = value;
      // console.log(picker.getColumnIndex(index), value, index)
    },
    onCloseTimeType() {
      this.deliveryTime = null;
      this.timeModal = false;
    },
    confirmDeliveryTime() {
      this.selectedDeliveryTime = this.deliveryTime;
      this.timeModal = false;
    },
    getColumnValue(value, index) {
      console.log(value, index, 'kkk')
    },
    closeAuthModal() {
      this.authModal = false
      this.$emit("close-auth-modal");
    },
    createOrder() {
      if (this.token) {
        this.$emit('create-order')
      } else {
        this.authModal = true;
      }
    },
    changeCountry(country) {
      this.currentCountry = country;
    },
    getImage(item, size) {
      let image = "";
      if (!item.imageUrl) return image = '/img/dummy.png';
      if (size === "big") image = item.bigImageUrl;
      if (size === "small") image = item.imageUrl;
      if (image) image = image.replace("http:", "https:");
      return image;
    },
    getBackground(image) {
      return `background-image: url('${image}')`;
    },
    closeCheckoutPopup() {
      this.checkoutModal = false;
      this.$emit("close-checkout-modal");
    },
    handleDeliveryTime() {
      function getMins(time) {
        const parts = time.split(":");
        return +parts[0] * 60 + +parts[1];
      }

      function convertMins(totalMinutes) {
        const minutes = (totalMinutes % 60).toString().padStart(2, '0');
        let hrs = (totalMinutes - minutes) / 60;
        const hours = hrs.toString().padStart(2, '0');
        return `${hours}:${minutes}`;
      }

      function getTimes(start, end, timeInterval) {
        const startMins = getMins(start);
        const endMins = getMins(end);
        return Array.from({length: Math.ceil((endMins - startMins) / timeInterval) + 1})
            .map((_, i) => convertMins(Math.min(startMins + i * timeInterval, endMins)));
      }

      const today = new Date();
      const hours = today.getHours();
      const rounded = new Date(Math.round(today.getTime() / 600000) * 600000);
      const startTime = `${hours}:${rounded.getMinutes() + 30}`;
      const endTime = "23:50";
      this.timeTodayTypes = getTimes(startTime, endTime, 30);
      this.timeTomorrowTypes = getTimes("00:00", endTime, 30);
      this.timeAfterTomorrowTypes = getTimes("00:00", endTime, 30);

      // const branchWorkingStartTimeAfterDayTime = "00:00"; // todo init
      // const branchWorkingEndTime = "02:30" // from back
      // const afterDayTime = getTimes(branchWorkingStartTimeAfterDayTime, branchWorkingEndTime, 10);
      //
      // this.timeTodayTypes = this.timeTodayTypes.concat(afterDayTime);
      // console.log(this.timeTodayTypes)

      const date_milliseconds = new Date().getTime();
      const one_day_millisec = (1000 * 60 * 60 * 24) * 2;
      const one_day_before = new Date(date_milliseconds + one_day_millisec);
      const result = one_day_before.toLocaleString('en-us', {month: 'long', day: 'numeric', weekday: 'long'});

      this.afterTomorrowDate = {
        day: result.split(' ')[2],
        month: result.split(' ')[1],
        weekday: result.split(' ')[0].replace(',', ''),
      };

      const arr1 = [], arr2 = [], arr3 = [];
      this.timeTodayTypes.map(item => {
        arr1.push({text: item + ' ( ±10 )'})
      });
      this.timeTomorrowTypes.map(item => {
        arr2.push({text: item + ' ( ±10 )'})
      });
      this.timeAfterTomorrowTypes.map(item => {
        arr3.push({text: item + ' ( ±10 )'})
      });
      this.timeTodayTypes = arr1;
      this.timeTomorrowTypes = arr2;
      this.timeAfterTomorrowTypes = arr3;
    },
    handleDeliveryTime2() {
      const x = 10; //minutes interval
      const times = []; // time array
      let tt = 0; // start time
      // const ap = ['AM', 'PM']; // AM-PM

      //loop to increment the time and push results in array
      for (let i = 0; tt < 24 * 60; i++) {
        const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        const mm = (tt % 60); // getting minutes of the hour in 0-55 format
        // times[i] = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
        times[i] = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ' ( ±10 )'; // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + x;
      }
    },
    handleDeliveryTime1() {
      const makeTimeIntervals = function (startTime, endTime, increment) {
        startTime = startTime.toString().split(':');
        endTime = endTime.toString().split(':');
        increment = parseInt(increment, 10);

        let pad = function (n) {
              return (n < 10) ? '0' + n.toString() : n;
            },
            startHr = parseInt(startTime[0], 10),
            startMin = parseInt(startTime[1], 10),
            endHr = parseInt(endTime[0], 10),
            // endMin = parseInt(endTime[1], 10),
            currentHr = startHr,
            currentMin = startMin,
            previous = currentHr + ':' + pad(currentMin),
            current = '',
            r = [];

        do {
          currentMin += increment;
          if ((currentMin % 60) === 0 || currentMin > 60) {
            currentMin = (currentMin === 60) ? 0 : currentMin - 60;
            currentHr += 1;
          }
          current = currentHr + ':' + pad(currentMin);
          r.push(previous + '  ' + '( ±10 )');
          previous = current;
        } while (currentHr !== endHr);

        return r;
      };
      const date = new Date();
      const hours = date.getHours();
      const coeff = 1000 * 60 * 50; // 600 000
      const rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
      const startTime = '09:00';
      const endTime = '23:00';
      let currentTimes = makeTimeIntervals(`${hours}:${rounded.getMinutes()}`, endTime, 10);
      let tomorrowTimes = makeTimeIntervals(startTime, endTime, 10); //±
      let afterTomorrowTimes = makeTimeIntervals(startTime, endTime, 10);

      // eslint-disable-next-line no-prototype-builtins
      for (let i in currentTimes) if (currentTimes.hasOwnProperty(i)) {
        this.timeTodayTypes.push({text: currentTimes[i]});
      }
      // eslint-disable-next-line no-prototype-builtins
      for (let i in tomorrowTimes) if (tomorrowTimes.hasOwnProperty(i)) {
        this.timeTomorrowTypes.push({text: tomorrowTimes[i]});
      }
      // eslint-disable-next-line no-prototype-builtins
      for (let i in afterTomorrowTimes) if (afterTomorrowTimes.hasOwnProperty(i)) {
        this.timeAfterTomorrowTypes.push({text: afterTomorrowTimes[i]});
      }
    }
  },
  computed: {
    ...mapGetters(["cart", "address", "addDishesMode", "btnColor", "token"]),
    reduceCart() {
      return this.cart.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x || current.modifiers.length) {
          return [...acc, current];
        } else {
          return acc.map(x => x.id === current.id ? ({...x, modifier: false}) : x);
        }
      }, []);
    },
    cartTotalPrice() {
      if (!this.cart || !this.cart.length) return 0;
      let price = 0, price_mod = 0;
      this.cart.forEach((cartItem) => {
        price += cartItem.price * 1;
        if (cartItem.modifiers.length) {
          const calc = cartItem.modifiers.reduce((sum, item) => sum + (item.price * item.quantity), 0);
          price_mod += calc;
        }
      });
      return price + price_mod;
    },
    timeTypes() {
      return [
        {
          text: 'Сегодня',
          children: this.timeTodayTypes,
        },
        {
          text: 'Завтра',
          children: this.timeTomorrowTypes,
        },
        {
          text: this.$t(`Weeks.${this.afterTomorrowDate.weekday}`) + ', ' +
              this.afterTomorrowDate.day + ' ' + this.$t(`Month.${this.afterTomorrowDate.month}`),
          children: this.timeAfterTomorrowTypes,
        },
      ]
    },
  },
  watch: {
    cartInfoModal(value) {
      if (value) {
        this.authModal = !this.token
      }
      this.modal = value;
    },
    checkout(value) {
      this.checkoutModal = value;
    },
  },
};
</script>

<style scoped>
._delivery-move, /* apply transition to moving elements */
._delivery-enter-active,
._delivery-leave-active {
  transition: all 0.5s ease;
}

._delivery-enter-from,
._delivery-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
._delivery-leave-active {
  position: absolute;
}

.time-button {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background: linear-gradient(133.1deg, #29BF9B 24.25%, #0FAEAE 62.73%);
  border: none;
  border-radius: 12px;
  padding: 24px 40px;
  margin: 0 auto 10px;
  width: 80%;
  white-space: nowrap;
}
</style>
