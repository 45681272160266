<template>
  <ul class="float-menu">
    <li v-if="showBellList.call_waitress"><a @click.prevent="call('waitress')" class="user-link">Позвать официанта</a></li>
    <li v-if="showBellList.call_hookah"><a @click.prevent="call('hookah')" class="user-link">Позвать кальянщика</a></li>
    <li v-if="showBellList.call_bill"><a @click.prevent="call('bill')" class="cheque-link">Попросить счет</a></li>
  </ul>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CallModal",
  props: {
    storeId: {
      type: Number,
      default: null
    },
    deskId: {
      type: String,
      default: null
    },
    showBellList: {
      type: Object,
      default: () => {
        return {
          call_bill: 0,
          call_hookah: 0,
          call_waitress: 0,
        }
      }
    },
  },
  emits: ['clicked'],
  methods: {
    ...mapActions(['askBill']),
    call(type) {
      if (this.canOrder) {
        this.askBill({
          store: this.storeId,
          desk: this.deskId ?? '',
          type: type,
        })
        this.$emit('clicked');
      }
    }
  },
  computed: {
    ...mapGetters(['canOrder']),
  },
}
</script>
