<template>
  <div>
    <div v-if="checkModal" @click="$emit('closed')" class="overlay"></div>
    <div v-if="checkModal" class="modal check">
      <div @click="$emit('closed')" class="modal-close">
        <i role="button" tabindex="0"
           class="van-icon van-icon-close van-popup__close-icon van-popup__close-icon--top-right"><!----></i>
      </div>
      <div class="check__main main-check" id="main-check">
        <div class="main-check__top d-flex justify-content-between align-content-center">
          <div
              v-if="checkStore.system"
              class="main-check__paytype"
              :style="{backgroundImage: `url(/payments/${checkStore.system}.svg)`}"
          >
          </div>
          <div
              class="main-check__paytype __last"
              :style="{backgroundImage: `url(${checkStore.store_logo})`}"
          >
          </div>
        </div>
        <div class="main-check__label text-center mt-20">{{ checkStore.store_name }}</div>
        <p class="text-center mt-5">Тип оплаты: <span
            style="color: #0faeae; font-weight: 700">{{ checkStore.type === 'bill' ? 'счет' : 'чаевые' }}</span></p>
        <div
            class="main-check__detail detail"
        >
          <hr v-if="checkStore.details?.length && checkStore.type === 'bill'">
          <table v-if="checkStore.details?.length && checkStore.type === 'bill'" class="detail__table mt-10">
            <tr>
              <th>Наименование</th>
              <th>Кол-во</th>
              <th>Цена</th>
            </tr>
            <tr v-for="item in checkStore.details" :key="item.name">
              <td>{{ item.name }}</td>
              <td class="text-center">{{ item.count ?? item['qty'] ?? '' }}</td>
              <td class="text-right">{{ (item.price / 100) | money() }}</td>
            </tr>
            <tr class="detail__table--total">
              <td colspan="3" class="">
                <span>Итого:</span>
                <span class="float-right">{{ (checkStore.total / 100) | money() }}</span>
              </td>
            </tr>
          </table>
          <hr class="mt-10 mb-10" v-if="checkStore.details?.length && checkStore.type === 'bill'">

          <div v-if="checkStore.card" class="pb-5 detail__item d-flex align-items-baseline justify-content-between">
            <div class="__first">Карта:</div>
            <span>{{ checkStore.card }}</span>
          </div>
          <div v-if="checkStore.service"
               class="pb-5 detail__item d-flex align-items-baseline justify-content-between">
            <div>Стоимость услуги</div>
            <span>{{ (checkStore.service / 100) | money($i18n.locale) }}</span>
          </div>
          <div v-if="checkStore.amount"
               class="pb-5 detail__item d-flex align-items-baseline justify-content-between">
            <div class="__last">Сумма платежа:</div>
            <span>{{ (checkStore.amount / 100) | money($i18n.locale) }}</span>
          </div>
          <div v-if="checkStore.amount"
               class="pb-5 detail__item d-flex align-items-baseline justify-content-between">
            <div class="__last">Дата платежа:</div>
            <span>{{ checkStore.added_on }}</span>
          </div>
        </div>
      </div>
      <div class="check__action d-flex justify-content-around mt-20 mb-10">
        <div @click="downloadPdf" class="check__action-item d-flex flex-column align-items-center">
          <button class="action-btn"><img src="/download-outline.svg" alt=""></button>
          <span>Скачать</span>
        </div>
        <div v-if="navigatorShare" @click="sharePdf" class="check__action-item d-flex flex-column align-items-center">
          <button class="action-btn"><img src="/share-social-outline.svg" alt=""></button>
          <span>Поделиться</span>
        </div>
        <div v-if="checkStore.qr" @click="openWindow(checkStore.qr)"
             class="check__action-item d-flex flex-column align-items-center">
          <button class="action-btn"><img src="/qr-code-outline.svg" alt=""></button>
          <span>{{ $t('download-fiscal') }}</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import html2pdf from 'html2pdf.js'
import {useUserAgent} from "@/helpers/useUserAgent";

export default {
  name: 'CheckModal',
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    check: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      checkModal: false,
      checkStore: {},
    }
  },
  methods: {
    downloadPdf() {
      html2pdf(document.getElementById('main-check'), {
        margin: 30,
        filename: 'check',
        image: {type: 'png'},
        html2canvas: {useCORS: true, scale: 1},
        format: [2, 4],
        orientation: 'portrait'
      })
    },
    sharePdf() {
      const el = document.getElementById('main-check');
      const opt = {
        margin: 10,
        filename: 'check',
        image: {type: 'png'},
        html2canvas: {useCORS: true, scale: 1}
      }
      html2pdf().set(opt).from(el).toPdf().output('blob').then((data) => {
        console.log('data', data)
        const uploadedFile = new File([data], `share_${Date.now()}.pdf`, {lastModified: Date.now()})
        // const blobData = new Blob([uploadedFile], { type: 'application/pdf' /* uploadedFile.type */ });
        // const blobUrl = URL.createObjectURL(blobData);
        // window.open(blobUrl, '_blank');
        // URL.revokeObjectURL(blobUrl);

        if (navigator.share) {
          navigator.share({
            text: 'copied link',
            files: [uploadedFile],
            title: 'test'
          })
        }
        else {
          console.log('Sharing is on Web')
        }
      })
    },
    openWindow(url) {
      console.log(url, 'uuu')
      if (this.useragent === 'multicard')
        window.flutter_inappwebview.callHandler('browser', {url})
      else
        window.open(url, '_blank')
    }
  },
  computed: {
    navigatorShare() {
      return navigator.share?.();
    },
    useragent() {
      return useUserAgent().getUserAgent()
    }
  },
  watch: {
    modal(value) {
      this.checkModal = value;
    },
    check(value) {
      this.checkStore = value;
      console.log(value)
    }
  },
  components: {}
}
</script>

<style scoped lang="scss">
.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  //transform: scale(.9);
  background-color: rgba(0, 0, 0, .8);
  z-index: 2500;
}

.modal-close {
  position: absolute;
  right: 41px;

  i {
    left: 0;
    top: 0;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.check {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 90%;
  height: 90%;
  border-radius: 20px;
  background-color: #F5F5F5;
  max-width: 768px;
  margin: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2501;
  // .check__main
  &__main {
    overflow-y: auto;
  }

  &__action {
  }

  // .main-check__action-item
  &__action-item {
    flex: 0 0 33.333%;

    .action-btn {
      width: 50px;
      height: 50px;
      background-color: #eaeaea;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: none;
      margin-bottom: 5px;
    }

    span {
      color: #000000;
      font-size: 12px;
      text-align: center;
    }
  }
}

.main-check {
  background-color: #FFFFFF;
  border-radius: 2px;
  padding: 10px;
  margin-top: 40px;
  // .main-check__label
  &__label {
    font-weight: 700;
    text-transform: uppercase;
  }

  &__top {

  }

  &__paytype {
    width: 60px;
    height: 60px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &.__last{
    }
  }

  // .main-check__detail
  &__detail {
  }

  // .main-check__action

}

.detail {
  &__item {
    padding: 0 5px;

    span {
      font-weight: 600;
      color: #000000;
    }
  }

  // .detail__table
  &__table {
    width: 100%;

    th {
      text-align: center;
      padding: 8px 5px;
      white-space: nowrap;

      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
    }

    td {
      padding: 5px;
    }
  }

  // .detail__table--total
  &__table--total {
    span {
      font-weight: 700;
    }

    .float-right {
      float: right;
    }
  }
}

</style>
