<template>
  <div>
    <div class="m-title plr-20 mb-10">Вкусно и выгодно</div>
    <swiper
      :slides-per-view="2"
      :space-between="5"
      loop
      style="padding-left: 20px"
      class="carousel-style2"
    >
      <swiper-slide class="fooditem style-2">
        <div class="food-img">
          <img src="/img/burger.png" alt="" />
        </div>
        <div class="fooditem_desc">
          <div class="f-13 font-semibold mb-10">Два биг бургера</div>
          <div class="green f-10">122 000 сум</div>
        </div>
      </swiper-slide>
      <swiper-slide class="fooditem style-2">
        <div class="food-img">
          <img src="/img/burger.png" alt="" />
        </div>
        <div class="fooditem_desc">
          <div class="f-13 font-semibold mb-10">Два биг бургера</div>
          <div class="green f-10">122 000 сум</div>
        </div>
      </swiper-slide>
      <swiper-slide class="fooditem style-2">
        <div class="food-img">
          <img src="/img/burger.png" alt="" />
        </div>
        <div class="fooditem_desc">
          <div class="f-13 font-semibold mb-10">Два биг бургера</div>
          <div class="green f-10">122 000 сум</div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper-vue2";

export default {
  name: "TastyBlock",
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style scoped></style>
