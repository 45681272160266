export default function Filter(attrs) {
  this.sweets = typeof attrs.sweets === "boolean" ? [] : attrs.sweets ? attrs.sweets : [];
  this.kitchens = typeof attrs.kitchens === "boolean" ? [] : attrs.kitchens ? attrs.kitchens : [];
  this.delivery = attrs.delivery ? attrs.delivery : "30";
  this.avg_from = attrs.avg_from ? attrs.avg_from : 0;
  this.avg_to = attrs.avg_to ? attrs.avg_to : 0;
  this.type = attrs.type ? attrs.type : "delivery";
  this.sort = attrs.sort ? attrs.sort : "";
  this.name = attrs.name ? attrs.name : "";
  this.brand_id = attrs.brand_id ? attrs.brand_id : "";
}
