<template>
  <div>
    <div v-if="getCards.length" class="card-payment__row">
      <button :disabled="disablePayment" type="button" @click="$emit('add-new-card')" class="card-payment__add">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.23809 4.23822V0.00012207H5.7619V4.23822H10V5.76203H5.7619V10.0001H4.23809V5.76203H0V4.23822H4.23809Z" fill="url(#paint0_linear_556_2252)"/>
          <defs>
            <linearGradient id="paint0_linear_556_2252" x1="-0.596026" y1="-9.99988" x2="8.05333" y2="-9.13213" gradientUnits="userSpaceOnUse">
              <stop :stop-color="getColor"/>
              <stop offset="0.826058" :stop-color="getColor"/>
            </linearGradient>
          </defs>
        </svg>
      </button>
      <swiper
          ref="card_payment"
          class="card-payment__swiper"
          :slides-per-view="'auto'"
          :space-between="12"
          :loop="false"
          :mousewheel="true"
      >
        <swiper-slide
            v-for="(item, index) in getCards"
            @click="addClass(index, $event)"
            :key="index"
            class="card-payment__slide"
            :class="{active: index === current}"
            :style="{borderColor: index === current ? getColor + '!important' : ''}"
        >
          <input type="radio" name="card-payment" @change="addClass(index)"/>
          <div class="card-payment__title">{{ item.bank }}</div>
          <div class="d-flex align-items-end justify-content-between">
            <span class="card-payment__number">***{{item.pan?.slice(-4)}}</span>
            <img class="card-ps" :src="`/payments/${item.ps}.svg`" alt="" />
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div v-else>
      <button :disabled="disablePayment" type="button" @click="$emit('add-new-card')" class="card-payment__add w-100">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.23809 4.23822V0.00012207H5.7619V4.23822H10V5.76203H5.7619V10.0001H4.23809V5.76203H0V4.23822H4.23809Z" fill="url(#paint0_linear_556_2252)"/>
          <defs>
            <linearGradient id="paint0_linear_556_2252" x1="-0.596026" y1="-9.99988" x2="8.05333" y2="-9.13213" gradientUnits="userSpaceOnUse">
              <stop :stop-color="getColor"/>
              <stop offset="0.826058" :stop-color="getColor"/>
            </linearGradient>
          </defs>
        </svg>
        <span class="add-text" :style="{color: getColor}">{{ $t('add-new-card') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {Swiper, SwiperSlide} from "swiper-vue2";
import {useUserAgent} from "@/helpers/useUserAgent";

export default {
  props: {
    disablePayment: {
      type: Boolean,
      default: false,
    },
    cards: {
      type: Array,
      default: () => []
    },
    currentCard: {
      type: Object,
      default: () => {}
    }
  },
  name: "PaymentCard",
  data() {
    return {
      current: 0,
      slickOptions: {
        slidesToShow: 3,
      },
    };
  },
  computed: {
    ...mapState(["store", "employee"]),
    getCards() {
      if (this.useragent === 'multicard') {
        if (this.cards.length) {
          this.$emit("selectedCard", 0);
        }
        return this.cards
      }
      return this.$store.state.cards;
    },
    getColor() {
      let store = Object.keys(this.employee).length ? this.employee.store : this.store;
      return store?.['button_color'] ?? "#1fb7a4";
    },
    useragent() {
      return useUserAgent().getUserAgent()
    },
  },
  watch: {
    current: {
      handler(value) {
        if (value || value === 0) {
          this.$emit("selectedCard", value);
        }
      },
      deep: true,
    },
    currentCard: {
      handler() {
        if (this.currentCard?.token) {
          const current = this.cards.find(c => c.token === this.currentCard.token)
          this.current = this.cards.indexOf(current)
        }
        // this.currentCard = this.current
      },
      deep: true,
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions(["removeCard", "fetchAuthMe"]),
    async getAuthData() {
      const token = this.$store.getters.token;
      const device = this.$store.getters.deviceUuid;
      await this.fetchAuthMe({token, device}).then((response) => {
        if (response?.cards?.length) {
          this.$store.commit("FLUSH_CARDS")
          response?.cards.forEach((item) => {
            this.$store.commit("SET_CARD", item);
          });
        }
      }).finally(() => {
      })
    },
    addClass: function (index) {
      this.current = index;
    },
  },
  created() {
    this.getAuthData()
  },
  components: { Swiper, SwiperSlide },
}
</script>

<style scoped>

</style>
