<template>
  <div class="fast-view-content">
    <LoadingBlock v-if="loading" />
    <BillHeaderBlock
      v-if="!storeLoading"
      :logo="store?.['brand_logo']"
      :name="store?.['brand_name']"
      :description="store?.['name']"
    />
    <navigation-bill v-if="showNavigationMenu" />
    <SuccessBlock
      class="fast-view-wrapper"
      v-if="success"
      :transferInfo="transferInfo"
    ></SuccessBlock>
    <div v-else class="fast-view-wrapper">
      <StoreBlock
        :store="store?.['terminal_name']"
        :logo="store['logo_url']"
      />
      <ProductsBlock
        :products="store.products ?? []"
        :color="color"
      />
      <div class="store-loading-wrap" v-if="storeLoading">
        <van-loading
          type="spinner"
          :color="color"
        />
      </div>
      <transition appear name="slideCheque">
        <div class="slide-cheque">
          <div class="p-25 pb-0">
            <currency-input
              v-if="changeAmount"
              class="form-control"
              style="position: relative"
              v-model="selectedSum"
              v-bind="option"
              inputmode="numeric"
            />
            <div class="selectsum mb-20">
              <div class="selectsum-inner" :class="{animation: animation}">
                <div
                  v-for="price in paymentOptions"
                  :key="price"
                  @click="chooseSum(price)"
                  :style="
                    parseInt(selectedSum) === parseInt(price) ? 'background:' + getGradient : ''
                  "
                  :class="parseInt(selectedSum) === parseInt(price) ? 'active' : ''"
                  class="pointer btn-tip fast-view"
                >
                  <span>{{ price | money($i18n.locale) }}</span>
                </div>
              </div>
            </div>
          </div>
          <PaymentInfo
            :total="total"
            :color="color"
            :selectedSum="parseInt(selectedSum)"
            :service-commission="serviceCommission"
          />
          <div class="payment-details-content">
            <PaymentBlock
              @transfer="transfer"
              @success="showSuccess"
              :amount="parseInt(selectedSum)"
              :payment-systems="paymentSystems"
              :service-commission="serviceCommission"
              :service-commission-price="serviceCommissionPrice"
              :total="parseInt(total)"
              :color="color"
            />
          </div>
        </div>
      </transition>
    </div>
    <div v-if="!success" class="px-25">
      <review-agreement-text
        :get-color="color"
        :border="false"
      />
    </div>
  </div>
</template>

<script>
import {Dialog, Loading} from "vant";
import Service from "@/services/api/service";
import LoadingBlock from "@/components/Loading.vue";
import {CurrencyInput} from "vue-currency-input";
import SuccessBlock from "@/components/SuccessBlock.vue";
import NavigationBill from "@/components/NavigationBill.vue";
import BillHeaderBlock from "@/components/BillHeaderBlock.vue";
import PaymentBlock from "@/views/fastview/PaymentBlock.vue";
import ReviewAgreementText from "@/components/formblock-items/ReviewAgreementText.vue";
import StoreBlock from "@/views/fastview/StoreBlock.vue";
import ProductsBlock from "@/views/fastview/ProductsBlock.vue";
import PaymentInfo from "@/views/fastview/PaymentInfo.vue";

export default {
  title: "Быстрая оплата",
  name: "FastView",
  components: {
    "van-loading": Loading,
    ReviewAgreementText,
    BillHeaderBlock,
    NavigationBill,
    ProductsBlock,
    CurrencyInput,
    PaymentBlock,
    SuccessBlock,
    LoadingBlock,
    PaymentInfo,
    StoreBlock,
  },
  data() {
    return {
      store: {},
      response: {},
      totalPrice: 0,
      selectedSum: 0,
      success: false,
      activeSum: null,
      animation: false,
      transferInfo: {},
      paymentSystems: [],
      storeLoading: true,
      chequeDetailsLoading: false,
    };
  },
  created() {
    this.fetchStore();
  },
  computed: {
    option() {
      let suffix = this.$i18n.locale ? this.$t("sum") : "";
      let locale = this.$i18n.locale ? this.$i18n.locale : "ru";
      return {
        locale: locale,
        currency: {
          prefix: "",
          suffix: " " + suffix,
        },
        valueAsInteger: false,
        distractionFree: true,
        precision: 0,
        autoDecimalMode: true,
        valueRange: {min: 0},
        allowNegative: false,
      };
    },
    getGradient() {
      return this.store['button_color'] ?? "linear-gradient(to left, #29BF9B, #0FAEAE)";
    },
    paymentOptions() {
      return this.store['payment_options'] ?? [];
    },
    changeAmount() {
      return this.store['change_amount'] ?? false;
    },
    posName() {
      return this.store['pos_type_name'] ?? '';
    },
    posStatus() {
      return this.store['pos_system_status'] ?? true;
    },
    serviceCommission() {
      return this.store['bill_commission_up'] ?? 0;
    },
    serviceCommissionPrice() {
      const total = (this.selectedSum * 10000) / (100 - this.serviceCommission);
      return (Math.floor(total, -2) - this.selectedSum * 100) / 100;
    },
    total() {
      return this.selectedSum + this.serviceCommissionPrice
    },
    color() {
      return this.store['button_color'] ?? "#1fb7a4";
    },
    loading() {
      return this.$store.state.loading;
    },
    showNavigationMenu() {
      return window.showNavigationMenu ?? true
    }
  },
  mounted() {
    this.$store.dispatch("showLoading", false);
    if (document.documentElement.scrollTop > 150) {
      this.isShowTooltip = true;
    }
    this.$store.commit("SET_NAVIGATION_BILL_HOME_ROUTE", this.$route.fullPath);
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SET_NAVIGATION_BILL_HOME_ROUTE", from.fullPath);
    next();
  },
  beforeCreate() {
    this.$store.commit("SET_EMPLOYEE", []);
    this.$store.dispatch("clearResponse");
  },
  methods: {
    fetchStore() {
      this.storeLoading = true;
      Service.getTerminalData(this.$route.params.storeId)
        .then((res) => {
          this.store = res.data.data;
          if (this.paymentOptions.length > 3) {
            this.animation = true;
          }
          if (this.paymentOptions.length === 1) {
            this.chooseSum(this.paymentOptions[0])
          }
          this.$store.dispatch("setStore", this.store);
          this.storeLoading = false;
          this.paymentSystems = res.data.data['payment_systems']
        })
        .catch(() => {
          this.storeLoading = false;
        })
        .finally(() => {
          this.storeLoading = false;
          if (this.posStatus !== 'Ok' &&
              (
                  this.posName.toLowerCase() === 'alivewater' ||
                  this.posName.toLowerCase() === 'smvend'
              )
          ) {
            let title
            let message
            switch (this.posStatus) {
              case 'Disabled':
                title = 'Ошибка';
                message = 'На аппарате отключен приём оплаты онлайн'
                break;
              case 'Offline':
                title = 'Аппарат не на связи'
                message = 'Попробуйте позже'
                break;
              default:
                title = 'Ошибка'
                message = 'Аппарат не найден'
                break;
            }
            Dialog.alert({
              title: title,
              message: message,
              theme: "round-button",
              confirmButtonColor: '#1fb7a4',
              confirmButtonText: "Закрыть",
            });
          }
        });
    },
    chooseSum(sum) {
      this.selectedSum = parseInt(sum);
    },
    showSuccess() {
      this.$store.dispatch("clearResponse");
      this.success = true;
    },
    transfer(transferInfo) {
      this.transferInfo = transferInfo;
    },
    updateCashbackSum() {
      const cashbackElements = document.getElementsByClassName('cashback-hint')
      const amount = parseInt(this.selectedSum)
      const percent = 1
      const cashback = parseInt(amount / 100 * percent)
      cashbackElements.forEach(el => {
        el.innerHTML = this.$t('cashback', { percent: cashback + ' ' + this.$t('sum') })
      })
    }
  },
  watch: {
    selectedSum: {
      handler(value) {
        if (value.length > 1 && value.substring(0, 1) === '0') {
          this.selectedSum = parseInt(value.substring(1))
        }
        this.updateCashbackSum()
      }
    },
    paymentSystems: {
      handler(value) {
        if (value.length) {
          this.$nextTick(() => {
            this.updateCashbackSum()
          })
        }
      }
    },
  }
};
</script>
