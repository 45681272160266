<template>
  <div
    style="
      height: 100vh;
      left: 0;
      top: 0;
      width: 100%;
      position: fixed;
      backdrop-filter: blur(15px);
      z-index: 4;
    "
    class="container flex-column d-flex align-items-center justify-content-center"
  >
    <div class="text-center mt-auto mb-auto white">
      <div class="lds-dual-ring" :style="{'--loading-color': getColor}"></div>
      <div class="f-18 mt-10 text-black text-capitalize" style="letter-spacing: 1px">{{$t('loading')}}</div>
    </div>
    <div v-if="false" class="loading-snowflakes">
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="loading-snowflake"></div>
      <div class="text-center mt-auto mb-auto white">
        <van-loading type="spinner" size="80" color="#ffffff" />
        <div class="f-18 mt-10 text-capitalize">{{$t('loading')}}</div>
      </div>
    </div>

<!--    <div>-->
<!--      <div class="lds-circle"><div></div></div>-->
<!--      <div class="text-center mt-auto mb-auto white">-->
<!--        <van-loading type="spinner" size="80" color="#ffffff" />-->
<!--        <div class="f-18 mt-10">Загрузка... !!!</div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import { Loading } from "vant";
export default {
  name: "LoadingBlock",
  components: {
    "van-loading": Loading,
  },
  computed: {
    employee() {
      return this.$store.getters.employee;
    },
    getColor() {
      const store = Object.keys(this.employee).length ? this.employee.store : this.store;
      return store?.['button_color'] ?? "#1fb7a4";
    },
  }
};
</script>

<style>
/*body {*/
/*  overflow: hidden;*/
/*}*/
</style>
