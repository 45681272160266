<template>
  <div class="header header-wrapper">
    <van-sticky
          :offset-top="-160"
          :z-index="8"
          class="top-sticky-address"
          @change="stickyChange"
      >
        <div v-if="stickyEnabled" :style="{top: adModal ? '100px' : '-1px'}" class="header-fixed">
          <div class="vendor-logo-fixed">
            <div
                v-if="store['logo_url']"
                :style="{backgroundImage: `url(${store['logo_url']})`}"
                class="store-logo"
            >
            </div>
            <div
                v-else-if="employee.store && employee.store.logo_url"
                :style="{backgroundImage: `url(${employee.store.logo_url})`}"
                class="store-logo"
            >
            </div>
          </div>
          <div class="header-fixed-brandname" v-if="store?.name">{{ employee.store?.name }}</div>
          <div class="header-fixed-brandname" v-else>{{ employee.store?.name }}</div>
        </div>
      </van-sticky>
    <div class="garlands"></div>
    <div
        class="relative grey_bg"
        :style="{background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 14.87%, rgba(0, 0, 0, 0.01) 99.78%),
          linear-gradient(180deg, rgba(0, 0, 0, 0.00) 12.98%, rgba(0, 0, 0, 0.50) 83.52%, rgba(0, 0, 0, 0.50) 99.79%),
          url(${employee.store?.background_url}),
          lightgray -27.255px -1.304px / 113.337% 100.573% no-repeat`, backgroundSize: 'cover'}"
    >
      <div class="brand">
        <svg xmlns="http://www.w3.org/2000/svg" width="68" height="69" viewBox="0 0 68 69" fill="none">
          <g filter="url(#filter0_bdd_355_3458)">
            <rect x="19" y="16.6094" width="30" height="30" rx="8" fill="url(#paint0_linear_355_3458)"
                  shape-rendering="crispEdges"/>
            <path
                d="M30.2589 30.1096L29.4489 28.9396H28.5549V30.1096H27.5829V25.9096H29.4009C29.7729 25.9096 30.0949 25.9716 30.3669 26.0956C30.6429 26.2196 30.8549 26.3956 31.0029 26.6236C31.1509 26.8516 31.2249 27.1216 31.2249 27.4336C31.2249 27.7456 31.1489 28.0156 30.9969 28.2436C30.8489 28.4676 30.6369 28.6396 30.3609 28.7596L31.3029 30.1096H30.2589ZM30.2409 27.4336C30.2409 27.1976 30.1649 27.0176 30.0129 26.8936C29.8609 26.7656 29.6389 26.7016 29.3469 26.7016H28.5549V28.1656H29.3469C29.6389 28.1656 29.8609 28.1016 30.0129 27.9736C30.1649 27.8456 30.2409 27.6656 30.2409 27.4336Z"
                fill="white"/>
            <path
                d="M34.761 29.2096H32.811L32.439 30.1096H31.443L33.315 25.9096H34.275L36.153 30.1096H35.133L34.761 29.2096ZM34.455 28.4716L33.789 26.8636L33.123 28.4716H34.455Z"
                fill="white"/>
            <path
                d="M40.4408 25.9096V30.1096H39.4688V28.3876H37.5608V30.1096H36.5888V25.9096H37.5608V27.5656H39.4688V25.9096H40.4408Z"
                fill="white"/>
            <path
                d="M31.4196 37.4168L31.4136 34.8968L30.1776 36.9728H29.7396L28.5096 34.9508V37.4168H27.5976V33.2168H28.4016L29.9736 35.8268L31.5216 33.2168H32.3196L32.3316 37.4168H31.4196Z"
                fill="white"/>
            <path
                d="M36.094 36.5168H34.144L33.772 37.4168H32.776L34.648 33.2168H35.608L37.486 37.4168H36.466L36.094 36.5168ZM35.788 35.7788L35.122 34.1708L34.456 35.7788H35.788Z"
                fill="white"/>
            <path d="M38.5808 34.0088H37.2368V33.2168H40.8968V34.0088H39.5528V37.4168H38.5808V34.0088Z" fill="white"/>
          </g>
          <defs>
            <filter id="filter0_bdd_355_3458" x="0" y="0.609375" width="68" height="68" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5"/>
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_355_3458"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                             result="hardAlpha"/>
              <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect2_dropShadow_355_3458"/>
              <feOffset dy="3"/>
              <feGaussianBlur stdDeviation="10"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.941667 0 0 0 0 0.941667 0 0 0 0 0.941667 0 0 0 0.1 0"/>
              <feBlend mode="normal" in2="effect1_backgroundBlur_355_3458" result="effect2_dropShadow_355_3458"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                             result="hardAlpha"/>
              <feOffset dy="1"/>
              <feGaussianBlur stdDeviation="2.5"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
              <feBlend mode="normal" in2="effect2_dropShadow_355_3458" result="effect3_dropShadow_355_3458"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_355_3458" result="shape"/>
            </filter>
            <linearGradient id="paint0_linear_355_3458" x1="17.2119" y1="-13.3906" x2="43.16" y2="-10.7874"
                            gradientUnits="userSpaceOnUse">
              <stop stop-color="#29BF9B"/>
              <stop offset="0.826058" stop-color="#0BABAB"/>
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="d-flex align-items-center">
        <div class="vendor-logo">
          <div
              v-if="employee.store && employee.store.logo_url"
              :style="{backgroundImage: `url(${employee.store.logo_url})`}"
              class="store-logo"
          >
          </div>
        </div>
        <div class="info">
          <div class="title" :class="{'mb-0': !employee.store?.address}" v-if="store?.name">{{ store.name }}</div>
          <div class="title" :class="{'mb-0': !employee.store?.address}" v-else>{{ employee.store?.name }}</div>
          <span v-if="store?.name" class="address d-flex align-items-center">
            <svg v-if="store?.name" data-v-28dcf1f8="" xmlns="http://www.w3.org/2000/svg" fill="#fff" height="18" viewBox="0 -960 960 960"
                 width="18" style="min-width: 18px; margin-right: 5px;">
              <path data-v-28dcf1f8=""
                    d="M479.693-86.043q-11.693 0-24.334-4t-22.598-13q-46.478-39.522-97.601-90.748-51.123-51.227-93.739-109.5-42.617-58.274-70.617-121.69-28-63.416-28-129.258 0-151.564 100.398-247.88Q343.599-898.435 480-898.435t236.918 96.316q100.517 96.316 100.517 247.88 0 65.842-28.12 129.258-28.119 63.416-70.736 121.69-42.616 58.273-93.739 109.5-51.123 51.226-96.362 90.748-11.228 9-24.16 13t-24.625 4Zm.44-398.718q31.106 0 53.226-22.133 22.119-22.132 22.119-53.239 0-31.106-22.252-53.226-22.252-22.119-53.359-22.119-31.106 0-53.106 22.252t-22 53.359q0 31.106 22.133 53.106 22.132 22 53.239 22Z"></path></svg>
            {{ store?.address }}
          </span>
          <span v-else-if="employee.store?.address" class="address d-flex align-items-center">
            <svg data-v-28dcf1f8="" xmlns="http://www.w3.org/2000/svg" fill="#fff" height="18" viewBox="0 -960 960 960"
                 width="18" style="min-width: 18px; margin-right: 5px;">
              <path data-v-28dcf1f8=""
                    d="M479.693-86.043q-11.693 0-24.334-4t-22.598-13q-46.478-39.522-97.601-90.748-51.123-51.227-93.739-109.5-42.617-58.274-70.617-121.69-28-63.416-28-129.258 0-151.564 100.398-247.88Q343.599-898.435 480-898.435t236.918 96.316q100.517 96.316 100.517 247.88 0 65.842-28.12 129.258-28.119 63.416-70.736 121.69-42.616 58.273-93.739 109.5-51.123 51.226-96.362 90.748-11.228 9-24.16 13t-24.625 4Zm.44-398.718q31.106 0 53.226-22.133 22.119-22.132 22.119-53.239 0-31.106-22.252-53.226-22.252-22.119-53.359-22.119-31.106 0-53.106 22.252t-22 53.359q0 31.106 22.133 53.106 22.132 22 53.239 22Z"></path></svg>
            {{ employee.store?.address }}
          </span>
        </div>
      </div>
    </div>
    <div class="rounded-bg">
      <div class="user mt-10 p-25 pb-0">
        <transition appear name="slideAvatar">
          <div class="d-flex align-items-center justify-content-center">
            <div class="user-info ">
              <div class="name">{{ employee?.name }}</div>
              <div v-if="employee.target_text" class="desc">{{ employee.target_text }}</div>
              <div class="rating d-flex align-items-center justify-content-end">
                <svg class="rate" width="19" height="18" viewBox="0 0 19 18" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_501_3118)">
                    <path
                        d="M10.1482 1.01643L12.0566 5.33361C12.2027 5.66408 12.5188 5.89049 12.8818 5.92283L17.531 6.33627C18.0571 6.41221 18.2669 7.05205 17.8855 7.42049L14.3833 10.3385C14.0998 10.5747 13.9707 10.946 14.0487 11.3046L15.0668 16.031C15.1561 16.5499 14.6074 16.9464 14.1366 16.7003L10.0787 14.3435C9.76676 14.1621 9.3811 14.1621 9.06917 14.3435L5.01122 16.6989C4.5419 16.9436 3.99177 16.5485 4.08109 16.0296L5.09912 11.3031C5.17569 10.9446 5.04808 10.5733 4.76451 10.3371L1.26094 7.42189C0.880955 7.05486 1.0908 6.41361 1.61541 6.33768L6.26461 5.92424C6.62759 5.89189 6.94378 5.66549 7.08982 5.33502L8.99827 1.01783C9.23506 0.545332 9.9128 0.545332 10.1482 1.01643Z"
                        fill="#FDD835"/>
                    <path
                        d="M10.0091 5.59293L9.68581 2.41199C9.67304 2.23481 9.63618 1.93106 9.92259 1.93106C10.1494 1.93106 10.2728 2.39934 10.2728 2.39934L11.2426 4.95309C11.6084 5.92481 11.4581 6.25809 11.1051 6.45496C10.6996 6.67996 10.1012 6.50418 10.0091 5.59293Z"
                        fill="#FFFF8D"/>
                    <path
                        d="M14.0093 10.0562L16.7911 7.90324C16.9287 7.78934 17.1768 7.60793 16.9783 7.40121C16.8209 7.23809 16.3955 7.47293 16.3955 7.47293L13.9611 8.41652C13.2351 8.66543 12.753 9.03387 12.7105 9.49793C12.6552 10.1167 13.2153 10.5934 14.0093 10.0562Z"
                        fill="#F4B400"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_501_3118">
                      <rect width="18.1488" height="18" fill="white" transform="translate(0.5)"/>
                    </clipPath>
                  </defs>
                </svg>
                <span v-if="employee.rating" class="rate">{{ employee.rating}}</span>
                <span v-if="employee.rated" class="review">({{employee.rated}})</span>
              </div>
            </div>
            <img
                :style="'border-color: ' + getColor"
                :src="employee['photo_url'] | photo"
                class="avatar"
                alt=""
            />
          </div>
        </transition>
        <div class="d-none nickname">@{{ employee['login'] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {Sticky} from "vant";

export default {
  name: "HeaderBlock",
  props: {
    logo: {
      type: String,
      default: "",
    },
    store: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      storyModal: false,
      storyKey: 0,
      currentStoryIndex: 0,
      items: [],
      stickyEnabled: false,
    }
  },
  methods: {
    closeStories() {
      this.storyModal = false;
    },
    currentImgEnd(index) {
      this.currentStoryIndex = index;
    },
    currentAllImagesEnd(index) {
      this.items[index].images[this.items[index].images.length - 1].viewed = false;
    },
    onMoveItem(index) {
      this.storyKey++;
      this.currentStoryIndex = index;
      this.storyModal = true;
    },
    stickyChange(value) {
      this.stickyEnabled = value;
    }
  },
  computed: {
    ...mapState(["employee", "adModal"]),
    hideLogo() {
      let store = Object.keys(this.employee).length ? this.employee.store : this.store;
      return store?.['hide_logo'] ?? false;
    },
    getColor() {
      let store = Object.keys(this.employee).length ? this.employee.store : this.store;
      return store?.['button_color'] ?? "#1fb7a4";
    },
  },
  mounted() {
    // this.fetchStore();
  },
  components: {
    "van-sticky": Sticky,
    // Stories
  },
};
</script>

<style scoped></style>
