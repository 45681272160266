<template>
  <div id="market">
    <loading-block v-if="loading"/>
  </div>
</template>

<script>
import LoadingBlock from "@/components/menu/Loading.vue";
import Menu from "@/services/menu/service";
import {mapActions} from "vuex";

export default {
  title: "RAHMAT",
  name: "AuthPage",
  components: {
    LoadingBlock
  },
  data() {
    return {
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    Menu.appAuth({
      user_id: this.$route.query.user_id,
      token: this.$route.query.token,
      app: this.$route.query.app
    }).then(({ data }) => {
      if (data.data.authenticated) {
        this.$i18n.locale = data.data.locale;
        this.setLanguage(data.data.locale)
        console.log(this.$i18n.locale)
        this.saveToken(data.data.token)
        this.$router.push({ name: "MarketplacePage" })
      }
    }).catch((res) => {
      console.log(res)
    })
  },
  methods: {
    ...mapActions(['saveToken', "setLanguage"])
  },
  computed: {

  },
  mounted() {

  },
  beforeCreate() {
  },
  watch: {
  },
};
</script>

<style scoped lang="scss">
</style>
