<template>
  <div class="d-flex w-100 p20">
    <div class="d-flex flex-column w-100">
      <table v-if="(serviceCommissionPrice || tips || delivery)" class="table details-table mb-20 w-100">
        <tbody>
          <tr v-if="total">
            <td class="align-left border-bottom">{{ $t("check") }}</td>
            <td class="align-right border-bottom font-bold">
              <span>{{ total | money($i18n.locale) }}</span>
            </td>
          </tr>
          <tr v-if="tips">
            <td class="align-left border-bottom">{{ $t("tips") }}</td>
            <td class="align-right border-bottom font-bold">
              <span>{{ tips | money($i18n.locale) }}</span>
            </td>
          </tr>
          <tr v-if="serviceCommissionPrice">
            <td class="align-left border-bottom">{{ $t("service-commission") }}</td>
            <td class="align-right border-bottom font-bold">
              <span>{{ serviceCommissionPrice | money($i18n.locale) }}</span>
            </td>
          </tr>
          <tr v-if="delivery">
            <td class="align-left border-bottom">{{ $t("delivery-payment") }}</td>
            <td class="align-right border-bottom font-bold">
              {{ delivery | money($i18n.locale) }}
            </td>
          </tr>
        </tbody>
      </table>
      <p class="label-total">{{ $t("total-payment") }}</p>
      <p :style="{ color }" class="price-total">
        <span>{{ total + tips + serviceCommissionPrice + delivery | money($i18n.locale) }}</span>
      </p>
      <div class="label text-center mb-25">{{ $t("enter-code-from-sms") }}</div>
      <input
          class="otp"
          ref="otpInput"
          type="number"
          v-model="otpCode"
          :style="{ textIndent: otpCode.length ? '20px' : '0' }"
          maxlength="6"
          @input="handleOnChange"
      />
      <div class="text-center mt-30 pb-30">
        <van-button
          :loading="loadingOtp"
          :disabled="otpCode.length < 4"
          @click="confirm"
          style="width: 200px"
          :color="color"
          type="primary"
          >{{ $t("confirm") }}</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "vant";
import Service from "@/services/api/service";
import {mapActions, mapState} from "vuex";
export default {
  name: "PayCard",
  props: {
    mode: {
      type: String,
      default: "fastpay"
    },
    paydata: {
      type: Object,
      default: () => {}
    },
    cardId: {
      type: Number,
      required: true,
    },
    delivery: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
    },
    chequeDetails: {
      type: Object,
    },
    color: {
      type: String,
      default: '#1fb7a4'
    },
    serviceCommissionPrice: {
      type: Number,
      default: 0
    },
    tips: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      otpCode: "",
      loadingOtp: false,
      transfer: {},
    };
  },
  components: {
    "van-button": Button,
  },
  computed: {
    ...mapState(["store"]),
    transferFullAmount() {
      return this.$store.getters.transferFullAmount;
    },
    employee() {
      return this.$store.getters.employee;
    },
  },
  methods: {
    ...mapActions(["showLoading"]),
    handleOnChange(e) {
      this.otpCode = e.target.value;
    },
    confirm() {
      if (this.mode === 'fastpay')
        this.confirmFastPay()

      if (this.mode === 'precheck')
        this.confirmPrecheck()
    },
    async confirmPrecheck() {
      this.loadingOtp = true;
      await Service.precheckConfirm(this.paydata?.id, {
        otp: this.otpCode
      }).then((data) => {
        this.$emit("closeModal");
        if (data?.data?.data?.transfer) {
          this.transfer = data.data.data.transfer;
          this.$emit("transfer", this.transfer);
        }
      })
    },
    async confirmFastPay() {
      this.loadingOtp = true;
      await Service.fastPayConfirm(this.cardId, {
        otp: this.otpCode,
      }).then((data) => {
        this.loadingOtp = false;
        //check if not card push this card
        this.$store.commit("SET_CARD", data.data.data.card);
        this.success = true;
        this.$emit("closeModal");
        if (data?.data?.data?.transfer) {
          this.transfer = data.data.data.transfer;
          this.$emit("transfer", this.transfer);
        }
      }).catch((error) => {
        console.log(error);
        this.loadingOtp = false;
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.otpInput.focus();
    })
  },
};
</script>

<style scoped>
.details-table tr:last-child td {
  border: none;
}
</style>
