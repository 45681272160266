<template>
  <div class="cheque-details-wrap p-15 pb-0" v-if="products && products.length">
    <table class="table details-table no-border">
      <thead>
      <tr>
        <th
            :style="{ color }"
            style="width: 25%"
            class="text-left"
        >
          {{ $t("cheque-details-name") }}
        </th>
        <th
            :style="{ color }"
            style="width: 25%"
            class="text-center"
        >
          {{ $t("cheque-details-size") }}
        </th>
        <th
            :style="{ color }"
            style="width: 25%"
            class="text-right"
        >
          {{ $t("cheque-details-price") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(product, productKey) in products.slice(0, detailsLength)" :key="productKey">
        <td style="border-bottom: 1px solid #e4e4e4" class="text-left">
          {{ product.name }}
        </td>
        <td
            style="border-bottom: 1px solid #e4e4e4"
            class="text-center"
        >
          {{ product.weight }} {{ product.unit }}
        </td>
        <td
            style="border-bottom: 1px solid #e4e4e4"
            class="text-right"
        >
          {{ product.price | money($i18n.locale) }}
        </td>
      </tr>
      </tbody>
    </table>
    <div class="mt-25">
      <a
          v-if="products?.length > detailsLength"
          @click="detailsLength = products?.length"
          :style="{ color }"
      >{{ $t('cheque-details-more') }}</a>
      <a
          v-else-if="products?.length <= detailsLength && products?.length > 10"
          @click="detailsLength = 10"
          :style="{ color }"
      >{{ $t('cheque-details-hide') }}</a>
    </div>
  </div>
</template>
<script>

export default {
  name: "ProductsBlock",
  props: {
    products: {
      type: Array,
      default: null
    },
    color: {
      type: String,
      default: '#1fb7a4',
    }
  },
  data() {
    return {
      detailsLength: 10,
    }
  }
}
</script>
