<template>
  <div
    @click="pay(paymentSystem['name'])"
    class="payment-list__item pointer"
    :class="{
      'loading': loadingCard === paymentSystem['name'],
      'disabled': loadingCard && loadingCard !== paymentSystem['name'],
      'forbidden': paymentSystem['disabled'] || forbidden,
      'd-none': paymentSystem['hidden']
    }"
  >
    <div class="d-flex align-items-center">
      <van-loading
        v-if="loadingCard === paymentSystem['name']"
        class="text-center"
        type="spinner"
        size="22"
        :color="getColor"
      />
      <img
        v-else-if="paymentSystem['icon']"
        :src="paymentSystem['icon']"
        :alt="paymentSystem['name']"
        width="22"
      />
      <div class="payment-list__text">
        <div v-if="paymentSystem['label']">
          <img :src="paymentSystem['label']" alt="" />
        </div>
        <p v-else>{{ paymentSystem['name'] }} <i v-if="['alif', 'click'].includes(paymentSystem['name'])"> — </i>
          <span class="cashback-hint" v-if="paymentSystem['name'] === 'alif'">{{ $t('cashback', { percent: '1%'}) }}</span>
          <span v-if="paymentSystem['name'] === 'click'">{{ $t('cashback-up-to', { percent: '2%' }) }}</span>
        </p>
        <span v-html="$t(paymentSystem['hint'])"></span>
      </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="20" viewBox="0 0 11 20" fill="none">
      <path d="M10 10L10.7071 10.7071C11.0976 10.3166 11.0976 9.68342 10.7071 9.29289L10 10ZM0.292893 18.2929C-0.0976311 18.6834 -0.0976311 19.3166 0.292893 19.7071C0.683417 20.0976 1.31658 20.0976 1.70711 19.7071L0.292893 18.2929ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM7.75 11H10V9H7.75V11ZM9.29289 9.29289L0.292893 18.2929L1.70711 19.7071L10.7071 10.7071L9.29289 9.29289ZM10.7071 9.29289L1.70711 0.292893L0.292893 1.70711L9.29289 10.7071L10.7071 9.29289Z" fill="#D1D1D1"/>
    </svg>
  </div>
</template>

<script>
import {Loading} from "vant";

export default {
  props: {
    paymentSystem: {
      type: Object,
      default: () => {}
    },
    loadingCard: {
      type: [ String, Number, Boolean ],
      default: false
    },
    forbidden: {
      type: [ String, Number, Boolean ],
      default: false
    },
    getColor: String
  },
  methods: {
    pay(key) {
      this.$emit('pay', key);
    }
  },
  components: {
    "van-loading": Loading,
  }
};
</script>

<style scoped>
</style>
