import apiClient from "@/services/axios";
import vuexStore from "@/store";

class Marketplace {
  getStoresList(params) {
    apiClient.defaults.headers = {
      'X-Locale': vuexStore.state.language
    }
    return apiClient.get(`/qr-menu/marketplace`, {params});
  }
}

export default new Marketplace();
