<template>
  <div class="relative">
    <navigation-bill/>
    <AccountBlock/>
  </div>
</template>

<script>
import NavigationBill from "@/components/NavigationBill.vue";
import AccountBlock from "@/components/AccountBlock.vue";

export default {
  name: "AccountPage",
  components: {
    AccountBlock,
    "navigation-bill": NavigationBill,
  }
};
</script>

