import apiClient from "@/services/axios";
import vuexStore from "@/store";

class Menu {
  appAuth(data) {
    return apiClient.post(`/menu/auth/app-auth`, data)
  }

  getCurrentOrder(desk) {
    let headers = {}
    if (vuexStore.state.token) {
      headers["X-Menu-Access-Token"] = `Bearer ${vuexStore.state.token}`;
    }
    return apiClient.get(`/menu/order/view?desk=${desk}`, {headers})
  }

  getProducts(payload) {
    apiClient.defaults.headers = {
      "X-Menu-Access-Token": `Bearer ${vuexStore.state.token}`,
      "X-Device-Uuid": vuexStore.state.deviceUuid,
      "X-Locale": vuexStore.state.language
    }
    const params = new URLSearchParams(payload);
    return apiClient.get("/qr-menu/list", { params });
  }

  getDesks(brand) {
    apiClient.defaults.headers = {
      "X-Menu-Access-Token": `Bearer ${vuexStore.state.token}`,
      "X-Device-Uuid": vuexStore.state.deviceUuid,
      "X-Locale": vuexStore.state.language
    }
    return apiClient.get("/qr-menu/desks?brand=" + brand);
  }

  getReservationTable(payload) {
    apiClient.defaults.headers = {
      'X-Locale': vuexStore.state.language
    }
    const params = new URLSearchParams(payload);
    return apiClient.get("/qr-menu/store", { params });
  }

  getReservationData({number, token}) {
    apiClient.defaults.headers = {
      'X-Locale': vuexStore.state.language
    }
    let headers = {
      "X-Menu-Access-Token": `Bearer ${token}`,
    };
    console.log(number, 'number')
    return apiClient.get(`/menu/profile/reservation?number=${number}`, {headers});
  }

  submitReservation(payload, token, uuid) {
    let headers = {
      "X-Menu-Access-Token": `Bearer ${token}`,
      "X-Device-Uuid": uuid
    };
    return apiClient.post("/menu/order/reserve", payload, { headers });
  }

  getStoresList(brand, coords) {
    return apiClient.get(`/qr-menu/stores?brand=${brand}&coords=${coords}`);
  }

  authorize(phone, device_id) {
    return apiClient.post("/menu/auth/otp", {
      phone,
      device_id,
    });
  }

  verify(phone, otp) {
    return apiClient.post("/menu/auth/verify", {
      phone,
      otp,
    });
  }

  order(order) {
    let headers = {
      "X-Menu-Access-Token": `Bearer ${vuexStore.state.token}`,
      "X-Device-Uuid": vuexStore.state.deviceUuid,
    };

    return apiClient.post("/menu/order/create", order, { headers });
  }

  editOrder(order, token, uuid) {
    let headers = {
      "X-Menu-Access-Token": `Bearer ${token}`,
      "X-Device-Uuid": uuid,
    };

    return apiClient.put("/menu/order/add", order, { headers });
  }

  call(store, type, desk) {
    apiClient.defaults.headers = {
      "X-Menu-Access-Token": `Bearer ${vuexStore.state.token}`,
      "X-Device-Uuid": vuexStore.state.deviceUuid,
    };

    const params = new URLSearchParams({ store, type, desk });
    return apiClient.get("/menu/order/call", { params });
  }

  orderInfo(order) {
    let headers = {
      "X-Menu-Access-Token": `Bearer ${vuexStore.state.token}`,
      "X-Device-Uuid": vuexStore.state.deviceUuid,
    };
    return apiClient.get(`/menu/order/view?order=${order.uuid}`, { headers });
  }

  closeBill(order, token, uuid) {
    apiClient.defaults.headers = {
      "X-Menu-Access-Token": `Bearer ${token}`,
      "X-Device-Uuid": uuid,
    };
    return apiClient.post(`/menu/order/close?id=${order}`);
  }

  deleteOrder(order, token, uuid) {
    apiClient.defaults.headers = {
      "X-Menu-Access-Token": `Bearer ${token}`,
      "X-Device-Uuid": uuid,
    };
    return apiClient.delete(`/menu/order/delete?id=${order}`);
  }
}
export default new Menu();
