<template>
  <div class="auth-popup-content">
    <div class="auth-popup-inner">
      <div class="top">
        <h2 class="title">{{ $t("enter-code-from-sms") }}</h2>
        <h6 class="hint">
          {{$t("sent-code-number")}} <br/>{{ phoneNumber | phone(country) }}
        </h6>
      </div>
      <div class="otp-input-wrapper">
        <input
            class="otp"
            ref="otpInput"
            type="number"
            v-model="otp"
            :style="{ textIndent: otp.length ? '20px' : '0' }"
            @input="otpChange"
        />
<!--        <v-otp-input-->
<!--            ref="otpInput"-->
<!--            input-classes="otp-input"-->
<!--            separator=""-->
<!--            v-model="otp"-->
<!--            :num-inputs="4"-->
<!--            :should-auto-focus="true"-->
<!--            @on-change="onChangeOtp"-->
<!--            @on-complete="onCompleteOtp"-->
<!--        />-->
      </div>
      <p @click="onResetOtp" :class="resetOtp ? 'text-underline' : ''" class="text-center text-secondary">{{ info }}</p>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
// import OtpInput from "@bachdgvn/vue-otp-input";
import {Notify} from "vant";

export default {
  name: "VerificationPage",
  props: {
    phone: {
      type: String,
      default: ""
    },
    country: {
      type: String,
      default: "uz"
    }
  },
  emits: ["change-country", "close", "authenticated"],
  components: {
    // "v-otp-input": OtpInput
  },
  data() {
    return {
      countrySwitcherActive: false,
      phoneModel: "",
      modal: false,
      loading: false,
      emitAction: false,
      resetOtp: false,
      otp: "",
      mountedTime: new Date(),
      now: new Date(),
      remained: 30,
      info: `${this.$t("left")} 30 ${this.$t("seconds")}`
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.otpInput.focus();
    })
    this.mountedTime = new Date();
    setInterval(() => {
      this.now = new Date();
    }, 1000);
  },
  methods: {
    ...mapActions(["getToken", "authorize", "fetchAuthMe"]),
    // onChangeOtp() {
    //   this.$nextTick(() => {
    //     let list = document.getElementsByClassName("otp-input");
    //     for (let item of list) {
    //       if (item.value !== "") {
    //         item.classList.add("active");
    //       } else {
    //         item.classList.remove("active");
    //       }
    //     }
    //   });
    // },
    onResetOtp(e) {
      if (!this.resetOtp) {
        e.preventDefault();
        return;
      }
      const phone = this.phoneNumber;
      this.authorize(phone).then(() => {});
    },
    otpChange(e) {
      const phone = this.phoneNumber;
      const otp = e.target.value
      if (otp.length > 3)
        this.getToken({phone, otp})
            .then(() => this.getAuthData())
            .finally(() => {
              this.otp = "";
              this.$nextTick(() => {
                let list = document.getElementsByClassName("otp-input");
                for (let item of list) {
                  item.classList.remove("active");
                }
              });
            });
    },
    getCountryCode() {
      if (this.country === "uz") {
        return +998;
      }
      if (this.country === "ru") {
        return +7;
      }
      return +998;
    },
    async getAuthData() {
      this.loading = true;
      const token = this.$store.getters.token
      const device = this.$store.getters.deviceUuid
      await this.fetchAuthMe({token, device})
        .then((response) => {
          if (response) {
            this.$emit("authenticated", true);
            Notify({
              type: "success",
              message: this.$t("registered-successfully")
            });
          }
        })
        .catch(() => {
          this.$store.commit("SET_TOKEN", null);
          this.$store.commit("SET_REFRESH_TOKEN", null);
          this.$emit("authenticated", null);
          Notify({
            type: "warning",
            message: this.$t("incorrect-code")
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    phoneNumber() {
      return this.getCountryCode() + this.phone;
    }
  },
  watch: {
    now() {
      const diffTime = Math.abs(this.now - this.mountedTime);
      const diff = Math.round(diffTime / 1000);
      let left = this.remained - diff;
      if (left > 0) {
        this.info = `${this.$t("left")} ${left} ${this.$t("seconds")}`;
        this.resetOtp = false;
      } else {
        this.info = this.$t("send-code-again");
        this.resetOtp = true;
      }
    },
  }
};
</script>

<style scoped></style>
