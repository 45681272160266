<template>
  <van-popup
    class="map-popup"
    v-model="modalVisible"
    position="bottom"
    :style="{ height: '100%' }"
    closeable
    @opened="onModalOpened"
    close-icon-position="top-right"
  >
    <yandex-map
      :settings="mapSettings"
      :coords="center"
      :zoom="11"
      @click="onClickMap"
      style="height: 100%"
    >
      <ymap-marker
        :marker-id="markerId"
        :coords="coords"
        :marker-type="'placemark'"
        :icon="{ content: iconAddress }"
      />
    </yandex-map>
  </van-popup>
</template>

<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import { Popup } from "vant";
import { mapActions, mapGetters } from "vuex";
import Service from "@/services/menu/service";

export default {
  name: "DeliveryAddress",
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  components: {
    "van-popup": Popup,
    yandexMap,
    ymapMarker,
  },
  data() {
    return {
      mapSettings: {
        apiKey: "e9fe7cab-eb17-4480-976c-a9bd1756a5c1",
        lang: this.$i18n.locale,
        coordorder: "latlong",
        enterprise: false,
        version: "2.1",
      },
      center: [41.31115, 69.279737],
      coords: [],
      iconAddress: "...",
      markerId: 0,
    };
  },
  methods: {
    ...mapActions(["setCoords", "setAddress"]),
    getMapAddress: function () {
      window.ymaps?.ready(() => {
        window.ymaps.geocode(this.coords).then((res) => {
          const firstGeoObject = res.geoObjects.get(0);
          const address = firstGeoObject
            .getAddressLine()
            .replace(firstGeoObject.getCountry() + ", ", "");
          this.updateAddress(address);
        });
      });
      if (this.coords.length === 2) {
        if (this.$route.name !== "MarketplacePage") {
          Service.getStoresList(this.$route.params.brand, this.coords.toString());
        }
      }
    },
    onModalOpened: function () {
      this.coords = JSON.parse(JSON.stringify(this.coordinates));
      this.getMapAddress();
    },
    onClickMap: function (e) {
      this.iconAddress = "...";
      this.center = e.get("coords");
      this.setCoords(e.get("coords"));
      this.coords = e.get("coords");
      this.getMapAddress();
    },
    updateAddress: function (address) {
      this.setAddress(address);
      this.iconAddress = address;
    },
  },
  computed: {
    ...mapGetters(["coordinates"]),
    modalVisible: {
      get: function () {
        return this.modal;
      },
      set: function () {
        this.$emit("close");
      },
    },
  },
};
</script>

<style scoped></style>
