<template>
  <div>
    <van-popup
        class="date-modal sales-modal h-100 p-10"
        v-model="dateModal"
        position="bottom"
        closeable
        close-icon="close"
        close-icon-position="top-right"
        @close="dateModal = false"
        @open="$emit('open-date-modal')"
        :style="{ maxHeight: '50%' }"
    >
      <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="Choose Date"
          class="mt-30"
          :min-date="minDate"
          :max-date="maxDate"
          :confirm-button-text="'Confirm'"
          :cancel-button-text="''"
          :item-height="44"
          @confirm="confirmDate"
          @change="changeDate"
      />
    </van-popup>
    <div class="auth__top mb-20 mt-5">
      <div v-if="false" class="auth__avatar"><img src="/unknown.png" alt=""></div>
      <div class="form__item form__item--identify">
        <input v-model.trim="model.name" type="text" id="identify" class="input" name="identify" autocomplete="off" required>
        <label for="identify" class="label-name"><span class="content-name">{{ !model.name ? $t("unknown-friend") : $t("user-name") }}</span></label>
        <p v-if="!model.name" class="identify-edit cursor-pointer"><img src="/edit.svg" alt=""></p>
      </div>
      <span v-if="!model.name" class="identify-empty ">{{ $t("not-identified") }}</span>
    </div>
    <div class="auth__main mb-20">
      <form class="auth__form form">
        <div class="form__group">
          <div class="form__item">
            <input :value="currentDatePicker" @input="inputHandler" id="birth" @focus="onFocusDate" ref="birthInput" type="text" class="input" name="birth"
                   autocomplete="off" required/>
            <label for="birth" class="label-name"><span class="content-name">{{ $t("date-birth") }}</span></label>
          </div>
        </div>
        <div>
          <van-field class="mb-5" name="radio" :label="$t('gender')">
            <template #input>
              <van-radio-group v-model="model.gender" direction="horizontal">
                <van-radio
                    :name="1"
                    :checked-color="btnColor"
                    icon-size="18px"
                >{{$t("man")}}
                </van-radio>
                <van-radio
                    :name="2"
                    :checked-color="btnColor"
                    icon-size="18px"
                >{{$t("woman")}}
                </van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div class="form__group">
          <div class="form__item form__item--phone">
            <input
                type="text"
                v-model.trim="model.phone"
                @input="inputPhone"
                @focus="inputPhone"
                :disabled="isAuth"
                ref="phone"
                class="input"
                name="phone"
                id="phone"
                autocomplete="off"
                required
            />
            <label for="phone" class="label-name"><span class="content-name">{{ $t("phone") }}</span></label>
            <p v-if="!isAuth" @click="$emit('open-auth-model')" class="phone-edit cursor-pointer">
              <img src="/edit.svg" alt="">
            </p>
          </div>
        </div>
        <div class="form__group">
          <div class="form__item">
            <input v-model.trim="model.email" type="text" id="email" class="input" name="email" autocomplete="off" required/>
            <label for="email" class="label-name"><span class="content-name">{{ $t("email") }}</span></label>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {DatetimePicker, Field, Popup, Radio, RadioGroup,} from "vant";

let initialModel = {
  "phone": "",
  "name": "",
  "email": "",
  "gender": 0,
  "birthdate": null,
  "key": null
}

export default {
  name: "AuthForm",
  emits:["open-date-modal"],
  props: {
    authModel: {
      type: Object,
      default: () => ({})
    },
    savedData: {
      type: Boolean,
      default: true
    },
  },
  components: {
    "van-datetime-picker": DatetimePicker,
    "van-popup": Popup,
    "van-field": Field,
    "van-radio-group": RadioGroup,
    "van-radio": Radio,
  },
  data: function () {
    return {
      model: {...initialModel},
      minDate: new Date(1960, 1, 1),
      maxDate: new Date(2025, 12, 30),
      currentDatePicker: null,
      dateModal: false,
      isConfirmedDate: false,
      username: false,
    }
  },
  methods: {
    inputPhone() {
      this.$emit('open-auth-model');
      if (this.$refs.phone) this.$refs.phone.blur();
    },
    formatPhoneNumber(phoneNumberString) {
      const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/);
      if (match) {
        return '+' + match[1] + ' (' + match[2] + ') ' + match[3] + ' ' + match[4] + ' ' + match[5];
      }
      return null;
    },
    confirmDate() {
      this.model.birthdate = this.currentDatePicker;
      this.isConfirmedDate = true;
      this.dateModal = false;
    },
    onFocusDate() {
      this.isConfirmedDate = false;
      this.dateModal = true;
      if (this.$refs.birthInput)
        this.$refs.birthInput.blur();
    },
    changeDate() {
      this.inputHandler();
    },
    inputHandler() {
      this.currentDatePicker = this.currentDate;
    },
  },
  computed: {
    ...mapGetters(["token", "btnColor"]),
    isAuth() {
      return !!this.token;
    },
    hasChanged() {
      return Object.keys(this.model).some(field => this.model[field] !== initialModel[field]);
    },
    currentDate: {
      get() {
        if (!this.authModel || !this.authModel.birthdate)
          return null;

        return new Date(this.authModel.birthdate);
      },
      set(dateValue) {
        if (!dateValue) {
          const date = this.model.birthdate ? new Date(this.model.birthdate).toISOString().slice(0, 10) : null;
          this.currentDatePicker = date;
          return date;
        }

        const dt = new Date(dateValue);
        let day;
        if (this.savedData) {
          day = dt.getUTCDate();
        } else {
          day = dt.getUTCDate() + 1;
        }
        const month = dt.getUTCMonth();
        const year = dt.getUTCFullYear();
        const result = new Date(Date.UTC(year, month, day));
        const updated = new Date(result).toISOString().slice(0, 10);
        this.currentDatePicker = updated;
        this.model.birthdate = updated;
        return updated;
      }
    }
  },
  watch: {
    authModel(value) {
      if (Object.keys(value).length > 0) {
        this.currentDatePicker = value.birthdate ? new Date(value.birthdate).toISOString().slice(0, 10) : null;
        this.model = {...value};
        initialModel = {...value};
        this.currentDate = this.currentDatePicker;
        this.$nextTick(() => {
          this.model.phone = this.formatPhoneNumber(this.model.phone);
          initialModel.phone = this.formatPhoneNumber(this.model.phone);
        });
      }
    },
    model: {
      handler() {
        this.$emit("change-form", this.hasChanged);
        this.$emit("change-model", this.model);
      },
      deep: true,
    },
    isConfirmedDate() {
      this.$emit("change-form", this.hasChanged);
    },
  }
}
</script>

<style scoped>

.van-cell{
  flex-direction: column;
}
.input {
  background: none;
}

</style>
