<template>
  <div
    id="filterCatalog"
  >
    <van-popup
      v-model="modal"
      id="__filter"
      class="order-info-popup market-filter-modal pb-90"
      ref="filter"
      position="bottom"
      closeable
      close-icon="close"
      @closed="closedModal"
      @close="closeModal"
      @open="openModal"
      :style="{ maxHeight: '90%' }"
    >
      <div class="market-filter pt-35">
        <div v-if="false" class="market-filter__card">
          <h3 class="market-filter__label mt-0">{{ $t("filters.labels.delivery-time") }}</h3>
          <div class="market-filter__picks filter-delivery">
            <div
              v-for="time in deliveryTimes"
              :key="time"
              class="market-filter__pick"
            >
              <input
                v-model="setDelivery"
                :value="time"
                @change="setQuery"
                type="radio"
                name="delivery_time"
              />
              <p>
                {{ time }}
              </p>
            </div>
          </div>
        </div>
        <div
          @touchstart="() => {return;}"
          @touchmove="() => {return;}"
          @touchend="() => {return;}"
          class="market-filter__card pb-5">
          <h3 class="market-filter__label mt-0">{{ $t("filters.labels.check") }}</h3>
          <div class="range-slider pb-15 pl-5 pr-5">
            <div>
              <HistogramSlider
                :key="histogramKey"
                style="margin: auto;"
                :width="histogramWidth"
                :bar-height="50"
                :data="histogramData"
                :prettify="prettify"
                :step="10000"
                :min="getBillMin"
                :max="getBillMax"
                :drag-interval="true"
                :force-edges="false"
                :colors="['#29BF9B', '#0BABAB']"
                primaryColor="#29BF9B"
                labelColor="#29BF9B"
                :handle-size="20"
                :grid="false"
                :hideMinMax="true"
                @change="touchMoveMethod({changedTouches: []})"
                @finish="finishHistogram"
              >
              </HistogramSlider>
            </div>
          </div>
        </div>
        <div v-if="false" class="market-filter__card">
          <h3 class="market-filter__label mt-0">{{ $t("filters.labels.type") }}</h3>
          <div class="market-filter__picks filter-type">
            <div
              v-for="type in types"
              :key="type.id"
              class="market-filter__pick"
            >
              <input
                v-model="setType"
                :value="type.text"
                @change="setQuery"
                type="radio"
                name="type_check"
              />
              <p class="d-flex align-items-center">
                <img
                  :src="`/img/marketplace/filters/type_${type.id}${setType && setType.includes(type.text) ? '_active' : ''}.png`"
                  alt=""/>
                <span class="ml-10">{{ $t(`filters.types.${type.text}`) }}</span>
              </p>
            </div>
          </div>
        </div>
        <div v-if="false" class="market-filter__card">
          <h3 class="market-filter__label mt-0">{{ $t("filters.labels.sweet-profitable") }}</h3>
          <div :key="sweetsKey" class="market-filter__picks filter-sweet">
            <label
              v-for="sweet in sweets"
              :key="sweet.id"
              :for="sweet.id"
              class="market-filter__pick"
            >
              <input
                v-model="setSweets"
                :id="sweet.id"
                :value="sweet.text"
                type="checkbox"
                @change="setQuery"
              />
              <p class="d-flex align-items-center">
                <img
                  :src="`/img/marketplace/filters/tasty_${sweet.id}${setSweets.length &&
                   setSweets?.includes(sweet.text)
                   ? '_active' : ''}.png`"
                  alt=""/>
                <span class="ml-10">{{ $t(`filters.sweets.${sweet.text}`) }}</span>
              </p>
            </label>
          </div>
        </div>
        <div class="market-filter__card">
          <h3 class="market-filter__label mt-0">{{ $t("filters.labels.kitchen-food") }}</h3>
          <div class="market-filter__picks filter-kitchen align-items-center">
            <label
                for="cashback"
                class="market-filter__pick filter-kitchen__item d-flex flex-column align-items-center"
                :class="{active: typeof setKitchens === 'boolean' ?
                  setKitchens.toString().includes('cashback')
                  : setKitchens.includes('cashback')}"
            >
              <input
                  v-model="setKitchens"
                  id="cashback"
                  value="cashback"
                  type="checkbox"
                  @change="setQuery"
              />
              <img src="/img/cashback.png" style="padding: 4px" alt=""/>
              <span class="filter-kitchen__title">Кешбэк</span>
            </label>
            <label
              v-for="item in cuisine"
              :key="item.value"
              :for="item.value"
              class="market-filter__pick filter-kitchen__item d-flex flex-column align-items-center"
              :class="{active: typeof setKitchens === 'boolean' ?
                  setKitchens.toString().includes(item.value)
                  : setKitchens.includes(item.value)}"
            >
              <input
                v-model="setKitchens"
                :id="item.value"
                :value="item.value"
                type="checkbox"
                @change="setQuery"
              />
              <img :src="item.icon" alt=""/>
              <span class="filter-kitchen__title">{{ item.label?.slice(0, 9) }}{{item.label?.length > 9 ? '..' : ''}}</span>
            </label>
            <div
              v-if="loadMoreItem.cuisine"
              class="d-flex flex-column align-items-center load-down-block"
              @click="loadMore('cuisine')"
            >
              <div class="load-down"></div>
              <span class="load-down-text">+Ещё</span>
            </div>
          </div>
        </div>
        <div class="market-filter__card">
          <h3 class="market-filter__label mt-0">{{ $t('market.classification') }}</h3>
          <div class="market-filter__picks filter-kitchen justify-content-start">
            <label
              v-for="item in classification"
              :key="item.value"
              :for="item.value"
              class="market-filter__pick filter-kitchen__item filter-clickable d-flex flex-column align-items-center"
              :class="{active: typeof setKitchens === 'boolean' ?
                  setKitchens.toString().includes(item.value)
                  : setKitchens.includes(item.value)}"
            >
              <input
                v-model="setKitchens"
                :id="item.value"
                :value="item.value"
                type="checkbox"
                @change="setQuery"
              />
              <span class="clickable">{{ item.label?.slice(0, 9) }}{{item.label?.length > 9 ? '..' : ''}}</span>
            </label>
            <label
                v-if="loadMoreItem.classification"
                class="market-filter__pick filter-kitchen__item filter-clickable d-flex flex-column align-items-center"
                @click="loadMore('classification')"
            >
              <span class="clickable">+Ещё</span>
            </label>
          </div>
        </div>
        <div class="market-filter__card">
          <h3 class="market-filter__label mt-0">{{ $t('market.additional') }}</h3>
          <div class="market-filter__picks filter-kitchen justify-content-start">
            <label
              v-for="item in additional"
              :key="item.value"
              :for="item.value"
              class="market-filter__pick filter-kitchen__item filter-clickable d-flex flex-column align-items-center"
              :class="{active: typeof setKitchens === 'boolean' ?
                  setKitchens.toString().includes(item.value)
                  : setKitchens.includes(item.value)}"
            >
              <input
                v-model="setKitchens"
                :id="item.value"
                :value="item.value"
                type="checkbox"
                @change="setQuery"
              />
              <span class="clickable">{{ item.label?.slice(0, 9) }}{{item.label?.length > 9 ? '..' : ''}}</span>
            </label>
            <label
                v-if="loadMoreItem.additional"
                class="market-filter__pick filter-kitchen__item filter-clickable d-flex flex-column align-items-center"
                @click="loadMore('additional')"
            >
              <span class="clickable">+Ещё</span>
            </label>
          </div>
        </div>
        <div v-if="false" class="market-filter__card">
          <h3 class="market-filter__label mt-0">{{ $t("filters.labels.sorting") }}</h3>
          <div class="market-filter__picks filter-sort">
            <van-radio-group v-model="setSort">
              <van-radio
                v-for="sort in sorts"
                :key="sort.text"
                :name="sort.text"
                checked-color="#0babab"
                icon-size="18px"
                class="mb-20 label"
                @click="setQuery"
              >
                {{ $t(`filters.sorts.${sort.text}`) }}
              </van-radio>
            </van-radio-group>
          </div>
        </div>
      </div>
    </van-popup>
    <div class="filter-fixed" :class="{ show: modal }">
      <div class="filter-fixed__btns">
        <button
          v-if="checkFilterStatus"
          @click="reset"
          :class="{active: checkFilterStatus}"
          class="filter-fixed__btn filter-fixed__btn--reset"
        >
          {{ $t("filters.throw") }}
        </button>
        <button @click="onApply" class="filter-fixed__btn">{{ $t("filters.confirm") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {Popup, Radio, RadioGroup} from "vant";
import Filter from "@/models/Filter";

export default {
  name: "FilterPopup",
  components: {
    "van-popup": Popup,
    "van-radio": Radio,
    "van-radio-group": RadioGroup
  },
  props: {
    filterModal: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: () => []
    },
    billMin: {
      type: Number,
      default: 0
    },
    billMax: {
      type: Number,
      default: 0
    },
    histogramData: {
      type: Array,
      default: () => [],
    }
  },
  data: function () {
    return {
      modal: false,
      modalPosition: 0,
      average: [150000, 200000],
      activeTime: 0,
      checkActive: 0,
      typeActive: 0,
      sweetsKey: 0,
      histogramKey: 0,
      deliveryTimes: ["30", "45", "60", "60+"],
      types: [
        {id: 1, text: "delivery"},
        {id: 2, text: "takeaway"},
        {id: 3, text: "on_table"},
        // { id: 4, text: "В зале" }
      ],
      sweets: [
        {id: 1, text: "sales"},
        {id: 2, text: "cashback"},
        {id: 3, text: "top"},
        {id: 4, text: "delivery"}
      ],
      kitchens: [
        {id: 1, text: "burger", imgUrl: "/img/marketplace/filters/kitchen-1.png"},
        {id: 2, text: "pizza", imgUrl: "/img/marketplace/filters/kitchen-2.png"},
        {id: 3, text: "wok", imgUrl: "/img/marketplace/filters/kitchen-3.png"},
        {id: 4, text: "breakfast", imgUrl: "/img/marketplace/filters/kitchen-4.png"},
        {id: 5, text: "coffee", imgUrl: "/img/marketplace/filters/kitchen-4.png"}
      ],
      sorts: [
        // {id: "1", text: "sorting"},
        {id: "2", text: "default"},
        // {id: "3", text: "rating"},
        // {id: "4", text: "delivery_time"},
        {id: "5", text: "cheap_price"},
        {id: "6", text: "expensive_price"}
      ],
      isApply: false,
      oldQueryParams: {},
      oldKitchen: "",
      scrollPosition: 0,
      posYStart: null,
      animation: false,
      closed: false,
      changeRange: false,
      loadMoreItem: {
        cuisine: false,
        classification: false,
        additional: false,
      },
      cuisine: [],
      cuisineRest: [],
      cuisineData: [],
      classification: [],
      classificationRest: [],
      classificationData: [],
      additional: [],
      additionalRest: [],
      additionalData: [],
    };
  },
  methods: {
    finishHistogram(value) {
      this.$store.commit("UPDATE_QUERY_PARAMS_AVG_FROM", value.from);
      this.$store.commit("UPDATE_QUERY_PARAMS_AVG_TO", value.to);
      this.setQuery();
    },
    prettify: function (ts) {
      return this.formatAsCurrency(ts, 0);
    },
    touchStartMethod(event) {
      if (event.changedTouches.length !== 1) {
        return;
      }

      this.posYStart = event.changedTouches[0]?.clientY;
      this.animation = this.$refs.filter.$el.scrollTop === 0;
    },
    touchMoveMethod(event) {
      let hasVueHistogram = false;
      const elements = [
        "irs", "irs-bar", "irs-handle", "irs-shadow", "irs-grid",
        "irs-line", "irs-min", "irs-max", "irs-from", "irs-to",
        "irs-single", "vue-histogram-view", "slider-wrapper",
        "vue-histogram-slider-wrapper", "histogram", "range-slider"
      ];
      elements.forEach(element => {
        if (event.srcElement && event.srcElement.classList.contains(element)) {
          hasVueHistogram = true;
        }
      });
      if (event.changedTouches.length !== 1 || hasVueHistogram) {
        return;
      }

      const posYEnd = event.changedTouches[0].clientY;
      if (this.posYStart < posYEnd) {
        this.scrollPosition = "down";
      } else if (this.posYStart > posYEnd) {
        this.scrollPosition = "up";
      }
      const element = document.getElementById("__filter");

      this.closed = false;
      if (this.animation && this.scrollPosition === "down") {
        const digit = event.changedTouches[0].clientY.toFixed(0);
        element.style.transform = `translateY(${digit / 2}px)`;
        if ((digit / 2.4) < (posYEnd - this.posYStart).toFixed(0)) {
          element.style.transition = `transform .35s ease-in`;
          element.style.transform = `translateY(100%)`;
          this.closed = true;
          setTimeout(() => {
            this.modal = false;
            setTimeout(() => {
              element.style.transform = ``;
              element.style.transition = `transform .3s`;
            }, 300)
          }, 200)
        }
      }
    },
    touchEndMethod(event) {
      if (event.changedTouches.length !== 1) {
        return;
      }

      const element = document.getElementById("__filter");
      const posYEnd = event.changedTouches[0].clientY;
      if (this.posYStart < posYEnd && !this.closed) {
        element.style.transform = `translateY(0)`;
        element.style.transition = `transform .3s`;
      }
    },
    closeModal() {
      const element = document.getElementById("__filter");
      element.style.transform = `translateY(100%)`;
      element.style.transition = `transform .3s`;
      setTimeout(() => {
        element.style.transform = ``;
        element.style.transition = `transform .3s`;
      }, 300);
    },
    openModal() {
      this.loadMoreItem['cuisine'] = this.cuisineData.length > 10;
      this.loadMoreItem['classification'] = this.classificationData.length > 11;
      this.loadMoreItem['additional'] = this.additionalData.length > 11;
      if (this.checkFilterStatus) this.histogramKey++;
    },
    setQuery() {
      const query = this.$route.query;
      if (!Object.keys(query).length) {
        this.$store.commit("UPDATE_ROUTE_QUERY_PARAMS", {...query});
      }

      if ("sweets" in query) {
        if (typeof this.$route.query.sweets === "string") {
          this.$route.query.sweets = this.$route.query.sweets.split();
        }
        if (typeof this.$store.state.queryParams.sweets === "string") {
          this.$store.state.queryParams.sweets = this.$store.state.queryParams.sweets.split();
        }
      }
      this.$store.commit("UPDATE_QUERY_COLLECT");
      this.$store.commit("CHECK_QUERY_COLLECT");
      this.$store.commit("SET_QUERY_PARAMS");
    },
    onApply(type) {
      if (type !== "reset") this.modal = false;
      this.isApply = true;
      if (!this.checkFilterStatus) {
        this.$router.replace('/').catch(() => {
        });
        this.$store.commit("QUERY_PARAMS_COUNT", 0);
        this.$emit("confirm-filter");
        return;
      }

      this.$store.commit("CHECK_QUERY_COLLECT");
      this.$router.push({query: this.$store.getters["queryCollect"]}).catch(() => {
      });
      this.calcParams();
      this.$emit("confirm-filter");
    },
    reset() {
      this.oldQueryParams = {...this.$store.getters["queryParams"]};
      this.$store.commit("UPDATE_QUERY_PARAMS", new Filter({}));
      this.$store.commit("CHECK_QUERY_COLLECT");
      this.calcParams();
      this.isApply = false;
      this.$nextTick(() => {
        this.histogramKey++;
      })
    },
    closedModal() {
      if (!this.isApply) {
        this.$store.commit("UPDATE_QUERY_PARAMS", {...this.oldQueryParams});
      }
      this.$store.commit("CHECK_QUERY_COLLECT");
      this.$emit("on-closed");
    },
    calcParams() {
      const match = document.location.search.match(/=/g);
      if (match && match.length) {
        this.$store.commit("QUERY_PARAMS_COUNT",
          JSON.stringify(this.$route.query).includes("avg_from") ? match.length - 1 : match.length);
      } else {
        this.$store.commit("QUERY_PARAMS_COUNT", 0);
      }
    },
    formatAsCurrency(value, dec) {
      dec = dec || 0
      if (value == null) {
        return 0
      }

      return '' + parseInt(value).toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")
    },
    loadMore(type) {
      switch (type) {
        case 'cuisine':
          this.cuisine.push(...this.cuisineRest);
          break;
        case 'classification':
          this.classification.push(...this.classificationRest);
          break;
        case 'additional':
          this.additional.push(...this.additionalRest);
          break;
      }
      this.loadMoreItem[type] = false;
    },
  },
  computed: {
    checkFilterStatus: {
      get() {
        const initial = new Filter({});
        return JSON.stringify(this.$store.state.queryParams) !== JSON.stringify(initial);
      },
      set(value = this.isApply) {
        return value;
      }
    },
    setSweets: {
      get() {
        return this.$store.getters["queryParams"].sweets;
      },
      set(value) {
        return this.$store.commit("UPDATE_QUERY_PARAMS_SWEETS", value);
      }
    },
    setKitchens: {
      get() {
        return this.$store.getters["queryParams"].kitchens;
      },
      set(value) {
        return this.$store.commit("UPDATE_QUERY_PARAMS_KITCHEN", value);
      }
    },
    setSort: {
      get() {
        return this.$store.getters["queryParams"].sort;
      },
      set(value) {
        return this.$store.commit("UPDATE_QUERY_PARAMS_SORT", value);
      }
    },
    setType: {
      get() {
        return this.$store.getters["queryParams"].type;
      },
      set(value) {
        return this.$store.commit("UPDATE_QUERY_PARAMS_TYPE", value);
      }
    },
    setDelivery: {
      get() {
        return this.$store.getters["queryParams"].delivery;
      },
      set(value) {
        return this.$store.commit("UPDATE_QUERY_PARAMS_DELIVERY", value);
      }
    },
    setAvgTo: {
      get() {
        const result = this.$store.getters["queryParams"].avg_to;
        return this.formatAsCurrency(result, 0);
      },
      set(value) {
        value = value.toString().replace(/\s/g, '');
        if (value.length < 1) {
          value = 0;
        }
        return this.$store.commit("UPDATE_QUERY_PARAMS_AVG_TO", value);
      }
    },
    setAvgFrom: {
      get() {
        const result = this.$store.getters["queryParams"].avg_from;
        return this.formatAsCurrency(result, 0);
      },
      set(value) {
        value = value.toString().replace(/\s/g, '');
        if (value.length < 1) {
          value = 0;
        }
        return this.$store.commit("UPDATE_QUERY_PARAMS_AVG_FROM", value);
      }
    },
    maxRange() {
      const result = Math.max.apply(null, this.histogramData);
      new Filter({avg_to: result});
      return result;
    },
    minRange() {
      const result = Math.min.apply(null, this.histogramData);
      new Filter({avg_from: result});
      return result
    },
    histogramWidth() {
      let width = 0;
      const out = 90;
      if (window.innerWidth > 768) {
        width = 768 - out;
      } else {
        width = window.innerWidth - out;
      }
      return width;
    },
    getBillMin() {
      const result = this.$store.getters['queryParams'].avg_from;
      return result ? parseInt(result) : this.billMin;
    },
    getBillMax() {
      const result = this.$store.getters['queryParams'].avg_to;
      return result ? parseInt(result) : this.billMax;
    },
  },
  watch: {
    filterModal(value) {
      if (value) {
        this.oldQueryParams = {...this.$store.state.queryParams};
        this.isApply = false;
      }
      if (!this.isApply && !value) {
        this.$store.commit("UPDATE_QUERY_PARAMS", {...this.oldQueryParams})
      }
      this.modal = value;
    },
    filters(data) {
      if (data.length) {
        this.cuisineData = data.filter(f => f.type === "cuisine");
        this.cuisine = this.cuisineData.slice(0, 10);
        this.cuisineRest = this.cuisineData.slice(10);

        this.classificationData = data.filter(f => f.type === "classification");
        this.classification = this.classificationData.slice(0, 11);
        this.classificationRest = this.classificationData.slice(11);

        this.additionalData = data.filter(f => f.type === "additional");
        this.additional = this.additionalData.slice(0, 11);
        this.additionalRest = this.additionalData.slice(11);
      }
    },
  }
};
</script>

<style scoped lang="scss">
.range-toast {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .5);
  background: linear-gradient(95.73deg, #29BF9B -14.53%, #0BABAB 51.05%);

  span {
    position: absolute;
    width: auto;
    padding: 3px 4px;
    background-color: rgba(0, 0, 0, 0.9);
    color: #FFFFFF;
    font-size: 11px;
    border-radius: 2px;
    min-width: 50px;
    white-space: nowrap;
    top: -21px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
}

.average-minmax {
  span {
    font-size: 12px;
  }
}

.swipe-modal__swipe-line {
  position: fixed;
  width: 50px;
  height: 5px;
  background: #FFFFFF;
  border-radius: 10px;
  right: calc(50% - 25px);
  box-shadow: 0 0.7px 0.7px rgba(0, 0, 0, 0.15);
  top: 7px;
}

.filter-fixed {
  position: fixed;
  bottom: 12px;
  left: 0;
  right: 0;
  padding: 16px;
  z-index: 2100;
  display: none;
  max-width: 768px;
  margin: 0 auto;
  &.show {
    display: block;
  }

  &__btns {
    display: flex;
    align-items: center;
  }

  &__btn {
    color: #FFFFFF;
    background: linear-gradient(95.73deg, #29BF9B -14.53%, #0BABAB 51.05%);
    width: 100%;
    height: 50px;
    border-radius: 16px;
    padding: 0 10px;
    border: 1px solid transparent;

    &--reset {
      margin-right: 8px;
      background: #f5f5f5;
      border-color: rgba(0,0,0,.1);
      transition: all .25s;
      color: #21201F;

      &.active {
        color: #000000;
        background: #FFFFFF;
      }
    }
  }
}

.load-down{
  width: 56px;
  border: 0;
  height: 56px;
  margin: 0 8px 4px;
  padding: 0;
  border-radius: 16px;
  background-size: contain;
  background-color: #f5f5f5;
  background-image: url("https://eda.yandex/files/bdu/filters/more/icon.png");
  background-position: center;
  &-text {
    font-size: 11px;
    color: #0faeae;
  }
  &-block {
    padding: 4px 5px 8px;
    margin: 0 5px 10px;
  }
}
</style>
