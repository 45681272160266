import Vue from "vue";
import App from "./App.vue";
import HistogramSlider from "vue-histogram-slider";
import { Lazyload } from 'vant';
import i18n from "./i18n";
import title from "./mixins/title";
import router from "./router";
import store from "./store";
import "vue-histogram-slider/dist/histogram-slider.css";
import "vant/lib/index.css";
import "./assets/main.css";
import "./assets/main-new.css";
import "./assets/new.css";
import "./assets/styles/marketplace.css"
import "./utils/filters";
import './registerServiceWorker'
Vue.config.productionTip = false;
Vue.mixin(title);
Vue.use(Lazyload);
Vue.component(HistogramSlider.name, HistogramSlider);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
