<template>
  <van-popup
    class="auth-popup"
    v-model="modal"
    closeable
    close-icon="close"
    close-icon-position="top-right"
    position="bottom"
    @close="close"
    :style="{ maxHeight: '100%', minHeight: '60%' }"
  >
    <otp-page
      v-if="phoneVerified"
      :phone="phone"
      :current-country="currentCountry"
      @update-phone="updatePhone"
      @change-country="changeCountry"
    />
    <verification-page
      v-if="!phoneVerified"
      :key="verificationKey"
      :country="currentCountry"
      :phone="phoneModel"
      @close="close"
      @authenticated="authenticated"
    />
  </van-popup>
</template>

<script>
import {Popup} from "vant";
import OtpPage from "@/components/menu/auth/OtpPage";
import VerificationPage from "@/components/menu/auth/VerificationPage";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AuthBlock",
  props: {
    authModal: {
      type: Boolean,
      default: false,
    },
    requiredOpen: {
      type: Boolean,
      default: true,
    },
    currentCountry: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
  },
  emits: ["change-country", "close"],
  components: {
    "otp-page": OtpPage,
    "verification-page": VerificationPage,
    "van-popup": Popup,
  },
  data() {
    return {
      countrySwitcherActive: false,
      verificationKey: 0,
      phoneModel: "",
      phoneNumber: "",
      modal: false,
    };
  },
  mounted() {
    this.setVerified(true);
    if (!this.token && this.requiredOpen) {
      this.modal = true;
    }
  },
  methods: {
    ...mapActions(["setVerified"]),
    close() {
      this.$emit("close");
    },
    changeCountry(country) {
      this.$emit("change-country", country);
    },
    updatePhone(phone) {
      this.phoneModel = phone;
      this.verificationKey++;
    },
    authenticated(data) {
      this.$emit('close');
      console.error(data);
    },
  },
  computed: {
    ...mapGetters(["phoneVerified", "token"]),
  },
  watch: {
    phoneVerified(value) {
      if (value) {
        this.modal = false;
      }
    },
    authModal(value) {
      this.modal = value;
    },
    token(value) {
      if (value) {
        this.$emit('close');
        this.modal = false;
      }
    }
  },
};
</script>

<style scoped></style>
