<template>
  <van-popup
    v-model="modal"
    class="order-info-popup"
    closeable
    close-icon-position="top-right"
    close-icon="close"
    position="bottom"
    @closed="onClosed"
    :style="{ maxHeight: '90%' }"
  >
    <h2 class="title">Ваш заказ №{{ orderNumber }}</h2>
    <p class="hint">
      Информация о статусе обновляется автоматически. Отображается статус вашего
      прошлого заказа.
    </p>
    <van-button class="add-dishes" @click="addDishes"
      >Добавить блюдо</van-button
    >
    <van-button class="make-order" @click="newOrder"
      >Сделать новый заказ</van-button
    >
    <van-steps direction="vertical" :active="statusStep">
      <van-step>
        <h3>Заказ принят</h3>
        <p v-if="orderStatus === 2">Ваш заказ принят</p>
      </van-step>
      <van-step>
        <h3>Приготовление</h3>
        <p v-if="orderStatus === 4">Ваш заказ готовится</p>
      </van-step>
      <van-step>
        <h3>Заказ на кассе</h3>
        <p v-if="orderStatus === 5">Ваш заказ готов, можете забрать на кассе</p>
      </van-step>
      <van-step>
        <h3>Заказ выполнен</h3>
        <p v-if="orderStatus === 7">Ваш заказ выполнен</p>
      </van-step>
    </van-steps>
    <div class="w-100 text-center">
      <van-button class="leave-tips-btn">Оставить чаевые</van-button>
    </div>
  </van-popup>
</template>

<script>
import { Button, Popup, Step, Steps } from "vant";
import { mapActions } from "vuex";

export default {
  name: "OrderInfo",
  emits: ["close-order-info-modal", "add-dishes"],
  props: {
    orderInfoModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modal: false,
      orderNumber: null,
      orderStatus: 0,
    };
  },
  components: {
    "van-step": Step,
    "van-steps": Steps,
    "van-popup": Popup,
    "van-button": Button,
  },
  updated() {
    this.getOrderStatus().then((res) => {
      let order = res?.data?.data;
      if (order) {
        this.orderNumber = order.number;
        this.orderStatus = order.status;
        if (order.number > 6) {
          this.flushOrder()
        }
      }
    });
  },
  methods: {
    ...mapActions(["getOrderStatus", "flushOrder"]),
    onClosed: function () {
      this.$emit("close-order-info-modal");
    },
    newOrder: function () {
      this.flushOrder();
      this.$emit("close-order-info-modal");
    },
    addDishes: function () {
      this.$emit("add-dishes");
    },
  },
  watch: {
    orderInfoModal(value) {
      this.modal = value;
    },
  },
  computed: {
    statusStep() {
      return this.orderStatus - 1;
    },
  },
};
</script>

<style scoped></style>
