<template>
  <van-popup
    :key="keyMap"
    class="map-popup map-popup--branch"
    v-model="modalVisible"
    position="bottom"
    :style="{ height: '100%' }"
    closeable
    @opened="onModalOpened"
    @close="onModalClose"
    close-icon-position="top-right"
  >
    <yandex-map
      :settings="mapSettings"
      :coords="center"
      :zoom="15"
      :controls="['zoomControl', 'geolocationControl']"
      @click.stop="() => {}"
      style="
      height: 50%;
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 5;
      left: unset;
      max-width: 768px;
      margin: 0 auto;"
    >
      <ymap-marker
        :marker-id="markerId"
        :coords="center"
        :marker-type="'placemark'"
        :icon="{ content: iconAddress }"
      />
    </yandex-map>
    <div class="branches branches-address pt-25 pb-45 pr-20 pl-20" style="margin-top: 50vh">
      <van-radio-group v-model="branch">
        <van-radio
          v-for="(branch, key) in branches"
          :key="key"
          :name="key"
          :checked-color="btnColor"
          icon-size="18px"
          class="mb-5 branch-label align-items-baseline"
          :class="!branch.address ? 'd-none' : ''"
          @click="changeAddress(branch.coordinates.split(','), branch.address)"
        >
          <div v-if="branch.address" class="address-content-text w-100">
            <span class="ml-0 d-block font-semibold">{{ branch.address }}</span>
            <div class="address-actions d-flex align-items-center">
              <a v-if="branch.phone" @click="openWindow('tel:+' + branch.phone)" class="item item-phone">
                <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22">
                  <path fill="#adadad" d="M806.696-102.804q-123.283 0-249.066-58-125.782-58-232.065-164.283Q219.043-430.609 160.924-557.13q-58.12-126.522-58.12-249.326 0-22.153 14.294-36.566 14.293-14.413 36.446-14.413h153.26q18.066 0 30.837 11.555 12.772 11.554 17.25 30.38l28.522 132.63q1.239 16.109-1.978 30.261-3.217 14.153-11.37 21.827l-103.521 103.13q23.043 40.565 51.445 76.869 28.402 36.305 62.446 69.109 35.282 37.522 73.467 66.446t78.511 50.967l102.043-102.804q10.674-11.913 24.185-16.131 13.511-4.217 27.859-1.261l128.478 28.848q18.587 5.196 30.522 19.109 11.935 13.913 11.935 32.5v150.456q0 22.153-14.413 36.446-14.413 14.294-36.326 14.294Z"/>
                </svg>
              </a>
              <div v-if="branch.telegram" @click="openWindow('https://t.me/' + branch.telegram)" class="item item-telegram">
                <svg width="22" height="22" viewBox="0 0 48 48"  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M35.344 39.6566L41.3898 11.2638C41.5179 10.6319 40.897 10.1085 40.2957 10.3417L6.91785 23.0657C6.24543 23.3269 6.23612 24.3717 6.89211 24.6706L15.4425 28.1773L18.6266 38.2198C18.8161 38.7608 19.4901 38.942 19.9255 38.5697L24.8158 34.0188L34.1131 40.185C34.5955 40.4873 35.2307 40.2141 35.344 39.6566ZM20.1971 30.0791L19.6539 35.2416L17.0727 27.2941L35.2762 15.3395L20.1971 30.0791Z" fill="#adadad"/>
                </svg>
              </div>
              <div v-if="branch.instagram" @click="openWindow('https://instagram.com/' + branch.instagram)" class="item item-instagram">
                <img src="/catalog/icons/logo-instagram.svg" alt=""/>
              </div>
              <!--              v-if="isDeviceOnMobile"-->
              <div v-if="false" @click="$emit('open-map-modal', branch.coordinates.split(','))" class="item item-map">
                <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22">
                  <path fill="#adadad" d="M352.29-111.848q-70.232 0-118.087-48.259-47.855-48.259-47.855-117.523v-341.174q-35.239-14.353-57.62-43.848-22.38-29.496-22.38-67.015 0-50.746 34.481-85.116 34.481-34.369 83.643-34.369 50.401 0 84.791 34.619 34.389 34.62 34.389 84.533 0 37.608-22.88 67.282-22.881 29.675-57.12 43.914v341.182q0 36.903 25.534 62.686 25.533 25.784 63.133 25.784 37.927 0 63.728-25.763 25.801-25.762 25.801-62.636V-682.37q0-70.152 47.735-118.467 47.736-48.315 117.968-48.315 70.471 0 118.906 48.315 48.434 48.315 48.434 118.467v340.174q34 15.239 57 44.788 23 29.548 23 66.995 0 49.707-34.709 84.136-34.71 34.429-84.752 34.429-49.303 0-83.693-34.489-34.389-34.489-34.389-84.007 0-37.466 22.38-67.42 22.381-29.953 57.62-44.432V-682.37q0-36.948-26.271-63.213t-63.396-26.265q-36.927 0-62.728 26.265-25.801 26.265-25.801 63.213v404.74q0 69.264-48.315 117.523T352.29-111.848Z"/>
                </svg>
              </div>
              <div  @click="$emit('open-yandex-modal', branch.coordinates.split(','))" class="item item-yandex">
                <img
                  src="/yandex-taxi.jpeg" alt="">
              </div>
            </div>
          </div>
        </van-radio>
      </van-radio-group>
    </div>
  </van-popup>
</template>

<script>
import {yandexMap, ymapMarker} from "vue-yandex-maps";
import {useUserAgent} from "@/helpers/useUserAgent";
import { Popup, Radio, RadioGroup} from "vant";
import Service from "@/services/menu/service";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CatalogBranchAddress",
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    coordinators: {
      type: Array,
      default: () => []
    },
    branches: {
      type: Array,
      default: () => []
    },
    isDeviceOnMobile: {
      type: Boolean,
      default: false
    }
  },
  emits: ["close"],
  components: {
    "van-popup": Popup,
    "van-radio": Radio,
    "van-radio-group": RadioGroup,
    yandexMap,
    ymapMarker,
  },
  data() {
    return {
      mapSettings: {
        apiKey: "e9fe7cab-eb17-4480-976c-a9bd1756a5c1",
        lang: this.$i18n.locale,
        coordorder: "latlong",
        enterprise: false,
        version: "2.1",
      },
      center: this.coordinators,
      coords: [],
      iconAddress: "...",
      markerId: 0,
      branch: 0,
      keyMap: 0
    };
  },
  methods: {
    ...mapActions(["setCoords", "setAddress"]),
    openWindow(url) {
      if (this.useragent === 'multicard') {
        window.flutter_inappwebview.callHandler('browser', {url})
      // } else if (useUserAgent().iosAndroid() && url.match('tg://t.me')) {
      //   const updatedUrl = url.replace('tg://t.me', 'https://t.me')
      //   window.open(updatedUrl, '_blank')
      //   return;
      } else {
        window.open(url, '_blank')
      }
    },
    changeAddress(coords, address) {
      this.center = coords;
      this.updateAddress(address);
    },
    getMapAddress: function () {
      window.ymaps?.ready(() => {
        window.ymaps.geocode(this.coords).then((res) => {
          const firstGeoObject = res.geoObjects.get(0);
          const address = firstGeoObject
            .getAddressLine()
            .replace(firstGeoObject.getCountry() + ", ", "");
          this.updateAddress(address);
        });
      });
      if (this.coords.length === 2) {
        if (this.$route.name !== "MarketplacePage") {
          Service.getStoresList(this.$route.params.brand, this.coords.toString());
        }
      }
    },
    onModalOpened: function () {
      this.coords = JSON.parse(JSON.stringify(this.coordinates));
      this.getMapAddress();
    },
    onModalClose() {
    },
    onClickMap: function (e) {
      this.iconAddress = "...";
      this.center = e.get("coords");
      this.setCoords(e.get("coords"));
      this.coords = e.get("coords");
      this.getMapAddress();
    },
    updateAddress: function (address) {
      this.iconAddress = address;
    },
  },
  computed: {
    ...mapGetters(["coordinates", "btnColor"]),
    modalVisible: {
      get: function () {
        return this.modal;
      },
      set: function () {
        this.$emit("close");
      },
    },
    useragent() {
      return useUserAgent().getUserAgent()
    },
  },
  watch: {
    coordinators(value) {
      this.center = value;
      this.keyMap++;
    },
    modal(value) {
      if (value) {
        this.center = this.coordinators;
        this.branch = 0;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.address-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 18px;

  &-text {
    position: relative;
    padding-left: 15px;
    font-size: 15px;
    margin-bottom: 10px;

    .location-icon {
      position: absolute;
      left: 0;
      top: 0px;
    }
  }
}

.address-actions {
  align-self: flex-end;
  float: right;
  margin-top: 10px;
}


</style>
