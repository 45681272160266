import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import Service from "@/services/api/service";
import Menu from "@/services/menu/service";
import Profile from "@/services/profile/service";
import Product from "@/models/Product";
import Filter from "@/models/Filter";
import {Notify} from "vant";

Vue.use(Vuex);
Vue.config.devtools = true;

export default new Vuex.Store({
  state: {
    store: {},
    storesList: [],
    brandsList: [],
    filtersList: [],
    employee: {},
    storeEmployees: [],
    storeLogo: "",
    cards: [],
    loading: true,
    review: {},
    response: {},
    transferFullAmount: false,
    language: "",
    dontAsk: false,
    onlyTips: false,
    onlyTipsAsked: 0,
    modeOnlyBill: false,
    status: false,
    adModal: false,
    // MENU
    menuLoading: true,
    addressLoading: false,
    products: [],
    cart: [],
    order: [],
    canOrder: true,
    canReserve: false,
    addDishesMode: false,
    address: null,
    coordinates: [],
    btnColor: '#0faeae',
    menuCoords: [],
    bookingCoords: [],
    marketCatalogCurrentCoords: [],
    token: null,
    refreshToken: null,
    phoneVerified: true,
    deviceUuid: null,
    favorites: [],
    userInfo: {},
    // MARKETPLACE
    queryParams: new Filter({}),
    queryCollect: {},
    paramsCount: 0,
    orderType: "На доставку",
    navigationMenuHomeRoute: "/",
    navigationBillHomeRoute: "",
    userAddress: "",
  },
  getters: {
    employee: (state) => state.employee,
    store: (state) => state.store,
    storesList: (state) => state.storesList,
    brandsList: (state) => state.brandsList,
    filtersList: (state) => state.filtersList,
    review: (state) => state.review,
    storeEmployees: (state) => state.storeEmployees,
    storeLogo: (state) => state.storeLogo,
    response: (state) => state.response,
    transferFullAmount: (state) => state.transferFullAmount,
    language: (state) => state.language,
    dontAsk: (state) => state.dontAsk,
    onlyTips: (state) => state.onlyTips,
    modeOnlyBill: (state) => state.modeOnlyBill,
    onlyTipsAsked: (state) => state.onlyTipsAsked,
    status: (state) => state.status,
    adModal: (state) => state.adModal,
    // MENU
    menuLoading: (state) => state.menuLoading,
    addressLoading: (state) => state.addressLoading,
    products: (state) => state.products,
    cart: (state) => state.cart,
    order: (state) => state.order,
    canOrder: (state) => state.canOrder,
    canReserve: (state) => state.canReserve,
    addDishesMode: (state) => state.addDishesMode,
    deviceUuid: (state) => state.deviceUuid,
    address: (state) => state.address,
    coordinates: (state) => state.coordinates,
    token: (state) => state.token,
    refreshToken: (state) => state.refreshToken,
    phoneVerified: (state) => state.phoneVerified,
    btnColor: (state) => state.btnColor,
    menuCoords: (state) => state.menuCoords,
    bookingCoords: (state) => state.bookingCoords,
    userInfo: (state) => state.userInfo,
    // MARKETPLACE
    queryParams: (state) => state.queryParams,
    queryCollect: (state) => state.queryCollect,
    favorites: (state) => state.favorites,
    userAddress: (state) => state.userAddress,
    marketCatalogCurrentCoords: (state) => state.marketCatalogCurrentCoords,
  },
  mutations: {
    SET_STORE(state, store) {
      state.store = store;
    },

    SET_STORES_LIST(state, stores) {
      state.storesList = stores;
    },

    SET_BRANDS_LIST(state, brands) {
      state.brandsList = brands;
    },

    SET_FILTERS_LIST(state, filters) {
      state.filtersList = filters;
    },

    SET_EMPLOYEE(state, employee) {
      state.employee = employee;
    },

    SET_STORE_EMPLOYEES(state, employees) {
      state.storeEmployees = employees;
    },

    SET_STORE_LOGO(state, logo) {
      state.storeLogo = logo;
    },

    SET_RESPONSE(state, response) {
      state.response = response;
    },

    SET_LOADING(state, loading) {
      state.loading = loading;
    },

    SET_CARD(state, card) {
      if (state.cards.filter((e) => e.pan === card.pan).length > 0) {
        console.log("card not found");
      } else {
        state.cards.push(card);
      }
    },

    REMOVE_CARD(state, index) {
      state.cards.splice(index, 1);
    },

    FLUSH_CARDS(state) {
      state.cards = []
    },

    SET_REVIEW(state, review) {
      state.review = review;
    },

    SET_TRANSFER_FULL_AMOUNT(state, value) {
      state.transferFullAmount = value;
    },

    SET_LANGUAGE(state, language) {
      state.language = language;
    },

    SET_DONT_ASK(state, value) {
      state.dontAsk = value;
    },

    SET_ONLY_TIPS(state, value) {
      state.onlyTips = value;
    },

    SET_MODE_ONLY_BILL(state, value) {
      state.modeOnlyBill = value;
    },

    SET_ONLY_TIPS_ASKED(state, count) {
      state.onlyTipsAsked = count;
    },

    SET_STATUS(state, status) {
      state.status = status;
    },

    SET_AD_MODAL(state, value) {
      state.adModal = value;
    },

    SET_BOOKING_COORDS(state, coords) {
      state.bookingCoords = coords;
    },

    // MENU

    SET_MENU_LOADING(state, loading) {
      state.menuLoading = loading;
    },

    SET_ADDRESS_LOADING(state, loading) {
      state.addressLoading = loading;
    },

    SET_PRODUCTS(state, products) {
      state.products = products;
    },

    SET_VERIFIED(state, verified) {
      state.phoneVerified = verified;
    },

    SET_USER_INFO(state, payload) {
      state.userInfo = payload;
    },

    SET_CAN_RESERVE(state, payload) {
      state.canReserve = payload;
    },

    CART_PUSH_MOD(state, {id, item, count}) {
      const product = state.cart.find(p => p.item.id === id);
      if (product) {
        const data = product.item.modifiers.find(mod => {
          return mod.item.id === item.id
        });

        if (data) {
          data.count = count;
        } else {
          product.item.modifiers.push({
            item,
            count
          });
        }
      }
    },

    CART_TOGGLE_MOD(state, {id, item}) {
      const product = state.cart.find(p => p.item.id === id);
      if (product) {
        const data = product.item.modifiers.find(mod => {
          return mod?.item.id === item.id;
        });

        if (!data) {
          product.item.modifiers.push({
            item,
            count: 1
          });
        } else {
          const index = product.item.modifiers.findIndex(f => f.item.id === item.id);
          if (index > -1) product.item.modifiers.splice(index, 1);
        }
      }
    },

    CLEAR_CURRENT_MENU_MODIFIER(state, id) {
      const product = state.cart.find((cart) => cart.item?.id === id);
      if (product && product.item.modifiers.length) {
        product.item.modifiers = [];
      }
    },

    CART_PUSH(state, payload) {
      state.cart.push(...payload);
    },

    CART_PUSH_WITHOUT_MOD(state, payload) {
        state.cart.push(payload);
    },

    CART_REMOVE(state, {item}) {
      const newArray = [];
      for (const object of state.cart) {
        if (object.id === item.id) {
          newArray.push(object);
        }
      }

      newArray.pop();
      state.cart = state.cart.filter(obj => obj.id !== item.id);
      state.cart.push(...newArray);
    },

    CART_REMOVE_ONE(state, item) {
      const index = state.cart.findIndex(obj => obj.id === item.id);
      if (index > -1)
        state.cart.splice(index, 1);
    },

    CART_REMOVE_BY_INDEX(state, cartItem) {
      if (!cartItem.modifierRequired) {
        const order = state.cart.find(f => f.id === cartItem.id);
        if (order)
          state.cart = state.cart.filter(f => f.id !== order.id);
      } else {
        const index = state.cart.findIndex(f => f.idx === cartItem.idx);
        state.cart.splice(index, 1);
        console.log(index, 'orderIndex')
      }

      // Array.prototype.removeByValue = function (payload) {
      //   for (let i = 0; i < this.length; i++) {
      //     if (this[i].item.id === payload.item.id) {
      //       if (payload.all) {
      //         this[i].count = 0;
      //       } else {
      //         this[i].count--;
      //       }
      //       if (this[i].count <= 0) {
      //         this.splice(i, 1);
      //       }
      //       //     i--;
      //     }
      //   }
      // };
      //
      // state.cart.removeByValue(payload);
      // return this;
    },

    CART_REMOVE_MINUS(state, {item, minusCount}) {
      const newArray = [];
      for (const object of state.cart) {
        if (object.id === item.id) {
          newArray.push(object);
        }
      }

      const arrLength = newArray.length;
      const decreased = arrLength - minusCount;

      for (let i = 0; i < decreased; i++) {
        newArray.pop();
      }

      state.cart = state.cart.filter(obj => obj.id !== item.id);
      state.cart.push(...newArray);
    },

    CART_CLEAR(state) {
      state.cart = [];
    },

    CART_FLUSH(state) {
      while (state.cart.length > 0) {
        state.cart.pop();
      }
      return this;
    },

    PUSH_ORDER(state, order) {
      state.order.push(order);
    },

    FLUSH_ORDER(state) {
      state.order = [];
    },

    SET_CAN_ORDER(state, value) {
      state.canOrder = value;
    },

    SET_ADD_DISHES(state, value) {
      state.addDishesMode = value;
    },

    SET_DEVICE_UUID(state, uuid) {
      state.deviceUuid = uuid;
    },

    SET_ADDRESS(state, address) {
      state.address = address;
    },

    SET_COORDS(state, coordinates) {
      state.coordinates = coordinates;
    },

    SET_BTN_COLOR(state, color) {
      state.btnColor = color;
    },

    SET_TOKEN(state, token) {
      state.token = token;
    },

    SET_REFRESH_TOKEN(state, token) {
      state.refreshToken = token;
    },

    SET_MENU_COORDS(state, coords) {
      state.menuCoords = coords;
    },

    // MARKETPLACE
    SET_FAVORITE(state, store) {
      const itemIndex = state.favorites.findIndex(item => +item.id === +store.id);
      if (itemIndex > -1) {
        state.favorites.splice(itemIndex, 1);
      } else {
        state.favorites.push(store);
      }
    },
    REMOVE_FAVORITE(state, id) {
      const itemIndex = state.favorites.findIndex(item => +item.id === +id);
      if (itemIndex > -1) {
        state.favorites.splice(itemIndex, 1);
      }
    },
    CHECK_QUERY_COLLECT(state) {
      const initial = new Filter({});
      Object.entries(state.queryCollect).forEach(([key, value]) => {
        if (Object.values(initial).some(item => item === value)) {
          delete state.queryCollect[key];
        }
        if (!state.queryCollect['name']) {
          delete state.queryCollect['name'];
        }
      });
    },
    QUERY_PARAMS_COUNT(state, payload) {
      state.paramsCount = payload;
    },
    SET_QUERY_PARAMS(state) {
      Object.entries(state.queryParams).forEach(([key, value]) => {
        if (value) {
          state.queryCollect[key] = value;
          if (key === "type") {
            state.orderType = value;
          }
        }
      });
    },
    UPDATE_ORDER_TYPE(state, payload) {
      state.orderType = payload;
    },
    UPDATE_QUERY_PARAMS(state, payload) {
      state.queryParams = payload;
    },
    UPDATE_QUERY_COLLECT(state) {
      Object.entries(state.queryParams).forEach(([key, value]) => {
        if (value) {
          state.queryCollect[key] = value;
        }
      });
    },
    UPDATE_QUERY_PARAMS_KITCHEN(state, payload) {
      state.queryParams.kitchens = payload;
    },
    UPDATE_QUERY_PARAMS_SORT(state, payload) {
      state.queryParams.sort = payload;
    },
    UPDATE_QUERY_PARAMS_TYPE(state, payload) {
      state.queryParams.type = payload;
    },
    UPDATE_QUERY_PARAMS_AVG_TO(state, payload) {
      state.queryParams.avg_to = payload;
    },
    UPDATE_QUERY_PARAMS_AVG_FROM(state, payload) {
      state.queryParams.avg_from = payload;
    },
    UPDATE_QUERY_PARAMS_SWEETS(state, payload) {
      state.queryParams.sweets = payload;
    },
    UPDATE_QUERY_PARAMS_DELIVERY(state, payload) {
      state.queryParams.delivery = payload;
    },
    UPDATE_QUERY_PARAMS_BRAND(state, payload) {
      state.queryParams.brand_id = payload;
    },
    UPDATE_ROUTE_QUERY_PARAMS(state, params) {
      if (params) {
        const check = ["false", "true", false, true];
        if (check.some(el => typeof state.queryParams["kitchens"] === "boolean" || state.queryParams["kitchens"]?.includes(el))) {
          state.queryParams["kitchens"] = [];
        }
        if (check.some(el => typeof state.queryParams["sweets"] === "boolean" || state.queryParams["sweets"]?.includes(el))) {
          state.queryParams["sweets"] = [];
        }
        Object.keys(state.queryParams).forEach((key) => {
          if (Object.keys(params).some(someKey => someKey === key)) {
            state.queryParams[key] = params[key];
            if (check.some(el => state.queryParams["kitchens"]?.includes(el))) {
              state.queryParams["kitchens"] = [];
            }
            if (check.some(el => state.queryParams["sweets"]?.includes(el))) {
              state.queryParams["sweets"] = [];
            }
          }
        });
      } else {
        state.queryParams = new Filter({});
      }
      state.queryCollect = {...state.queryParams};
    },
    SET_NAVIGATION_MENU_HOME_ROUTE(state, path) {
      state.navigationMenuHomeRoute = path;
    },
    SET_NAVIGATION_BILL_HOME_ROUTE(state, path) {
      state.navigationBillHomeRoute = path;
    },
    SET_MARKET_SEARCH(state, value) {
      state.queryParams.name = value;
    },
    UPDATE_USER_ADDRESS(state, value) {
      state.userAddress = value;
    },

    SET_MARKET_CATALOG_COORDS(state, coords) {
      state.marketCatalogCurrentCoords = coords;
    },
    // ACCOUNT
    SET_AUTH_DATA(state, payload) {
      state.authData = payload;
    },
  },
  actions: {
    setTransferFullAmount({commit}, value) {
      commit("SET_TRANSFER_FULL_AMOUNT", value);
    },
    saveResponse({commit}, response) {
      commit("SET_RESPONSE", response);
    },
    clearResponse({commit}) {
      commit("SET_RESPONSE", null);
    },
    showLoading({commit}, loading) {
      commit("SET_LOADING", loading);
    },
    setEmployee({commit}, employee) {
      commit("SET_EMPLOYEE", employee);
    },
    setLanguage({commit}, language) {
      commit("SET_LANGUAGE", language);
    },
    setDontAsk({commit}, value) {
      commit("SET_DONT_ASK", value);
    },
    setOnlyTips({commit}, value) {
      commit("SET_ONLY_TIPS", value);
    },
    setModeOnlyBill({commit}, value) {
      commit("SET_MODE_ONLY_BILL", value);
    },
    setOnlyTipsAsked({commit}, count) {
      commit("SET_ONLY_TIPS_ASKED", count);
    },
    setStore({commit}, store) {
      commit("SET_STORE", store);
    },
    async fetchEmployee({commit}, id) {
      commit("SET_EMPLOYEE", {});
      return await Service.getEmployee(id).then((res) => {
        if (res) {
          window.paymentSystems = res.data.data['payment_systems']
          commit("SET_EMPLOYEE", res.data.data);
          return res;
        } else {
          window.location.href = "https://rhmt.uz";
        }
      });
    },
    async fetchReview({commit}, payload) {
      commit("SET_REVIEW", {});
      commit("SET_LOADING", true);
      commit("SET_RESPONSE", null);
      return await Service.getReview(payload.userId, payload.reviewId).then((res) => {
        if (res) {
          commit("SET_LOADING", false);
          commit("SET_REVIEW", res.data.data?.review);
          commit("SET_RESPONSE", res.data.data?.review?.order_details);
          return res;
        } else {
          window.location.href = "https://rhmt.uz";
        }
      });
    },

    removeCard({commit}, key) {
      commit("REMOVE_CARD", key);
    },

    async fetchUser({commit}, uuid) {
      commit("SET_LOADING", true);
      await Service.getReviewUser(uuid).then((res) => {
        commit("SET_LOADING", false);
        res.data.data.forEach((item) => {
          commit("SET_CARD", item);
        });
      });
    },

    async fetchStore({commit}, payload) {
      commit("SET_EMPLOYEE", {});
      await Service.employeePos(payload.storeId, payload.employeeId).then(
        (res) => {
          commit("SET_EMPLOYEE", res.data.data);
        }
      );
    },

    async fetchEmployeesByStore({commit}, storeId) {
      commit("SET_LOADING", true);
      commit("SET_STORE_EMPLOYEES", []);
      commit("SET_STORE_LOGO", "");
      await Service.storeEmployees(storeId).then((res) => {
        if (res) {
          commit("SET_STORE_EMPLOYEES", res.data.data.employees);
          commit("SET_STORE_LOGO", res.data.data.logo);
          commit("SET_LOADING", false);
        }
      });
    },

    async getStatus({commit}, payload) {
      commit("SET_LOADING", true);
      commit("SET_STATUS", false);
      commit("SET_STORE_LOGO", "");
      commit("SET_EMPLOYEE", {});
      await Service.paymentStatus(payload.id, payload.system).then((res) => {
        if (res) {
          commit("SET_STATUS", res.data.data.payment_info);
          commit("SET_STORE_LOGO", res.data.data.store_logo);
          commit("SET_EMPLOYEE", res.data.data.employee);
          commit("SET_LOADING", false);
        }
      });
    },

    updateAdModal({commit}, value) {
      commit("SET_AD_MODAL", value);
    },

    // MENU
    showMenuLoading({commit}, loading) {
      commit("SET_MENU_LOADING", loading);
    },

    showAddressLoading({commit}, loading) {
      commit("SET_ADDRESS_LOADING", loading);
    },

    setVerified({commit}, verified) {
      commit("SET_VERIFIED", verified);
    },

    getOrder({commit}, desk) {
      commit("FLUSH_ORDER");
      commit("SET_MENU_LOADING", true);
      return Menu.getCurrentOrder(desk).then((res) => {
        if (res) {
          commit("PUSH_ORDER", {
            desk: desk,
            data: res.data.data
          });
          return res.data.data;
        }
      })
      .finally(() => {
        commit("SET_MENU_LOADING", false);
      });
    },

    async fetchDesks({commit}, brand) {
      commit("SET_MENU_LOADING", true);
      return Menu.getDesks(brand).then((res) => {
        return res
      }).finally(() => {
        commit("SET_MENU_LOADING", false);
      })
    },

    async fetchProducts({commit}, payload) {
      commit("SET_MENU_LOADING", true);
      return Menu.getProducts(payload)
        .then(async (res) => {
          if (res) {
            let products = [];
            await res.data.data.result.forEach((item) => {
              products.push(new Product(item));
            });
            await commit("SET_PRODUCTS", products);
            if (res.data.data.address) {
              let address = res.data.data.address.split(",");
              commit("SET_COORDS", address);
            }
            return res.data.data;
          } else {
            await commit("SET_PRODUCTS", []);
          }
        })
        .finally(() => {
          commit("SET_MENU_LOADING", false);
        });
    },

    async fetchReservationTable({commit}, payload) {
      commit("SET_MENU_LOADING", true);
      return Menu.getReservationTable(payload)
        .then(async (res) => {
          if (res) {
            return res.data.data;
          }
        })
        .finally(() => {
          commit("SET_MENU_LOADING", false);
        });
    },

    async fetchReservationData({commit}, payload) {
      commit("SET_MENU_LOADING", true);
      return Menu.getReservationData(payload)
        .then(async (res) => {
          if (res) {
            return res.data.data;
          }
        })
        .finally(() => {
          commit("SET_MENU_LOADING", false);
        });
    },
    async fetchSubmitReservationTable({commit}, payload) {
      commit("SET_MENU_LOADING", true);
      let token = this.state.token ?? "";
      return Menu.submitReservation(payload, token, this.state.deviceUuid)
        .then()
        .finally(() => {
          commit("SET_MENU_LOADING", false);
        });
    },

    addToFavorite({commit}, store) {
      commit("SET_FAVORITE", store);
    },

    addModToCart({commit}, payload) {
      commit("CART_PUSH_MOD", payload);
    },

    addToCart({commit}, payload) {
      commit("CART_PUSH", payload);
    },

    addToCartWithoutMod({commit}, payload) {
      commit("CART_PUSH_WITHOUT_MOD", payload);
    },

    addToCartCopy({commit}, payload) {
      commit("CART_PUSH_COPY", payload);
    },

    removeFromCart({commit}, payload) {
      commit("CART_REMOVE", payload);
    },

    removeFromCartByIndex({commit}, payload) {
      commit("CART_REMOVE_BY_INDEX", payload);
    },

    cartFlush({commit}) {
      commit("CART_FLUSH");
    },

    setCanOrder({commit}, value) {
      commit("SET_CAN_ORDER", value);
    },

    setAddDishes({commit}, value) {
      commit("SET_ADD_DISHES", value);
    },

    setDeviceUuid({commit}, uuid) {
      commit("SET_DEVICE_UUID", uuid);
    },

    setAddress({commit}, address) {
      commit("SET_ADDRESS", address);
    },

    setCoords({commit}, coordinates) {
      commit("SET_COORDS", coordinates);
    },

    setBtnColor({commit}, color) {
      commit("SET_BTN_COLOR", color);
    },

    flushOrder({commit}) {
      commit("FLUSH_ORDER");
    },

    setMenuCoords({commit}, coords) {
      commit("SET_MENU_COORDS", coords);
    },

    async makeOrder({commit}, order) {
      commit("FLUSH_ORDER");
      commit("SET_MENU_LOADING", true);
      return await Menu.order(order)
        .then((res) => {
          if (res) {
            commit("PUSH_ORDER", {
              desk: order.table,
              data: res.data.data
            });
            commit("CART_FLUSH");
            return res.data.data;
          }
        })
        .finally(() => {
          commit("SET_MENU_LOADING", false);
        });
    },

    setOrder({commit}, order) {
      commit("FLUSH_ORDER");
      commit("PUSH_ORDER", order);
    },

    async editOrder({commit}, order) {
      let token = this.state.token ?? "";
      commit("FLUSH_ORDER");
      commit("SET_MENU_LOADING", true);
      return await Menu.editOrder(order, token, this.state.deviceUuid).then((res) => {
        if (res) {
          commit("PUSH_ORDER", {
            desk: order.table,
            data: res.data.data
          });
          commit("CART_FLUSH");
          return res.data.data;
        }
      }).catch((err) => {
        return err;
      }).finally(() => {
        commit("SET_MENU_LOADING", false);
      });
    },

    async askBill({commit}, payload) {
      commit("SET_MENU_LOADING", true);
      return await Menu.call(payload.store, payload.type, payload.desk)
        .then(({data}) => {
          if (data?.data) {
            Notify({
              type: "success",
              message: "Уведомление отправлено",
            });
          } else {
            Notify({
              type: "warning",
              message: "Не удалось отправить уведомление",
            });
          }
        })
        .catch(() => {
          Notify({
            type: "warning",
            message: "Не удалось отправить уведомление",
          });
        })
        .finally(() => {
          commit("SET_MENU_LOADING", false);
        });
    },

    async getOrderStatus({commit}) {
      if (!this.state.order.length) {
        return;
      }
      let currentOrder = this.state.order[this.state.order.length - 1];
      return await Menu.orderInfo(currentOrder)
        .then((res) => {
          if (res) {
            return res;
          }
        })
        .finally(() => {
          commit("SET_MENU_LOADING", false);
        });
    },

    async authorize({commit}, phone) {
      commit("SET_MENU_LOADING", true);
      return await Menu.authorize(phone, this.state.deviceUuid)
        .then((res) => {
          if (res) {
            commit("SET_VERIFIED", false);
          }
        })
        .finally(() => {
          commit("SET_MENU_LOADING", false);
        });
    },

    async getToken({commit}, payload) {
      commit("SET_MENU_LOADING", true);
      commit("SET_VERIFIED", false);
      return await Menu.verify(payload.phone, payload.otp)
        .then((res) => {
          if (res) {
            commit("SET_TOKEN", res.data.data.token);
            commit("SET_REFRESH_TOKEN", res.data.data.refresh);
            commit("SET_VERIFIED", res.data.data.verified);
          }
          return res;
        })
        .finally(() => {
          commit("SET_MENU_LOADING", false);
        });
    },

    async saveToken({commit}, token) {
      commit("SET_TOKEN", token)
    },

    async saveRefreshToken({commit}, token) {
      commit("SET_REFRESH_TOKEN", token)
    },

    // ACCOUNT
    fetchAuthMe({commit}, {token, uuid}) {
      return Profile.authMe(token, uuid)
        .then((res) => {
          if (res.data?.data) {
            commit("SET_LOADING", false);
            // commit("SET_AUTH_DATA", res.data.data);
            return res.data.data;
          } else {
            return false;
          }
        }).catch(() => {
          commit("SET_TOKEN", null)
        })
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
