<template>
  <div class="d-flex w-100 p20">
    <div class="d-flex flex-column w-100">
      <table v-if="(serviceCommissionPrice || tips || delivery)" class="table details-table mb-20 w-100">
        <tbody>
          <tr v-if="total">
            <td class="align-left border-bottom">{{ $t("check") }}</td>
            <td class="align-right border-bottom font-bold">
              <span>{{ total | money($i18n.locale) }}</span>
            </td>
          </tr>
          <tr v-if="tips">
            <td class="align-left border-bottom">{{ $t("tips") }}</td>
            <td class="align-right border-bottom font-bold">
              <span>{{ tips | money($i18n.locale) }}</span>
            </td>
          </tr>
          <tr v-if="serviceCommissionPrice">
            <td class="align-left border-bottom">{{ $t("service-commission") }}</td>
            <td class="align-right border-bottom font-bold">
              <span>{{ serviceCommissionPrice | money($i18n.locale) }}</span>
            </td>
          </tr>
          <tr v-if="delivery">
            <td class="align-left border-bottom">{{ $t("delivery-payment") }}</td>
            <td class="align-right border-bottom font-bold">
              {{ delivery | money($i18n.locale) }}
            </td>
          </tr>
        </tbody>
      </table>
      <p class="label-total">{{ $t("total-payment") }}</p>
      <p :style="{ color }" class="price-total">
        <span>{{ total + tips + serviceCommissionPrice + delivery | money($i18n.locale) }}</span>
      </p>
      <div class="text-center mt-30 pb-30">
        <van-button
          :loading="loading"
          @click="confirm"
          style="width: 200px"
          :color="color"
          type="primary"
        >{{ $t("confirm") }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "vant";
import {mapActions, mapState} from "vuex";
export default {
  name: "FastConfirmCard",
  props: {
    delivery: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
    },
    chequeDetails: {
      type: Object,
    },
    color: {
      type: String,
      default: '#1fb7a4'
    },
    serviceCommissionPrice: {
      type: Number,
      default: 0
    },
    loading: {
      type: [ String, Number, Boolean ],
      default: false,
    },
    tips: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      paydata: {},
      transfer: {},
    };
  },
  components: {
    "van-button": Button,
  },
  computed: {
    ...mapState(["store"]),
    transferFullAmount() {
      return this.$store.getters.transferFullAmount;
    },
    employee() {
      return this.$store.getters.employee;
    },
  },
  methods: {
    ...mapActions(["showLoading"]),
    confirm() {
      // this.loading = true;
      this.$emit("confirm")
    },
  },
};
</script>

<style scoped>
.details-table tr:last-child td {
  border: none;
}
</style>
