<template>
  <div
      class="vendor-header menu-header text-center"
      :class="[
          isMarketplace && 'menu-header--marketplace',
          adModal ? 'admodal' : '',
          stickyEnabled ? 'sticky' : ''
      ]"
      :style="{
        backgroundPositionY: headerBgPosition,
        backgroundImage: !stickyEnabled ? `url(${currentStore?.['background_url'] })` : 'none'
      }"
  >
    <div class="menu-header-gradient" v-if="!stickyEnabled"></div>
    <van-search
        ref="vanSearchInput"
        v-model="search"
        v-show="searchInput"
        class="search-input"
        show-action
        :placeholder="$t('marketplace.search-food')"
        :style="{top: adModal ? '108px' : '8px'}"
        @cancel="clearSearch"
    >
      <template #action>
        <div class="pt-5" @click="clearSearch">
          <van-icon name="cross" size="24" color="#303030"/>
        </div>
      </template>
    </van-search>
    <div
        v-if="!isMarketplace"
        class="menu-header-buttons align-items-center justify-content-between"
        :style="{top: adModal ? '100px' : 0, background: searchInput || stickyEnabled ? '#fff' : 'transparent'}"
        :class="{'ad-modal': adModal && stickyEnabled}"
    >
        <div
            v-show="!isMarketplace"
            class="home-btn circle-btn ml-10"
            :style="`background-image: url('${logo}')`"
            :class="{ sticky: stickyEnabled, hide: searchInput }"
            @click="goToHome"
        ></div>
        <div
            v-if="deskName"
            :class="{ sticky: stickyEnabled, hide: searchInput }"
            class="desk-name"
        >
          {{ deskName }}
        </div>
      <div v-if="!deskName && stickyEnabled" class="stick-header" :class="isMarketplace && 'stick-header--market'">
        <div class="store-name store-name--for-market">
          <p v-show="!searchInput" style="color: #0f1a14;" @click="goToHome">{{ !isMarketplace ? storeName : storeName.toLowerCase() }}</p>
        </div>
      </div>
      <div v-if="!searchInput" class="d-flex align-items-center justify-content-end">
        <div class="info-btn info-btn--catalog d-flex align-items-center justify-content-center mr-10"  :style="{'z-index': zIndexValue}" @click="share" style="padding-left: 5px;padding-right: 7px">
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M729.445-68.13q-51.941 0-88.726-36.594-36.784-36.594-36.784-87.993 0-6.687 1.619-16.37 1.62-9.684 4.381-17.617L319.636-395.087q-17.006 17.717-39.947 28.315-22.941 10.598-47.104 10.598-51.307 0-88.261-36.518-36.954-36.519-36.954-88.142t36.954-87.949q36.954-36.326 88.261-36.326 23.809 0 46.427 8.979 22.618 8.978 40.336 26.695l290.587-166.466q-2.761-6.936-4.381-16.209-1.619-9.273-1.619-16.787 0-51.5 36.622-87.877 36.622-36.378 88.563-36.378 50.858 0 87.684 36.399t36.826 88.022q0 51.622-36.954 87.948-36.954 36.327-88.261 36.327-25.516 0-47.299-7.232t-38.513-24.964L352.065-516.747q2 7.98 3.5 18.639 1.5 10.66 1.5 17.589 0 6.928-1.5 14.846-1.5 7.919-3.5 15.899L642.603-285.87q16.73-14.493 37.69-22.844 20.959-8.351 48.122-8.351 51.307 0 88.261 36.518 36.954 36.519 36.954 88.142 0 51.383-36.663 87.829-36.664 36.446-87.522 36.446Z"/>
          </svg>
        </div>
        <div @click="showInfo" class="info-btn mr-10 d-flex align-items-center justify-content-center">
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M479.861-699.891q-33.306 0-55.659-22.493-22.354-22.493-22.354-56.299 0-33.904 22.493-56.328t55.798-22.424q33.306 0 56.159 22.567 22.854 22.566 22.854 56.474 0 33.909-23.23 56.206-23.231 22.297-56.061 22.297Zm-.035 597.087q-25.219 0-41.599-17.253-16.379-17.253-16.379-41.9v-379.782q0-24.23 16.553-41.191 16.554-16.961 41.773-16.961 25.219 0 42.099 16.961 16.879 16.961 16.879 41.191v379.782q0 24.647-17.303 41.9-17.304 17.253-42.023 17.253Z"/>
          </svg>
        </div>
        <div class="search-btn circle-btn mr-10 d-flex align-items-center justify-content-center" @click="showSearch" :key="zIndexValue"
             :style="{'z-index': zIndexValue}">
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M376.481-317.283q-114.769 0-192.843-78.168-78.073-78.168-78.073-190.358 0-112.191 78.168-190.311 78.169-78.119 190.359-78.119 112.191 0 190.31 78.154 78.12 78.154 78.12 190.474 0 44.328-13.381 84.111-13.38 39.783-40.663 75.304L827-189.674q11.478 11.273 11.478 28.327 0 17.054-11.478 28.282-11.797 11.217-28.172 11.217t-27.937-11.717L534.482-370.087q-30.873 25.264-71.995 39.034t-86.006 13.77Zm-1.952-77.304q80.372 0 135.53-55.536t55.158-135.539q0-80.003-55.105-135.638-55.105-55.635-135.494-55.635-81.223 0-136.486 55.635-55.262 55.635-55.262 135.638 0 80.003 55.227 135.539 55.228 55.536 136.432 55.536Z"/>
          </svg>
          <van-icon v-if="false" name="cross" color="#7c7c7c"/>
        </div>
      </div>
    </div>
    <template v-else>
      <div
          class="info-btn d-flex align-items-center justify-content-center"
          :style="{'z-index': zIndexValue}"
          style="padding-left: 5px;padding-right: 7px"
          @click="share"
      >
        <img width="16" src="/img/share.svg" alt=""/>
      </div>
      <div
          @click="favorite"
          class="search-btn favorite-circle circle-btn d-flex align-items-center justify-content-center"
          :style="{'z-index': zIndexValue}"
      >
        <img width="18" :src="`/img/${setFavorite ? 'heart-full' : 'heart'}.svg`" alt=""/>
      </div>
      <div
          class="back-marketplace d-flex align-items-center justify-content-center"
          @click="backToMain"
          :style="{'z-index': zIndexValue}"
      >
        <img width="13" src="/img/arrow-left.svg" alt=""/>
      </div>
    </template>
    <template v-if="!isMarketplace">
      <div class="vendor-logo" v-if="!stickyEnabled">
        <div class="store-logo" :style="`background-image: url('${logo}')`"></div>
      </div>
      <van-sticky
          @change="stickyChange"
          class="top-sticky-address"
          :style="{zIndex: zIndexValue - 1, background: stickyEnabled ? '#fff' : 'none'}"
      >
        <div class="stick-header">
          <div
              class="f-13 dark-grey address"
              :class="{ 'has-address': address }"
              @click="showDeliveryAddress"
              v-if="showAddress && stickyEnabled"
          >
            <div class="f-11 address-title" v-if="address">
              {{ $t("marketplace.address-delivery") }} <i class="arrow-right"></i>
            </div>
            <div class="address-value" v-if="address">
              <marquee-text v-if="stickyEnabled" :text="address"/>
              <span v-else>{{ address }}</span>
            </div>
            <div class="f-13 address-value text-gradient" v-else>
              {{ $t("marketplace.show-address-delivery") }}
              <i class="arrow-right text-gradient"></i>
            </div>
          </div>
          <div v-else class="store-name">
            <h6>{{ !stickyEnabled ? storeName : '&nbsp;' }}</h6>
            <h3 v-if="deskName && !stickyEnabled">{{ deskName }}</h3>
          </div>
        </div>
      </van-sticky>
    </template>
    <template v-else>
      <div class="d-flex align-items-center justify-content-start mb-15">
        <div class="vendor-logo m-0">
          <div class="store-logo" :style="`background-image: url('${logo}')`"></div>
        </div>
        <van-sticky @change="stickyChange" class="top-sticky-address" :style="{zIndex: zIndexValue - 1}">
          <div class="stick-header" :class="isMarketplace && 'stick-header--market'">
            <div class="store-name store-name--for-market">
              <h6 @click="goToHome">{{ !isMarketplace ? storeName : storeName.toLowerCase() }}</h6>
            </div>
          </div>
        </van-sticky>
      </div>
    </template>
    <div v-if="false" class="tags">
      <div class="tag promo-tag">5%<sup>&nbsp;</sup></div>
      <div class="tag time-of-delivery">14-18<sup>{{$t('min')}}</sup></div>
      <div class="tag working-hours">
        <span>10</span><sup>00</sup><span>-23</span><sup>00</sup>
      </div>
      <div class="tag min-order-price">от 15<sup>000</sup></div>

      <div class="tag min-pocket-price">от 150<sup>000</sup></div>
      <div class="tag star-tag">4.8 (105)</div>
      <div class="tag info-tag">Инфо</div>
    </div>
    <van-share-sheet
        v-model="showShare"
        :options="shareOptions"
        :cancel-text="'Закрыть'"
        @select="onSelectShare"
        class="share-modal"
    />
    <van-popup
        v-model="qrCodeModal"
        position="bottom"
        class="qrcode-modal"
        closeable
        close-icon="close"
        close-icon-position="top-right"
        :style="{ height: '70%' }"
        @closed="closedQrCode"
    >
      <div class="qrcode-modal-content d-flex justify-content-center align-items-center flex-column h-100">
        <h1 class="qr-code">QR-code</h1>
        <img :src="qrCodeLink" alt="">
      </div>
    </van-popup>
  </div>
</template>

<script>
import {Icon, Search, Sticky, ShareSheet, Popup, Toast} from "vant";
import {mapGetters, mapActions} from "vuex";
import {Dialog} from "vant";
import MarqueeText from "@/components/menu/MarqueeText.vue";

export default {
  name: "MenuHeader",
  props: {
    logo: {
      type: String,
      default: "/img/nologo.png",
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: "",
    },
    autoDetectAddress: {
      type: Boolean,
      default: false,
    },
    storeName: {
      type: String,
      default: "",
    },
    deskName: {
      type: String,
      default: "",
    },
    isMarketplace: {
      type: Boolean,
      default: false,
    },
    currentStore: {
      type: Object,
      default: () => {
      }
    },
  },
  emits: ["search-update", "delivery-modal"],
  components: {
    MarqueeText,
    "van-sticky": Sticky,
    "van-search": Search,
    "van-icon": Icon,
    "van-share-sheet": ShareSheet,
    "van-popup": Popup,
  },
  data() {
    return {
      search: "",
      searchInput: false,
      stickyEnabled: false,
      showShare: false,
      qrCodeModal: false,
      qrCodeLink: "",
      headerBgPosition: "0px",
      favorites: [],
      zIndexValue: 4,
      isScrolled: false,
      shareOptions: [
        {name: 'Telegram', icon: '/telegram-icon-share.png'},
        {name: 'Qrcode', icon: 'qrcode'},
        {name: 'Link', icon: 'link'},
      ],
    };
  },
  methods: {
    ...mapActions(["showAddressLoading", "setCanOrder"]),
    handleScroll() {
      const scroll = document.documentElement.scrollTop;
      // this.headerBgPosition = scroll + "px";
      this.isScrolled = scroll >= 50;
      // if (scroll >= 165) {
      //   setTimeout(() => {
      //     this.stickyEnabled = true;
      //     this.$emit("sticky-change", true);
      //   }, 100)
      // } else {
      //   setTimeout(() => {
      //     this.stickyEnabled = false;
      //     this.$emit("sticky-change", false);
      //     this.searchInput = false;
      //   }, 100)
      // }
    },
    showSearch() {
      this.searchInput = !this.searchInput;
      if (!this.searchInput) {
        this.search = "";
      }
      this.$nextTick(() => {
        this.$refs.vanSearchInput.focus();
      });
    },
    showInfo() {
      this.$emit("show-detail");
      console.log("info");
    },
    goToHome: function () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    clearSearch() {
      this.search = "";
      this.searchInput = !this.searchInput;
    },
    showDeliveryAddress: function () {
      this.$emit("delivery-modal");
    },
    stickyChange(isFixed) {
      this.stickyEnabled = isFixed;
      if (!isFixed) this.searchInput = false;
      this.$emit("sticky-change", isFixed);
    },
    degreesToRadians(degrees) {
      return (degrees * Math.PI) / 180;
    },
    distanceInKmBetweenCoordinates(lat1, lon1, lat2, lon2) {
      let earthRadiusKm = 6371;

      let dLat = this.degreesToRadians(lat2 - lat1);
      let dLon = this.degreesToRadians(lon2 - lon1);

      lat1 = this.degreesToRadians(lat1);
      lat2 = this.degreesToRadians(lat2);

      let a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(lat1) *
          Math.cos(lat2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return earthRadiusKm * c;
    },
    share() {
      const currentLink = window.location.href;
      if (navigator.share) {
        navigator.share({
          text: this.storeName + ' copied link',
          url: currentLink,
          title: this.storeName
        })
      } else {
        this.showShare = true;
        console.log('Sharing is on Web')
      }
    },
    onSelectShare(option) {
      const currentLink = window.location.href;

      if (option.name === "Telegram") {
        // window.open(`tg://msg_url?url=${currentLink}`, 'newWindow', "width=500,height=700");
        window.open(`tg://msg_url?url=${currentLink}`, '_self');
      } else if (option.name === "Qrcode") {
        this.qrCodeLink = `https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=${currentLink}&choe=UTF-8`
        this.qrCodeModal = true;
      } else if (option.name === "Link") {
        navigator.clipboard.writeText(currentLink);
        Toast('Ссылка скопирована');
        // Notify({
        //     message: 'Ссылка скопирована успешно',
        //     color: '#ffffff',
        //     className: 'share-notify',
        //     background: '#21201F',
        //     duration: 800,
        // });
        this.showShare = false;
      }
    },
    closedQrCode() {
      this.qrCodeLink = "";
    },
    favorite() {
      this.$store.dispatch("addToFavorite", this.currentStore);
    },
    backToMain() {
      const query = this.queryCollect;
      this.$router.push({ path: '/', query}).catch(() => {});
    },
    changeZIndex(value) {
      this.zIndexValue = value ? 2 : 4;
      console.log(this.zIndexValue)
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    if (this.autoDetectAddress) {
      this.showAddressLoading(false);
      if (!navigator.geolocation) {
        Dialog.alert({
          title: "Error",
          message: "Geolocation is not supported by your browser",
          theme: "round-button",
          confirmButtonText: "Close",
        }).then(() => {
          window.location.reload();
        });
      } else {
        // this.showAddressLoading(true);
        // navigator.geolocation.getCurrentPosition(
        //   async (position) => {
        //     const latitude = position.coords.latitude;
        //     const longitude = position.coords.longitude;
        //     const storeAddress = this.$store.getters.coordinates;
        //     let distance = this.distanceInKmBetweenCoordinates(
        //       latitude,
        //       longitude,
        //       storeAddress[0],
        //       storeAddress[1]
        //     );
        //     this.showAddressLoading(false);
        //     if (distance > 0.05) {
        //       this.setCanOrder(false);
        //       Notify({type: "warning", message: this.$t("location-to-far")});
        //     } else {
        //       this.setCanOrder(true);
        //     }
        //   },
        //   (error) => {
        //     this.showAddressLoading(false);
        //     Dialog.alert({
        //       title: "Error",
        //       message: error.message,
        //       theme: "round-button",
        //       confirmButtonText: "Close",
        //     }).then(() => {
        //       window.location.reload();
        //     });
        //   }
        // );
      }
    }
    this.$nextTick(() => {
      this.zIndexValue = 4;
    })
  },
  computed: {
    ...mapGetters(["address", "coordinates", "canOrder", "adModal", "queryCollect"]),
    setFavorite() {
      return !!this.$store.state.favorites.find(fav => fav.menu === this.currentStore.menu);
    }
  },
  watch: {
    searchValue(value) {
      this.search = value;
    },
    search(value) {
      this.$emit("search-update", value);
    },
  },
};
</script>

<style>
.van-dialog {
  z-index: 99999 !important;
}

.qr-code {
  font-weight: 500;
}
</style>
