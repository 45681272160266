
export function localeStorageGetItem(key) {
  return localStorage.getItem(key);
}
export function localeStorageSetItem(key, value) {
  return localStorage.setItem(key, value);
}
export function localeStorageRemoveItem(key) {
  return localStorage.removeItem(key);
}
export function localeStorageClear() {
  return localStorage.clear();
}
