<template>
  <section v-if="false" class="sales">
    <h1 class="marketplace-title">{{$t("marketplace.sales")}}</h1>
    <swiper
      class="sales-swiper"
      :breakpoints="swiperSalesOptions.breakpoints"
      :space-between="10"
      :loop="false"
    >
      <swiper-slide
        v-for="(sale, i) in sales"
        :key="sale.id"
        class="sales-swiper__slide"
        :class="sale.size"
      >
        <div
          :style="getBg(sale.img)"
          @click="$emit('move-sale', i)"
        >
        </div>
      </swiper-slide>
      <swiper-slide></swiper-slide>
    </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper-vue2";

export default {
  name: "SalesBlock",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    sales: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      swiperSalesOptions: {
        breakpoints: {
          0: {
            slidesPerView: 3.2,
          },
          481: {
            slidesPerView: 4.4,
          },
          576: {
            slidesPerView: 4.4,
          },
        },
      },
    };
  },
  methods: {
    getBg: function(img) {
      return `background-image: url('/img/marketplace/${img}')`;
    }
  }
};
</script>

<style scoped></style>
