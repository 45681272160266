export default function Item(attrs) {
  this.id = attrs.id ? attrs.id : null;
  this.name = attrs.name ? attrs.name : null;
  this.description = attrs.description ? attrs.description : null;
  this.price = attrs.price ? attrs.price : null;
  this.imageUrl = attrs.imageUrl ? attrs.imageUrl : null;
  this.bigImageUrl = attrs.bigImageUrl ? attrs.bigImageUrl : null;
  this.weight = attrs.weight ? attrs.weight : null;
  this.unit = attrs.unit ? attrs.unit : null;
  this.modifiers = attrs.modifiers ? attrs.modifiers : null;
  this.available = typeof attrs.available === 'number' ? attrs.available : null;
  this.disabled = attrs.disabled ? attrs.disabled : null;
  this.modifierRequired = attrs.modifierRequired ? attrs.modifierRequired : false;
}

