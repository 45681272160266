<template>
  <table class="table details-table mt-25 mb-25" v-if="price || tips || serviceCommissionPrice || delivery">
    <tbody>
      <tr v-if="price">
        <td class="align-left border-bottom">{{ $t("check") }}</td>
        <td class="align-right border-bottom">
          <span>{{ price | money($i18n.locale) }}</span>
        </td>
      </tr>
      <tr v-if="tips">
        <td class="align-left border-bottom">{{ $t("tips") }}</td>
        <td class="align-right border-bottom">
          <span>{{ tips | money($i18n.locale) }}</span>
        </td>
      </tr>
      <tr v-if="serviceCommissionPrice">
        <td class="align-left border-bottom">{{ $t("service-commission") }}</td>
        <td class="align-right border-bottom">
          <span>{{ serviceCommissionPrice | money($i18n.locale) }}</span>
        </td>
      </tr>
      <tr v-if="delivery">
        <td class="align-left border-bottom">{{ $t("delivery-payment") }}</td>
        <td class="align-right border-bottom">
          <span>{{ delivery | money($i18n.locale) }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "CommissionDetails",
  props: {
    serviceCommissionPrice: {
      type: Number,
      default: 0
    },
    tips: {
      type: Number,
      default: 0
    },
    price: {
      type: Number,
      default: 0,
    },
    delivery: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style scoped>
.details-table tr:last-child td {
  border: none;
}
</style>