<template>
  <div class="fast-pay-content">
    <div class="payment-header-content">
      <HeaderView
        :store="store.name"
        :logo="store.logo_url"
        :background="store.background_url"
        :address="store.address"
      />
    </div>
    <div v-if="success" class="success-block-wrapper">
      <SuccessBlock
        :transfer-info="transferInfo"
        :return-url="order['return_url'] ?? null"
      />
    </div>
    <div v-else class="payment-details-content">
      <BillDetails
        :loading="loading"
        :price="price"
        :details="details"
        :closed="order.closed === '1' || order.paid === '1'"
        :number="number"
        :date="date"
        :color="color"
        :discount="parseInt(order.discount ?? '0')"
        :service="parseInt(order.service ?? '0')"
      />
      <div class="commission-details-wrapper">
        <CommissionDetails
          v-if="price !== toPay"
          :service-commission-price="serviceCommissionPrice"
          :delivery="delivery"
          :price="price"
        />
      </div>
<!--    </div>-->
<!--    <div class="payment-info-content">-->
      <PaymentInfo
        :to-pay="toPay"
        :color="color"
      />
<!--    </div>-->
<!--    <div class="payment-types-content">-->
      <PaymentTypesBlock
        mode="fastpay"
        :price="price"
        :payment-systems="paymentSystems"
        :service-commission-price="serviceCommissionPrice"
        :delivery="delivery"
        :loading="loadingCard"
        :otp="otpModal"
        :confirm="confirmModal"
        :card-id="cardId"
        :color="color"
        @sendOtp="sendOtp"
        @payCard="payCard"
        @transfer="transfer"
        @paySystem="paySystem"
        @selectCard="selectCard"
        @confirm="paymentInstant"
        @closeConfirm="closeConfirm"
      />
      <NavigationBill />
    </div>
    <div class="px-25">
      <review-agreement-text
        :get-color="color"
        :border="false"
      />
    </div>
  </div>
</template>

<script>
import {Notify} from "vant";
import Service from "@/services/api/service";
import HeaderView from "@/components/HeaderView";
import BillDetails from "@/components/BillDetails";
import {useUserAgent} from "@/helpers/useUserAgent";
import PaymentInfo from "@/components/PaymentInfo.vue";
import SuccessBlock from "@/components/SuccessBlock.vue";
import NavigationBill from "@/components/NavigationBill.vue";
import PaymentTypesBlock from "@/components/PaymentTypesBlock";
import CommissionDetails from "@/components/CommissionDetails.vue";
import ReviewAgreementText from "@/components/formblock-items/ReviewAgreementText.vue";

export default {
  title: "Оплата счета",
  name: "FastPay",
  components: {
    CommissionDetails,
    ReviewAgreementText,
    PaymentTypesBlock,
    NavigationBill,
    SuccessBlock,
    PaymentInfo,
    HeaderView,
    BillDetails
  },
  data() {
    return {
      store: {},
      order: {},
      loading: false,
      cardId: null,
      paymentSystems: [],
      loadingCard: false,
      otpModal: false,
      confirmModal: false,
      selectedCard: this.$store.state.cards[0],
      success: false,
      transferInfo: {},
    }
  },
  mounted() {
    this.$store.commit("SET_NAVIGATION_BILL_HOME_ROUTE", this.$route.fullPath);
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SET_NAVIGATION_BILL_HOME_ROUTE", from.fullPath);
    next();
  },
  beforeCreate() {
    this.$store.commit("SET_EMPLOYEE", []);
    this.$store.dispatch("clearResponse");
  },
  created() {
    this.loading = true;
    Service.order(
      this.$route.params.store,
      this.$route.params.order
    ).then(({ data }) => {
      this.$store.dispatch("setStore", data.data.store)
      this.store = data.data.store
      this.order = data.data.order
      this.paymentSystems = data.data['payment_systems']
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      this.loading = false;
    })
  },
  methods: {
    paySystem(system) {
      this.loadingCard = system
      this.payOtp({
        payment_system: system
      }).then((data) => {
        if (this.useragent === 'uzum') {
          this.uzumPay(data)
          return;
        }
        const systems = ["payme", "payze", "oson", "uzum", "click", "humans", "alif", "anorbank", "multicard", "xazna"];
        if (systems.includes(system)) {
          if (system === "multicard") {
            this.multicardPay(data)
            return;
          }
          if (system === "humans") {
            if (!data?.data?.data?.id || !data?.data?.data?.amount)
              return;

            // this.humansRedirect(data)
            return;
          }
          if (data?.data?.data?.redirect) {
            window.location.href = data.data.data.redirect;
          } else {
            if (this.order['paid']) {
              Notify({
                type: "warning",
                message: this.$t("cheque-paid-already"),
              });
            } else {
              Notify({
                type: "warning",
                message: this.$t("payment-error"),
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        this.loadingCard = false;
      });
    },
    sendOtp(card, callback) {
      Service.fastPay(this.$route.params.store, this.$route.params.order, {card, payment_system: "multicard"}).then((data) => {
        callback(data)
      }).catch(() => {
        callback(null)
      });
    },
    payCard(card) {
      this.loadingCard = true;
      if (this.$store.getters.token) {
        this.loadingCard = false;
        this.confirmModal = true;
        this.selectedCard = card;
      } else {
        this.pay({
          payment_system: 'multicard',
          card: {
            token: card.token
          }
        })
      }
    },
    pay(params) {
      this.payOtp(params).then((data) => {
        this.cardId = data.data.data.id;
        this.otpModal = true;
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.loadingCard = false;
      });
    },
    closeConfirm() {
      this.confirmModal = false;
    },
    selectCard(card) {
      this.selectedCard = card;
    },
    paymentInstant(callback) {
      this.payInstant({
        payment_system: 'multicard',
        card: this.selectedCard,
        token: this.$store.getters.token
      }).then((data) => {
        const success = !!data?.data?.data?.transfer
        if (data?.data?.data?.code === "ERROR_CARD_TOKEN") {
          this.tryOtpPayment()
          callback(true)
        } else {
          callback(false)
        }
        if (success) {
          this.transfer(data.data.data.transfer);
          // this.$emit("success", true);
          this.loadingCard = false;
        }
      }).catch(() => {
        callback(false)
      }).finally(() => {});
    },
    tryOtpPayment() {
      this.loadingCard = true;
      this.closeConfirm();
      this.pay({
        payment_system: 'multicard',
        card: this.selectedCard
      })
    },
    payInstant(params) {
      return Service.fastPayInstantOrder(this.$route.params.store, this.$route.params.order, params)
    },
    payOtp(params) {
      return Service.fastPay(this.$route.params.store, this.$route.params.order, params)
    },
    transfer(transferInfo) {
      this.success = true;
      this.transferInfo = transferInfo;
    },
    uzumPay(data) {
      if (data?.data?.data?.redirect) {
        window.flutter_inappwebview.callHandler('browser', {
          url: data?.data?.data?.redirect
        })
      }
    },
    multicardPay(data) {
      if (data?.data?.data?.redirect) {
        if (this.useragent === 'multicard') {
          const redirectUrl = URL.parse(data.data.data.redirect)
          const link = URL.parse(redirectUrl.searchParams.get('link'))
          const params = JSON.parse(link.searchParams.get('params'))
          const invoiceUuid = params.details['invoice_uuid']
          const routeParams = {
            fixed_amount: parseInt(data?.data?.data?.amount),
            details: {
              invoice_id: data?.data?.data?.payment_id,
              invoice_uuid: invoiceUuid
            }
          }
          window.flutter_inappwebview.callHandler('deeplink', {
            route: "/payments/checkout/96?params=" + JSON.stringify(routeParams)
          })
        } else {
          window.location.href = data.data.data.redirect
        }
      }
    },
    updateCashbackSum() {
      const cashbackElements = document.getElementsByClassName('cashback-hint')
      const amount = parseInt(this.toPay)
      const percent = 1
      const cashback = parseInt(amount / 100 * percent)
      cashbackElements.forEach(el => {
        el.innerHTML = this.$t('cashback', { percent: cashback + ' ' + this.$t('sum') })
      })
    }
    /*
    humansRedirect(data) {
      const id = data?.data?.data?.id
      const paymentAmount = parseInt(data?.data?.data?.amount) / 100
      let form = document.createElement('form')
      form.method = "POST"
      form.action = "https://capi.upay.uz/humansPay.do"
      document.getElementById('app').append(form)
      let service = document.createElement('input')
      let amount = document.createElement('input')
      let account = document.createElement('input')
      let redirect = document.createElement('input')
      let trx = document.createElement('input')
      let lang = document.createElement('input')
      let api = document.createElement('input')
      service.type = "hidden"
      service.name = "serviceId"
      service.value = "1304"
      amount.type = "hidden"
      amount.name = "amount"
      amount.value = paymentAmount.toString()
      account.type = "hidden"
      account.name = "personalAccount"
      account.value = id
      redirect.type = "hidden"
      redirect.name = "redirectUrl"
      redirect.value = `https://app.rhmt.uz/result?id=${id}&amount=${paymentAmount}`
      trx.type = "hidden"
      trx.name = "partnerTrxId"
      trx.value = "rahmat_00001"
      lang.type = "hidden"
      lang.name = "lang"
      lang.value = "ru"
      api.type = "hidden"
      api.name = "apiVersion"
      api.value = 1
      form.append(service)
      form.append(amount)
      form.append(account)
      form.append(redirect)
      form.append(trx)
      form.append(lang)
      form.append(api)
      form.submit()
    },
     */
  },
  computed: {
    useragent() {
      return useUserAgent().getUserAgent()
    },
    delivery() {
      return this.order['delivery']
          ? parseInt(this.order['delivery']) / 100
          : 0;
    },
    serviceCommission() {
      return this.store['bill_commission_up'] ?? 0;
    },
    details() {
      return this.order['order_list'] ?? []
    },
    number() {
      const number = this.order['number'] ?? this.order['bill_id'] ?? 0;
      return number.toString();
    },
    date() {
      return this.order['create_date']
    },
    price() {
      return this.order['total_price']
          ? parseInt(this.order['total_price']) / 100
          : 0;
    },
    toPay() {
      return this.price + this.serviceCommissionPrice + this.delivery
    },
    serviceCommissionPrice() {
      const price = (this.price * 10000) / (100 - this.serviceCommission);
      const commission = (Math.floor(price, -2) - this.price * 100) / 100;
      return parseInt(commission);
    },
    color() {
      return this.store['button_color'] ?? '#1fb7a4'
    },
  },
  watch: {
    paymentSystems: {
      handler(value) {
        if (value.length) {
          this.$nextTick(() => {
            this.updateCashbackSum()
          })
        }
      }
    },
  }
}
</script>
