var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-content-wrapper",style:({
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 14.87%, rgba(0, 0, 0, 0.01) 99.78%),
      linear-gradient(180deg, rgba(0, 0, 0, 0.00) 12.98%, rgba(0, 0, 0, 0.50) 83.52%, rgba(0, 0, 0, 0.50) 99.79%),
      url(${_vm.background}) center / cover,
      lightgray no-repeat`,
  })},[_c('i',{staticClass:"rahmat-logo"}),_c('div',{staticClass:"store-info"},[(_vm.logo)?_c('div',{staticClass:"store-logo",style:({
        background: `url(${_vm.logo}) center / contain no-repeat,
        linear-gradient(180deg, #f4f4f7 0%, #F4F4F7 100%),
        lightgray no-repeat`,
        backgroundSize: 'contain',
      })}):_vm._e(),_c('div',{staticClass:"store-information"},[_c('div',{staticClass:"store-title"},[_vm._v(_vm._s(_vm.store))]),(_vm.address)?_c('div',{staticClass:"store-address"},[_vm._v(_vm._s(_vm.address))]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }