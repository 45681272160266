<template>
  <div>
    <loading-block v-if="menuLoading" :color="btnColor" />
    <address-loading-block v-if="addressLoading" />
    <menu-header
      :logo="logo"
      :deskName="deskName"
      :storeName="selectedStore?.text ?? brandName"
      :current-store="{
        background_url: selectedStore?.background
      }"
      :is-marketplace="false"
    />
    <div class="vendor-content d-flex flex-column" style="gap: 5px; padding: 20px">
      <van-dropdown-menu active-color="#606060">
        <van-dropdown-item v-model="store" :options="stores" />
      </van-dropdown-menu>
      <div v-for="(hall, h) in selectedStore?.halls" :key="h">
        <h5 style="margin: 5px 0">{{ hall.name }}</h5>
        <div v-for="(desk, d) in hall.desks" :key="d">
          <router-link
            :to="{ name: 'MenuPage', params: { brand: $route.params.brand, desk: desk.number } }"
            class="d-block"
            style="padding: 10px; background: #f6f6f6; border-radius: 8px; margin: 4px 0; color: #606060"
          >{{ desk.name.length ? desk.name : 'Стол №' + parseInt(d + 1) }}</router-link>
        </div>
      </div>
    </div>
    <navigation-menu v-if="!menuLoading" />
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import {DeviceUUID} from "device-uuid";
import {mapActions, mapGetters} from "vuex";
import {DropdownItem, DropdownMenu} from "vant";
import LoadingBlock from "@/components/menu/Loading.vue";
import MenuHeader from "@/components/menu/MenuHeader.vue";
import NavigationMenu from "@/components/menu/NavigationMenu.vue";
import AddressLoadingBlock from "@/components/menu/AddressLoading.vue";

export default {
  title: "Заказ за столом",
  name: "DesksPage",
  components: {
    "van-dropdown-item": DropdownItem,
    "van-dropdown-menu": DropdownMenu,
    AddressLoadingBlock,
    NavigationMenu,
    LoadingBlock,
    MenuHeader,
  },
  data() {
    return {
      stores: [],
      store: null,
      deskName: "",
      brandName: "",
      logo: "/img/nologo.png",
    };
  },
  created() {
    let uuid = new DeviceUUID().get();
    this.setDeviceUuid(uuid);
  },
  mounted() {
    this.getDesks();
    this.$store.commit("SET_NAVIGATION_MENU_HOME_ROUTE", this.$route.fullPath)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SET_NAVIGATION_MENU_HOME_ROUTE", from.fullPath)
    next();
  },
  methods: {
    ...mapActions([
      "setOrder",
      "flushOrder",
      "addToCart",
      "removeFromCart",
      "removeFromCartByIndex",
      "cartFlush",
      "makeOrder",
      "editOrder",
      "showMenuLoading",
      "fetchDesks",
      "setCanOrder",
      "setAddDishes",
      "setDeviceUuid",
      "setBtnColor",
      "showAddressLoading",
      "setMenuCoords",
    ]),
    async getDesks() {
      this.fetchDesks(this.$route.params.brand).then((data) => {
        if (data?.data?.success) {
          this.logo = data.data.data.logo;
          this.brandName = data.data.data.name;
          this.stores = data.data.data.stores
          this.store = this.stores.find(() => true)?.value
        }
      });
    },
  },
  computed: {
    ...mapGetters([
      "cart",
      "btnColor",
      "order",
      "menuLoading",
      "addressLoading",
      "addDishesMode",
      "products",
      "token",
      "address",
      "canOrder",
      "deviceUuid",
      "menuCoords",
    ]),
    useragent() {
      return this.userAgent.getUserAgent()
    },
    selectedStore() {
      return this.stores.find(store => store.value === this.store)
    }
  },
};
</script>

<style scoped lang="scss">
.brand-detail {
  height: 90%;
  max-height: 90%;
  padding: 50px 0 17px !important;
}

.location-modal {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 768px;
  margin: auto;
  left: auto;
  z-index: 99999999999;

  &-btn {
    color: #FFFFFF;
    background: linear-gradient(95.73deg, #29BF9B -14.53%, #0BABAB 51.05%);
    min-width: 68px;
    height: 40px;
    border-radius: 16px;
    padding: 0 12px;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid transparent;
  }

  .btn-light {
    margin-right: 8px;
    transition: all .25s;
    color: #21201F;
    background: #F6F6F6;
    border-color: transparent;
  }
}

:deep(.van-dropdown-menu__bar) {
  border-radius: 18px;
  background: #f6f6f6;
  box-shadow: none;

}
:deep(.van-cell) {
  padding: 10px 20px;
}
:deep(.van-dropdown-item__content) {
  border-radius: 0 0 20px 20px;
}

</style>
