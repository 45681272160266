import apiClient from "@/services/axios";

class Profile {

  authMe(token, uuid) {
    let headers = {
      "X-Menu-Access-Token": `Bearer ${token}`,
      "X-Device-Uuid": uuid,
    };

    return apiClient.post("/menu/auth/me", {}, { headers });
  }

  verify(phone, otp) {
    return apiClient.post("/menu/auth/verify", {
      phone,
      otp,
    });
  }

  getPayments(token, uuid, params) {
    let headers = {
      "X-Menu-Access-Token": `Bearer ${token}`,
      "X-Device-Uuid": uuid,
    };
    return apiClient.get(`/menu/profile/payments?skip=${params.skip}&offset=${params.offset}`,  { headers });
  }

  getCards(token, uuid) {
    let headers = {
      "X-Menu-Access-Token": `Bearer ${token}`,
      "X-Device-Uuid": uuid,
    };

    return apiClient.get(`/menu/profile/cards`,  { headers });
  }

  getReservations(token, uuid, params) {
    let headers = {
      "X-Menu-Access-Token": `Bearer ${token}`,
      "X-Device-Uuid": uuid,
    };

    return apiClient.get(`/menu/profile/reservations?skip=${params.skip}&offset=${params.offset}`,  { headers });
  }

  deleteCard(id, token, uuid) {
    let headers = {
      "X-Menu-Access-Token": `Bearer ${token}`,
      "X-Device-Uuid": uuid,
    };
    return apiClient.post(`/menu/profile/delete-card?id=${id}`, {}, { headers });
  }

  saveAccountInfo(data, token, uuid){
    let headers = {
      "X-Menu-Access-Token": `Bearer ${token}`,
      "X-Device-Uuid": uuid,
    };

    return apiClient.post(`/menu/profile/save?user=${data.user_id}`, data, { headers })
  }

  deleteAccount(token, uuid) {
    let headers = {
      "X-Menu-Access-Token": `Bearer ${token}`,
      "X-Device-Uuid": uuid,
    };
    return apiClient.post(`/menu/profile/delete-account`, {}, { headers });
  }

}
export default new Profile();
