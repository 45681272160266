<template>
  <div
      style="
        height: 100vh;
        left: 0;
        top: 0;
        width: 100%;
        background-color: #ffffff;
        position: fixed;
        z-index: 20;
        opacity: 0.9;
      "
      class="container flex-column bg-green d-flex align-items-center justify-content-center"
      :class="[accountClass]"
  >
    <div class="text-center mt-auto mb-auto white">
      <div class="lds-dual-ring" :style="`--loading-color: ${color}`"></div>
      <div class="f-18 mt-10 text-black text-capitalize">{{$t('loading')}}</div>
    </div>
  </div>
</template>

<script>
// import { Loading } from "vant";
export default {
  name: "LoadingBlock",
  components: {
    // "van-loading": Loading,
  },
  props: {
    accountClass: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: '#1fb7a4'
    }
  }
};
</script>

<style scoped>

/* loading */

.text-black {
  color: #000;
}
.payment-loading {
  height: 100% !important;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute !important;
  z-index: 2 !important;
}
</style>
