<template>
  <div class="relative">
    <navigation-market />
    <AccountBlock/>
  </div>
</template>

<script>
import AccountBlock from "@/components/AccountBlock.vue";
import NavigationMarket from "@/components/menu/NavigationMarket.vue";

export default {
  name: "MenuAccountPage",
  components: {
    AccountBlock,
    "navigation-market": NavigationMarket,
  }
};
</script>

