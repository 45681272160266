<template>
  <van-sticky :class="detailPage ? 'header-detail-page' : ''" :style="{zIndex: zIndexValue}">
    <div :class="zIndexValue === 2 ? 'd-none' : ''" class="header-marketplace__bottom">
      <div class="header-marketplace__actions">
        <search-item
          ref="searchItem"
          v-if="scrollPosition || detailPage"
          :search="search"
          @search-update="$emit('search-update', $event)"
        />
        <div v-if="false" class="header-marketplace__services">
          <label
            v-for="service in fullServices"
            :key="service.id"
            class="header-marketplace__service"
            :class="{active: checkIsActive(service)}"
          >
            <input
              v-if="mainPage"
              v-model="setType"
              :value="service.text"
              @change="setQuery"
              type="radio"
              name="type_check"
            />
            <input
              v-else
              v-model="orderType"
              :value="service.text"
              @change="setQuery"
              type="radio"
              name="type_check_order"
            />
            <img :src="`/svg/${service.imgUrl}`" alt=""/>
          </label>
        </div>
      </div>
    </div>
  </van-sticky>
</template>

<script>
import {Sticky} from "vant";
import SearchItem from "@/components/marketplace/header/SearchItem.vue";

export default {
  name: "HeaderBottom",
  components: {
    SearchItem,
    "van-sticky": Sticky
  },
  inject: {
    services: {
      from: "services",
      default: () => []
    },
    searchFood: {
      from: "searchFood",
      default: ""
    }
  },
  props: {
    detailPage: {
      type: Boolean,
      default: false
    },
    isMainPage: {
      type: Boolean,
      default: false
    },
  },
  data: function () {
    return {
      search: this.searchFood,
      fullServices: this.services,
      serviceIndex: 0,
      oldQueryParams: {},
      orderType: "",
      mainPage: false,
      zIndexValue: 4,
      scrollPosition: false,
      scrollY: 0
    };
  },
  methods: {
    setQuery() {

      if (this.mainPage) {
        this.$store.commit("SET_QUERY_PARAMS");
        this.$store.commit("CHECK_QUERY_COLLECT");
        this.$router.push({query: this.$store.state.queryCollect}).catch(() => {
        });
        this.calcParams();
      } else {
        this.$store.commit("UPDATE_ORDER_TYPE", this.orderType);
      }
    },
    calcParams() {
      const match = document.location.search.match(/=/g);
      if (match && match.length) {
        this.$store.commit("QUERY_PARAMS_COUNT", JSON.stringify(this.$route.query).includes("avg_from") ? match.length - 1 : match.length);
      } else {
        this.$store.commit("QUERY_PARAMS_COUNT", 0);
      }
    },
    checkIsActive(service) {
      if (this.mainPage) {
        return this.$store.state.queryParams.type && this.$store.state.queryParams.type.includes(service.text)
      }
      return this.orderType && this.orderType.includes(service.text);
    },
    changeZIndex(value) {
      this.zIndexValue = value ? 2 : 4;
      console.log(this.zIndexValue, 'hb')
    },
    onScroll() {
      this.scrollY = window.top.scrollY;
    }
  },
  computed: {
    setType: {
      get() {
        return this.$store.state["queryParams"].type
      },
      set(value) {
        return this.$store.commit("UPDATE_QUERY_PARAMS_TYPE", value);
      }
    }
  },
  created() {
    this.orderType = this.$store.state.orderType;
    this.mainPage = this.isMainPage;
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  watch: {
    // search(value) {
    //   this.$emit("search-update", value);
    // },
    scrollY(value) {
      if (value > 65) {
        this.scrollPosition = true;
        this.$nextTick(() => {
          this.search = this.$store.getters["queryParams"].search;
          if (this.$refs.searchItem && this.$refs.searchItem.$el && this.search?.trim()) {
            this.$refs.searchItem.$el.children[0].focus();
          }
        });
      } else {
        this.scrollPosition = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">

</style>
