<template>
  <div class="store-block-bg">
    <div class="user p-25 pb-0">
      <transition appear name="slideAvatar">
        <div class="d-flex align-items-center justify-content-center" style="gap: 10px">
          <div class="user-info ">
            <div class="name">{{ store }}</div>
          </div>
          <img
              :style="{
                  border: 'none',
                  objectFit: 'contain',
                  background: 'none'
                }"
              :src="logo | photo"
              class="avatar"
              alt=""
          />
        </div>
      </transition>
    </div>
  </div>
</template>
<script>

export default {
  name: "StoreBlock",
  props: {
    store: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    }
  },
}
</script>
