<template>
  <div class="mb-20 pl-20">
    <swiper :slides-per-view="4" :space-between="7" loop>
      <swiper-slide class="fooditem">
        <div class="fooditem style-1">
          <img src="/img/1.png" alt="" />
          <div class="title">Лаваш с сыром</div>
        </div>
      </swiper-slide>
      <swiper-slide class="fooditem">
        <div class="fooditem style-1">
          <img src="/img/2.png" alt="" />
          <div class="title">Греческий салат</div>
        </div>
      </swiper-slide>
      <swiper-slide class="fooditem">
        <div class="fooditem style-1">
          <img src="/img/3.png" alt="" />
          <div class="title">Бургеры на мангале</div>
        </div>
      </swiper-slide>
      <swiper-slide class="fooditem">
        <div class="fooditem style-1">
          <img src="/img/1.png" alt="" />
          <div class="title">Лаваш с сыром</div>
        </div>
      </swiper-slide>
      <swiper-slide class="fooditem">
        <div class="fooditem style-1">
          <img src="/img/2.png" alt="" />
          <div class="title">Греческий салат</div>
        </div>
      </swiper-slide>
      <swiper-slide class="fooditem">
        <div class="fooditem style-1">
          <img src="/img/3.png" alt="" />
          <div class="title">Бургеры на мангале</div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper-vue2";

export default {
  name: "StoriesBlock",
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style scoped></style>
