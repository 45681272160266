<template>
  <div class="payment-types-cards-list">
    <div v-if="cards.length === 0" class="pt-20">
      <button :disabled="false" type="button" @click="addCard" class="card-payment__add w-100">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.23809 4.23822V0.00012207H5.7619V4.23822H10V5.76203H5.7619V10.0001H4.23809V5.76203H0V4.23822H4.23809Z" fill="url(#paint0_linear_556_2252)"/>
          <defs>
            <linearGradient id="paint0_linear_556_2252" x1="-0.596026" y1="-9.99988" x2="8.05333" y2="-9.13213" gradientUnits="userSpaceOnUse">
              <stop :stop-color="color"/>
              <stop offset="0.826058" :stop-color="color"/>
            </linearGradient>
          </defs>
        </svg>
        <span class="add-text" :style="{ color }">{{ $t('add-new-card') }}</span>
      </button>
    </div>
    <div v-else>
      <swiper
        class="card-slider"
        :slides-per-view="'auto'"
        :space-between="12"
        :loop="false"
      >
        <swiper-slide class="add-card-wrap">
          <div :style="{ color }" @click="addCard" class="add-card-button">+</div>
        </swiper-slide>
        <swiper-slide
            v-for="(card, index) in cards"
            @click="selectCard(index, $event)"
            :key="index"
            class="card-payment__slide"
            :class="{active: index === current}"
            :style="{borderColor: index === current ? color + '!important' : ''}"
        >
          <input type="radio" name="card-payment" @change="selectCard(index)"/>
          <div class="card-payment__title">{{ card['bank'] }}</div>
          <div class="d-flex align-items-end justify-content-between">
            <span class="card-payment__number">***{{card.pan?.slice(-4)}}</span>
            <img class="card-ps" :src="`/payments/${card['ps']}.svg`" alt="" />
          </div>
        </swiper-slide>
      </swiper>
      <van-button
        :loading="loading === true"
        :disabled="loading"
        @click="pay"
        style="width: 150px"
        :color="color"
        type="primary"
      >{{ $t("pay") }}</van-button>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper-vue2";
import { mapActions, mapState } from "vuex";
import { Button } from "vant";
export default {
  props: {
    cards: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    }
  },
  components: { "van-button": Button, Swiper, SwiperSlide },
  name: "CardSlider",
  data() {
    return {
      current: 0,
      cardsLoading: false,
      slickOptions: {
        slidesToShow: 3,
      },
    };
  },
  computed: {
    ...mapState(["store", "employee"]),
    getGradient() {
      let store = Object.keys(this.employee).length ? this.employee.store : this.store;
      return store?.['button_color'] ?? "linear-gradient(to left, #29BF9B, #0FAEAE)";
    },
  },
  watch: {
    current: {
      handler() {
        this.$emit("selectedCard", this.current);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['removeCard', 'fetchAuthMe']),
    addCard() {
      this.$emit("addCard");
    },
    async getAuthData() {
      this.cardsLoading = true;
      const token = this.$store.getters.token;
      const device = this.$store.getters.deviceUuid;
      await this.fetchAuthMe({token, device})
        .then((response) => {
          if (response?.cards?.length) {
            this.$store.commit("FLUSH_CARDS")
            response?.cards.forEach((item) => {
              this.$store.commit("SET_CARD", item);
            });
          }
        })
        .finally(() => {
          this.cardsLoading = false;
        })
      this.cardsLoading = false;
    },
    deleteCard(index) {
      if (confirm('Вы действительно хотите удалить карту?')) {
        this.removeCard(index);
      }
    },
    selectCard: function (index) {
      this.current = index;
    },
    pay() {
      this.$emit('payCard', this.current)
    }
  },
  created() {
    this.getAuthData()
  }
};
</script>
