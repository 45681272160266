<template>
  <div class="check-details-wrapper">
    <div v-if="loading" class="w-100 p-30 text-center">
      <van-loading
        v-if="loading"
        type="spinner"
        :color="color"
      />
    </div>
    <div v-else>
<!--    <div class="check-title">{{ $t('your-check') }}</div>-->
<!--    <div class="check-price">{{ price | money($i18n.locale) }}-->
<!--      <div class="check-expand"></div>-->
<!--    </div>-->
      <div class="check-details-number">{{ $t('cheque-number') }}{{ number }}</div>
      <div
          v-if="closed"
          class="text-center orange"
      >
        {{ $t("cheque-paid") }}
      </div>
      <div class="check-details-date">{{ formattedDate(date) }}</div>
      <hr class="check-details-gradient">
      <table class="check-details-table">
        <thead>
        <tr>
          <th class="text-left" :style="{ color }">{{ $t('cheque-details-name') }}</th>
          <th :style="{ color }">{{ $t('cheque-details-price') }}</th>
          <th :style="{ color }">{{ $t('cheque-details-count') }}</th>
          <th class="text-right" :style="{ color }">{{ $t('cheque-details-amount') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(detail, key) in details"
            :key="key"
        >
          <td v-if="parseInt(detail['total'])" class="text-left" style="width: 25%">{{ detail['name'] }}</td>
          <td v-if="parseInt(detail['total'])" class="text-center" style="width: 25%">{{ formattedBalance(detail['price'], true) }}</td>
          <td v-if="parseInt(detail['total'])" class="text-center" style="width: 25%">{{ detail['qty'] }}</td>
          <td v-if="parseInt(detail['total'])" class="text-right text-bold" style="width: 25%">{{ formattedBalance(detail['total'], true) }}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr v-if="discount > 0">
          <td
            :style="{ color }"
            colspan="2"
            class="text-right font-bold"
          >
            {{ $t("cheque-details-discount") }}
          </td>
          <td
            :style="{ color }"
            colspan="2"
            class="text-right font-bold"
          >
            -{{ formattedBalance(discount, true) }} {{ $t('sum') }}
          </td>
        </tr>
        <tr v-if="service > 0">
          <td
            :style="{ color }"
            colspan="2"
            class="text-right font-bold"
          >
            {{ $t("cheque-details-service") }}
          </td>
          <td
            :style="{ color }"
            colspan="2"
            class="text-right font-bold"
          >
            {{ formattedBalance(service, true) }} {{ $t('sum') }}
          </td>
        </tr>
        <tr>
          <td
            :style="{ color }"
            colspan="2"
            class="text-right font-bold"
          >
            {{ $t("cheque-details-total") }}
          </td>
          <td
            class="text-right font-bold"
            :style="{ color }"
            colspan="2"
          >
            {{ price | money($i18n.locale) }}
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
import { Loading } from "vant";

export default {
  name: "BillDetails",
  props: {
    details: {
      type: Array,
      default: () => []
    },
    price: {
      type: Number,
      default: 0,
    },
    number: {
      type: String,
      default: '0',
    },
    color: {
      type: String,
      default: '#1fb7a4',
    },
    discount: {
      type: Number,
      default: 0
    },
    service: {
      type: Number,
      default: 0
    },
    closed: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: '-',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    "van-loading": Loading
  },
  data() {
    return {

    }
  },
  methods: {
    formattedBalance(amount, penny = false) {
      if (penny) amount = amount / 100;
      return amount
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    formattedDate(date) {
      if (!date || date === "-")
        return null;
      return (new Date(date)).toLocaleDateString()
    }
  }
}
</script>
