export function useUserAgent() {
  const isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    },
    iosAndroid: function () {
      return (isMobile.Android() || isMobile.iOS());
    },
    getUserAgent: function () {
      const userAgent = navigator.userAgent
      const multicardMatch = userAgent.match("Multicard")
      const osonMatch = userAgent.match("oson_app")
      const qrmenuMatch = userAgent.match("QrMenu")

      if (multicardMatch !== null)
        return 'multicard';
      else if (osonMatch !== null)
        return 'oson';
      else if (qrmenuMatch !== null)
        return 'qrmenu';
      else
        return 'browser';
    }
  };
  return isMobile;
}
