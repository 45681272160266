import anime from 'animejs';

export function translate(element, isOpen) {
  anime({
    targets: element,
    height: el => isOpen ? el.scrollHeight : 32,
    duration: 200,
    complete() {

    },
  });
}
