<template>
  <div class="home" v-if="employees && Object.keys(employees).length">
    <div class="p-25 pb-0 mt-40 grey_bg">
      <div class="logos mb-0">
        <img height="65" :src="logo | logo" alt=""/>
        <i class="van-icon van-icon-cross"></i>
        <img src="/img/multitips.png" alt="" width="63" height="10"/>
      </div>
      <div class="store-hint text-center">
        {{ $t('choose-employee') }}
      </div>
    </div>
    <div class="rounded-bg p-20">
      <div class="store-tabs-tips" v-if="roles.length > 1">
        <div
          v-for="(role, key) in roles"
          :key="key"
          :class="{ active: activeTab === key }"
          @click="activeTab = key"
          style="text-transform: capitalize"
        >
          {{ role }}
        </div>
      </div>
      <div class="search-wrapper mb-15">
        <van-search
          v-model="search"
          :placeholder="$t('enter-employee-name')"
          :clearable="false"
        />
        <p v-if="search.length" @click="search = ''" class="search-close">
          <img src="/close-silver.svg" alt=""/>
        </p>
      </div>
      <div class="employees-wrapper">
        <div
          v-for="employee in activeEmployees"
          :key="employee.uuid"
          class="employee-item"
          @click="handleMove(employee)"
        >
          <div
            class="employee-photo"
            :style="getPhoto(employee['photo_url'])"
          ></div>
          <div class="employee-info">
            <div class="employee-name">{{ employee.name }}</div>
            <div class="employee-role">
              <span v-if="employee?.['employeeRole']?.['localizedName']?.title">{{ employee?.['employeeRole']?.['localizedName']?.title }}</span>
              <span v-else>{{ employee?.['employeeRole']?.name }}</span>
            </div>
          </div>
          <div class="goto">
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.97656 6.92969C7.96875 6.65625 7.86719 6.42188 7.65625 6.21094L1.57812 0.265625C1.39844 0.09375 1.1875 0 0.929688 0C0.40625 0 0 0.40625 0 0.929688C0 1.17969 0.101562 1.41406 0.28125 1.59375L5.75 6.92969L0.28125 12.2656C0.101562 12.4453 0 12.6719 0 12.9297C0 13.4531 0.40625 13.8594 0.929688 13.8594C1.17969 13.8594 1.39844 13.7656 1.57812 13.5938L7.65625 7.64062C7.875 7.4375 7.97656 7.20312 7.97656 6.92969Z"
                fill="#C7C7CC"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div v-if="payTipModal" @click="payTipModal = false" class="pay-tip-modal-overlay"></div>
    <div
      v-if="payTipModal"
      class="pay-tip-modal h-100"
    >
      <p @click="payTipModal=false" class="pay-tip-close">
        <i class="van-icon van-icon-cross"></i>
      </p>
      <iframe
        height="100%"
        width="100%"
        :src="payTipUrl"
        frameborder="0"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {Search} from "vant"

export default {
  data() {
    return {
      search: "",
      storeId: "",
      activeTab: 0,
      payTipUrl: '',
      payTipModal: false,
    }
  },
  methods: {
    ...mapActions(["fetchEmployeesByStore"]),
    getPhoto(url) {
      let photo =
        url === null || url === undefined || url === ""
          ? "/img/superman.png"
          : url;
      return "background-image: url(" + photo + ")";
    },
    handleMove(employee) {
      if (this.$route.meta.pageType === 'menu') {
        if (this.$route.params.storeId)
          this.payTipUrl = `https://app.rhmt.uz/${employee.uuid}`;
        this.payTipModal = true;
        return;
      }
      this.$router.push({
        name: 'TipsPage',
        params: {user: employee.uuid},
      })
    },
    clear(){
      console.log('clear')
    }
  },
  computed: {
    employees() {
      return this.$store.getters.storeEmployees;
    },
    logo() {
      return this.$store.getters.storeLogo;
    },
    activeEmployees() {
      let list = this.employees ? this.employees[this.activeRole] : [];
      return list.filter((employee) => {
        return employee.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    activeRole() {
      return this.roles[this.activeTab];
    },
    roles() {
      if (!this.employees) return [];
      let employees = Object.keys(this.employees);
      return employees.map((item) => {
        return item;
      });
    },
  },
  mounted() {
    this.storeId = this.$route.params.storeId;
    this.fetchEmployeesByStore(this.storeId);
  },
  components: {
    'van-search': Search,
    // 'van-popup': Popup,
  }
}
</script>

<style scoped lang="scss">
.search-wrapper {
  position: relative;
}
.search-close {
  position: absolute;
  right: 22px;
  top: -5px;
  width: 20px;
  img {
    width: 100%;
  }
}
.pay-tip-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 768px;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background-color: transparent;
  z-index: 8;
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: 0px 4px 20px -1px rgba(240, 240, 240, 0.2), 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    z-index: 7;
  }
}
.pay-tip-close {
  position: fixed;
  left: 15px;
  top: 5px;
  width: 30px;
  height: 30px;
  z-index: 10;
  .van-icon{
    font-size: 30px;
    color: #7C7C7C;
  }
}

</style>
