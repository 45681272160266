<template>
  <div :class="['c-dropdown', classes]">
    <div @click="isActive = !isActive" class="d-flex justify-content-between align-items-center">
      <p class="font-semibold">
        {{ modifier.title }}
      </p>
      <span :class="['material-symbols-rounded expand-icon', classes]">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-348.326q-8.239 0-14.957-2.859-6.717-2.858-11.717-9.098L254.804-558.804q-12.478-11.239-12.098-26.816.381-15.576 11.859-27.054 11.478-11.478 27.555-11.478 16.076 0 28.315 11.478L480-442.348l170.326-170.326q11.478-11.478 26.935-11.478 15.456 0 27.935 11.478 11.478 12.239 11.478 28.315t-11.478 27.555L507.674-360.283q-6 6.24-12.717 9.098-6.718 2.859-14.957 2.859Z"/></svg>
      </span>
    </div>
    <div :class="['mod-collapse', classes]" v-if="modifier.items?.length">
      <van-checkbox-group
        v-if="modifier.up > 1"
        v-model="modifiers"
        :checked-color="btnColor"
      >
        <div
          v-for="item in modifier.items"
          :key="item.id"
          class="d-flex justify-content-between align-items-center modifier-block"
        >
          <van-checkbox
            :ref="'checkboxes-' + item.group_id"
            class="modifier-checkbox"
            :name="item.id"
            :disabled="item.available == 0"
            @click.stop="toggleModItemInCart(item)"
          >
            <div>{{ item.name }}</div>
          </van-checkbox>
          <div style="height: 25px" v-if="item.up > 1">
            <van-stepper
              v-if="hasModInCart(item)?.['quantity']"
              :value="hasModInCart(item)['quantity']"
              async-change
              :max="item.up"
              @change="changeModifier(item, $event)"
              button-size="22"
              disable-input
            />
          </div>
          <span v-if="item.price" class="modifier-price"> +{{ item.price | money($i18n.locale) }}</span>
        </div>
      </van-checkbox-group>
      <van-radio-group
        v-else
        v-model="modifierModel"
        :checked-color="btnColor"
      >
        <div
          v-for="item in modifier.items"
          :key="item.id"
          class="d-flex justify-content-between align-items-center modifier-block"
        >
          <van-radio
            :ref="'checkboxes-' + item.group_id"
            class="modifier-checkbox"
            :name="item.id"
            :disabled="item.available == 0"
            @click.stop="switchModItemInCart(item)"
          >
            <div>{{ item.name }}</div>
          </van-radio>
          <div style="height: 25px" v-if="item.up > 1">
            <van-stepper
              v-if="hasModInCart(item)?.['quantity']"
              :value="hasModInCart(item)['quantity']"
              :max="item.up"
              async-change
              @change="changeModifier(item, $event)"
              button-size="22"
              disable-input
            />
          </div>
          <span v-if="item.price" class="modifier-price"> +{{ item.price | money($i18n.locale) }}</span>
        </div>
      </van-radio-group>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Checkbox, CheckboxGroup, Radio, RadioGroup, Stepper} from "vant";

export default {
  name: "ProductModifier",
  props: {
    modifier: {
      type: Object,
      default: () => {
      },
    },
    activeItem: {
      type: Object,
      default: () => {
      },
    },
    modifierTemp: {
      type: Array,
      default: () => [],
    },
    isCollapsed: {
      type: Boolean,
      default: false
    },
    selectedModifier: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      isActive: this.isCollapsed,
      resultModifier: [],
      stepper: 1,
      limit: 0,
      modifiers: [],
      modifierModel: null,
    }
  },
  computed: {
    ...mapGetters(["cart", "btnColor"]),
    classes() {
      return {
        'is-active': this.isActive
      }
    }
  },
  mounted() {
    if (this.selectedModifier) {
      this.modifierModel = this.selectedModifier
    }
  },
  methods: {
    hasModInCart(item) {
      if (!this.modifierTemp?.length) return;
      return this.modifierTemp.find(m => m.id === item.id);
    },
    switchModItemInCart(item) {
      if (item.available != 0) {
        this.activeModifier = item;
        this.$emit('switch-modifier', item);
      }
    },
    toggleModItemInCart(item) {
      if (item.available != 0) {
        this.activeModifier = item;
        this.$emit('toggle-modifier', item);
      }
      // this.$nextTick(() => {
      // const checkedLength = this.$refs[`checkboxes-${item.group_id}`]?.filter(check => check.checked).length || 0;
      // if (checkedLength < item.down) {
      //   if (this.checkedArray.length) {
      //     this.checkedArray = this.checkedArray.filter((val, ind) => this.checkedArray.indexOf(val) !== ind);
      //   }
      // } else{
      //   this.checkedArray.push(item.group_id);
      // }
      // })
    },
    changeModifier(item, quantity) {
      this.$emit('change-modifier', {item, quantity});
    },
  },
  watch: {
    modifierTemp: {
      handler(value) {
        this.modifiers = value.map(m => m.id);
        this.resultModifier = value.map(m => m.down);
        if (this.modifier.up > 1) {
          const checkedLength = this.$refs[`checkboxes-${this.activeModifier.group_id}`]?.filter(check => check.checked).length;
          this.$refs[`checkboxes-${this.activeModifier.group_id}`]?.forEach(el => {
            if (checkedLength >= this.modifier.up) {
              el?.$el.classList.add('disabled');
              el.$el.disabled = true;
              if (el.checked) {
                el?.$el.classList.remove('disabled');
                el.$el.disabled = false;
              }
            } else {
              el?.$el.classList.remove('disabled');
              el.$el.disabled = false;
            }
          });
        }
        this.$emit('can-add-order', !!this.resultModifier.length);
      },
      deep: true
    },
  },
  components: {
    "van-radio": Radio,
    "van-stepper": Stepper,
    "van-checkbox": Checkbox,
    "van-radio-group": RadioGroup,
    "van-checkbox-group": CheckboxGroup,
  }
}
</script>

<style scoped>

</style>
