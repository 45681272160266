<template>
  <div>
    <div v-if="booksModal" @click="$emit('closed')" class="overlay"></div>
    <div v-if="booksModal" class="modal check">
      <div @click="$emit('closed')" class="modal-close">
        <i role="button" tabindex="0" class="van-icon van-icon-close van-popup__close-icon van-popup__close-icon--top-right"></i>
      </div>
      <BookList :reserve-number="reserve" :menu-account="true"/>
    </div>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js'
import BookList from "@/components/menu/BookList";
import {useUserAgent} from "@/helpers/useUserAgent";

export default {
  name: 'BookModal',
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    bookReserve: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      booksModal: false,
      reserve: null,
    }
  },
  methods: {
    downloadPdf() {
      html2pdf(document.getElementById('main-check'), {
        margin: 30,
        filename: 'check',
        image: {type: 'png'},
        html2canvas: {useCORS: true, scale: 1},
        format: [2, 4],
        orientation: 'portrait'
      })
    },
    sharePdf() {
      const el = document.getElementById('main-check');
      const opt = {
        margin: 10,
        filename: 'check',
        image: {type: 'png'},
        html2canvas: {useCORS: true, scale: 1}
      }
      html2pdf().set(opt).from(el).toPdf().output('blob').then((data) => {
        console.log('data', data)
        const uploadedFile = new File([data], `share_${Date.now()}.pdf`, {lastModified: Date.now()})
        // const blobData = new Blob([uploadedFile], { type: 'application/pdf' /* uploadedFile.type */ });
        // const blobUrl = URL.createObjectURL(blobData);
        // window.open(blobUrl, '_blank');
        // URL.revokeObjectURL(blobUrl);

        if (navigator.share) {
          navigator.share({
            text: 'copied link',
            files: [uploadedFile],
            title: 'test'
          })
        } else {
          console.log('Sharing is on Web')
        }
      })
    },
    openWindow(url) {
      console.log(url, 'uuu')
      if (this.useragent === 'multicard')
        window.flutter_inappwebview.callHandler('browser', {url})
      else
        window.open(url, '_blank')
    },
  },
  computed: {
    navigatorShare() {
      return navigator.share?.();
    },
    useragent() {
      return useUserAgent().getUserAgent()
    }
  },
  watch: {
    modal(value) {
      this.booksModal = value;
    },
    bookReserve(value) {
      this.reserve = value;
    }
  },
  components: {BookList}
}
</script>

<style scoped lang="scss">
.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  //transform: scale(.9);
  background-color: rgba(0, 0, 0, .8);
  z-index: 2500;
}

.modal-close {
  position: absolute;
  right: 41px;

  i {
    left: 0;
    top: 0;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.check {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 90%;
  height: 90%;
  border-radius: 20px;
  background-color: #F5F5F5;
  max-width: 768px;
  margin: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2501;
  // .check__main
  &__main {
    overflow-y: auto;
  }

  &__action {
  }

  // .main-check__action-item
  &__action-item {
    flex: 0 0 33.333%;

    .action-btn {
      width: 50px;
      height: 50px;
      background-color: #eaeaea;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: none;
      margin-bottom: 5px;
    }

    span {
      color: #000000;
      font-size: 12px;
      text-align: center;
    }
  }
}

.main-check {
  background-color: #FFFFFF;
  border-radius: 2px;
  padding: 10px;
  margin-top: 40px;
  // .main-check__label
  &__label {
    font-weight: 700;
    text-transform: uppercase;
  }

  &__top {

  }

  &__paytype {
    width: 60px;
    height: 60px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &.__last {
    }
  }

  // .main-check__detail
  &__detail {
  }

  // .main-check__action

}

.detail {
  &__item {
    padding: 0 5px;

    span {
      font-weight: 600;
      color: #000000;
    }
  }

  // .detail__table
  &__table {
    width: 100%;

    th {
      text-align: center;
      padding: 8px 5px;
      white-space: nowrap;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }

    td {
      padding: 5px;
    }
  }

  // .detail__table--total
  &__table--total {
    span {
      font-weight: 700;
    }

    .float-right {
      float: right;
    }
  }
}

</style>
