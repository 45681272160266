<template>
  <div class="auth p-25" :style="{ minHeight: adModal ? 'calc(100vh - 165px)' : 'calc(100vh - 65px)', '--menu-item-color': btnColor }">
    <loading-block v-if="loading" />
    <div class="auth-form-wrapper">
      <auth-form
          :auth-model="authModel"
          :saved-data="savedData"
          @change-form="changeForm"
          @change-model="changeAuthModel"
          @open-auth-model="$emit('open-auth-model')"
          @open-date-modal="savedData = false"
      />
      <auth-list @language-modal="$emit('language-modal')" @open-modal="$emit('open-modal', $event)"/>
    </div>
    <div class="auth__bottom align-items-end mb-50" v-if="useragent !== 'multicard'">
      <my-auth-btn
          v-if="isAuth"
          class="auth-btn auth-delete-account hide"
          @button="deleteAccount"
      >
        <svg width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 -256 1792 1792" id="svg3741" version="1.1">
          <defs id="defs3749"/>
          <g transform="matrix(1,0,0,-1,197.42373,1255.0508)" id="g3743">
            <path d="M 512,800 V 224 q 0,-14 -9,-23 -9,-9 -23,-9 h -64 q -14,0 -23,9 -9,9 -9,23 v 576 q 0,14 9,23 9,9 23,9 h 64 q 14,0 23,-9 9,-9 9,-23 z m 256,0 V 224 q 0,-14 -9,-23 -9,-9 -23,-9 h -64 q -14,0 -23,9 -9,9 -9,23 v 576 q 0,14 9,23 9,9 23,9 h 64 q 14,0 23,-9 9,-9 9,-23 z m 256,0 V 224 q 0,-14 -9,-23 -9,-9 -23,-9 h -64 q -14,0 -23,9 -9,9 -9,23 v 576 q 0,14 9,23 9,9 23,9 h 64 q 14,0 23,-9 9,-9 9,-23 z M 1152,76 v 948 H 256 V 76 Q 256,54 263,35.5 270,17 277.5,8.5 285,0 288,0 h 832 q 3,0 10.5,8.5 7.5,8.5 14.5,27 7,18.5 7,40.5 z M 480,1152 h 448 l -48,117 q -7,9 -17,11 H 546 q -10,-2 -17,-11 z m 928,-32 v -64 q 0,-14 -9,-23 -9,-9 -23,-9 h -96 V 76 q 0,-83 -47,-143.5 -47,-60.5 -113,-60.5 H 288 q -66,0 -113,58.5 Q 128,-11 128,72 v 952 H 32 q -14,0 -23,9 -9,9 -9,23 v 64 q 0,14 9,23 9,9 23,9 h 309 l 70,167 q 15,37 54,63 39,26 79,26 h 320 q 40,0 79,-26 39,-26 54,-63 l 70,-167 h 309 q 14,0 23,-9 9,-9 9,-23 z" id="path3745" style="fill:currentColor"/>
          </g>
        </svg>
        <span class="sidebar-title logout ml-10">{{ $t("delete-account") }}</span>
      </my-auth-btn>
      <div class="w-100">
        <my-auth-btn
            v-if="hasChanged && isAuth"
            class="auth-btn auth-save"
            @button="save"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-save">
            <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
            <polyline points="17 21 17 13 7 13 7 21"></polyline>
            <polyline points="7 3 7 8 15 8"></polyline>
          </svg>
          <span class="sidebar-title logout ml-10"> {{ $t("save") }}</span>
        </my-auth-btn>
        <my-auth-btn
            v-if="isAuth"
            class="auth-btn auth-logout mb-20"
            @button="logout"
        >
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.3999 7.55999C9.7099 3.95999 11.5599 2.48999 15.6099 2.48999H15.7399C20.2099 2.48999 21.9999 4.27999 21.9999 8.74999V15.27C21.9999 19.74 20.2099 21.53 15.7399 21.53H15.6099C11.5899 21.53 9.7399 20.08 9.4099 16.54"
                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.5001 12H4.12012" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M6.35 8.65002L3 12L6.35 15.35" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
          <span class="sidebar-title logout ml-10">{{ $t("exit") }}</span>
        </my-auth-btn>
      </div>
    </div>
    <div class="auth__bottom" v-else>
      <my-auth-btn
          v-if="hasChanged && isAuth"
          class="auth-btn auth-save"
          @button="save"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-save">
          <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
          <polyline points="17 21 17 13 7 13 7 21"></polyline>
          <polyline points="7 3 7 8 15 8"></polyline>
        </svg>
        <span class="sidebar-title logout ml-10"> {{ $t("save") }}</span>
      </my-auth-btn>
    </div>
  </div>
</template>

<script>
import {Notify, Dialog} from "vant";
import {mapActions, mapGetters, mapMutations} from "vuex";
import AuthForm from "@/components/auth/AuthForm.vue";
import AuthList from "@/components/auth/AuthList.vue";
import MyAuthBtn from "@/components/auth/UI/MyAuthBtn.vue";
import Profile from "@/services/profile/service";
import LoadingBlock from "@/components/Loading.vue";
import apiClient from "@/services/axios";
import {useUserAgent} from "@/helpers/useUserAgent";

const initialModel = {
  "phone": "",
  "name": "",
  "email": "",
  "gender": 0,
  "birthdate": null,
  "key": null
}
export default {
  name: "AuthorizationBlock",
  components: {LoadingBlock, MyAuthBtn, AuthList, AuthForm},
  data() {
    return {
      authModel: {...initialModel},
      hasChanged: false,
      savedData: false,
      authForm: {},
      loading: false
    }
  },
  methods: {
    ...mapMutations(["SET_USER_INFO"]),
    ...mapActions(["fetchAuthMe"]),
    deleteAccount() {
      Dialog.confirm({
        message: 'Вы действительно хотите удалить свой аккаунт?',
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Отменить',
        confirmButtonColor: '#ED5E68',
        cancelButtonColor: '#B0B0B0',
        theme: 'round-button',
      }).then(() => {
        Profile.deleteAccount(this.token, this.deviceUuid).then((data) => {
          if (data.data.data) {
            this.loading = false;
            Notify({
              type: "success",
              message: this.$t("saved-successfully")
            });
            this.logout()
          }
        })
      })
    },
    logout() {
      this.$store.commit("SET_TOKEN", null);
      this.$store.commit("SET_REFRESH_TOKEN", null);
      this.$store.commit("SET_USER_INFO", {});
      apiClient.delete(`/menu/auth/refresh?token=${this.$store.getters.refreshToken}`)
      this.authModel = {...initialModel};
      this.savedData = false;
      Notify({
        type: "success",
        message: this.$t("logged-successfully")
      });
    },
    async setAuthData(token) {
      this.loading = true;
      try {
        const device = this.$store.getters.deviceUuid
        await this.fetchAuthMe({token, device})
          .then((response) => {
            this.authModel = {...response};
            this.SET_USER_INFO(response);
            this.loading = false;
            this.savedData = true;
          })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false;
      }
    },
    save() {
      this.loading = true;
      const date = this.authForm.birthdate ? new Date(this.authForm.birthdate).toISOString().slice(0, 10) : null;
      Profile.saveAccountInfo({
        ...this.authForm,
        birthdate: date
      }, this.token, this.deviceUuid).then((data) => {
        if (data.data.data) {
          this.loading = false;
          Notify({
            type: "success",
            message: this.$t("saved-successfully")
          });
          this.savedData = true;
          this.authModel = {...data.data.data}
        }
      })
          .catch(e => console.error(e))
          .finally(() => {
            this.loading = false;
          });
    },
    changeForm(value) {
      this.hasChanged = value;
    },
    changeAuthModel(data) {
      this.authForm = {...data};
    }
  },
  computed: {
    ...mapGetters(["token", "adModal", "btnColor"]),
    isAuth() {
      return !!this.token;
    },
    useragent() {
      return useUserAgent().getUserAgent()
    },
  },
  mounted() {
    if (this.token) {
      this.setAuthData(this.token);
    }
  },
  created() {
    this.savedData = true;
  },
  watch: {
    token(value) {
      if (value) {
        this.setAuthData(value);
      }
    }
  }
};
</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}

.auth {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 90px;

  &-btn {
    color: #FFFFFF;
    width: 100%;
    height: 50px;
    border-radius: 16px;
    padding: 0 10px;
    border: 1px solid transparent;

    &.hide {
      display: none !important;
    }
  }

  &-logout {
    //background: linear-gradient(95.73deg,#ffd01e,#ff8917);
    background: linear-gradient(95.73deg, #ea858c -14.53%, #ED5E68 51.05%);
  }

  &-delete-account {
    font-size: 14px;
    color: #ea858c;
    background: #fff;
    width: fit-content;
  }

  &-save {
    //background: linear-gradient(95.73deg, #29BF9B -14.53%, #0BABAB 51.05%);
    background: var(--menu-item-color);
  }

  &__top {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__avatar {
    margin-right: 29px;
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }


  &__list {
    &-item {
      position: relative;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      padding: 13px 0;
      border-bottom: 1px solid #EFEFEF;
      display: block;
      width: 90%;

      &:after {
        content: "";
        position: absolute;
        right: -20px;
        top: 50%;
        width: 7px;
        height: 13px;
        transform: translateY(-50%);
        background: url("@/assets/svg/arrow-r.svg") center no-repeat;
        background-size: 100%;
      }
    }
  }
}

.input {
  width: 100%;
  height: 40px;
  padding: 16px 0 0;
  border: none;
}

.form__group {
  margin-bottom: 4px;
}

.label-name {
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  line-height: 15px;
  color: #666666;
  border-bottom: 1px solid #efefef;
}

.label-name::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid var(--menu-item-color);
  opacity: .6;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.form__item {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;

  &--phone {
    .phone-edit {
      position: absolute;
      right: 6px;
      bottom: -12px;
      width: 23px;
      height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 13px;
        height: 13px;
      }
    }

  }

  &--identify {
    width: 100%;
    overflow-y: unset;

    .input {
      font-size: 18px;
      line-height: 22px;
      width: 100%;
    }

    .content-name {
      font-size: 18px;
      line-height: 22px;
      color: #B2B2b2;
    }

    .identify-edit {
      position: absolute;
      right: 0px;
      bottom: -10px;
      width: 23px;
      height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 15px;
        height: 15px;
      }
    }

    .label-name {
      border-bottom: 1px solid #B2B2B2;
    }

    input:focus + .label-name .content-name,
    input:valid + .label-name .content-name,
    input:disabled + .label-name .content-name {
      -webkit-transform: translate3d(0, -70%, 0) !important;
      transform: translate3d(0, -70%, 0) !important;
      font-size: 13.5px !important;
      left: 0;
      color: #AAA8D0;
    }
  }
}

.form__item input:focus + .label-name .content-name,
.form__item input:valid + .label-name .content-name,
.form__item input:disabled + .label-name .content-name {
  -webkit-transform: translate3d(0, -110%, 0);
  transform: translate3d(0, -110%, 0);
  font-size: 12px;
  left: 0;
  color: #AAA8D0;
}

.form__item input:focus + .label-name::after,
.form__item input:valid + .label-name::after,
.form__item input:disabled + .label-name::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.content-name {
  position: absolute;
  bottom: 5px;
  left: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #B0B0B0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.identify-empty {
  position: absolute;
  bottom: 0px;
  left: 0;
  font-style: italic;
  font-weight: 300;
  font-size: 11px;
  line-height: 12px;
  color: #B0B0B0;
}
.van-dialog--round-button .van-dialog__cancel {
  margin-right: 8px;
}

</style>
