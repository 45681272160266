<template>
  <section v-if="brands.length" class="brands">
    <h1 class="marketplace-title">{{ $t("marketplace.top-brands") }}</h1>
    <swiper
        ref="bSwiper"
        class="brands-swiper"
        :breakpoints="swiperBrandsOptions.breakpoints"
        :space-between="10"
        :loop="false"
        :mousewheel="true"
    >
      <swiper-slide
          v-for="(brand, index) in brands"
          :key="index"
          class="brands-swiper__slide"
          :class="{active: $store.getters['queryParams'].brand_id == brand.id}"
          :style="`background-image: url('${brand.logo_url}')`"
          :virtualIndex="brand?.id"
      >
        <input
            v-model="setBrand"
            :key="index"
            :value="brand.id"
            name="brand_id"
            @change="onChangeBrandQuery"
            @click="onClickBrand($event)"
            type="radio"
            class="brands-input"
        />
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper-vue2";

export default {
  name: "BrandsBlock",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    brands: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      swiperBrandsOptions: {
        breakpoints: {
          0: {
            slidesPerView: 5
          },
          481: {
            slidesPerView: 5.4
          },
          576: {
            slidesPerView: 6.4
          }
        }
      },
    };
  },
  methods: {
    onClickBrand(event) {
      if (event.target.value * 1 === this.setBrand * 1) {
        const query = Object.assign({}, this.$route.query);
        delete query.brand_id;
        this.$router.replace({query});
        this.onChangeBrandQuery(event.target.value);
      }
    },
    onChangeBrandQuery(value) {
      const currentQuery = this.$route.query;
      if (!Object.keys(currentQuery).length) {
        this.$store.commit("UPDATE_ROUTE_QUERY_PARAMS", {...currentQuery});
      }
      this.$store.commit("SET_QUERY_PARAMS");
      this.$store.commit("UPDATE_QUERY_COLLECT");
      this.$store.commit("CHECK_QUERY_COLLECT");
      const query = {
        ...this.$route.query,
        brand_id: this.$store.getters["queryParams"].brand_id
      };

      if (value * 1 === this.setBrand * 1) {
        delete query.brand_id;
        this.$store.commit("UPDATE_QUERY_PARAMS_BRAND", "");
      }
      this.$router.push({query}).catch(() => {
      });
      this.calcParams();
      this.$emit("filter-brand");
    },
    calcParams() {
      const match = document.location.search.match(/=/g);
      if (match && match.length) {
        this.$store.commit("QUERY_PARAMS_COUNT", JSON.stringify(this.$route.query).includes("avg_from") ? match.length - 1 : match.length);
      } else {
        this.$store.commit("QUERY_PARAMS_COUNT", 0);
      }
    },
    scrolling() {
      this.$nextTick(() => {
        const scrollContainer = this.$refs.bSwiper.$el;
        scrollContainer.addEventListener("wheel", (evt) => {
          evt.preventDefault();
          scrollContainer.scrollLeft += evt.deltaY;
        });
      })
    },
  },
  computed: {
    setBrand: {
      get() {
        return this.$store.getters["queryParams"].brand_id;
      },
      set(value) {
        return this.$store.commit("UPDATE_QUERY_PARAMS_BRAND", value);
      }
    }
  },
  watch: {
    'brands': {
      handler(value) {
        if (value.length) this.scrolling();
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.clear {
  cursor: pointer;
  text-decoration: underline;
  font-size: 18px;
  color: #d31f38;
}
</style>
