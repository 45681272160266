<template>
  <div class="employee-content-wrapper">
    <div class="employee-info">
      <div class="employee-name">{{ name }}</div>
      <div class="employee-target">{{ target }}</div>
      <div class="employee-rating">
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="rate"><g data-v-234ec000="" clip-path="url(#clip0_501_3118)"><path data-v-234ec000="" d="M10.1482 1.01643L12.0566 5.33361C12.2027 5.66408 12.5188 5.89049 12.8818 5.92283L17.531 6.33627C18.0571 6.41221 18.2669 7.05205 17.8855 7.42049L14.3833 10.3385C14.0998 10.5747 13.9707 10.946 14.0487 11.3046L15.0668 16.031C15.1561 16.5499 14.6074 16.9464 14.1366 16.7003L10.0787 14.3435C9.76676 14.1621 9.3811 14.1621 9.06917 14.3435L5.01122 16.6989C4.5419 16.9436 3.99177 16.5485 4.08109 16.0296L5.09912 11.3031C5.17569 10.9446 5.04808 10.5733 4.76451 10.3371L1.26094 7.42189C0.880955 7.05486 1.0908 6.41361 1.61541 6.33768L6.26461 5.92424C6.62759 5.89189 6.94378 5.66549 7.08982 5.33502L8.99827 1.01783C9.23506 0.545332 9.9128 0.545332 10.1482 1.01643Z" fill="#FDD835"></path><path data-v-234ec000="" d="M10.0091 5.59293L9.68581 2.41199C9.67304 2.23481 9.63618 1.93106 9.92259 1.93106C10.1494 1.93106 10.2728 2.39934 10.2728 2.39934L11.2426 4.95309C11.6084 5.92481 11.4581 6.25809 11.1051 6.45496C10.6996 6.67996 10.1012 6.50418 10.0091 5.59293Z" fill="#FFFF8D"></path><path data-v-234ec000="" d="M14.0093 10.0562L16.7911 7.90324C16.9287 7.78934 17.1768 7.60793 16.9783 7.40121C16.8209 7.23809 16.3955 7.47293 16.3955 7.47293L13.9611 8.41652C13.2351 8.66543 12.753 9.03387 12.7105 9.49793C12.6552 10.1167 13.2153 10.5934 14.0093 10.0562Z" fill="#F4B400"></path></g><defs data-v-234ec000=""><clipPath data-v-234ec000="" id="clip0_501_3118"><rect data-v-234ec000="" width="18.1488" height="18" fill="white" transform="translate(0.5)"></rect></clipPath></defs></svg>
        <span class="rating">{{ parseFloat(rating).toFixed(1) }}</span>
        <span class="rated" v-if="rated">({{ rated }})</span>
      </div>
    </div>
    <div class="employee-photo" :style="avatar"></div>
  </div>
</template>
<script>

export default {
  name: "EmployeeView",
  props: {
    name: {
      type: String,
      default: "",
    },
    photo: {
      type: String,
      default: ""
    },
    target: {
      type: String,
      default: ""
    },
    rating: {
      type: String,
      default: ""
    },
    rated: {
      type: String,
      default: null
    }
  },
  data() {
    return {

    }
  },
  computed: {
    avatar() {
      if (!this.photo) {
        return 'background-image: url("/img/superman.png")'
      }
      return `background-image: url("${this.photo}")`
    }
  }
}
</script>
<style scoped></style>