<template>
  <div class="payment-info-wrapper" v-if="toPay">
    <div class="payment-info-total">{{ $t('total-payment') }}</div>
    <div class="payment-info-price" :style="{ color }">{{ toPay | money($i18n.locale) }}</div>
  </div>
</template>

<script>
export default {
  name: "PaymentInfo",
  props: {
    toPay: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#1fb7a4'
    }
  },
}
</script>
