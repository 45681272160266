<template>
  <div
    id="catalog_menu_header"
    class="menu-header vendor-header text-center"
    style="transition: all .2s ease 0s"
    :style="{
      backgroundPositionY: headerBgPosition,
      backgroundImage: `url(${currentStore.background_url}) `
    }"
    :class="[isMarketplace && 'menu-header--marketplace', adModal ? 'admodal' : '']"
  >
    <div class="menu-header-gradient"></div>
    <div
      v-if="!isMarketplace"
      class="home-btn circle-btn"
      :style="`background-image: url('${logo}')`"
      :class="{ sticky: stickyEnabled, hide: searchInput }"
      @click="goToHome"
    ></div>
    <div :style="{top: adModal && !isScrolled ? '100px' : '0', margin: 'auto'}" id="catalogMenuHeader" class="d-flex align-items-center justify-content-between menu-header-buttons pr-10 pl-10">
      <div
        class="back-marketplace d-flex align-items-center justify-content-center"
        @click="backToMain"
        :style="{'z-index': zIndexValue}"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
          <path d="m290.935-441.087 215.739 215.978q11.478 11.5 11.598 27.446.119 15.946-10.598 27.528-11.478 11.613-28.011 11.613t-27.098-11.717L170.239-452.326q-5.978-5.279-8.848-12.384-2.869-7.104-2.869-15.556 0-7.212 2.869-14.298 2.87-7.086 8.848-12.349L453.565-790q11.598-11.478 27.495-11.478T508.435-790q10.717 11.717 10.717 27.658t-10.717 26.451l-217.5 217.5h488.108q16.166 0 27.279 11.097 11.113 11.098 11.113 27.055 0 17.196-11.113 28.174-11.113 10.978-27.279 10.978H290.935Z"/>
        </svg>
      </div>
      <div class="d-flex">
        <div class="info-btn info-btn--catalog d-flex align-items-center justify-content-center mr-10"  :style="{'z-index': zIndexValue}" @click="share" style="padding-left: 5px;padding-right: 7px">
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M729.445-68.13q-51.941 0-88.726-36.594-36.784-36.594-36.784-87.993 0-6.687 1.619-16.37 1.62-9.684 4.381-17.617L319.636-395.087q-17.006 17.717-39.947 28.315-22.941 10.598-47.104 10.598-51.307 0-88.261-36.518-36.954-36.519-36.954-88.142t36.954-87.949q36.954-36.326 88.261-36.326 23.809 0 46.427 8.979 22.618 8.978 40.336 26.695l290.587-166.466q-2.761-6.936-4.381-16.209-1.619-9.273-1.619-16.787 0-51.5 36.622-87.877 36.622-36.378 88.563-36.378 50.858 0 87.684 36.399t36.826 88.022q0 51.622-36.954 87.948-36.954 36.327-88.261 36.327-25.516 0-47.299-7.232t-38.513-24.964L352.065-516.747q2 7.98 3.5 18.639 1.5 10.66 1.5 17.589 0 6.928-1.5 14.846-1.5 7.919-3.5 15.899L642.603-285.87q16.73-14.493 37.69-22.844 20.959-8.351 48.122-8.351 51.307 0 88.261 36.518 36.954 36.519 36.954 88.142 0 51.383-36.663 87.829-36.664 36.446-87.522 36.446Z"/>
          </svg>
        </div>
        <div class="info-btn info-btn--favorite d-flex align-items-center justify-content-center"  :style="{'z-index': zIndexValue}" @click="favorite">
          <svg v-if="inFavorite" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
            <path d="M479.761-126.326q-14.718 0-28.435-4.739-13.717-4.739-24.435-15.457l-58.087-54.326q-122.478-113-214.239-218.858-91.76-105.859-91.76-226.098 0-96.718 65.238-161.935 65.24-65.218 160.957-65.218 53.043 0 102.804 24.663 49.761 24.664 87.957 74.381 43.196-49.717 89.956-74.381 46.761-24.663 100.805-24.663 95.956 0 161.435 65.218 65.478 65.217 65.478 161.935 0 120.239-92.62 226.217-92.619 105.978-212.337 218.217l-59.608 54.848q-10.718 10.718-24.555 15.457-13.837 4.739-28.554 4.739Z"/>
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
            <path d="M479.769-126.326q-14.425 0-28.214-4.62-13.79-4.619-24.932-15.826l-57.819-54.076q-122.478-113-214.239-218.858-91.76-105.859-91.76-226.098 0-96.88 65.439-162.016 65.439-65.137 160.266-65.137 53.533 0 103.294 24.663 49.761 24.664 87.957 74.381 43.196-49.717 89.956-74.381 46.761-24.663 101.05-24.663 95.774 0 161.221 65.137 65.447 65.136 65.447 162.016 0 120.239-92.62 226.217-92.619 105.978-212.337 218.217l-59.563 54.802q-11.013 11.145-24.716 15.693-13.704 4.549-28.43 4.549Zm-31.53-560.565q-22.717-43.957-66.865-76.359-44.148-32.402-92.237-32.402-64.551 0-106.789 41.987-42.239 41.988-42.239 108.526 0 56.725 38.479 120.833t92.538 123.94q54.058 59.832 112.131 111.54 58.073 51.709 96.05 86.196 38.067-34.761 96.091-87.055 58.024-52.293 112.498-111.924 54.474-59.63 93.354-123.408t38.88-120.801q0-65.791-42.44-107.812-42.44-42.022-107.052-42.022-48.883 0-93.761 31.902-44.877 31.902-67.355 76.859-5.071 9.761-12.837 14.141-7.767 4.38-17.45 4.38-9.513 0-17.661-4.38-8.149-4.38-13.335-14.141ZM480-499.761Z"/>
          </svg>
        </div>
      </div>
    </div>
      <div class="d-flex align-items-center justify-content-center flex-column" style="position: relative">
        <div v-if="currentStore.cashback" class="restaurant__cashback restaurant__cashback-in_catalog">{{currentStore.cashback}}%</div>
        <div class="vendor-logo m-0">
          <div class="store-logo" :style="`background-image: url('${logo}')`"></div>
        </div>
        <van-sticky @change="stickyChange" class="top-sticky-address" :style="{zIndex: zIndexValue - 1}">
          <div class="stick-header stick-header--catalog" :class="isMarketplace && 'stick-header--market'">
            <div class="store-name store-name--for-market catalog-name">
              <h6 style="" @click="goToHome">{{ !isMarketplace ? storeName : storeName.toLowerCase() }}</h6>
            </div>
          </div>
        </van-sticky>
      </div>
    <van-share-sheet
      v-model="showShare"
      :options="shareOptions"
      :cancel-text="'Закрыть'"
      @select="onSelectShare"
      class="share-modal"
    />
    <van-popup
      v-model="qrCodeModal"
      position="bottom"
      class="qrcode-modal"
      closeable
      close-icon="close"
      close-icon-position="top-right"
      :style="{ height: '70%' }"
      @closed="closedQrCode"
    >
      <div class="qrcode-modal-content d-flex justify-content-center align-items-center flex-column h-100">
        <h1 class="qr-code">QR-code</h1>
        <img :src="qrCodeLink" alt="">
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Sticky, ShareSheet, Popup, Toast} from "vant";
import {mapGetters, mapActions} from "vuex";
import {Dialog, Notify} from "vant";

export default {
  name: "CatalogMenuHeader",
  props: {
    logo: {
      type: String,
      default: "/img/nologo.png",
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: "",
    },
    autoDetectAddress: {
      type: Boolean,
      default: false,
    },
    storeName: {
      type: String,
      default: "",
    },
    isMarketplace: Boolean,
    currentStore: {
      type: Object,
      default: () => ({})
    },
  },
  emits: ["search-update", "delivery-modal"],
  components: {
    "van-sticky": Sticky,
    "van-share-sheet": ShareSheet,
    "van-popup": Popup,
  },
  data() {
    return {
      search: "",
      searchInput: false,
      stickyEnabled: false,
      showShare: false,
      qrCodeModal: false,
      qrCodeLink: "",
      headerBgPosition: "0px",
      marketplaceAction: this.isMarketplace,
      favorites: [],
      zIndexValue: 4,
      isScrolled: false,
      shareOptions: [
        {name: 'Telegram', icon: '/telegram-icon-share.png'},
        {name: 'Qrcode', icon: 'qrcode'},
        {name: 'Link', icon: 'link'},
      ],
    };
  },
  methods: {
    ...mapActions(["showAddressLoading", "setCanOrder"]),
    handleScroll() {
      const scroll = document.documentElement.scrollTop;
      // this.headerBgPosition = scroll + "px";
      this.isScrolled = scroll >= 50;
    },
    showSearch() {
      this.searchInput = !this.searchInput;
      if (!this.searchInput) {
        this.search = "";
      }
      this.$nextTick(() => {
        this.$refs.vanSearchInput.focus();
      });
    },
    showInfo() {
      console.log("info");
    },
    goToHome: function () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    clearSearch() {
      // this.search = "";
    },
    showDeliveryAddress: function () {
      this.$emit("delivery-modal");
    },
    stickyChange(isFixed) {
      this.stickyEnabled = isFixed;
      if (!isFixed) this.searchInput = false;
    },
    degreesToRadians(degrees) {
      return (degrees * Math.PI) / 180;
    },
    distanceInKmBetweenCoordinates(lat1, lon1, lat2, lon2) {
      let earthRadiusKm = 6371;

      let dLat = this.degreesToRadians(lat2 - lat1);
      let dLon = this.degreesToRadians(lon2 - lon1);

      lat1 = this.degreesToRadians(lat1);
      lat2 = this.degreesToRadians(lat2);

      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(lat1) *
        Math.cos(lat2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return earthRadiusKm * c;
    },
    share() {
      const currentLink = window.location.href;
      if (navigator.share) {
        navigator.share({
          text: this.storeName + ' copied link',
          url: currentLink,
          title: this.storeName
        })
      } else {
        this.showShare = true;
        console.log('Sharing is on Web')
      }
    },
    onSelectShare(option) {
      const currentLink = window.location.href;

      if (option.name === "Telegram") {
        // window.open(`tg://msg_url?url=${currentLink}`, 'newWindow', "width=500,height=700");
        window.open(`tg://msg_url?url=${currentLink}`, '_self');
      } else if (option.name === "Qrcode") {
        this.qrCodeLink = `https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=${currentLink}&choe=UTF-8`
        this.qrCodeModal = true;
      } else if (option.name === "Link"){
        navigator.clipboard.writeText(currentLink);
        Toast('Ссылка скопирована');
        // Notify({
        //     message: 'Ссылка скопирована успешно',
        //     color: '#ffffff',
        //     className: 'share-notify',
        //     background: '#21201F',
        //     duration: 800,
        // });
        this.showShare = false;
      }
    },
    closedQrCode() {
      this.qrCodeLink = "";
    },
    favorite() {
      this.$store.dispatch("addToFavorite", {...this.currentStore, id: this.$route.params.store});
    },
    backToMain() {
      const query = this.queryCollect;
      this.$router.push({ path: '/', query}).catch(() => {});
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    if (this.autoDetectAddress) {
      if (!navigator.geolocation) {
        Dialog.alert({
          title: "Error",
          message: "Geolocation is not supported by your browser",
          theme: "round-button",
          confirmButtonText: "Close",
        }).then(() => {
          window.location.reload();
        });
      } else {
        this.showAddressLoading(true);
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const storeAddress = this.$store.getters.coordinates;
            let distance = this.distanceInKmBetweenCoordinates(
              latitude,
              longitude,
              storeAddress[0],
              storeAddress[1]
            );
            this.showAddressLoading(false);
            if (distance > 0.05) {
              this.setCanOrder(false);
              Notify({type: "warning", message: this.$t("location-to-far")});
            } else {
              this.setCanOrder(true);
            }
          },
          (error) => {
            this.showAddressLoading(false);
            Dialog.alert({
              title: "Error",
              message: error.message,
              theme: "round-button",
              confirmButtonText: "Close",
            }).then(() => {
              window.location.reload();
            });
          }
        );
      }
    }
    this.$nextTick(() => {
      this.zIndexValue = 4;
    });

    // scrollHeaderFn();
    // function scrollHeaderFn() {
    //   const catalogMenuHeader = document.getElementById('catalogMenuHeader');
    //
    //   window.addEventListener('scroll', () => {
    //
    //     const scrollY = window.scrollY || window.pageYOffset;
    //     console.log(scrollY)
    //     if (scrollY > 100) {
    //       catalogMenuHeader.classList.add('active');
    //     }else {
    //       catalogMenuHeader.classList.remove('active');
    //     }
    //
    //   })
    // }
  },
  computed: {
    ...mapGetters(["address", "coordinates", "canOrder", "adModal", "queryCollect"]),
    inFavorite() {
      return !!this.$store.getters['favorites'].find(fav => +fav.id === +this.$route.params.store);
    },
  },
  watch: {
    searchValue(value) {
      this.search = value;
    },
    search(value) {
      this.$emit("search-update", value);
    },
  },
};
</script>

<style scoped lang="scss">
.van-dialog {
  z-index: 99999 !important;
}
.qr-code {
  font-weight: 500;
}
</style>
