<template>
  <div>
    <div class="label text-center">{{ $t("leave-tip") }}</div>
    <div v-if="total && onlyTips" class="label text-center hint">
      {{ $t("your-check") }}: {{ total | money($i18n.locale) }}
    </div>
    <alert-warning
      :selected-sum="tips"
      :get-color="color"
      @choose-sum="onChooseSum"
      :alert-open="alertWarning"
      @confirm-alert="confirmAlert"
    />
    <TipsOptions
      :mode="mode"
      :tips="tips"
      :total="total"
      :loading="loading"
      :payment-enabled="paymentEnabled"
      :only-tips="onlyTips"
      :payment-options="paymentOptions"
      :default-option="defaultOption"
      :gradient="getGradient"
      @set-tips="setTips"
    />
    <div class="commission-block" v-if="tips && (tipsCommission || billCommissionUp)">
      <div class="text-left">
        <div class="title">{{ $t("transfer-full-amount") }}</div>
        <div class="hint">
          {{
            $t("transfer-full-amount-hint", {
              "commission-price": commissionPrice
                .toFixed(0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            })
          }}
        </div>
      </div>
      <div class="text-right">
        <van-switch
          @change="switcherUpdate"
          v-model="switcher"
          :active-color="color"
          inactive-color="#c4c4c4"
        />
      </div>
    </div>
    <van-popup
      v-model="tipsPopup"
      closeable
      class="tips-popup"
      @closed="closeTipsPopup"
    >
      <div class="content">
        <p>{{ $t('employee-cant-receive-tips') }}</p>
      </div>
      <div class="footer" style="justify-content: space-between; gap: 20px">
        <van-button class="" @click="tipsPopup = false" type="default">{{ $t('cancel-remove') }}</van-button>
        <van-button class="remove-tips-button" @click="removeTips" type="primary">{{ $t('remove-tips') }}</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Vue from "vue";
import apiClient from "@/services/axios";
import {Popup, Switch, Button} from "vant";
import {mapActions, mapGetters} from "vuex";
import TipsOptions from "@/views/precheck/TipsOptions.vue";

export default {
  name: "TipsBlock",
  props: {
    mode: {
      type: String,
      default: 'precheck',
    },
    total: {
      type: Number,
      default: 0,
    },
    store: {
      type: Object,
      default: null,
    },
    employeeId: {
      type: String,
      default: null,
    },
    onlyTips: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false,
    },
    closed: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#1fb7a4',
    },
    tips: {
      type: Number,
      default: 0,
    },
    storePaymentOptions: {
      type: Array,
      default: () => []
    },
    storeTipsOptions: {
      type: Array,
      default: () => []
    },
    defaultOption: {
      type: Number,
      default: 0
    },
    tipsCommission: {
      type: Number,
      default: 0
    },
    billCommissionUp: {
      type: Number,
      default: 0
    },
    paymentEnabled: {
      type: Boolean,
      default: false
    },
  },
  emits: ['choose-sum', 'set-tips', 'set-commission', 'set-tips-commission'],
  data() {
    return {
      rate: 0,
      tipsModel: 0,
      activeTips: 0,
      switcher: false,
      activeSum: null,
      sumActive: true,
      tipsPopup: false,
      buttonColor: null,
      alertWarning: false,
      tipsPopupShown: false,
      showReviewForm: false,
    }
  },
  components: {
    TipsOptions,
    "van-popup": Popup,
    "van-button": Button,
    "van-switch": Switch,
  },
  mounted() {
    this.switcher = this.transferFullAmount
    this.tipsModel = this.tips
  },
  methods: {
    ...mapActions(["setTransferFullAmount"]),
    onChooseSum(value) {
      this.$emit('choose-sum', value)
    },
    setTips(sum) {
      if (this.onlyTips) {
        this.$emit("set-commission", 0)
      }
      if (!this.onlyTips && !this.tipsPopupShown) {
        this.checkTips(this.employeeId, sum)
      }
      this.$emit("set-tips", sum)
    },
    checkTips(employee, tips) {
      apiClient.get(`/employee/can-receive-tips/${employee}/${tips}`).then(({ data }) => {
        if (!data.data && !this.tipsPopupShown) {
          this.tipsPopupShown = true
          this.tipsPopup = true
        }
      })
    },
    closeTipsPopup() {
      this.tipsPopup = false
    },
    removeTips() {
      this.setTips(0);
      this.tipsModel = 0;
      this.tipsPopup = false
    },
    confirmAlert() {
      this.sumActive = false;
      this.alertWarning = false;
    },
    switcherUpdate(value) {
      this.setTransferFullAmount(value)
    }
  },
  computed: {
    ...mapGetters(['transferFullAmount']),
    getGradient() {
      return this.buttonColor ?? "linear-gradient(to left, #29BF9B, #0FAEAE)";
    },
    serviceCommissionPrice() {
      const total = (this.total * 10000) / (100 - this.billCommissionUp);
      let serviceCommissionPrice = (Math.floor(total, -2) - this.total * 100) / 100;
      if (!this.paymentEnabled || this.onlyTips || !this.billCommissionUp)
        serviceCommissionPrice = 0

      return serviceCommissionPrice
    },
    commissionPrice() {
      const tipsCommission = this.transferFullAmount ? this.tipsCommission : 0;
      if (!tipsCommission)
        return 0;

      const total = (this.tips * 10000) / (100 - tipsCommission);
      return (Math.floor(total, -2) - this.tips * 100) / 100;
    },
    paymentOptions() {
      if (this.mode === 'tips') {
        return this.storePaymentOptions;
      } else if (this.mode === 'precheck') {
        return this.storeTipsOptions;
      }
      return [];
    },
  },
  watch: {
    serviceCommissionPrice: {
      handler(value) {
        this.$emit('set-commission', value)
      }
    },
    commissionPrice: {
      handler(value) {
        this.$emit('set-tips-commission', value)
      }
    },
    store: {
      handler(value) {
        if (value) {
          if (!value['pay_guest']) {
            this.setTransferFullAmount(false)
            this.switcher = false
          }
          this.buttonColor = value['button_color']
        }
      }
    }
  }
}
Vue.component("AlertWarning", {
  props: {
    alertOpen: {
      type: Boolean,
      default: false,
    },
    selectedSum: {
      type: [Number, String],
      default: 0,
    },
    getColor: {
      type: String,
      default: '#1fb7a4',
    },
  },
  data() {
    return {
      sum: 0,
      open: false,
    }
  },
  template: `
    <div class="form-alert" :class="open && 'active'">
    <div class="d-flex align-items-start form-alert-inner">
      <img width="21" src="/payments/warning.svg" alt="">
      <div class="ml-10">{{ $t('tip-alert.paragraph1') }} <br> <span
          :style="{color: getColor}">{{ sum | money($i18n.locale) }}</span>
        {{ $t('tip-alert.paragraph2') }}
      </div>
    </div>
    <div class="d-flex justify-content-end form-alert-action">
      <button @click="onChooseSum('from_alert')" class="form-alert-btn cancel">Нет</button>
      <button @click="onConfirmAlert" :style="{backgroundColor: getColor}" class="form-alert-btn">Дa</button>
    </div>
    </div>
  `,
  methods: {
    onChooseSum(value) {
      this.$emit("choose-sum", value);
    },
    onConfirmAlert() {
      this.$emit("confirm-alert");
    },
  },
  watch: {
    alertOpen(value) {
      this.open = value;
    },
    selectedSum(value) {
      this.sum = value;
    },
  },
});
</script>
<style>
.tips-popup {
  width: 80%;
  max-width: 300px;
  z-index: 2002;
  padding: 20px 30px;
  border-radius: 18px;
}
.tips-popup .content {
  font-size: 16px;
  font-weight: 600;
}
.tips-popup .footer {
  display: flex;
  justify-content: space-around;
}
.tips-popup .footer .van-button {
  width: 45%;
  border-radius: 10px;
}
.tips-popup .remove-tips-button {
  background-color: #16b3aa;
  border-color: #16b3aa;
}
</style>