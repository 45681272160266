<template>
  <div v-if="isAuth" class="auth__list">
    <a
        v-for="item in items"
        :key="item.id"
        @click.prevent="$emit('open-modal', item.type)"
        class="auth__list-item"
        href=""
    >{{ item.name }}
    </a>
    <a @click.prevent="$emit('language-modal')" href="#" class="auth__list-item">{{ $t('choose-language') }}</a>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {useUserAgent} from "@/helpers/useUserAgent";

export default {
  name: "AuthList",
  mounted() {
  },
  computed: {
    ...mapGetters(["token"]),
    isAuth() {
      return !!this.token;
    },
    items() {
      let list = [
        {id: 1, name: this.$t("my-checks"), type: "checks"}
      ];

      if (this.useragent !== 'multicard')
        list.push({id: 2, name: this.$t("my-cards"), type: "cards"});

      list.push({id: 3, name: this.$t("my-books"), type: "books"});

      return list;
    },
    useragent() {
      return useUserAgent().getUserAgent()
    },
  },
}
</script>

<style scoped>

</style>
