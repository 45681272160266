<template>
  <div class="book-table p-15">
    <div class="book-table-top">
      <div></div>
      <h3>{{$t('table-reservation')}}</h3>
    </div>
    <div class="book-table-content">
      <div v-if="!menuAccount" class="book-table-address">
        {{$t('reserve-message')}}
      </div>
      <div v-if="Object.keys(bookedItem).length" class=" justify-content-between align-items-center mt-20 mb-30">
        <h3 class="mb-10 mt-0">{{ bookedItem['username'] }}</h3>
        <div class="d-flex justify-content-between book-content">
          <div>
            <div class="d-flex align-items-center mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path d="M180.109-63.326q-30.746 0-54.025-23.447-23.28-23.447-23.28-53.857v-620.022q0-30.844 23.28-54.194 23.279-23.35 54.025-23.35H231.5v-58.717h78.239v58.717h340.522v-58.717H728.5v58.717h51.391q30.845 0 54.194 23.35 23.35 23.35 23.35 54.194v297.87q-18.126-9.243-37.618-14.698-19.491-5.455-39.926-7.356v-92.294H180.109v436.5h323.617q7.187 21.434 18.144 41.01 10.956 19.577 26.391 36.294H180.109Zm566.208 33.26q-83.611 0-141.268-57.785-57.658-57.787-57.658-141.158 0-83.372 57.667-141.149 57.666-57.777 141.277-57.777 83.372 0 141.149 57.786 57.777 57.787 57.777 141.158 0 83.372-57.786 141.149-57.787 57.777-141.158 57.777Zm58.492-94.977 30.713-31.435-76-75.048v-121.322h-43.913v137.019l89.2 90.786Z"/>
              </svg>
              <div class="ml-5">{{ bookedItem.date }}</div>
            </div>
            <div class="d-flex align-items-center mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path d="M520.478-495.587v-161.261q0-15.186-10.663-25.952-10.662-10.765-26.219-10.765-15.556 0-26.554 10.765-10.999 10.766-10.999 25.952v176.131q0 8.543 2.62 15.461 2.62 6.917 7.859 11.973l139.087 143.087q10.611 12 26.903 12 16.292 0 28.412-12 10.119-10 10.619-26.598.5-16.597-10.739-27.836L520.478-495.587ZM480.309-62.804q-85.188 0-161.857-32.988t-133.165-89.476q-56.496-56.487-89.49-133.144-32.993-76.656-32.993-161.837 0-85.438 33.1-161.463 33.098-76.026 89.66-132.609 56.563-56.583 133.095-89.848 76.533-33.266 161.617-33.266 85.446 0 161.452 33.262 76.005 33.261 132.595 89.85 56.589 56.59 89.85 132.622 33.262 76.032 33.262 161.509 0 85.476-33.266 161.771-33.265 76.294-89.848 132.857-56.583 56.562-132.576 89.66-75.993 33.1-161.436 33.1Z"/>
              </svg>
              <div class="ml-5">{{ bookedItem.time.slice(0, -3) }}</div>
            </div>
          </div>
          <div>
            <div class="d-flex align-items-center mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path d="M12.109-240.812q0-39.677 19.098-70.846 19.097-31.168 55.098-46.668 74.956-32.739 136.418-47.739t125.821-15q65.177 0 125.969 15 60.791 15 135.791 47.761 35.384 14.934 55.268 46.174 19.885 31.239 19.885 71.239v33.587q0 32.674-22.435 54.989Q640.587-130 607.913-130h-518.5q-32.435 0-54.87-22.315Q12.11-174.63 12.11-207.304v-33.508ZM724.304-130q7.718-17.957 14.435-37.033 6.718-19.076 6.718-40.271v-29.083q0-63.722-29-109.602-29-45.881-93.957-76.837 74.174 8.239 140.38 23.478 66.207 15.239 108.772 37.044 35.761 20.521 56.5 51.597Q948.891-279.63 948.891-241v33.696q0 32.674-22.935 54.989Q903.022-130 871.431-130H724.304ZM348.783-487.152q-72.761 0-117.783-45.022-45.022-45.022-45.022-117.283 0-72.26 45.022-117.782 45.022-45.522 117.283-45.522 72.021 0 117.782 45.409 45.761 45.41 45.761 117.395 0 72.761-45.648 117.783-45.649 45.022-117.395 45.022Zm398.174-162.805q0 72.522-45.519 117.663-45.518 45.142-117.251 45.142-13.796 0-29.622-1.881-15.826-1.88-29.065-6.88 25.239-28.173 37.739-67.16 12.5-38.987 12.5-86.589 0-47.364-12.5-84.59-12.5-37.226-37.739-69.748 13.033-3.75 29.207-6.255 16.173-2.506 29.445-2.506 71.747 0 117.276 45.529 45.529 45.529 45.529 117.275Z"/>
              </svg>
              <div class="ml-5">{{ bookedItem.guests }}</div>
            </div>
            <div v-if="bookedItem.hall_name" class="d-flex align-items-center mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path d="M619.152-519.152v-250h192.696q30.745 0 54.025 23.279 23.279 23.28 23.279 54.025v172.696h-270Zm-470 327.304q-31.383 0-54.344-22.96-22.96-22.961-22.96-54.344v-422.696q0-30.745 22.96-54.025 22.961-23.279 54.344-23.279h392.696v577.304H149.152Zm157.738-230q24.414 0 41.262-16.931Q365-455.711 365-480.042q0-25.331-16.89-42.221-16.89-16.889-41.304-16.889-25.331 0-42.221 17.181-16.889 17.182-16.889 42.013 0 24.331 16.931 41.221 16.932 16.889 42.263 16.889Zm312.262 230v-250h270v172.696q0 31.383-23.279 54.344-23.28 22.96-54.025 22.96H619.152Z"/>
              </svg>
              <div class="ml-5">{{ bookedItem.hall_name }}</div>
            </div>
          </div>
        </div>
        <div class="mb-10">{{ bookedItem['comment'] }}</div>
      </div>
    </div>
    <div v-if="!menuAccount" class="w-100 text-center">
      <van-button
          @click="$router.go(-1)"
          class="book-table-btn"
      >Назад
      </van-button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Button} from "vant";

export default {
  name: "BookList",
  props: {
    reserveNumber: {
      type: String,
      default: null
    },
    menuAccount: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      reserve_number: null,
      bookedItem: {},
    }
  },
  methods: {
    ...mapActions(["fetchReservationData"]),
    getData() {
      this.fetchReservationData({
        number: this.reserve_number,
        token: this.token,
      }).then((res) => {
        this.bookedItem = res;
      });
    }
  },
  computed: {
    ...mapGetters(["token"]),
  },
  mounted() {
   this.getData();
  },
  created() {
    this.reserve_number = this.$route.params.id || this.reserveNumber;
  },
  components: {
    "van-button": Button,
  }
}
</script>

<style scoped>

</style>
