<template>
  <div>
    <LoadingBlock v-if="loading"></LoadingBlock>
    <store-view-item
      status="store-view"
    />
  </div>
</template>

<script>
import LoadingBlock from "@/components/Loading";
import StoreViewItem from "@/views/StoreViewItem";

export default {
  title: "Список сотрудников",
  name: "StoreView",
  components: {
    StoreViewItem,
    LoadingBlock,
  },
  data() {
    return {
      // search: "",
      // storeId: "",
      // activeTab: 0,
    };
  },
  // mounted() {
  //   this.storeId = this.$route.params.storeId;
  //   this.fetchEmployeesByStore(this.storeId);
  // },
  computed: {
    // employees() {
    //   return this.$store.getters.storeEmployees;
    // },
    // logo() {
    //   return this.$store.getters.storeLogo;
    // },
    // activeEmployees() {
    //   let list = this.employees ? this.employees[this.activeRole] : [];
    //   return list.filter((employee) => {
    //     return employee.name.toLowerCase().includes(this.search.toLowerCase());
    //   });
    // },
    // activeRole() {
    //   return this.roles[this.activeTab];
    // },
    // roles() {
    //   if (!this.employees) return [];
    //   let employees = Object.keys(this.employees);
    //   return employees.map((item) => {
    //     return item;
    //   });
    // },
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    // ...mapActions(["fetchEmployeesByStore"]),
    // getPhoto(url) {
    //   let photo =
    //     url === null || url === undefined || url === ""
    //       ? "/img/superman.png"
    //       : url;
    //   return "background-image: url(" + photo + ")";
    // },
  },
};
</script>
