<template>
  <div class="total-price-wrapper">
    <table v-if="serviceCommissionPrice" class="table details-table mt-25 mb-25">
      <tbody>
      <tr>
        <td class="align-left border-bottom">{{ $t("check") }}</td>
        <td class="align-right border-bottom font-bold">
          <span>{{ selectedSum | money($i18n.locale) }}</span>
        </td>
      </tr>
      <tr v-if="selectedSum && serviceCommissionPrice">
        <td class="align-left border-bottom">{{ $t("service-commission") }}</td>
        <td class="align-right border-bottom font-bold">
          {{ serviceCommissionPrice | money($i18n.locale) }}
        </td>
      </tr>
      </tbody>
    </table>
    <p class="label-total">{{ $t("total-payment") }}</p>
    <p class="price-total" :style="{ color }">
      <span>{{ total | money($i18n.locale) }}</span>
    </p>
  </div>
</template>

<script>

export default {
  name: "PaymentInfo",
  props: {
    serviceCommission: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#1fb7a4',
    },
    selectedSum: {
      type: Number,
      default: 0
    }
  },
  computed: {
    serviceCommissionPrice() {
      const total = (this.selectedSum * 10000) / (100 - this.serviceCommission);
      return (Math.floor(total, -2) - this.selectedSum * 100) / 100;
    },
  }
}
</script>

<style scoped>

</style>