<template>
  <section v-if="false" class="adv">
    <swiper
      class="adv-swiper"
      :slides-per-view="1.4"
      :space-between="15"
      :loop="false"
    >
      <swiper-slide
        v-for="(adv, index) in advertisement"
        :key="adv.img"
        class="adv-swiper__slide"
        :style="getBg(adv.img)"
      >
        <div
          @click="$emit('move-adv', index)"
        ></div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper-vue2";

export default {
  name: "AdvBlock",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    advertisement: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getBg: function(img) {
      return `background-image: url('/img/marketplace/${img}'); z-index: 99999`;
    }
  }
};
</script>

<style scoped></style>
