import Item from "@/models/Item";

export default function Product(attrs) {
  this.id = attrs.id ? attrs.id : null;
  this.name = attrs.name ? attrs.name : null;
  this.items = [];
  let items = attrs.items;
  if (items && items.length) {
    items.forEach((data) => {
      this.items.push(new Item(data));
    });
  }
}
