<template>
  <div>
    <loading-block v-if="menuLoading" />
    <address-loading-block v-if="addressLoading" />
    <menu-header
      v-if="productsLoaded"
      :auto-detect-address="true"
      :logo="logo"
      :store-name="storeName"
      :search-value="search"
      @search-update="searchUpdate"
      :is-marketplace="false"
      :current-store="currentStore"
    />
    <div class="vendor-content">
      <div class="takeaway-wrapper mb-10">
        <div class="takeaway-type">
          <a
            :class="{ active: orderType === 'on_table' }"
            @click="orderType = 'on_table'"
          >{{ $t("filters.types.on_table") }}</a
          >
          <a
            :class="{ active: orderType === 'takeaway' }"
            @click="orderType = 'takeaway'"
          >{{ $t("filters.types.takeaway") }}</a
          >
        </div>
      </div>
      <stories-block v-if="1 === 0" />
      <tasty-block v-if="1 === 0" />
      <products-block
          :show-menu="showMenu"
          :search="search"
          :is-marketplace="false"
          @category="categoryModal = true"
      />
    </div>
    <cart-info
      :auth-modal="authModal"
      @close-auth-modal="closeAuthModal"
      :cart-info-modal="cartModal"
      @close-cart-info-modal="closeCartModal"
      @close-checkout-modal="checkoutModal = false"
      :checkout="checkoutModal"
      @remove-item="removeItemFromCart"
      :delivery-price="deliveryPrice"
      @finish-order="finishOrder"
      @show-delivery-address="showDeliveryAddress"
      @show-checkout-modal="showCheckoutModal"
    />
    <order-info
      @close-order-info-modal="closeOrderInfoModal"
      :order-info-modal="orderInfoModal"
    />
    <swipe-modal class="float-menu-modal" :modal="menuModal" @close="menuModal = false">
      <template v-slot:body>
        <call-modal :store-id="storeId" :show-bell-list="showBellList" @clicked="menuModal = false" />
      </template>
    </swipe-modal>
    <swipe-modal class="float-menu-modal" :modal="categoryModal" @close="categoryModal = false">
      <template v-slot:body>
        <ul class="float-menu" v-if="products.length && showMenu">
          <li v-for="category in products" :key="category.id">
            <a href="#" @click.prevent="goToCategory(category)" class="link">{{
              category.name
            }}</a>
          </li>
        </ul>
      </template>
    </swipe-modal>
    <div class="float-buttons-wrap right">
      <div
        class="orders-button"
        @click="showCartModal"
        v-if="cart && cart.length && !order.length"
      >
        <span class="price">{{ cartTotalPrice | money($i18n.locale) }}</span>
        <span class="remaining">Сделать заказ</span>
      </div>
      <div
        v-if="order.length"
        class="orders-button"
        @click="showOrderInfoModal"
      >
        <span class="price">Заказ на кассе</span>
        <span class="remaining">30 минут</span>
      </div>
    </div>
    <navigation-menu :store-id="storeId" />
  </div>
</template>

<script>
import LoadingBlock from "@/components/menu/Loading.vue";
import MenuHeader from "@/components/menu/MenuHeader.vue";
import SwipeModal from "@/components/menu/SwipeModal.vue";
import StoriesBlock from "@/components/menu/StoriesBlock.vue";
import TastyBlock from "@/components/menu/TastyBlock.vue";
import ProductsBlock from "@/components/menu/ProductsBlock.vue";
import { mapActions, mapGetters } from "vuex";
import AddressLoadingBlock from "@/components/menu/AddressLoading.vue";
import { DeviceUUID } from "device-uuid";
import OrderInfo from "@/components/menu/OrderInfo.vue";
import NavigationMenu from "@/components/menu/NavigationMenu.vue";
import CartInfo from "@/components/menu/CartInfo.vue";
import CallModal from "@/components/menu/CallModal.vue";

export default {
  title: "Вынос с точки",
  name: "TakeawayPage",
  components: {
    CallModal,
    CartInfo,
    OrderInfo,
    AddressLoadingBlock,
    LoadingBlock,
    MenuHeader,
    StoriesBlock,
    TastyBlock,
    ProductsBlock,
    NavigationMenu,
    SwipeModal,
  },
  data() {
    return {
      orderType: "on_table",
      payBeforeOrder: true,
      storeName: "",
      currentStore: {},
      storeId: null,
      logo: "/img/nologo.png",
      search: "",
      cartModal: false,
      timeModal: false,
      checkoutModal: false,
      authModal: false,
      orderInfoModal: false,
      menuModal: false,
      categoryModal: false,
      showMenu: false,
      showBellList: {
        call_bill: 0,
        call_hookah: 0,
        call_waitress: 0
      },
      deliveryPrice: 0,
      phone: "",
      productsLoaded: false,
    };
  },
  created() {
    this.storeId = this.$route.params.store;
    let uuid = new DeviceUUID().get();
    this.setDeviceUuid(uuid);
  },
  mounted() {
    this.getProducts();
    this.$store.commit("SET_NAVIGATION_MENU_HOME_ROUTE", this.$route.fullPath)
  },
  beforeRouteLeave(to, from, next){
    this.$store.commit("SET_NAVIGATION_MENU_HOME_ROUTE", from.fullPath)
    next()
  },
  methods: {
    ...mapActions([
      "removeFromCart",
      "cartFlush",
      "makeOrder",
      "showMenuLoading",
      "fetchProducts",
      "setCanOrder",
      "setDeviceUuid",
    ]),
    showDeliveryAddress: function () {
      this.deliveryAddress = true;
    },
    finishOrder() {
      this.checkoutModal = false;
      this.cartModal = false;
      this.createNewOrder();
    },
    createNewOrder() {
      this.makeOrder({
        store_id: this.$route.params.store,
        items: this.cart,
        price: this.cartTotalPrice,
        deliveryPrice: this.deliveryPrice,
        type: this.orderType,
      });
    },
    removeItemFromCart(item, all = false) {
      this.removeFromCart({ item, all });
    },
    flushCart() {
      this.cartFlush();
      this.cartModal = false;
    },
    async getProducts() {
      this.fetchProducts({
        brand: this.$route.params.brand,
        store: this.$route.params.store,
      }).then((res) => {
        this.productsLoaded = true;
        this.storeName = res?.name;
        this.showMenu = res?.menu === 1;
        this.showBellList = {
          call_bill: res.call_bill,
          call_hookah: res.call_hookah,
          call_waitress: res.call_waitress
        };
        this.logo = res?.logo;
        this.currentStore = res;
        this.storeId = res.store_id
        this.setCanOrder(res?.order === 1);
      });
    },
    showCheckoutModal: function () {
      if (this.payBeforeOrder) {
        this.checkoutModal = true;
      } else {
        this.createNewOrder();
      }
    },
    closeAuthModal: function () {
      this.authModal = false;
    },
    showCartModal: function () {
      this.cartModal = true;
    },
    closeCartModal: function () {
      this.cartModal = false;
    },
    showOrderInfoModal: function () {
      this.orderInfoModal = true;
    },
    closeOrderInfoModal: function () {
      this.orderInfoModal = false;
    },
    async goToCategory(category) {
      this.activeTab = await category.name;
      this.categoryModal = false;
      let block = await document.getElementById(category.id);
      await block.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    searchUpdate(value) {
      this.search = value;
    },
    clearSearch() {
      this.search = "";
    },
  },
  computed: {
    ...mapGetters([
      "cart",
      "order",
      "addressLoading",
      "menuLoading",
      "products",
      "token",
      "address",
      "canOrder",
      "deviceUuid",
    ]),
    cartTotalPrice() {
      if (!this.cart || !this.cart.length) return 0;
      let price = 0, price_mod = 0;

      this.cart.forEach((cartItem) => {
        price += cartItem.price * 1;
        if (cartItem.modifiers.length) {
          const calc = cartItem.modifiers.reduce((sum, item) => sum + (item.price * item.quantity), 0);
          price_mod += calc;
        }
      });
      return price + price_mod;
    },
  },
};
</script>

<style scoped></style>
