<template>
  <div>
    <div v-if="button" class="language-switcher" :style="{ background: getGradient, top: $store.getters.adModal ? '110px' : '10px' }">
      <ul class="switcher__navbar">
        <li class="switcher__item">
          <a href="#" @click.prevent="" class="switcher__link">
            <button
                v-if="switcherActive"
                class="switcher--button"
                key="on"
                @click="modalVisible = false"
            >
              <div class="d-flex flex-row-reverse">{{ currentLocale }}<i class="globe-icon"></i></div>
            </button>
            <button
                v-else
                class="switcher--button"
                key="off"
                @click="modalVisible = true"
            >
              <div class="d-flex flex-row-reverse">{{ currentLocale }}<i class="globe-icon"></i></div>
            </button>
          </a>
          <!-- Dropdown Menu -->
          <transition name="dropdown">
            <div
                class="dropdown__menu"
                v-bind:class="{ active: switcherActive }"
                v-if="switcherActive"
            >
              <ul class="dropdown__menu-nav">
                <li class="dropdown__menu-item">
                  <a
                      @click.prevent="switchLanguage('uz')"
                      href="#"
                      class="dropdown__menu-link"
                      title="O'zbekcha"
                  >
                    <div class="dropdown__menu-text">O'zbekcha</div>
                  </a>
                </li>
                <li class="dropdown__menu-item">
                  <a
                      @click.prevent="switchLanguage('ru')"
                      href="#"
                      class="dropdown__menu-link"
                      title="Русский"
                  >
                    <div class="dropdown__menu-text">Русский</div>
                  </a>
                </li>
                <li class="dropdown__menu-item">
                  <a
                      @click.prevent="switchLanguage('en')"
                      href="#"
                      class="dropdown__menu-link"
                      title="English"
                  >
                    <div class="dropdown__menu-text">English</div>
                  </a>
                </li>
                <li class="dropdown__menu-item">
                  <a
                      @click.prevent="switchLanguage('tr')"
                      href="#"
                      class="dropdown__menu-link"
                      title="Türkçe"
                  >
                    <div class="dropdown__menu-text">Türkçe</div>
                  </a>
                </li>
                <li class="dropdown__menu-item">
                  <a
                      @click.prevent="switchLanguage('fr')"
                      href="#"
                      class="dropdown__menu-link"
                      title="Français"
                  >
                    <div class="dropdown__menu-text">Français</div>
                  </a>
                </li>
                <li class="dropdown__menu-item">
                  <a
                      @click.prevent="switchLanguage('de')"
                      href="#"
                      class="dropdown__menu-link"
                      title="Deutsch"
                  >
                    <div class="dropdown__menu-text">Deutsch</div>
                  </a>
                </li>
                <li class="dropdown__menu-item">
                  <a
                      @click.prevent="switchLanguage('ka')"
                      href="#"
                      class="dropdown__menu-link"
                      title="ქართული"
                  >
                    <div class="dropdown__menu-text">ქართული</div>
                  </a>
                </li>
                <li class="dropdown__menu-item">
                  <a
                      @click.prevent="switchLanguage('kr')"
                      href="#"
                      class="dropdown__menu-link"
                      title="한국인"
                  >
                    <div class="dropdown__menu-text">한국인</div>
                  </a>
                </li>
                <li class="dropdown__menu-item">
                  <a
                      @click.prevent="switchLanguage('zh')"
                      href="#"
                      class="dropdown__menu-link"
                      title="中国人"
                  >
                    <div class="dropdown__menu-text">中国人</div>
                  </a>
                </li>
              </ul>
            </div>
          </transition>
        </li>
      </ul>
    </div>
    <template v-if="isMarket">
      <div v-if="modalVisible" class="language-modal">
        <ul class="languages-list">
          <li class="switch-uz">
            <button @click="switchLanguage('uz')">O'zbekcha</button>
          </li>
          <li class="switch-ru">
            <button @click="switchLanguage('ru')">Русский</button>
          </li>
          <li class="switch-en">
            <button @click="switchLanguage('en')">English</button>
          </li>
          <!--        <li class="switch-tr">-->
          <!--          <button @click="switchLanguage('tr')">Türkçe</button>-->
          <!--        </li>-->
          <!--        <li class="switch-fr">-->
          <!--          <button @click="switchLanguage('fr')">Français</button>-->
          <!--        </li>-->
          <!--        <li class="switch-de">-->
          <!--          <button @click="switchLanguage('de')">Deutsch</button>-->
          <!--        </li>-->
          <!--        <li class="switch-ka">-->
          <!--          <button @click="switchLanguage('ka')">ქართული</button>-->
          <!--        </li>-->
          <!--        <li class="switch-kr">-->
          <!--          <button @click="switchLanguage('kr')">한국인</button>-->
          <!--        </li>-->
          <!--        <li class="switch-zh">-->
          <!--          <button @click="switchLanguage('zh')">中国人</button>-->
          <!--        </li>-->
        </ul>
      </div>
    </template>
    <van-popup
        v-else
        v-model="modalVisible"
        class="lang-modal"
        position="center"
        style="max-width: 210px"
        :close-on-click-overlay="false"
        round
    >
<!--      <p class="text-center">Выберите язык</p>-->
      <ul class="lang-modal-content d-flex flex-wrap justify-content-center">
        <li v-for="lang in languages" :key="lang.name">
          <a
              @click.prevent="switchLanguage(lang.lang)"
              :class="lang.disabled ? 'disabled' : ''"
              href="#"
          >
<!--            <img width="32" height="22" :src="lang.img" alt="">-->
            {{ lang.name }}
          </a>
        </li>
      </ul>
    </van-popup>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {Popup} from "vant";

export default {
  name: "LanguageSwitcher",
  components: {
    "van-popup": Popup
  },
  props:{
    isMarket: {
      type: Boolean,
      default: false
    },
    button: {
      type: Boolean,
      default: true
    },
    modal: {
      type: Boolean,
      default: false
    }
  },
  emit: ['close'],
  data() {
    return {
      modalVisible: false,
      switcherActive: false,
      otherLanguage: 'en',
      languages: [
        {name: "O'zbekcha", lang: "uz", img: require('@/assets/flags/uz.svg')},
        {name: "Русский", lang: "ru", img: require('@/assets/flags/ru.svg')},
        {name: "English", lang: "en", img: require('@/assets/flags/en.svg')},
        // {name: "Ўзбекча", lang: "oz", img: require('@/assets/flags/uz.svg') },
        // {name: "Türkçe", lang: "tr"},
        // {name: "Қазақша", lang: "kz", disabled: true},
        // {name: "Українська", lang: "uk", disabled: true},
        // {name: "Français", lang: "fr"},
        // {name: "Deutsch", lang: "de"},
        // {name: "日本語", lang: "jp", disabled: true},
        // {name: "한국인", lang: "kr"},
        // {name: "中國人", lang: "zh"},
        // {name: "ქართული", lang: "ka"},
      ],
    };
  },
  methods: {
    async changeOtherLanguage(e) {

      const {value} = e.target;
      // const folderId = "enpe5t3qi3dqshobdqbf"
      try {
        const IAM_TOKEN = 'y0_AgAAAAAH1ZPfAATuwQAAAADoF8IjnMDnWFCHTjW0XKhWtbiDNIcJcEc';
        const res = await fetch('https://translate.api.cloud.yandex.net/translate/v2/languages', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + IAM_TOKEN,
          },
            "folderId": "enpe5t3qi3dqshobdqbf",
        });
        const data = await res.json();
        console.log('yData', data, value)
      } catch (e) {
        console.log(e)
      }
    },
    switchLanguage(lang) {
      this.setLanguage(lang);
      this.$i18n.locale = lang;
      this.switcherActive = false;
      this.modalVisible = false;
      this.$emit('switch-language');
    },
    ...mapActions(["setLanguage"]),
  },
  mounted() {
    // let lang = this.employee.store.default_lang;
    // let lang = "";
    // if (this.language && this.language !== "") {
    //   lang = this.language;
    // } else {
    //   let browserLocale = navigator.language;
    //   if (browserLocale.substring(0, 2) === "uz") {
    //     lang = browserLocale.substring(0, 2);
    //   } else {
    //     this.modalVisible = true;
    //   }
    // }
    // this.$i18n.locale = lang.substring(0, 2);
    // this.setLanguage(lang.substring(0, 2));
  },
  computed: {
    ...mapGetters(["language", "employee", "store"]),
    currentLocale() {
      if (this.$i18n.locale.toUpperCase() == 'OZ')
        return 'УЗ';

      return this.$i18n.locale.toUpperCase();
    },
    getGradient() {
      let store = Object.keys(this.employee).length ? this.employee.store : this.store;
      return store?.['button_color'] ?? "linear-gradient(to left, #29BF9B, #0FAEAE)";
    },
  },
  watch: {
    modal(value) {
      if (value)
        this.modalVisible = true
    },
    modalVisible(value) {
      if (!value) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.lang-modal {
  width: 90%;
  max-width: 768px;
  padding: 10px 30px 10px;

  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 30px;
  }
}

.lang-modal-content {
  text-align: center;
  flex-direction: column;

  li {
    a {
      display: block;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      padding: 14px 10px;
      border-bottom: 1px solid #EAEAEA;

      img {
        float: left;
        margin-right: 14px;
      }

      &.disabled {
        cursor: not-allowed;
        filter: gray(.4);

        &:after {
          display: none;
        }
      }

      //&:after {
      //  content: '';
      //  position: absolute;
      //  width: 25px;
      //  height: 0;
      //  left: 0;
      //  right: 0;
      //  margin: auto;
      //  bottom: 6px;
      //  border: .5px solid rgba(0, 0, 0, 0.4);
      //  border-radius: 20px;
      //}
    }
    &:last-child a {
      border-bottom: none;
    }
  }
}

</style>
