<template>
  <ul class="navigation-menu" :style="{'--menu-icon-color': getColor}" v-if="useragent === 'browser'">
    <li class="home-link">
      <router-link
        v-if="this.$store.state.navigationBillHomeRoute.length"
        :to="this.$store.state.navigationBillHomeRoute"
        exact-active-class="active"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
          <path d="M221.674-55.5q-51.942 0-87.243-36.009-35.3-36.009-35.3-86.534v-104.566q0-15.94 11.258-27.166Q121.647-321 137.522-321h92.239v-550.565q0-7.105 5.739-9.411 5.739-2.307 11.272 3.227L278.522-846q4.832 6.239 14.014 6.12 9.181-.12 14.421-6.359L342-881.043q4.68-5 13.938-5 9.258 0 14.258 5L404-846.239Q408.68-840 417.949-840q9.268 0 14.486-6.239l35.043-34.804q4.55-5 13.818-5 9.269 0 14.617 5l34.565 34.804Q535.375-840 544.644-840t14.269-6.239l35.044-34.804q4.397-5 13.752-5 9.356 0 14.682 5l34.566 34.804Q661.789-840 670.97-840q9.182 0 14.421-6.239l34.805-34.804q4.614-5 13.97-5 9.356 0 14.464 5l34.805 34.804q4.68 6.239 13.948 6.359 9.269.119 14.487-6.12l33.043-32.043q4.357-5.24 10.657-2.725 6.3 2.514 6.3 9.203v693.522q0 50.525-36.079 86.534-36.079 36.01-86.465 36.01H221.674Zm516.152-77.543q18.5 0 31-12.505t12.5-32.095v-613.335H310.065V-271h349.109q16.061 0 27.606 11.225 11.546 11.226 11.546 27.166v54.566q0 19.76 10 32.38 10 12.62 29.5 12.62ZM394.348-699.109h170.369q14.387 0 24.737 10.145 10.35 10.145 10.35 24.501 0 14.356-10.35 24.442t-24.737 10.086H394.348q-13.962 0-24.025-10.28-10.062-10.281-10.062-24.566 0-14.284 10.062-24.306 10.063-10.022 24.025-10.022Zm0 140.37h170.369q14.387 0 24.737 10.125 10.35 10.126 10.35 24.453t-10.35 24.462q-10.35 10.134-24.737 10.134H394.348q-13.962 0-24.025-10.31-10.062-10.309-10.062-24.637 0-14.327 10.062-24.277 10.063-9.95 24.025-9.95Zm297.035-71.196q-14.144 0-24.166-10.605-10.021-10.605-10.021-24.065t9.969-23.982q9.969-10.522 24.185-10.522 14.215 0 24.617 10.47 10.403 10.469 10.403 24 0 13.532-10.422 24.118-10.421 10.586-24.565 10.586Zm0 135.37q-14.144 0-24.166-10.634-10.021-10.635-10.021-24.137 0-13.503 9.969-23.953t24.185-10.45q14.215 0 24.617 10.45 10.403 10.45 10.403 23.953 0 13.502-10.422 24.137-10.421 10.634-24.565 10.634Z"/>
        </svg>
      </router-link>
      <a v-else href="#" @click.stop="() => {}" class="cursor-not-allowed">
        <img width="24" src="/svg/navigation/home-bill.svg" alt=""/>
      </a>
    </li>
    <li class="qr-link" v-if="false">
      <a href="#" @click.stop="() => {}" class="">
        <img width="24" src="/svg/navigation/qr-icon.svg" alt=""/>
      </a>
    </li>
    <li class="promo-link" v-if="false">
      <a href="#" @click.stop="() => {}" class="cursor-not-allowed">
        <img width="24" src="/svg/navigation/promo-icon.svg" alt=""/>
      </a>
    </li>
    <li class="account-link">
      <router-link
        :to="{ name: 'AccountPage' }"
        active-class="active"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
          <path d="M479.989-488.435q-72.745 0-118.182-45.557T316.37-652.136q0-72.588 45.399-118.074 45.399-45.486 118.144-45.486t118.351 45.459q45.606 45.46 45.606 118.22 0 72.468-45.568 118.025t-118.313 45.557ZM143.326-207.304v-32.976q0-41.985 20.513-72.333 20.514-30.348 53.878-45.691 68.761-32 133.142-47.381 64.38-15.38 129.077-15.38 65.174 0 128.88 16 63.706 16 132.467 46.761 34.428 15.064 55.029 45.432 20.601 30.368 20.601 72.592v32.976q0 32.769-22.379 55.037Q772.154-130 739.37-130H220.63q-32.685 0-54.995-22.267-22.309-22.268-22.309-55.037Z"/>
        </svg>

      </router-link>
    </li>
  </ul>
</template>

<script>
import {useUserAgent} from "@/helpers/useUserAgent";

export default {
  name: "NavigationBill",
  components: {},
  computed: {
    useragent() {
      return useUserAgent().getUserAgent()
    },
    employee() {
      return this.$store.getters.employee;
    },
    getColor() {
      const store = Object.keys(this.employee).length ? this.employee.store : this.store;
      return store?.['button_color'] ?? "#1fb7a4";
    },
  }
};
</script>

<style lang="scss" scoped>
.navigation-menu {
  position: fixed;
  z-index: 5;
  bottom: 20px;
  left: 20px;
  right: 20px;
  height: 54px;
  width: 128px;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;

  background: linear-gradient(153.32deg, rgba(255, 255, 255, 0.3) -65.62%, rgba(255, 255, 255, 0.1) 83.28%);
  box-shadow: 0 4px 30px -1px rgba(240, 240, 240, 0.4);
  border: 1px solid rgba(240, 240, 240, 0.4);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 22px;
  li {
    display: block;
    &.account-link {
        &::after {
          content: '';
          position: absolute;
          right: 62px;
          top: 13px;
          border: 1px solid;
          height: 30px;
          opacity: .1;
          border-color: #0faeae;
        }
    }
    a {
      vertical-align: middle;
      display: table-cell;
      height: 50px;
      transition: all 0.2s ease;
      position: relative;
      color: #0f1a14 !important;
      .stock {
        position: absolute;
        top: 11px;
        right: -10px;
        color: #21201F;
        font-size: 13px;
        background: #FCE000;
        font-weight: bold;
        border-radius: 44px;
        padding: 1px 5px;
      }
      &.cursor-not-allowed {
        cursor: not-allowed;
      }
      &.active {
        opacity: 1;
        filter: none;
        transition: all 0.2s ease;
        svg, path {
          fill: var(--menu-icon-color);
          stroke: var(--menu-icon-color);
        }
      }
      img {
        margin: 0 auto;
        display: block;
      }
    }
  }
}
</style>
