<template>
  <div>
    <loading-block v-if="menuLoading" :color="btnColor" />
    <address-loading-block v-if="addressLoading" />
    <menu-header
      :auto-detect-address="true"
      :logo="logo"
      :deskName="deskName"
      :storeName="storeName"
      :search-value="search"
      :is-marketplace="false"
      :current-store="currentStore"
      @search-update="searchUpdate"
      @show-detail="showDetail"
      @sticky-change="value => stickyEnabled = value"
    />
    <div class="vendor-content" :class="{ 'border-radius-none': stickyEnabled }">
      <stories-block v-if="1 === 0" />
      <tasty-block v-if="1 === 0" />
      <products-block
        ref="refProduct"
        :show-menu="showMenu"
        :search="search"
        :is-marketplace="false"
        :sticky-enabled="false"
        @category="categoryModal = true"
      />
    </div>
    <cart-info
      @close-auth-modal="() => false"
      :cart-info-modal="cartModal"
      @close-cart-info-modal="closeCartModal"
      @close-checkout-modal="checkoutModal = false"
      :checkout="checkoutModal"
      @remove-item="removeItemFromCart"
      :delivery-price="0"
      @finish-order="finishOrder"
      @show-delivery-address="showDeliveryAddress"
      @show-checkout-modal="showCheckoutModal"
      @create-order="createOrder"
      @update-order="updateOrder"
    />
<!--    <order-info-->
<!--      @close-order-info-modal="closeOrderInfoModal"-->
<!--      @add-dishes="addDishes"-->
<!--      :order-info-modal="orderInfoModal"-->
<!--    />-->
    <swipe-modal class="float-menu-modal" :modal="menuModal" @close="menuModal = false">
      <template v-slot:body>
        <call-modal
          :store-id="storeId"
          :desk-id="deskId"
          :show-bell-list="showBellList"
          @clicked="menuModal = false"
        />
      </template>
    </swipe-modal>
    <swipe-modal class="float-menu-modal" :modal="categoryModal" @close="categoryModal = false">
      <template v-slot:body>
        <ul class="float-menu" v-if="products.length && showMenu">
          <li v-for="category in products" :key="category.id">
            <a href="#" @click.prevent="goToCategory(category)" class="link">{{
              category.name
            }}</a>
          </li>
        </ul>
      </template>
    </swipe-modal>
    <div class="float-buttons-wrap right">
      <div v-if="false" class="float-button right pointer" @click="categoryModal = true">
        <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 -960 960 960" width="28">
          <path d="M141-182.804q-16.103 0-27.149-11.017-11.047-11.017-11.047-27.119 0-16.203 10.966-27.23 10.965-11.026 26.991-11.026H820q15.229 0 26.332 10.898 11.103 10.897 11.103 27.562 0 16.239-11.034 27.085-11.034 10.847-27.162 10.847H141Zm0-174q-16.103 0-27.149-11.08-11.047-11.079-11.047-26.274 0-15.464 11.047-26.87 11.046-11.407 27.149-11.407h679q15.229 0 26.332 11.357 11.103 11.357 11.103 27.134 0 15.352-11.103 26.246-11.103 10.894-26.332 10.894H141Zm0-171.761q-16.103 0-27.149-11.266-11.047-11.267-11.047-26.909 0-15.643 11.047-26.549 11.046-10.907 27.149-10.907h679q15.229 0 26.332 11.097 11.103 11.097 11.103 26.59 0 15.762-11.103 26.853-11.103 11.091-26.332 11.091H141Zm0-173.239q-16.103 0-27.149-11.287-11.047-11.288-11.047-26.79 0-15.502 11.047-26.528 11.046-11.026 27.149-11.026h679q15.229 0 26.332 11.151 11.103 11.152 11.103 26.725 0 15.573-11.103 26.664-11.103 11.091-26.332 11.091H141Z"/>
        </svg>
      </div>
<!--      <div-->
<!--        class="orders-button"-->
<!--        :style="{ backgroundColor: btnColor }"-->
      <div
        class="orders-button"
        :style="{ backgroundColor: '#0faeae' }"
        @click="showCartModal"
        v-if="(cart && cart.length && (!order.length || addDishesMode))"
      >
        <span class="badge">{{ cart.length }}</span>
        <span class="price">{{ cartTotalPrice | money($i18n.locale) }}</span>
        <span class="remaining" v-if="addDishesMode">Добавить блюдо</span>
        <span class="remaining" v-else>Сделать заказ</span>
      </div>
<!--      <div-->
<!--        v-if="order.length"-->
<!--        class="orders-button"-->
<!--        @click="showOrderInfoModal"-->
<!--      >-->
<!--        <span class="price">Заказ на кассе</span>-->
<!--        <span class="remaining">30 минут</span>-->
<!--      </div>-->
    </div>
    <div class="float-buttons-wrap left">
      <div
        v-if="Object.keys(showBellList).some(k => !!showBellList[k])"
        class="float-button pointer"
        @click="openMenu"
      >
        <img src="@/assets/images/ring.svg" alt="">
<!--        <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 -960 960 960" width="28">-->
<!--          <path d="M181.957-182.804q-17.196 0-28.174-10.979-10.979-10.978-10.979-28.174 0-15.956 10.979-27.054 10.978-11.098 28.174-11.098h38.695v-298.543q0-88.957 51.5-160.413 51.5-71.457 138.935-91.696v-17.761q0-27.956 20.098-48.554 20.098-20.598 48.815-20.598t48.815 20.598q20.098 20.598 20.098 48.554v17.761Q636.109-791 688.348-719.304q52.239 71.695 52.239 160.652v298.543h38.456q15.957 0 27.674 11.098 11.718 11.098 11.718 27.054 0 17.196-11.718 28.174-11.717 10.979-27.674 10.979H181.957ZM480.239-56.326q-36.478 0-61.478-25.12-25-25.119-25-61.358h172.717q0 36.239-25.119 61.358-25.12 25.12-61.12 25.12ZM125.37-560.652q-16.24 0-27.338-10.978-11.097-10.979-8.858-26.457 6-75 40.239-139.978 34.239-64.978 87.239-112.978 12.239-11.24 28.457-10.24 16.217 1 25.456 14.479 9.239 12.478 6.12 28.956-3.12 16.478-14.598 26.957-39.761 38.239-65.022 86.88-25.261 48.641-31.5 105.402-1.761 16.479-13.239 27.218-11.478 10.739-26.956 10.739Zm710.26 0q-15.717 0-27.195-10.739t-13.239-27.218q-6.239-56.761-31.5-105.402t-65.022-86.88q-12.239-10.479-15.359-26.957-3.119-16.478 7.12-28.956 9-13.479 25.217-14.479 16.218-1 28.457 10.24 53.239 48 86.858 112.978 33.62 64.978 40.62 139.978 1.478 15.478-9.62 26.457-11.097 10.978-26.337 10.978Z"/>-->
<!--        </svg>-->
      </div>
    </div>
    <navigation-menu v-if="!menuLoading" :store-id="storeId" @cart="openCartIfNotEmpty" />
    <van-popup
        class="brand-detail"
        v-model="detailModal"
        position="bottom"
        closeable
        round
        close-icon="close"
        @opened="detailModal = true"
    >
      <brand-detail
        v-if="Object.keys(currentStore).length"
        :current-store="currentStore"
        :store-name="storeName"
        @open-branch="branchAddress = true"
        @open-yandex-modal="openYandexModal"
      />
    </van-popup>
    <van-popup
        class="yandex-modal order-info-popup market-filter-modal"
        v-model="yandexModal"
        position="bottom"
        @opened="yandexModal = true"
        :style="{ maxHeight: '100%' }"
    >
      <div class="yandex-modal-content">
        <h2 class="pl-20 pr-20 mb-10 mt-0">{{$t('trip-forecast')}}</h2>
        <div v-if="Object.keys(tariffData).length" class="catalog-tags pt-0 pb-0 mb-0 mt-0">
          <swiper
              class="stickers-swiper"
              :breakpoints="swiperStickerOptions.breakpoints"
              :space-between="10"
              :loop="false"
          >
            <swiper-slide
                v-for="(tariff, index) in tariffData.options"
                :key="index"
            >
              <div
                  @click="selectTariff(tariff)"
                  class="catalog-tag catalog-tag-yandex mb-10 mt-0"
                  :class="yandexTimeActive === tariff.class_name ? 'active' : ''"
              >
                {{ tariff.class_text }}
              </div>
            </swiper-slide>
            <swiper-slide>
              <div
                  @click="selectTariff('fast')"
                  class="catalog-tag catalog-tag-yandex mb-10 mt-0"
              >{{$t('fast')}}</div>
            </swiper-slide>
          </swiper>
        </div>
        <div v-if="Object.keys(currentTariffData).length" class="div pl-30 pr-30 mb-10">
          <van-skeleton round :row="3" class="mb-20 p-0" :loading="loadingTariff">
            <div class="item-label mb-5 d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18">
                <path fill="grey" d="M201.239-193.174v37.326q0 19.448-13.974 33.061T154.4-109.174q-19.03 0-32.509-13.663-13.478-13.663-13.478-33.25V-463q0-6.299 1.12-12.358 1.119-6.06 3.119-12.077l76.761-231.761q8.239-22.956 28.224-37.413 19.984-14.456 44.841-14.456H370v-41q0-15.636 11.271-26.894 11.27-11.259 27.881-11.259h144.696q15.636 0 26.894 11.259Q592-827.701 592-812.065v41h106.522q23.68 0 43.753 14.456 20.073 14.457 28.551 37.413l76.522 231.761q2 6.017 3.239 12.077 1.239 6.059 1.239 12.358v307.152q0 19.448-13.953 33.061-13.954 13.613-32.815 13.613-18.842 0-33.07-13.955-14.227-13.954-14.227-33.958v-36.087H201.239Zm3.631-345.152h550.5L701.848-699.37H258.152L204.87-538.326Zm80.83 224.652q23.496 0 39.876-16.519 16.381-16.519 16.381-38.975 0-24.122-16.548-40.836-16.547-16.713-38.855-16.713-24.453 0-40.927 16.617-16.475 16.617-16.475 40.141 0 23.524 16.436 39.905 16.436 16.38 40.112 16.38Zm388.985 0q24.213 0 40.808-16.519 16.594-16.519 16.594-38.975 0-24.122-16.556-40.836-16.555-16.713-39.991-16.713-23.736 0-39.997 16.617-16.26 16.617-16.26 40.141 0 23.524 16.427 39.905 16.428 16.38 38.975 16.38Z"/>
              </svg>
              <div class="ml-10">{{$t('market.tariff')}}: {{ currentTariffData.class_text }}</div>
            </div>
            <div class="item-label mb-5 d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 -960 960 960" width="17">
                <path fill="grey" d="M520.478-495.587v-161.261q0-15.186-10.663-25.952-10.662-10.765-26.219-10.765-15.556 0-26.554 10.765-10.999 10.766-10.999 25.952v176.131q0 8.543 2.62 15.461 2.62 6.917 7.859 11.973l139.087 143.087q10.611 12 26.903 12 16.292 0 28.412-12 10.119-10 10.619-26.598.5-16.597-10.739-27.836L520.478-495.587ZM480.309-62.804q-85.188 0-161.857-32.988t-133.165-89.476q-56.496-56.487-89.49-133.144-32.993-76.656-32.993-161.837 0-85.438 33.1-161.463 33.098-76.026 89.66-132.609 56.563-56.583 133.095-89.848 76.533-33.266 161.617-33.266 85.446 0 161.452 33.262 76.005 33.261 132.595 89.85 56.589 56.59 89.85 132.622 33.262 76.032 33.262 161.509 0 85.476-33.266 161.771-33.265 76.294-89.848 132.857-56.583 56.562-132.576 89.66-75.993 33.1-161.436 33.1Z"/>
              </svg>
              <div class="ml-10">{{$t('market.waiting-time')}}: <span>{{(currentTariffData.waiting_time / 60).toFixed(1)}} мин</span></div>
            </div>
            <div v-if="tariffData.distance" class="item-label mb-5 d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 -960 960 960" width="17">
                <path fill="grey" d="M352.29-111.848q-70.232 0-118.087-48.259-47.855-48.259-47.855-117.523v-341.174q-35.239-14.353-57.62-43.848-22.38-29.496-22.38-67.015 0-50.746 34.481-85.116 34.481-34.369 83.643-34.369 50.401 0 84.791 34.619 34.389 34.62 34.389 84.533 0 37.608-22.88 67.282-22.881 29.675-57.12 43.914v341.182q0 36.903 25.534 62.686 25.533 25.784 63.133 25.784 37.927 0 63.728-25.763 25.801-25.762 25.801-62.636V-682.37q0-70.152 47.735-118.467 47.736-48.315 117.968-48.315 70.471 0 118.906 48.315 48.434 48.315 48.434 118.467v340.174q34 15.239 57 44.788 23 29.548 23 66.995 0 49.707-34.709 84.136-34.71 34.429-84.752 34.429-49.303 0-83.693-34.489-34.389-34.489-34.389-84.007 0-37.466 22.38-67.42 22.381-29.953 57.62-44.432V-682.37q0-36.948-26.271-63.213t-63.396-26.265q-36.927 0-62.728 26.265-25.801 26.265-25.801 63.213v404.74q0 69.264-48.315 117.523T352.29-111.848Z"/>
              </svg>
              <div class="ml-10">{{$t('market.distance')}}: <span>{{ (tariffData.distance / 1000).toFixed(1) }} км</span></div>
            </div>
            <div class="item-label mb-5 d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 -960 960 960" width="17">
                <path fill="grey" d="M499.87-82.848q-23.483 23.674-56.013 23.674-32.531 0-55.205-23.674L83.609-387.89q-24.957-24.957-24.837-57.489.12-32.533 24.076-56.729l376.804-375.043q11.747-12.648 26.069-17.541 14.322-4.894 30.04-4.894h306.282q33.674 0 55.609 21.767 21.935 21.768 21.935 55.777V-516q0 14.895-5.098 29.143-5.098 14.248-16.815 26.966L499.87-82.848Zm216.469-578.348q22.226 0 39.128-16.958 16.903-16.959 16.903-40.185 0-22.226-16.959-39.128-16.958-16.903-39.185-16.903-23.226 0-40.128 16.959-16.902 16.958-16.902 39.185 0 23.226 16.958 40.128 16.959 16.902 40.185 16.902Z"/>
              </svg>
              <div class="ml-10">{{$t('market.price')}}<span style="color: red">*</span>: <span>{{currentTariffData.price_text}}</span></div>
            </div>
            <div v-if="tariffData.time_text" class="item-label mb-5 d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 -960 960 960" width="17">
                <path fill="grey" d="M520.478-495.587v-161.261q0-15.186-10.663-25.952-10.662-10.765-26.219-10.765-15.556 0-26.554 10.765-10.999 10.766-10.999 25.952v176.131q0 8.543 2.62 15.461 2.62 6.917 7.859 11.973l139.087 143.087q10.611 12 26.903 12 16.292 0 28.412-12 10.119-10 10.619-26.598.5-16.597-10.739-27.836L520.478-495.587ZM480.309-62.804q-85.188 0-161.857-32.988t-133.165-89.476q-56.496-56.487-89.49-133.144-32.993-76.656-32.993-161.837 0-85.438 33.1-161.463 33.098-76.026 89.66-132.609 56.563-56.583 133.095-89.848 76.533-33.266 161.617-33.266 85.446 0 161.452 33.262 76.005 33.261 132.595 89.85 56.589 56.59 89.85 132.622 33.262 76.032 33.262 161.509 0 85.476-33.266 161.771-33.265 76.294-89.848 132.857-56.583 56.562-132.576 89.66-75.993 33.1-161.436 33.1Z"/>
              </svg>
              <div class="ml-10">{{$t('market.time')}}: <span>{{tariffData.time_text}}</span></div>
            </div>
            <div style="font-size: 12px; color: grey; margin-top: 12px"><span style="color: red">*</span>{{$t('market.notes')}}
              <a href="https://taxi.yandex.ru/">taxi.yandex.ru</a></div>
          </van-skeleton>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <button @click="yandexModal = false" class="btn-yandex btn-yandex--1">{{ $t('market.cancel') }}</button>
        <button @click="yandexOrder" class="btn-yandex btn-yandex--2">{{ $t('market.order') }}</button>
      </div>
    </van-popup>
    <catalog-branch-address
      :modal="branchAddress"
      :coordinators="mapCoords"
      :branches="branches"
      :isDeviceOnMobile="!!userAgent.iosAndroid() && hasNavigatorShare"
      @close="branchAddress = false;"
      @open-yandex-modal="openYandexModal(currentStore.coordinates?.split(','))"
      @open-map-modal="openMapModal"
    />
  </div>
</template>

<script>

import SwipeModal from "@/components/menu/SwipeModal.vue";
import LoadingBlock from "@/components/menu/Loading.vue";
import MenuHeader from "@/components/menu/MenuHeader.vue";
import TastyBlock from "@/components/menu/TastyBlock.vue";
import StoriesBlock from "@/components/menu/StoriesBlock.vue";
import ProductsBlock from "@/components/menu/ProductsBlock.vue";
import NavigationMenu from "@/components/menu/NavigationMenu.vue";
import CatalogBranchAddress from "@/components/menu/CatalogBranchAddress";
import {mapActions, mapGetters} from "vuex";
import AddressLoadingBlock from "@/components/menu/AddressLoading.vue";
import {DeviceUUID} from "device-uuid";
import CartInfo from "@/components/menu/CartInfo.vue";
import BrandDetail from "@/components/menu/BrandDetail";
import {Dialog, Notify, Popup, Skeleton} from "vant";
import {useUserAgent} from "@/helpers/useUserAgent";
import CallModal from "@/components/menu/CallModal.vue";
import apiClient from "@/services/axios";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper-vue2";

export default {
  title: "Заказ за столом",
  name: "MenuPage",
  components: {
    CallModal,
    CatalogBranchAddress,
    AddressLoadingBlock,
    NavigationMenu,
    ProductsBlock,
    StoriesBlock,
    LoadingBlock,
    BrandDetail,
    MenuHeader,
    TastyBlock,
    SwipeModal,
    // OrderInfo,
    CartInfo,
    SwiperSlide,
    Swiper,
    "van-popup": Popup,
    "van-skeleton": Skeleton,
  },
  data() {
    return {
      swiperStickerOptions: {
        breakpoints: {
          0: {
            slidesPerView: 3.5
          },
        }
      },
      payBeforeOrder: false,
      checkLocation: false,
      loadingTariff: false,
      storeId: null,
      deskId: null,
      storeName: "",
      deskName: "",
      currentStore: {},
      mapCoords: [],
      branches: [],
      currentBranchCoords: [],
      currentTariffData: {},
      tariffData: {},
      logo: "/img/nologo.png",
      search: "",
      cartModal: false,
      timeModal: false,
      yandexModal: false,
      yandexTimeActive: "start",
      checkoutModal: false,
      // orderInfoModal: false,
      detailModal: false,
      menuModal: false,
      categoryModal: false,
      showMenu: false,
      showBellList: {
        call_bill: 0,
        call_hookah: 0,
        call_waitress: 0,
      },
      deliveryPrice: 20000,
      productsLoaded: false,
      branchAddress: false,
      stickyEnabled: false,
      loadingBtn: false,
      userAgent: useUserAgent(),
      color: '#0faeae',
      distance: 0,
    };
  },
  created() {
    // this.getCurrentPositionFunc();
    this.deskId = this.$route.params.desk;
    let uuid = new DeviceUUID().get();
    this.setDeviceUuid(uuid);
  },
  mounted() {
    this.getProducts(true);
    this.$store.commit("SET_NAVIGATION_MENU_HOME_ROUTE", this.$route.fullPath)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SET_NAVIGATION_MENU_HOME_ROUTE", from.fullPath)
    next();
  },
  methods: {
    ...mapActions([
      "setOrder",
      "flushOrder",
      "addToCart",
      "removeFromCart",
      "removeFromCartByIndex",
      "cartFlush",
      "makeOrder",
      "editOrder",
      "showMenuLoading",
      "fetchProducts",
      "setCanOrder",
      "setAddDishes",
      "setDeviceUuid",
      "setBtnColor",
      "showAddressLoading",
      "setMenuCoords",
    ]),
    openMenu() {
      if (!this.canOrder) {
        Notify({type: "warning", message: this.$t(`Кажется Вы находитесь не в «${this.storeName}». Вам доступен только просмотр меню.`)});
        return;
      }
      this.menuModal = true;
    },
    degreesToRadians(degrees) {
      return (degrees * Math.PI) / 180;
    },
    distanceInKmBetweenCoordinates(lat1, lon1, lat2, lon2) {
      let earthRadiusKm = 6371;
      let dLat = this.degreesToRadians(lat2 - lat1);
      let dLon = this.degreesToRadians(lon2 - lon1);
      lat1 = this.degreesToRadians(lat1);
      lat2 = this.degreesToRadians(lat2);
      let a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(lat1) *
          Math.cos(lat2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return earthRadiusKm * c;
    },
    async successCallback(position) {
      this.defineMenuCoords = [position.coords.latitude, position.coords.longitude];
      const storeAddress = this.menuCoords;
      if (storeAddress.length) {
        this.distance = this.distanceInKmBetweenCoordinates(
            this.defineMenuCoords[0],
            this.defineMenuCoords[1],
            storeAddress[0],
            storeAddress[1]
        );
        this.showAddressLoading(false);
        this.checkToDistance();
      } else {
        this.setCanOrder(false);
      }
    },
    checkToDistance() {
      this.$nextTick(() => {
        if (this.checkLocation) {
          if (this.distance > 0.05) {
            this.setCanOrder(false);
            Notify({type: "warning", message: this.$t("location-to-far")});
          } else {
            // this.setCanOrder(true);
          }
        }
      })
    },
    errorCallback(error) {
      if (process.env.NODE_ENV !== 'production')
        return;

      this.showAddressLoading(false);
      Dialog.alert({
        title: "Error",
        message: error.message,
        theme: "round-button",
        confirmButtonText: "Close",
      }).then(() => {
        this.setMenuCoords([]);
        this.getProducts(false);
      });
    },
    getCurrentPositionFunc() {
      navigator.geolocation.getCurrentPosition(
          this.successCallback,
          this.errorCallback,
      );
    },
    openMapApp(type) {
      if (this.userAgent.iosAndroid()) {
        switch (type) {
          case "yandex-taxi":
            location.replace("https://taxi.yandex.uz");
            break;
          case "apple-map":
            location.replace("https://taxi.yandex.uz");
            break;
          case "google-map":
            location.replace("https://taxi.yandex.uz");
            break;
        }
        console.log('Opened Iphone or Android device')
      }
    },
    openMapModal() {
      if (this.userAgent.iosAndroid() && this.hasNavigatorShare) {
        const currentLink = window.location.href;
        navigator.share({
          text: this.storeName + ' copied link',
          url: currentLink,
          title: this.storeName
        });
        console.log('Sharing is on Mobile')
      } else {
        console.log('Sharing is on Web')
      }
    },
    openYandexModal(array) {
      this.currentBranchCoords = array;
      apiClient.get('/qr-menu/taxi-info', {
        params: {
          "clid": "ak210712",
          "apikey": "TvKFGxjIwLwPIZjPtdpmdOSHiFcbuVpJhUpFk",
          "rll": `${this.defineMenuCoords?.[1]},${this.defineMenuCoords?.[0]}~${this.currentBranchCoords?.[1]},${this.currentBranchCoords?.[0]}`,
          "class": "start,econom,business,vip",
        }
      }).then(res => {
        this.tariffData = res.data.data;
        const business = this.tariffData.options.find(t => t.class_name === 'business');
        this.selectTariff(business);
        this.yandexModal = true;
      }).catch(e => {
        console.log('Error getting taxi route: ', e)
      })
    },
    yandexOrder() {
      const params = {
        "start-lat": this.menuCoords[0],
        "start-lon": this.menuCoords[1],
        "end-lat": this.currentBranchCoords?.[0],
        "end-lon": this.currentBranchCoords?.[1],
        "level": this.currentTariffData.class_level,
        "ref": "ak210712",
        "appmetrica_tracking_id": "1178268795219780156",
      };
      const searchParams = new URLSearchParams(params);
      const url = `https://3.redirect.appmetrica.yandex.com/route?${searchParams.toString()}`

      if (this.useragent === 'multicard')
        window.flutter_inappwebview.callHandler('browser', { url })
      else
        window.open(url, "_blank")
    },
    selectTariff(value) {
      if (value === 'fast') {
        this.yandexTimeActive = value;
        const findOldest = (list) => {
          const minTime = Math.min(...list.map(obj => obj.waiting_time));
          const resultWithTime = list.filter(obj => obj.waiting_time === minTime);
          const maxPrice = Math.max(...resultWithTime.map(obj => obj.price));
          return resultWithTime.find(obj => obj.price === maxPrice);
        };
        const result = findOldest(this.tariffData.options);
        this.currentTariffData = this.tariffData.options.find(t => t.class_name === result.class_name);
        this.yandexTimeActive = this.currentTariffData.class_name;
      } else {
        this.yandexTimeActive = value.class_name;
        this.currentTariffData = value;
      }
      this.loadingTariff = true;
      setTimeout(() => {
        this.loadingTariff = false;
      }, 100);
    },
    showDetail() {
      this.detailModal = true;
    },
    showDeliveryAddress: function () {
      this.deliveryAddress = true;
    },
    finishOrder(mode = 'create') {
      this.checkoutModal = false;
      this.cartModal = false;
      switch (mode) {
        case 'create':
          this.makeOrder({
            store_id: this.$route.params.store ?? this.storeId,
            items: this.cart,
            price: this.cartTotalPrice,
            deliveryPrice: this.deliveryPrice,
            table: this.$route.params.desk,
            type: "on_table",
          }).then((order) => {
            if (order?.['current_order']) {
              this.setOrder(order['current_order'])
              this.setAddDishes(true)
            } else {
              this.flushOrder()
            }
            if (order.id) {
              Notify({
                type: "success",
                message: "Заказ создан. Перейти к заказу",
                duration: 5000,
                onClick: () => {
                  this.$router.push({
                    name: 'OrderPage',
                    params: {
                      desk: this.$route.params.desk,
                      order: this.$route.params.order ?? null
                    }
                  })
                }
              })
              // this.$router.push({
              //   name: 'MenuPage',
              //   params: {
              //     brand: this.$route.params.brand,
              //     desk: this.$route.params.desk,
              //     order: order.id
              //   }
              // })
            }
          });
          break;
        case 'update':
          this.editOrder({
            store_id: this.$route.params.store ?? this.storeId,
            items: this.cart,
            price: this.cartTotalPrice,
            deliveryPrice: this.deliveryPrice,
            table: this.$route.params.desk,
            type: "on_table",
          }).then((res) => {
            if (res?.['current_order']) {
              this.setOrder(res['current_order'])
              Notify({
                type: "success",
                message: "Заказ обновлен. Перейти к заказу",
                duration: 5000,
                onClick: () => {
                  this.$router.push({
                    name: 'OrderPage',
                    params: {
                      desk: this.$route.params.desk,
                      order: this.$route.params.order ?? null
                    }
                  })
                }
              })
            } else {
              this.flushOrder()
              this.setAddDishes(false)
            }
          }).catch((err) => {
            console.log(err)
          });
          break;
        default:
          return;
      }
    },
    removeItemFromCart(cartItem) {
      this.removeFromCartByIndex(cartItem);
    },
    async getProducts(setCurrentCoords = true) {
      this.fetchProducts({
        brand: this.$route.params.brand,
        desk: this.$route.params.desk,
        coordinates: this.menuCoords,
      }).then((res) => {
        if (res) {
          this.storeName = res.name;
          this.deskName = res.desk;
          this.showMenu = res.menu === 1;
          this.$store.commit("SET_CAN_RESERVE", Boolean(res.can_reserve));
          this.showBellList = {
            call_bill: res.call_bill,
            call_hookah: res.call_hookah,
            call_waitress: res.call_waitress
          };
          this.logo = res.brand_logo;
          this.currentStore = res;
          this.setMenuCoords(res.coordinates.split(','));
          this.setCanOrder(res.order === 1 && this.menuCoords?.length && setCurrentCoords);
          this.setBtnColor(res.button_color);
          this.checkLocation = res?.check_location;
          this.checkToDistance();
          this.mapCoords = res.coordinates?.split(",");
          this.storeId = res.store_id;
          if (res?.current_order) {
            this.setOrder(res.current_order)
          } else {
            this.flushOrder()
          }
          if (res.address && res['branch-address']) {
            this.branches = res['branch-address'];
            this.branches.unshift({
              address: res.address,
              coordinates: res.coordinates,
              telegram: res.telegram,
              phone: res.phone,
              instagram: res.instagram
            });
          }
        }
      });
    },
    createOrder: function () {
      if (this.payBeforeOrder) {
        this.showCheckoutModal();
      } else {
        this.finishOrder('create');
      }
    },
    updateOrder: function () {
      if (this.payBeforeOrder) {
        this.showCheckoutModal();
      } else {
        this.finishOrder('update');
      }
    },
    showCheckoutModal: function () {
      this.checkoutModal = true;
    },
    openCartIfNotEmpty: function (callback) {
      if (this.cart && this.cart.length) {
        this.cartModal = true
        callback(true)
      } else {
        callback(false)
      }
    },
    showCartModal: function () {
      this.cartModal = true;
    },
    closeCartModal: function () {
      this.cartModal = false;
    },
    // showOrderInfoModal: function () {
    //   this.orderInfoModal = true;
    // },
    // closeOrderInfoModal: function () {
    //   this.orderInfoModal = false;
    // },
    // addDishes: function () {
    //   this.setAddDishes(true);
    //   this.orderInfoModal = false;
    // },
    async goToCategory(category) {
      this.activeTab = await category.name;
      this.categoryModal = false;
      const block = document.getElementById(category.id).offsetTop;
      scroll({
        top: block + 110,
        behavior: "smooth"
      });
    },
    searchUpdate(value) {
      this.search = value;
    },
  },
  computed: {
    ...mapGetters([
      "cart",
      "btnColor",
      "order",
      "menuLoading",
      "addressLoading",
      "addDishesMode",
      "products",
      "token",
      "address",
      "canOrder",
      "deviceUuid",
      "menuCoords",
    ]),
    useragent() {
      return this.userAgent.getUserAgent()
    },
    hasNavigatorShare() {
      return navigator.share;
    },
    cartTotalPrice() {
      if (!this.cart || !this.cart.length) return 0;
      let price = 0, price_mod = 0;

      this.cart.forEach((cartItem) => {
        price += cartItem.price * 1;
        if (cartItem.modifiers.length) {
          const calc = cartItem.modifiers.reduce((sum, item) => sum + (item.price * item.quantity), 0);
          price_mod += calc;
        }
      });
      return price + price_mod;
    },
  },
};
</script>

<style scoped lang="scss">
.brand-detail {
  height: 90%;
  max-height: 90%;
  padding: 50px 0 17px !important;
}

.location-modal {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 768px;
  margin: auto;
  left: auto;
  z-index: 99999999999;

  &-btn {
    color: #FFFFFF;
    background: linear-gradient(95.73deg, #29BF9B -14.53%, #0BABAB 51.05%);
    min-width: 68px;
    height: 40px;
    border-radius: 16px;
    padding: 0 12px;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid transparent;
  }

  .btn-light {
    margin-right: 8px;
    transition: all .25s;
    color: #21201F;
    background: #F6F6F6;
    border-color: transparent;
  }
}

</style>
