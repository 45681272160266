<template>
  <div class="home justify-content-center p-30">
    <van-empty image="network" description="Нет подключение к интернету" />
    <van-button @click="refreshApp" type="primary">Обновить</van-button>
  </div>
</template>

<script>
import { Empty, Button } from "vant";
import update from '../mixins/update'

export default {
  title: "Нет подключение к интернету",
  name: "OfflinePage",
  components: {
    "van-empty": Empty,
    "van-button": Button,
  },
  data() {
  },
  mixins: [update],
  mounted: function () {
  },
};
</script>
