<template>
  <div class="tips-options">
    <div class="selectsum mb-20">
      <div class="selectsum-inner" :class="{animation: animation}">
        <div
            @click="withoutTips"
            :style="tips === 0 ? 'border-color:' + gradient : ''"
            :class="tips === 0 ? 'active' : ''"
            class="pointer btn-tip width-unset"
        >
          <div class="d-flex align-items-center justify-content-center">
            <span v-if="paymentOptions?.[0]?.includes('%')" class="percent">0%</span>
            <lottie-vue
                ref="firstLottie"
                :width="21"
                :height="21"
                :loop="true"
                :autoplay="false"
                :clickPlay="true"
                :manualLoop="true"
                path="/emojies/emoji_0.json"
            />
            <span v-if="mode === 'tips'" class="tip-value">{{ $t("no-tips") }}</span>
          </div>
          <span v-if="paymentOptions?.[0]?.includes('%')" class="tip-value">{{ $t("no-tips") }}</span>
        </div>
        <div
            v-for="(price, index) in paymentOptions"
            :key="price"
            @click="chooseSum(price, index+1)"
            :style="isActive(price) ? 'border-color:' + gradient : ''"
            :class="isActive(price) ? 'active' : ''"
            class="pointer btn-tip width-unset"
        >
          <div class="d-flex align-items-center justify-content-center">
            <span v-if="price.includes('%')" class="percent">{{ price }}</span>
            <lottie-vue
                :width="21"
                :height="21"
                :loop="true"
                ref="lottieVue"
                :autoplay="false"
                :clickPlay="true"
                :manualLoop="true"
                :path="`/emojies/emoji_${index+1}.json`"
            />
            <span v-if="mode === 'tips'" class="tip-value">{{ price | money($i18n.locale) }}</span>
          </div>
          <span v-if="price.includes('%')" class="tip-price">{{ calcPrice(price) | money($i18n.locale) }}</span>
        </div>
        <div
            @click="chooseOther"
            :style="otherPayOption ? 'border-color:' + gradient : ''"
            :class="otherPayOption ? 'active' : ''"
            class="pointer btn-tip width-unset"
        >
          <div class="d-flex align-items-center h-100">
            <span v-if="paymentOptions?.[0]?.includes('%')" class="tip-price other-price mr-5">{{$t('other-sum')}}</span>
            <lottie-vue
                ref="lastLottie"
                :width="21"
                :height="21"
                :loop="true"
                :autoplay="false"
                :clickPlay="true"
                :manualLoop="true"
                path="/emojies/emoji_6.json"
            />
            <span v-if="mode === 'tips'" class="tip-value">{{$t('other-sum')}}</span>
          </div>
        </div>
        <div class="btn-tip width-unset" style="border: none;width: 0;padding: 3px !important;" @click.stop="() => false"></div>
      </div>
    </div>
    <currency-input
      v-if="otherPayOption"
      ref="currencyInput"
      class="form-control"
      v-model="tipsModel"
      v-bind="inputOption"
      @focusout="updateCurrencyInput"
      inputmode="numeric"
    />
  </div>
</template>

<script>
import LottieVue from "lottie-vue";
import { CurrencyInput, parse } from "vue-currency-input";
export default {
  name: "TipsOptions",
  props: {
    mode: {
      type: String,
      default: 'precheck'
    },
    tips: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    onlyTips: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    paymentOptions: {
      type: Array,
      default: () => []
    },
    defaultOption: {
      type: Number,
      default: 0
    },
    gradient: {
      type: String,
      default: ""
    },
    paymentEnabled: {
      type: Boolean,
      default: false
    },
  },
  emits: ["set-tips"],
  components: {
    CurrencyInput,
    LottieVue,
  },
  data() {
    return {
      animation: false,
      tipsModel: this.tips,
      otherPayOption: false,
      sumActive: false,
    }
  },
  mounted() {
  },
  methods: {
    chooseSum(sum, index) {
      if (sum.includes("%")) {
        this.tipsModel = this.total * parseInt(sum) / 100
      } else {
        this.tipsModel = sum;
      }
      this.updateTips(this.tipsModel)
      this.notip = false;
      this.activeSum = sum;
      this.sumActive = true;
      this.alertWarning = false;
      this.otherPayOption = false;
      if (sum === 0) {
        this.tipsModel = 0;
        if (!this.total)
          this.notip = true;
        if (this.closed) {
          this.notip = true;
          this.showReviewForm = true;
        }
        this.handleLottie('sum-zero');
      } else {
        this.showReviewForm = false;
        this.handleLottie('sum-exist', index);
      }
    },
    isActive(price) {
      const sum = price.includes('%') ? this.calcPrice(price) : price
      return this.tips === parseInt(sum)
    },
    calcPrice(percent) {
      return (parseInt(percent.slice(0, -1)) * this.total) / 100;
    },
    chooseOther() {
      // this.updateTips(0);
      this.otherPayOption = true;
      this.$nextTick(() => {
        try {
          this.$refs.currencyInput.$el.focus()
        } catch {
          // console.log('error')
        }
      })
      // this.activeSum = null;
      // this.tipsModel = 0;
      // this.calcTipPrice = this.calcPrice("0%");
      this.handleLottie('choose-other');
    },
    withoutTips() {
      this.updateTips(0)
      this.notips = true;
      this.showReviewForm = true;
      // this.notipsSwitch = true;
      this.activeTips = 0;
      this.tipsModel = 0;
    },
    updateCurrencyInput(e) {
      this.updateTips(parse(e.target.value, this.inputOption))
    },
    updateTips(sum) {
      this.$emit("set-tips", sum)
    },
    handleLottie(type, index = null) {
      this.$nextTick(() => {
        try {
          if (this.$refs.firstLottie?.stop) this.$refs.firstLottie.stop();
          if (this.$refs.lastLottie?.stop) this.$refs.lastLottie.stop();
          if (this.$refs.lottieVue.length) this.$refs.lottieVue.map(l => l?.stop?.());
        } catch (error) {
          // console.log(error)
        }
        switch (type) {
          case 'sum-zero':
            this.$refs.firstLottie?.play();
            break;
          case 'sum-exist':
            try {
              this.$refs.lottieVue?.[index - 1].play()
              this.$refs.lottieVue?.[index - 1].$el.play()
            } catch (error) {
              // console.log(error)
            }
            break;
          case 'choose-other':
            this.$refs.lastLottie?.play();
            if (this.$refs.currency_input) {
              this.$refs.currency_input.$el?.focus();
            }
            break;
        }
      });
    },
  },
  computed: {
    inputOption() {
      let suffix = this.$i18n.locale ? this.$t("sum") : "";
      let locale = this.$i18n.locale ? this.$i18n.locale : "ru";
      return {
        locale: locale,
        currency: {
          prefix: "",
          suffix: " " + suffix,
        },
        valueAsInteger: false,
        distractionFree: true,
        precision: 0,
        autoDecimalMode: true,
        valueRange: {min: 0},
        allowNegative: false,
      };
    },
  },
  watch: {
    onlyTips: {
      handler(value) {
        if (value) {
          this.chooseSum(this.paymentOptions[this.defaultOption - 1] ?? '0', this.defaultOption)
        } else {
          this.chooseSum(this.paymentOptions[this.defaultOption - 1] ?? '0', this.defaultOption)
        }
      }
    },
    loading: {
      handler(value) {
        if (!value) {
          this.animation = true;
          this.chooseSum(this.paymentOptions[this.defaultOption - 1] ?? '0', this.defaultOption)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>