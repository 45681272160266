<template>
  <div class="header-marketplace__top">
    <div v-if="false" class="header-marketplace__col">
      <div class="header-marketplace__avatar">
        <img src="/img/girl.png" alt=""/>
      </div>
    </div>
    <div class="header-marketplace__col">
      <search-item
        ref="searchItem"
        :search="search"
        @search-update="searchUpdated"
      />
      <div class="stick-header p-0">
        <div
          class="f-13 dark-grey address text-center"
          style="max-width: 60vw;"
          :class="{ 'has-address': address }"
          @click="showDeliveryAddress"
          v-if="showAddress"
        >
          <div class="f-11 address-title" v-if="address">
            {{ $t("marketplace.address-delivery") }} <i class="arrow-right"></i>
          </div>
          <div class="address-value" v-if="address">
            <marquee-text :text="address"/>
          </div>
          <div v-else class="f-13 address-value text-gradient">
            {{ $t("marketplace.show-address-delivery") }}
            <i class="arrow-right text-gradient"></i>
          </div>
        </div>
      </div>
    </div>
    <div v-if="false" class="header-marketplace__col"></div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import MarqueeText from "@/components/menu/MarqueeText.vue";
import SearchItem from "@/components/marketplace/header/SearchItem.vue";

export default {
  name: "HeaderTop",
  inject: {
    services: {
      from: "services",
      default: () => []
    },
    searchFood: {
      from: "searchFood",
      default: ""
    }
  },
  components: {
    SearchItem,
    MarqueeText
  },
  props: {
    storeName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showAddress: true,
      search: this.searchFood,
    }
  },
  methods: {
    showDeliveryAddress() {
      this.$emit("delivery-modal");
    },
    searchUpdated(value) {
      this.$emit('search-update', value);
    }
  },
  computed: {
    ...mapGetters(["address", "coordinates", "canOrder"]),
  },
};
</script>

<style scoped lang="scss">
.address {
  -webkit-text-fill-color: inherit;
  background-clip: inherit;
  background: none;
  color: #22211F;
  max-width: 60vw;
  line-height: 38px;

  i {
    display: inline-block;
  }

  &.has-address {
    line-height: initial;
  }

  .address-value {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #7C7C7C;
    display: inline-block;
    max-width: 100%;

    &.text-gradient {
      background: linear-gradient(133.1deg, #29BF9B 24.25%, #0FAEAE 62.73%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      i {
        background: url("@/assets/svg/arrow-right-gradient.svg");
        width: 8px;
        height: 8px;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    i {
      background: url("@/assets/svg/arrow-right.svg");
    }
  }

  .address-title {
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    margin-bottom: 5px;
    visibility: visible;

    i {
      height: 6px;
      width: 6px;
      background: url("@/assets/svg/arrow-right.svg");
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0.7;
      margin-left: 4px;
    }
  }
}

</style>
