<template>
  <div class="favorite" :style="{'--menu-item-color': btnColor}">
    <h2 class="mt-0 favorite-title">{{ $t('marketplace.favorites') }}</h2>
    <div v-if="$store.getters.favorites.length" class="restaurant__cards mt-15">
      <template v-for="store in $store.getters.favorites">
        <div
          :key="store.id"
          @click.prevent="goToDetail(store)"
          class="restaurant__card"
        >
          <div class="restaurant__card-top">
            <div v-if="store.cashback" class="restaurant__cashback">{{store.cashback}}%</div>
            <div v-if="store.background_url">
              <img
                class="restaurant__card--image"
                :src="`${store.background_url }`"
                alt=""
              />
            </div>
            <div v-else class="restaurant__card--image--placeholder"></div>
            <div @click.stop="removeFavorite(store)"
                 class="restaurant__card--favorite search-btn circle-btn d-flex align-items-center justify-content-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                <path d="M480.761-425.891 274.913-220.283q-11.478 11.479-27.435 11.598-15.956.12-27.195-12.358-11.718-11.479-11.337-26.555.38-15.076 12.097-27.554L426.13-480 220.283-686.609q-11.718-11.478-11.718-26.554 0-15.076 11.718-27.554 11.239-11.479 27.195-11.359 15.957.119 27.435 11.598L480.761-534.87l204.326-205.608q11.478-11.479 27.435-11.598 15.956-.12 27.195 11.359 11.718 12.478 11.337 27.554-.38 15.076-11.097 26.554L534.87-480l205.087 204.848q10.717 11.717 11.097 27.174.381 15.456-11.337 26.935-11.239 12.478-27.195 12.358-15.957-.119-27.435-11.598L480.761-425.891Z"/>
              </svg>
            </div>
            <div v-if="false" class="restaurant__card--time">
              <img src="/img/marketplace/rocket.svg" alt=""/>
              <span>45 {{$t('min')}}</span>
            </div>
          </div>
          <div class="d-flex align-items-baseline justify-content-between pr-10 pl-10">
            <h2 class="restaurant__card--title">{{ store.name }}</h2>
            <span class="restaurant__card--mileage">{{ (+store.distance).toFixed(1) }} {{$t('km')}}</span>
          </div>
          <div v-if="false" class="restaurant__card--actions">
            <img src="/img/marketplace/fire.svg" alt=""/>
            <span class="rate">{{ store.rating }}</span>
            <p>Рекомендуем</p>
            <div>PPP</div>
          </div>
        </div>
      </template>
    </div>
    <div v-else class="text-center d-flex flex-column align-items-center justify-content-center"
         style="height: 80vh">
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <path d="M46.6665 64C46.3865 64 46.1065 63.912 45.8692 63.736C45.1918 63.232 29.3331 51.2827 29.3331 41.68C29.3331 36.3413 33.6771 32 39.0158 32C42.0585 32 44.8638 33.416 46.6665 35.744C48.4718 33.416 51.2771 32 54.3171 32C59.6558 32 63.9998 36.3413 63.9998 41.68C63.9998 51.2827 48.1411 63.232 47.4638 63.736C47.2265 63.912 46.9465 64 46.6665 64ZM39.0158 34.6667C35.1491 34.6667 31.9998 37.8133 31.9998 41.68C31.9998 48.152 41.9305 57.2747 46.6665 60.9867C51.4025 57.2747 61.3332 48.152 61.3332 41.68C61.3332 37.8133 58.1838 34.6667 54.3171 34.6667C51.5225 34.6667 48.9998 36.3173 47.8905 38.8747C47.6771 39.3627 47.1971 39.6773 46.6665 39.6773C46.1358 39.6773 45.6532 39.3627 45.4425 38.8747C44.3358 36.3173 41.8131 34.6667 39.0158 34.6667Z" :fill="btnColor"/>
          <path d="M22.6667 48H4C1.79467 48 0 46.2053 0 44C0 43.7707 0.024 43.568 0.056 43.344L8.07733 3.20533C8.44533 1.34933 10.0933 0 12 0H41.3333C43.24 0 44.888 1.34933 45.256 3.208L49.8933 26.4053C50.0373 27.128 49.5707 27.8293 48.8453 27.9733C48.1547 28.12 47.424 27.6507 47.2773 26.928L42.64 3.728C42.5173 3.112 41.968 2.66667 41.3333 2.66667H12C11.3653 2.66667 10.816 3.112 10.6933 3.72267L2.69333 43.7253C2.68267 43.8133 2.66667 43.9013 2.66667 44C2.66667 44.736 3.26667 45.3333 4 45.3333H22.6667C23.4027 45.3333 24 45.9307 24 46.6667C24 47.4027 23.4027 48 22.6667 48Z" fill="#BABAC0"/>
          <path d="M59.2507 28C58.6293 28 58.0747 27.5627 57.9467 26.928L53.3093 3.728C53.184 3.112 52.6347 2.66667 52 2.66667H41.3333C40.5973 2.66667 40 2.06933 40 1.33333C40 0.597333 40.5973 0 41.3333 0H52C53.9067 0 55.5547 1.34933 55.9227 3.208L60.56 26.4053C60.704 27.128 60.2373 27.8293 59.512 27.9733C59.4267 27.992 59.3387 28 59.2507 28Z" fill="#BABAC0"/>
          <path d="M26.6666 23.9999C20.784 23.9999 16 19.2159 16 13.3333V6.66659C16 5.93059 16.5973 5.33325 17.3333 5.33325C18.0693 5.33325 18.6667 5.93059 18.6667 6.66659V13.3333C18.6667 17.7439 22.256 21.3333 26.6666 21.3333C31.0773 21.3333 34.6666 17.7439 34.6666 13.3333V6.66659C34.6666 5.93059 35.264 5.33325 36 5.33325C36.736 5.33325 37.3333 5.93059 37.3333 6.66659V13.3333C37.3333 19.2159 32.5493 23.9999 26.6666 23.9999Z" fill="#BABAC0"/>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="64" height="64" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <p :style="{fontSize: '18px', marginBottom: '35px'}">{{ $t('marketplace.favorites-is-empty') }}</p>
      <router-link to="/" class="favorite-btn">{{ $t('marketplace.go-to-home') }}</router-link>
    </div>

    <navigation-market />
  </div>
</template>

<script>
import NavigationMarket from "@/components/menu/NavigationMarket.vue";
import {mapGetters} from "vuex";

export default {
  name: "FavoritePage",
  components: {
    NavigationMarket,
  },
  methods: {
    goToDetail(store) {
      this.$router.push({name: "CatalogPage", params: {brand: store.brand_id, store: store.id}});
    },
    removeFavorite(store) {
      this.$store.commit('REMOVE_FAVORITE', store.id);
    }
  },
  computed: {
    ...mapGetters(["btnColor"]),
  }
};
</script>

<style scoped lang="scss">
.favorite {
  padding: 16px 16px 20px;
}

.favorite-title {
  position: relative;
  font-weight: 600;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -15px;
    width: 100%;
    height: 1px;
    background-color: #ececec;
  }
}

</style>
