<template>
  <div>
    <div v-if="useragent === 'browser'">
      <p class="label-total mb-10 mt-20">{{$t('payment-methods')}}</p>
      <div class="card-payment mb-35">
        <payment-card
          :cards="cards"
          :current-card="card"
          :disable-payment="!Boolean(total)"
          @selectedCard="selectedCard"
          @add-new-card="addCardModal = true"
        />
      </div>
      <transition appear name="slidePaymentCardBtn">
        <div v-if="cards.length" class="text-center mb-20">
          <van-button
            :disabled="+total <= 0 || cards.length === 0 || loadingCard === true"
            :loading="loadingCard === true"
            @click="payCard"
            style="width: 150px"
            :color="getColor"
          >{{ $t("pay")}}</van-button>
        </div>
      </transition>
      <hr>
      <br>
      <!-- todo Payment types-->
      <div class="payment-list">
        <div v-for="system in paymentSystems" :key="system.name">
          <payment-system
            :paymentSystem="system"
            :forbidden="+total <= 0"
            :loading-card="loadingCard"
            :getColor="getColor"
            @pay="paySystem"
          />
        </div>
      </div>
    </div>
    <div v-if="useragent === 'multicard'">
      <transition appear name="slidePaymentCardBtn">
        <div class="text-center mb-20">
          <van-button
            :disabled="+total < 0"
            :loading="loadingCard === true"
            @click="paySystem('multicard')"
            style="width: 150px"
            :color="color"
            type="primary"
          >{{ $t("pay") }}</van-button>
        </div>
      </transition>
    </div>
    <van-popup
      position="bottom"
      v-model="addCardModal"
      class="web"
      :style="{ height: 'auto' }"
      closeable
    >
      <AddCard
        mode="fastpay"
        :total="amount"
        :color="color"
        :service-commission-price="serviceCommissionPrice"
        @otp="sendOtp"
        @closeModal="success"
        @transfer="transfer"
      />
    </van-popup>
    <van-popup
      position="bottom"
      v-model="payModal"
      class="web"
      :style="{ height: 'auto' }"
      closeable
    >
      <PayCard
        mode="fastpay"
        :cardId="cardId"
        :total="amount"
        :color="color"
        :service-commission-price="serviceCommissionPrice"
        @closeModal="success"
        @transfer="transfer"
      />
    </van-popup>
    <van-popup
      position="bottom"
      v-model="confirmModal"
      class="web"
      :style="{height: 'auto', zIndex: '2005'}"
      closable
      @closed="confirmModal = false"
    >
      <ConfirmCard
        :total="amount"
        :loading="loadingCard"
        :color="color"
        :service-commission-price="serviceCommissionPrice"
        @confirm="paymentInstant"
      />
    </van-popup>
    <div class="text-center pb-20" v-if="useragent === 'oson'">
      <van-button
        :loading="loadingCard === 'oson'"
        :disabled="loadingCard"
        @click="paySystem('oson')"
        style="width: 200px"
        :color="color"
        type="primary"
      >{{ $t("pay") }}</van-button>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import {Button, Notify, Popup} from "vant";
import Service from "@/services/api/service";
import AddCard from "@/components/AddCard.vue";
import PayCard from "@/components/PayCard.vue";
import {useUserAgent} from "@/helpers/useUserAgent";
import PaymentCard from "@/components/PaymentCard.vue";
import ConfirmCard from "@/components/ConfirmCard.vue";
import PaymentSystem from "@/components/PaymentSystem.vue";

export default {
  name: "PaymentBlock",
  props: {
    amount: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: null
    },
    serviceCommissionPrice: {
      type: Number,
      default: 0
    },
    paymentSystems: {
      type: Array,
      default: () => []
    }
  },
  components: {
    "van-button": Button,
    "van-popup": Popup,
    PaymentSystem,
    PaymentCard,
    ConfirmCard,
    AddCard,
    PayCard,
  },
  data() {
    return {
      loadingCard: false,
      addCardModal: false,
      payModal: false,
      confirmModal: false,
      card: this.$store.state.cards[0],
      cards: this.$store.state.cards,
      cardId: 0,
      paymentType: "cards",
    }
  },
  methods: {
    sendOtp(card, callback) {
      Service.fastPayWithoutOrder(this.$route.params.storeId, {
        amount: this.amount * 100,
        payment_system: "multicard",
        card
      }).then((data) => {
        callback(data)
      }).catch(() => {
        callback(null)
      });
    },
    paySystem(system) {
      this.loadingCard = system;
      this.payOtp({
        amount: this.amount * 100,
        payment_system: system
      }).then((data) => {
        const systems = ["payme", "payze", "oson", "uzum", "click", "humans", "alif", "anorbank", "multicard", "xazna"];
        if (systems.includes(system)) {
          if (system === "multicard") {
            this.multicardPay(data)
            return;
          }
          if (system === "humans") {
            if (!data?.data?.data?.id || !data?.data?.data?.amount)
              return;

            // this.humansRedirect(data)
            return;
          }
          if (data?.data?.data?.redirect) {
            window.location.href = data.data.data.redirect;
          } else {
            if (this.order['paid']) {
              Notify({
                type: "warning",
                message: this.$t("cheque-paid-already"),
              });
            } else {
              Notify({
                type: "warning",
                message: this.$t("payment-error"),
              });
            }
          }
        }
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        this.loadingCard = false;
      })
    },
    payCard() {
      this.loadingCard = true;
      if (this.$store.getters.token) {
        this.loadingCard = false;
        this.confirmModal = true;
      } else {
        this.pay({
          amount: this.amount * 100,
          payment_system: 'multicard',
          card: {
            token: this.card.token,
          }
        })
      }
    },
    pay(params) {
      this.payOtp(params).then((data) => {
        this.cardId = data.data.data.id;
        this.payModal = true;
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        this.loadingCard = false;
      });
    },
    payOtp(params) {
      return Service.fastPayWithoutOrder(this.$route.params.storeId, params);
    },
    paymentInstant() {
      this.loadingCard = true
      if (!this.$store.getters.token) {
        this.tryOtpPayment()
        this.loadingCard = false;
        return;
      }
      this.payInstant({
        amount: this.amount * 100,
        payment_system: 'multicard',
        card: this.card,
        token: this.$store.getters.token
      }).then((data) => {
        if (data?.data?.code === "ERROR_CARD_TOKEN") {
          this.tryOtpPayment()
        } else if (data?.data?.data?.transfer) {
          this.transfer(data.data.data.transfer);
          this.$emit("success", true);
          this.loadingCard = false;
        }
      }).catch(() => {}).finally(() => {
        this.loadingCard = false;
      });
    },
    tryOtpPayment() {
      this.loadingCard = true;
      this.confirmModal = false;
      this.pay({
        amount: this.amount * 100,
        payment_system: 'multicard',
        card: this.card
      })
    },
    payInstant(params) {
      return Service.fastPayInstant(this.$route.params.storeId, params)
    },
    success() {
      this.addCardModal = false;
      this.$emit("success", true);
    },
    transfer(data) {
      this.$emit("transfer", data);
    },
    selectedCard(card) {
      this.card = this.cards[card];
    },
    multicardPay(data) {
      if (data?.data?.data?.redirect) {
        if (this.useragent === 'multicard') {
          const redirectUrl = URL.parse(data.data.data.redirect)
          const link = URL.parse(redirectUrl.searchParams.get('link'))
          const params = JSON.parse(link.searchParams.get('params'))
          const invoiceUuid = params.details['invoice_uuid']
          const routeParams = {
            fixed_amount: parseInt(data?.data?.data?.amount),
            details: {
              invoice_id: data?.data?.data?.payment_id,
              invoice_uuid: invoiceUuid
            }
          }
          window.flutter_inappwebview.callHandler('deeplink', {
            route: "/payments/checkout/96?params=" + JSON.stringify(routeParams)
          })
        } else {
          window.location.href = data.data.data.redirect
        }
      }
    },
    /*
    humansRedirect(data) {
      const id = data?.data?.data?.id
      const paymentAmount = parseInt(data?.data?.data?.amount) / 100
      let form = document.createElement('form')
      form.method = "POST"
      form.action = "https://capi.upay.uz/humansPay.do"
      document.getElementById('app').append(form)
      let service = document.createElement('input')
      let amount = document.createElement('input')
      let account = document.createElement('input')
      let redirect = document.createElement('input')
      let trx = document.createElement('input')
      let lang = document.createElement('input')
      let api = document.createElement('input')
      service.type = "hidden"
      service.name = "serviceId"
      service.value = "1304"
      amount.type = "hidden"
      amount.name = "amount"
      amount.value = paymentAmount.toString()
      account.type = "hidden"
      account.name = "personalAccount"
      account.value = id
      redirect.type = "hidden"
      redirect.name = "redirectUrl"
      redirect.value = `https://app.rhmt.uz/result?id=${id}&amount=${paymentAmount}`
      trx.type = "hidden"
      trx.name = "partnerTrxId"
      trx.value = "rahmat_00001"
      lang.type = "hidden"
      lang.name = "lang"
      lang.value = "ru"
      api.type = "hidden"
      api.name = "apiVersion"
      api.value = 1
      form.append(service)
      form.append(amount)
      form.append(account)
      form.append(redirect)
      form.append(trx)
      form.append(lang)
      form.append(api)
      form.submit()
    },
    */
  },
  computed: {
    ...mapState(['store', 'employee']),
    getColor() {
      let store = Object.keys(this.employee).length ? this.employee.store : this.store;
      return store?.['button_color'] ?? "#1fb7a4";
    },
    useragent() {
      return useUserAgent().getUserAgent()
    },
  },
  watch: {
    addCardModal: {
      handler(value) {
        window.showNavigationMenu = !value
      }
    },
    payModal: {
      handler(value) {
        window.showNavigationMenu = !value
      }
    },
    confirmModal: {
      handler(value) {
        window.showNavigationMenu = !value
      }
    },
  }
}
</script>
