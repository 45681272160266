<template>
  <div :style="{'--menu-item-color': btnColor}">
    <div
        v-if="tags.length"
        ref="observer"
        class="catalog-tags mb-10"
    >
      <a
          v-for="(tag, key) in tags"
          :key="key"
          @click="onClickTag(tag)"
          class="catalog-tag"
      >
        {{ tag.name ? tag.name : tag.title }}
      </a>
    </div>
    <div v-if="currentStore.description" class="catalog-desc mb-5">
      <span
          ref="descContent"
          class="catalog-desc-content"
      > {{ currentStore.description }}
      </span>
      <a v-if="!showMoreDesc" class="show-more" @click.prevent="showMore" href="#">{{ $t("catalog.show-more") }}</a>
    </div>
    <div class="catalog-work mt-10">
      <div class="d-flex">
        <div>
          <div v-if="currentStore.cashback" @click="handleNotifyModal('cashback')" class="d-flex align-items-center mb-5">
            <svg :fill="btnColor" xmlns="http://www.w3.org/2000/svg" height="21" viewBox="0 -960 960 960" width="21">
              <path d="M300-520q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm0-80q25 0 42.5-17.5T360-660q0-25-17.5-42.5T300-720q-25 0-42.5 17.5T240-660q0 25 17.5 42.5T300-600Zm360 440q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm0-80q25 0 42.5-17.5T720-300q0-25-17.5-42.5T660-360q-25 0-42.5 17.5T600-300q0 25 17.5 42.5T660-240Zm-472 52q-11-11-11-28t11-28l528-528q11-11 28-11t28 11q11 11 11 28t-11 28L244-188q-11 11-28 11t-28-11Z"/>
            </svg>
            <span class="ml-5 font-semibold">Кешбэк</span>
            <svg fill="#303030" xmlns="http://www.w3.org/2000/svg" height="19" viewBox="0 -960 960 960" width="19">
              <path d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm2 160q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Zm4-172q25 0 43.5 16t18.5 40q0 22-13.5 39T502-525q-23 20-40.5 44T444-427q0 14 10.5 23.5T479-394q15 0 25.5-10t13.5-25q4-21 18-37.5t30-31.5q23-22 39.5-48t16.5-58q0-51-41.5-83.5T484-720q-38 0-72.5 16T359-655q-7 12-4.5 25.5T368-609q14 8 29 5t25-17q11-15 27.5-23t34.5-8Z"/>
            </svg>
          </div>
          <div v-if="currentStore.tips_enabled" @click="handleNotifyModal('tip')" class="d-flex align-items-center mb-5">
            <svg :fill="btnColor" xmlns="http://www.w3.org/2000/svg" height="21" viewBox="0 -960 960 960" width="21">
              <path d="M200-200v-560 560Zm0 80q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v100h-80v-100H200v560h560v-100h80v100q0 33-23.5 56.5T760-120H200Zm320-160q-33 0-56.5-23.5T440-360v-240q0-33 23.5-56.5T520-680h280q33 0 56.5 23.5T880-600v240q0 33-23.5 56.5T800-280H520Zm280-80v-240H520v240h280Zm-160-60q25 0 42.5-17.5T700-480q0-25-17.5-42.5T640-540q-25 0-42.5 17.5T580-480q0 25 17.5 42.5T640-420Z"/>
            </svg>
            <span class="ml-5 font-semibold">Чаевые с карты</span>
            <svg fill="#303030" xmlns="http://www.w3.org/2000/svg" height="19" viewBox="0 -960 960 960" width="19">
              <path d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm2 160q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Zm4-172q25 0 43.5 16t18.5 40q0 22-13.5 39T502-525q-23 20-40.5 44T444-427q0 14 10.5 23.5T479-394q15 0 25.5-10t13.5-25q4-21 18-37.5t30-31.5q23-22 39.5-48t16.5-58q0-51-41.5-83.5T484-720q-38 0-72.5 16T359-655q-7 12-4.5 25.5T368-609q14 8 29 5t25-17q11-15 27.5-23t34.5-8Z"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div @click="onExpandMore" class="d-flex align-items-center" v-if="todayOpenTime && todayCloseTime">
          <svg xmlns="http://www.w3.org/2000/svg" :fill="btnColor" height="21" viewBox="0 -960 960 960" width="21">
            <path d="M180.109-63.326q-30.746 0-54.025-23.447-23.28-23.447-23.28-53.857v-620.022q0-30.844 23.28-54.194 23.279-23.35 54.025-23.35H231.5v-58.717h78.239v58.717h340.522v-58.717H728.5v58.717h51.391q30.845 0 54.194 23.35 23.35 23.35 23.35 54.194v297.87q-18.126-9.243-37.618-14.698-19.491-5.455-39.926-7.356v-92.294H180.109v436.5h323.617q7.187 21.434 18.144 41.01 10.956 19.577 26.391 36.294H180.109Zm566.208 33.26q-83.611 0-141.268-57.785-57.658-57.787-57.658-141.158 0-83.372 57.667-141.149 57.666-57.777 141.277-57.777 83.372 0 141.149 57.786 57.777 57.787 57.777 141.158 0 83.372-57.786 141.149-57.787 57.777-141.158 57.777Zm58.492-94.977 30.713-31.435-76-75.048v-121.322h-43.913v137.019l89.2 90.786Z"/>
          </svg>
          <span
              v-if="!isAroundClock && !isTheSameTime"
              class="ml-5 font-semibold"
          >{{$t('today-from')}} {{ todayOpenTime }} {{ $t('to') }} {{ todayCloseTime }}</span>
          <span v-else-if="!isAroundClock && isTheSameTime" class="ml-5 font-semibold">{{$t('daily-from')}} {{
              currentTodayTime?.open
            }} {{ $t('to') }} {{ currentTodayTime?.close }}</span>
          <span v-else class="ml-5 font-semibold">{{ $t('round-the-clock') }}</span>
          <svg v-if="!isAroundClock && !isTheSameTime" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M480-348.326q-8.239 0-14.957-2.859-6.717-2.858-11.717-9.098L254.804-558.804q-12.478-11.239-12.098-26.816.381-15.576 11.859-27.054 11.478-11.478 27.555-11.478 16.076 0 28.315 11.478L480-442.348l170.326-170.326q11.478-11.478 26.935-11.478 15.456 0 27.935 11.478 11.478 12.239 11.478 28.315t-11.478 27.555L507.674-360.283q-6 6.24-12.717 9.098-6.718 2.859-14.957 2.859Z"/>
          </svg>
        </div>
      </div>
      <div v-if="expandMore && !isAroundClock" class="working-dates">
        <div v-for="(day, index) in weekdaysPriority" :key="index"
             :class="[(new Date()).getDay() === index + 1 ? 'active' : '']"
             class="d-flex justify-content-between date-item">
          <div class="date-label">{{ weekName(index + 1) }}</div>
          <span>{{ day?.open }}-{{ day?.close }}</span>
        </div>
      </div>
    </div>

    <div v-if="currentStore['coordinates']?.length && currentStore['branch-address'].length" class="catalog-address">
      <div class="address-content mt-10">
        <template v-for="(branch, key) in currentStore['branch-address']">
          <div v-if="key < 2 && branch.address" class="address-content-text w-100" :key="key">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-start">
                <svg xmlns="http://www.w3.org/2000/svg" style="min-width: 21px" :fill="btnColor" height="21" viewBox="0 -960 960 960" width="21">
                  <path d="M479.693-86.043q-11.693 0-24.334-4t-22.598-13q-46.478-39.522-97.601-90.748-51.123-51.227-93.739-109.5-42.617-58.274-70.617-121.69-28-63.416-28-129.258 0-151.564 100.398-247.88Q343.599-898.435 480-898.435t236.918 96.316q100.517 96.316 100.517 247.88 0 65.842-28.12 129.258-28.119 63.416-70.736 121.69-42.616 58.273-93.739 109.5-51.123 51.226-96.362 90.748-11.228 9-24.16 13t-24.625 4Zm.44-398.718q31.106 0 53.226-22.133 22.119-22.132 22.119-53.239 0-31.106-22.252-53.226-22.252-22.119-53.359-22.119-31.106 0-53.106 22.252t-22 53.359q0 31.106 22.133 53.106 22.132 22 53.239 22Z"/>
                </svg>
                <span class="d-block ml-5 font-semibold">{{ branch.address }}</span>
              </div>
              <span v-if="branch.distance" class="restaurant__card--mileage">{{branch.distance}} {{$t('km')}}</span>
            </div>
            <div class="address-actions d-flex justify-content-end align-items-center">
              <a v-if="branch.phone" @click.prevent="openWindow('tel:+' + branch.phone)" href="#" class="item item-phone">
                <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22">
                  <path fill="#adadad" d="M806.696-102.804q-123.283 0-249.066-58-125.782-58-232.065-164.283Q219.043-430.609 160.924-557.13q-58.12-126.522-58.12-249.326 0-22.153 14.294-36.566 14.293-14.413 36.446-14.413h153.26q18.066 0 30.837 11.555 12.772 11.554 17.25 30.38l28.522 132.63q1.239 16.109-1.978 30.261-3.217 14.153-11.37 21.827l-103.521 103.13q23.043 40.565 51.445 76.869 28.402 36.305 62.446 69.109 35.282 37.522 73.467 66.446t78.511 50.967l102.043-102.804q10.674-11.913 24.185-16.131 13.511-4.217 27.859-1.261l128.478 28.848q18.587 5.196 30.522 19.109 11.935 13.913 11.935 32.5v150.456q0 22.153-14.413 36.446-14.413 14.294-36.326 14.294Z"/>
                </svg>
              </a>
              <div v-if="branch.telegram" @click="openWindow('https://t.me/' + branch.telegram)" class="item item-telegram">
                <svg width="22" height="22" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M35.344 39.6566L41.3898 11.2638C41.5179 10.6319 40.897 10.1085 40.2957 10.3417L6.91785 23.0657C6.24543 23.3269 6.23612 24.3717 6.89211 24.6706L15.4425 28.1773L18.6266 38.2198C18.8161 38.7608 19.4901 38.942 19.9255 38.5697L24.8158 34.0188L34.1131 40.185C34.5955 40.4873 35.2307 40.2141 35.344 39.6566ZM20.1971 30.0791L19.6539 35.2416L17.0727 27.2941L35.2762 15.3395L20.1971 30.0791Z" fill="#adadad"/>
                </svg>
              </div>
              <div v-if="branch.instagram" @click="openWindow('https://instagram.com/' + branch.instagram)" class="item item-instagram">
                <img src="/catalog/icons/logo-instagram.svg" alt=""/>
              </div>
              <div  @click="$emit('open-yandex-modal', branch.coordinates?.split(','))" class="item item-yandex">
                <img src="/yandex-taxi.jpeg" alt=""/>
              </div>
            </div>
          </div>
        </template>
        <a
            v-if="currentStore['branch-address'].length > 2"
            @click.prevent="openAddressModal"
            class="all-address"
        >{{$t('all-address')}}</a>
      </div>
    </div>
    <div v-if="currentStore.coordinates?.length" @click="openAddressModal" class="catalog-branch-address mt-30">
      <yandex-map
          :key="yMapKey"
          :settings="mapSettings"
          :coords="currentStore.coordinates?.split(',')"
          :controls="[]"
          :zoom="15"
          style="height: 150px"
      >
        <ymap-marker
            :marker-id="markerId"
            :coords="currentStore.coordinates?.split(',')"
            :marker-type="'placemark'"
            :icon="{ content: iconAddress }"
        />
      </yandex-map>
    </div>
    <div v-if="false" class="catalog-photos">
      <h3 class="mb-10 pb-5">{{$t('photographs')}}</h3>
      <div v-if="storyImages.length" class="photos-row d-flex align-items-center flex-wrap">
        <div v-for="(story, index) in storyImages" :key="index" class="photos-col">
          <van-image
              v-if="index + 1 <= maxlengthImages"
              :width="'100%'"
              fit="cover"
              radius="20"
              :src="story.images[0]?.url"
              class="catalog-photo"
              lazy-load
              @click="onMovePhotoStory($event, index)"
          >
            <template v-slot:default>
              <div
                  v-if="!isClickedMorePhoto && index + 1 === maxlengthImages"
                  @click="loadMorePhotos($event)"
                  :class="'catalog-photo-more'"
              >
                <p>yana</p>
                <p>+{{ storyImages.length - maxlengthImages }}</p>
              </div>
            </template>
          </van-image>
        </div>
      </div>
    </div>
    <van-popup
        v-model="notifyModal"
        position="center"
        style="padding: 16px; border-radius: 16px; max-width: 768px; width: 90%;"
        @close="notifyModal = false"
    >
      <div class="text-center d-flex flex-column align-items-center">
        <svg class="mb-5" fill="#0faeae" xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 -960 960 960" width="50">
          <path d="M480-280q17 0 28.5-11.5T520-320v-160q0-17-11.5-28.5T480-520q-17 0-28.5 11.5T440-480v160q0 17 11.5 28.5T480-280Zm0-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
        </svg>
        <p v-if="notifyModalName === 'tip'" style="color: #303030" class="mb-10 mt-5">
          В заведении имеется возможность оставить официанту чаевые с банковской карты через сервис Rahmat.
          Для этого отсканируйте QR-код в счете и укажите сумму чаевых. Официант будет мгновенно уведомлен, отправленные средства он получит на свою банковскую карту.
        </p>
        <div v-else>
          <div style="color: #303030">
            Оплачивайте счет через QR в счете с карты Multicard Black и получайте 1% кешбэк.
          </div>
          <div style="color: #303030" class="mt-5 mb-5"><b>Условия:</b></div>
          <ul style="color: #0faeae; text-align: left" >
           <li class="mb-5"><span style="">1.</span> Закажите карту Multicard Black в приложении;</li>
           <li class="mb-5"><span style="">2.</span> Когда вам принесут счет в заведении, отсканируйте QR с помощью камеры смартфона или в приложении Multicard.</li>
           <li class="mb-5"><span style="">3.</span> На открывшейся странице выберите карту Multicard Black и оплатите с помощью нее.</li>
          </ul>
          <p style="color: #303030">
            Сразу после оплаты счета вам будет начислен кешбэк, который доступен для вывода на главной странице приложения Multicard.
          </p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {Image, Popup} from "vant";
import {DeviceUUID} from "device-uuid";
import {translate} from "@/utils/animate"
import {mapActions, mapGetters} from "vuex";
import {useUserAgent} from "@/helpers/useUserAgent";
import {yandexMap, ymapMarker} from "vue-yandex-maps";

export default {
  name: "BrandDetail",
  props: {
    currentStore: {
      type: Object,
      default: () => {
      },
    },
    storeName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      yandexTags: [
        {type: "Start", label: 50},
        {type: "Standard", label: 60},
        {type: "Comfort", label: 70},
        {type: "Business", label: 80},
      ],
      mapSettings: {
        apiKey: "e9fe7cab-eb17-4480-976c-a9bd1756a5c1",
        lang: this.$i18n.locale,
        coordorder: "latlong",
        enterprise: true,
        version: "2.1",
      },
      center: [41.31115, 69.279737],
      coords: [41.31115, 69.279737],
      iconAddress: "",
      markerId: 0,
      yMapKey: 0,
      storyModal: false,
      tariff: 0,
      yandexTimeActive: 0,
      yandexGoData: {},
      yandexGoModal: false,
      mapModal: false,
      isClickedMorePhoto: false,
      maxlengthImages: 6,
      stories: [],
      tags: [],
      weekdays: [],
      weekdaysPriority: [],
      distances: [],
      currentTodayTime: {},
      storyImages: [
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3568095/03320288962b8c4d982b2d00c23b2686-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/2050703/a9301f137950ee9ec5c79599bacc02a7-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3377781/4e1aee86ad8d385b76a0edc64a29186f-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3439028/06b9610ccc68b29db0b92d4a9f6da731-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3538649/b7ed5724ffe10cee6fd73cb69b758f42-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3568095/f45668519ee7ba0cbe893b1aa22cba09-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/2050703/e9ffeaa467b0ab62520f39c00eed2a8d-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3806023/478d895cc6ac23233bf84816465b8d98-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3595156/d3bbffed3b111bfed5411b06867a7ba4-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3807631/ff0c246bb26d42be4fcb900e81807272-300x300.jpg",
            },
          ],
        },
      ],
      currentStoryIndex: 0,
      cartModal: false,
      branchAddress: false,
      checkoutModal: false,
      showMoreDesc: false,
      isAroundClock: false,
      isTheSameTime: false,
      expandMore: false,
      userAgent: useUserAgent(),
      notifyModal: false,
      notifyModalName: 'tip'
    };
  },
  created() {
    const uuid = new DeviceUUID().get();
    this.setDeviceUuid(uuid);
  },
  mounted() {
    this.tags = [...this.currentStore.cuisines, ...this.currentStore.classifications, ...this.currentStore.additional];
    this.isAroundClock = Boolean(this.currentStore.day_n_night);
    const hours = this.currentStore.working_hours;
    Object.values(hours).map(item =>
      this.isTheSameTime = Object.values(hours).every(time => time.open === item.open)
          && Object.values(hours).every(time => time.close === item.close));
      this.weekdays = [
        hours.su,
        hours.mo,
        hours.tu,
        hours.we,
        hours.th,
        hours.fr,
        hours.sa,
      ];
    if (!this.isAroundClock && !this.isTheSameTime) {
      this.currentTodayTime = this.weekdays[(new Date()).getDay()];
      this.weekdaysPriority = this.weekdays;
      this.weekdaysPriority.push(this.weekdaysPriority.shift());
    } else if (this.isTheSameTime) {
      this.currentTodayTime = this.weekdays[0];
    }
    this.$store.commit("SET_NAVIGATION_MENU_HOME_ROUTE", this.$route.fullPath);
    this.yandexGoActions();
    this.$nextTick(() => {
      this.observerFunc();
    });
  },
  methods: {
    ...mapActions([
      "addToCart",
      "removeFromCart",
      "makeOrder",
      "fetchProducts",
      "setCanOrder",
      "setDeviceUuid",
    ]),
    route(coords) {
      console.log(coords)
      window.open(`yandexmaps://maps.yandex.ru/?ll=${coords[0]},${coords[1]}&z=12`, '_blank')
    },
    weekName(num) {
      switch (num) {
        case 1:
          return this.$t('WeeksMore.Monday')
        case 2:
          return this.$t('WeeksMore.Tuesday')
        case 3:
          return this.$t('WeeksMore.Wednesday')
        case 4:
          return this.$t('WeeksMore.Thursday')
        case 5:
          return this.$t('WeeksMore.Friday')
        case 6:
          return this.$t('WeeksMore.Saturday')
        case 7:
          return this.$t('WeeksMore.Sunday')
        default:
          return this.$t('WeeksMore.Sunday')
      }
    },
    onExpandMore() {
      if (this.isAroundClock || this.isTheSameTime) return;
      this.expandMore = !this.expandMore
    },
    observerFunc() {
      const callbackHeader = (entries) => {
        if (entries[0].isIntersecting) {
          this.maxlengthImages = 6;
          this.isClickedMorePhoto = false;
        }
      };
      const observerHeader = new IntersectionObserver(callbackHeader, {rootMargin: "0px", threshold: 1.0});
      if (this.$refs.observer) {
        if (this.$refs.menuHeader) {
          observerHeader.observe(this.$refs.menuHeader.$el);
        }
      }
    },
    async getAddress(value) {
      return value?.split(',');
    },
    shareTelegram(url) {
      if (useUserAgent().iosAndroid()) {
        window.open(`https://t.me/${url}`, '_self');
        return;
      }
      window.open(`tg://t.me/${url}`, '_self');
    },
    shareInstagram(login) {
      window.open(`https://instagram.com/${login}`, '_blank');
    },
    defineDistance(storeAddress) {
      let distance = null;
      navigator.geolocation.getCurrentPosition(
          async (position) => {
            // const storeAddress = coords;
            distance = this.distanceInKmBetweenCoordinates(
                position.coords.latitude,
                position.coords.longitude,
                storeAddress[0],
                storeAddress[1]
            );
            this.distances.push(distance);
            return distance;
          },
          (error) => {
            console.log(error);
          }
      );
      return distance;
    },
    distanceInKmBetweenCoordinates(lat1, lon1, lat2, lon2) {
      let earthRadiusKm = 6371;
      let dLat = this.degreesToRadians(lat2 - lat1);
      let dLon = this.degreesToRadians(lon2 - lon1);
      lat1 = this.degreesToRadians(lat1);
      lat2 = this.degreesToRadians(lat2);
      let a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(lat1) *
          Math.cos(lat2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return earthRadiusKm * c;
    },
    degreesToRadians(degrees) {
      return (degrees * Math.PI) / 180;
    },
    yandexGoActions() {
      this.yandexTimeActive = this.yandexTags[0].type || '-';
      this.tariff = this.yandexTags[0].level || 0;
    },
    openWindow(url) {
      if (this.useragent === 'multicard') {
        window.flutter_inappwebview.callHandler('browser', {url})
      // } else if (useUserAgent().isAndroid() && url.match('tg://t.me')) {
      //   const updatedUrl = url.replace('tg://t.me', 'https://t.me')
      //   window.open(updatedUrl, '_blank')
      //   return;
      } else {
        window.open(url, '_blank')
      }
    },
    openAddressModal() {
      this.$emit('open-branch');
    },
    showMore() {
      this.showMoreDesc = !this.showMoreDesc;
      if (this.showMoreDesc) {
        translate(this.$refs.descContent, this.showMoreDesc);
      }
    },
    loadMorePhotos(e) {
      this.onMovePhotoStory(e, "none");
      this.maxlengthImages = this.storyImages.length;
      this.isClickedMorePhoto = true;
    },
    onClickTag(value) {
      if (this.$route.name === "MenuPage") return;
      this.$store.commit("UPDATE_QUERY_PARAMS_KITCHEN", value.id);
      const query = {
        ...this.$route.query,
        kitchens: this.$store.getters["queryParams"].kitchens
      };
      this.$router.push({path: "/", query}).catch(() => {
      });
    },
    onMovePhotoStory(event, index) {
      if (index === "none") event.stopPropagation();
      else {
        index = 0;
        // this.storyKey++;
        this.stories = [];
        this.currentStoryIndex = index;
        const arr = [
          {
            images: [],
          },
        ];
        this.storyImages.map(item => {
          arr[0].images.push(item.images[0]);
        });
        this.stories = [...arr];
        this.storyModal = true;
      }
    },
    handleNotifyModal(value) {
      this.notifyModalName = value;
      this.notifyModal = true;
    },
  },
  computed: {
    todayOpenTime() {
      return this.currentTodayTime?.open
    },
    todayCloseTime() {
      return this.currentTodayTime?.close
    },
    ...mapGetters([
      "address",
      "canOrder",
      "deviceUuid",
      "btnColor",
    ]),
    hasNavigatorShare() {
      return navigator.share;
    },
    useragent() {
      return useUserAgent().getUserAgent()
    },
  },
  watch: {
    token(value) {
      if (value) {
        this.checkoutModal = true;
      }
    },
    currentStore: {
      handler: function (obj) {
        this.tags = [...obj.cuisines, ...obj.classifications, ...obj.additional];
      },
      deep: true
    }
  },
  components: {
    yandexMap,
    ymapMarker,
    "van-image": Image,
    "van-popup": Popup,
  },
};
</script>

<style scoped lang="scss">
.catalog {
  padding-bottom: 80px;
}

.working-dates {
  font-size: 13px;
  font-weight: 600;
  padding: 4px 20px;

  .date-label {
    padding: 1px 8px;
  }

  .date-item {
    margin-bottom: 3px;

    &.active {
      .date-label {
        border: 1px solid #0faeae;
        border-radius: 10px;
      }
    }
  }
}

.yandex-modal {
  &-content {
    padding: 0 0px 5px;
  }

  &-item {
    position: relative;
    margin-bottom: 15px;
    padding-left: 50px;

    img {
      position: absolute;
      left: 0;
      top: -5px;
      height: auto;
      object-fit: cover;
    }
  }

  .item-label {
    color: #21201F;
    font-weight: 500;
    font-size: 16px;

    span {
      color: rgba(0, 0, 0, .7);
      font-weight: 400;
    }
  }
}

.btn-yandex {
  color: #FFFFFF;
  background: linear-gradient(95.73deg, #29BF9B -14.53%, #0BABAB 51.05%);
  width: 100%;
  height: 50px;
  border-radius: 16px;
  padding: 0 10px;
  border: 1px solid transparent;

  &--1 {
    margin-right: 8px;
    border-color: rgba(153, 153, 153, 0.53);
    color: #21201F;
    background: #F6F6F6;
  }
}

.address-actions {
  align-self: flex-end;
  margin-top: 10px;
}

.block {
  pointer-events: none;
  position: relative;
  width: 128px;
  height: 128px;
  margin: 1px;
  background-color: currentColor;
  font-size: 12px;
  color: #2c3e50;
}

.catalog-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0;
  padding: 0 20px;
}

.catalog-tag {
  padding: 4px 8px;
  background-color: #f5f5f5;
  color: #303030;
  border-radius: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);

  &-yandex {
    padding: 2px 10px;
    font-size: 11px;
    font-weight: 600;
    margin-right: 5px;
  }

  &.active {
    border: 1px solid #0faeae;
    color: #0faeae;
  }
}

.catalog-desc {
  padding: 0 20px;

  a {
    font-size: 14px;
  }
}

.catalog-work {
  padding: 0 20px;
  width: fit-content;
}

.photos-row {
  margin: 0 -6px;
}

.photos-col {
  padding: 0 6px;
  flex: 0 1 33.333%;
  margin-bottom: 9px;
}

.catalog-photo {
  border: 1px solid #f2f2f2;
  cursor: pointer;
  position: relative;

  div {
    position: absolute;
    color: #FFFFFF;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.catalog-address {
  padding: 0 20px;

  .address-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 18px;

    &-text {
      position: relative;
      font-size: 15px;
      margin-bottom: 10px;

      .location-icon {
        position: absolute;
        left: 0;
        top: 0px;
      }
    }
  }

  .all-address {
    color: #4C7CF6;
    text-decoration: underline;
    margin-top: -10px;
    margin-left: 27px;
    font-size: 14px
  }
}

.catalog-desc-content {
  line-height: 1.15em;
  display: block;
  font-size: 14px;
  color: #303030;
  transition: all .2s ease 0s;
  height: 32px;
  overflow: hidden;
  white-space: pre-line;

  &.hide-content {
    overflow: hidden;
    height: 32px;
  }
}

.catalog-photos {
  padding: 0 20px;
}

.tr-group {
  display: block;
  transition: all .3s ease 0s;
}

.tr-group-enter {
  opacity: 0;
}

.tr-group-leave-to {
  opacity: 1;
}

.tr-group-leave-active {
  //position: absolute;
}

</style>
