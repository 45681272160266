<template>
  <div>
    <van-popup
        class="swipe-menu-modal p-10"
        v-model="menuListModal"
        position="bottom"
        :style="{ maxHeight: '90%' }"
        closeable
        close-icon="close"
        close-icon-position="top-right"
        @close="closeModal"
    >
      <slot name="body"></slot>
    </van-popup>
  </div>
</template>
<script>
import {Popup} from 'vant'

export default {
  name: "SwipeModal",
  components: {
    'van-popup': Popup,
  },
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    showSwipeLine: {
      type: Boolean,
      default: true
    },
  },
  data: () => ({
    menuListModal: false,
  }),
  computed: {

  },
  mounted() {
  },
  methods: {
    openModal() {
      this.menuListModal = true;
    },
    closeModal() {
      this.menuListModal = false;
      this.$emit("close");
    },
  },
  watch: {
    modal (newValue) {
      newValue === true
        ? this.openModal()
        : this.closeModal();
    }
  }
}
</script>

<style scoped lang="scss">

.swipe-overflow-enter-active {
  transition: opacity .3s ease;
}

.swipe-overflow-leave-active {
  transition: opacity .1s ease;
}

.swipe-overflow-enter, .swipe-overflow-leave-to {
  opacity: 0;
}

.swipe-modal {
  &__overflow {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 99;
    opacity: 1;
  }

  &__close-icon {
    position: absolute;
    width: 50px;
    height: 50px;
    font-size: 24px;
    right: 0;
    cursor: pointer;
    z-index: 100;

    &:after{
      display: inline-block;
      content: "\00d7";
    }
  }

  &__swipe-line {
    position: absolute;
    width: 50px;
    height: 5px;
    background: white;
    border-radius: 10px;
    right: calc(50% - 25px);
    top: -15px;
  }

  &__wrap {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    z-index: 1000001;
    right: 12px;
    left: 12px;
    opacity: 1;
    //overflow-y: auto;

    &.hide{
      opacity: 0;
      visibility: hidden;
    }
  }

  &__content {
    position: relative;
    background: transparent;
    max-width: 500px;
    width: 100%;
    max-height: 80vh;

    &__header, &__body, &__footer {
      background: white;
      padding: 25px;
    }

    &__body{
      border-radius: 15px 15px 0 0;
    }

    &__header {
      border-bottom: 1px solid #eee;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background: white;
    }
  }
}
</style>
