<template>
  <div class="auth-popup-content">
    <div class="auth-popup-inner">
      <div class="top">
        <h2 class="title">{{$t("enter-phone-number")}}</h2>
        <h6 class="hint">{{$t("confirmation-code")}}</h6>
      </div>
      <div class="content">
        <div class="phone-input-wrapper">
          <div class="country-switcher">
            <ul class="switcher__navbar">
              <li class="switcher__item">
                <a href="#" @click.prevent="" class="switcher__link">
                  <button
                    v-if="countrySwitcherActive"
                    class="switcher--button"
                    key="on"
                    @click="countrySwitcherActive = false"
                  >
                    <img
                      :src="require(`@/assets/flags/${currentCountry}.svg`)"
                      alt="uz"
                    />
                  </button>
                  <button
                    v-else
                    class="switcher--button"
                    key="off"
                    @click="countrySwitcherActive = true"
                  >
                    <img
                      :src="require(`@/assets/flags/${currentCountry}.svg`)"
                      alt="uz"
                    />
                  </button>
                </a>
                <!-- Dropdown Menu -->
                <transition name="dropdown">
                  <div
                    class="dropdown__menu"
                    v-bind:class="{ active: countrySwitcherActive }"
                    v-if="countrySwitcherActive"
                  >
                    <ul class="dropdown__menu-nav">
                      <li class="dropdown__menu-item">
                        <a
                          @click.prevent="switchCountry('uz')"
                          href="#"
                          class="dropdown__menu-link"
                          title="Русский"
                        >
                          <div class="dropdown__menu-text">
                            Uzbekistan (+998)
                          </div>
                        </a>
                      </li>
                      <li class="dropdown__menu-item">
                        <a
                          @click.prevent="switchCountry('ru')"
                          href="#"
                          class="dropdown__menu-link"
                          title="Узбекский"
                        >
                          <div class="dropdown__menu-text">Russia (+7)</div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </transition>
              </li>
            </ul>
          </div>
          <input
            type="text"
            disabled
            class="phone-input"
            :value="getCountryCode"
            :style="getCountryCodeWidth"
          />
          <the-mask
            v-model="phoneModel"
            name="phone-number"
            type="tel"
            class="phone-input"
            inputmode="number"
            :mask="phoneMask"
            ref="phoneInput"
            @input="onChangePhone"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { TheMask } from "vue-the-mask";
import { mapActions } from "vuex";
export default {
  name: "OtpPage",
  props: {
    currentCountry: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
  },
  emits: ["change-country", "update-phone"],
  components: {
    TheMask,
  },
  data() {
    return {
      countrySwitcherActive: false,
      phoneModel: "",
      modal: false,
    };
  },
  created() {
    if (!this.$i18n.locale.trim().length) {
      this.$i18n.locale = 'ru'
      this.setLanguage('ru');
    }
  },
  mounted() {
    /**
     * Focus disabled because mobile
     * browsers have a keyboard bug
     */
    // this.$nextTick(() => {
      // this.$refs.phoneInput.$el.focus();
    // });
  },
  methods: {
    ...mapActions(["authorize", "setLanguage"]),
    switchCountry(country) {
      this.$emit("change-country", country);
      this.countrySwitcherActive = false;
      this.$refs.phoneInput.$el.focus();
    },
    onChangePhone(value) {
      if (value.length > 8) {
        this.$emit("update-phone", this.phoneModel);
        let phone = this.getCountryCode.replace('+', '') + value;
        this.authorize(phone).then(() => {});
      }
    },
  },
  computed: {
    phoneMask: function () {
      if (this.currentCountry === "uz") {
        return "## ###-##-##";
      }
      if (this.currentCountry === "ru") {
        return "### ###-##-##";
      }
      return "## ###-##-##";
    },
    getCountryCode() {
      if (this.currentCountry === "uz") {
        return '+998';
      }
      if (this.currentCountry === "ru") {
        return '+7';
      }
      return '+998';
    },
    getCountryCodeWidth() {
      if (this.currentCountry === "uz") {
        return "width: 60px";
      }
      if (this.currentCountry === "ru") {
        return "width: 30px";
      }
      return "width: 60px";
    },
  },
};
</script>

<style scoped>
.auth-popup-inner .title {
  max-width: 58%;
  line-height: 28px;
}
</style>
