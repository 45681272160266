<template>
  <button @click="$emit('button')" type="button" class="d-flex align-items-center justify-content-center">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "MyAuthBtn"
}
</script>

<style scoped>

</style>