<template>
  <div
    class="header-content-wrapper"
    :style="{
      background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 14.87%, rgba(0, 0, 0, 0.01) 99.78%),
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 12.98%, rgba(0, 0, 0, 0.50) 83.52%, rgba(0, 0, 0, 0.50) 99.79%),
        url(${background}) center / cover,
        lightgray no-repeat`,
    }"
  >
    <i class="rahmat-logo" />
    <div class="store-info">
      <div
        v-if="logo"
        class="store-logo"
        :style="{
          background: `url(${logo}) center / contain no-repeat,
          linear-gradient(180deg, #f4f4f7 0%, #F4F4F7 100%),
          lightgray no-repeat`,
          backgroundSize: 'contain',
        }"
      ></div>
      <div class="store-information">
        <div class="store-title">{{ store }}</div>
        <div class="store-address" v-if="address">{{ address }}</div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "HeaderView",
  props: {
    store: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: ""
    },
    logo: {
      type: String,
      default: ""
    },
    address: {
      type: String,
      default: ""
    }
  },
  data() {
    return {

    }
  },
}
</script>
