<template>
  <section class="precheck-stickers">
    <swiper
      class="stickers-swiper"
      :breakpoints="swiperStickerOptions.breakpoints"
      :space-between="12"
      :loop="false"
      :style="{justifyContent: stickers.length <= 3 ? 'center' : 'space-between'}"
    >
      <swiper-slide
        v-for="(sticker, index) in stickers"
        :key="sticker.id"
        class="stickers-swiper__slide"
        :class="{active: isActive(sticker)}"
        :style="{borderColor: isActive(sticker) ? getColor : ''}"
      >
        <img
          :src="`${sticker.icon}`"
          :class="{svg_active: isActive(sticker)}"
          class="svg-image mb-15"
          :id="sticker.icon"
          alt=""
        />
        <input
          v-model="selectedStickers"
          :id="sticker.id"
          :value="sticker.id"
          :checked="sticker.id"
          type="checkbox"
          @change="changeSticker(index, sticker)"
        />
        <div
          :style="{background: isActive(sticker) && getColor !== '#1fb7a4' ? getColor : ''}"
          class="stickers-swiper__title">
          {{ sticker.text }}
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper-vue2";

export default {
  name: "StickerBlock",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    stickers: {
      type: Array,
      default: () => []
    },
    getColor: {
      type: String,
      default: "#1fb7a4"
    },
  },
  emits: ['selected'],
  data: function () {
    return {
      swiperStickerOptions: {
        breakpoints: {
          0: {
            slidesPerView: this.stickers.length > 4 ? 4.2 : 3.8
          },
          481: {
            slidesPerView: 5.5
          },
          576: {
            slidesPerView: 6.5
          }
        }
      },
      selectedStickers: [],
      animation: false,
      oldSvgColors: {
        firstColor: "grey",
        secondColor: "darkgrey",
      },
    };
  },
  methods: {
    isActive(value) {
      return this.selectedStickers.length && this.selectedStickers.includes(value.id)
    },
    changeSticker(index, st) {
      this.onClickSvg(index, st);
      this.$emit('selected', this.selectedStickers)
    },
    onClickSvg(id, sticker) {
      const element = document.querySelector(`[data-svgid="${id}"]`);
        const color = this.getColor;
      if (element) {
        element.querySelectorAll("linearGradient").forEach(linear => {
          if (this.selectedStickers.includes(sticker.id)) {
            linear.children[0].setAttribute('stop-color', color === "#1fb7a4" ? '#2FCA96' : color);
            linear.children[1].setAttribute('stop-color', color === "#1fb7a4" ? '#0C9A9A' : color);
          } else {
            linear.children[0].setAttribute('stop-color', this.oldSvgColors.firstColor);
            linear.children[1].setAttribute('stop-color', this.oldSvgColors.secondColor);
          }
        });
      }
    },
    handleImgToSvg() {
      if (this.stickers.length) {
        const elements = document.querySelectorAll('img.svg-image');
        const vm = this;
        if (elements.length) {
          elements?.forEach(function (img) {
            const imgID = img.id;
            const imgClass = img.className;
            const imgURL = img.src;
            fetch(imgURL).then(function (response) {
              return response.text();
            }).then(function (text) {

              const parser = new DOMParser();
              const xmlDoc = parser.parseFromString(text, "text/xml");

              // Get the SVG tag, ignore the rest
              const svg = xmlDoc.getElementsByTagName('svg')[0];

              if (svg) {
                // Add replaced image's ID to the new SVG
                if (typeof imgID !== 'undefined') {
                  svg.setAttribute('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== 'undefined') {
                  svg.setAttribute('class', imgClass + ' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                svg.removeAttribute('xmlns:a');

                // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
                if (!svg.getAttribute('viewBox') && svg.getAttribute('height') && svg.getAttribute('width')) {
                  svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width'))
                }

                // Replace image with new SVG
                img.parentNode.replaceChild(svg, img);
              }

              const svgs = document.querySelectorAll("svg.replaced-svg defs");
              svgs.forEach((defs, index) => {
                defs.setAttribute("data-svgid", index.toString());
                vm.oldSvgColors.firstColor = defs.querySelector("linearGradient").children[0].getAttribute("stop-color");
                vm.oldSvgColors.secondColor = defs.querySelector("linearGradient").children[1].getAttribute("stop-color");
              });
            });
          });
        }
      }
    }
  },
  mounted() {
    this.handleImgToSvg();
    if (this.stickers.length > 4) {
      this.animation = true;
      const elem = document.querySelector('.swiper-wrapper')
      elem.style.justifyContent = "space-between";
    }
  },
  watch: {
    animation(value) {
      if (value) {
        const elem = document.querySelector('.swiper-wrapper');
        elem.classList.add("animation");
      }
    }
  }
};

</script>

<style scoped>

.svg-image {
  width: 60px;
  height: 60px;
  max-width: 60px;
  max-height: 60px;
}

</style>
