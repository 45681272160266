<template>
  <div>
    <van-rate
      v-if="store?.['show_rating']"
      v-model="rate"
      :size="45"
      :color="color"
      void-icon="star"
      void-color="rgba(244, 244, 247, 1)"
      class="mb-20 van-rate pt-20"
    />
    <div :class="{ 'd-none': !store?.['show_rating'] }">
      <transition appear name="slideRate">
        <div class="rate-text text-center mb-20" v-if="rate === 1 || rate === 2">
          {{ $t("what-we-need-to-fix") }}
        </div>
      </transition>
      <transition appear name="slideRate">
        <div class="rate-text text-center mb-20" v-if="rate === 3">
          {{ $t("what-should-we-work-on") }}
        </div>
      </transition>
      <transition appear name="slideRate">
        <div class="rate-text text-center mb-20" v-if="rate === 4">
          {{ $t("what-can-we-improve") }}
        </div>
      </transition>
      <transition appear name="slideRate">
        <div class="rate-text text-center mb-20" v-if="rate === 5">
          {{ $t("what-did-you-like") }}
        </div>
      </transition>
      <sticker-block
        v-if="stickersList?.length && rate"
        :stickers="stickersList"
        :get-color="color"
        @selected="stickerSelect"
      />
      <div class="comment-block mb-20 mt-25" :style="`--tooltip-color: ${color}`">
        <div class="tooltip-lens" :style="tooltipBg" :class="{active: tooltip}">
          <div class="tooltip-lens-inner">
            <span>{{ $t('leave-emotion') }} <span class="st-jump"> 🎉</span></span>
          </div>
          <div class="innerGradient"></div>
        </div>
        <input
          type="text"
          v-model="commentModel"
          class="form-control"
          :disabled="!!audioMessage"
          :placeholder="audioMessage ? audioMessage : $t('comments')"
        />
        <input
          type="file"
          id="file-input"
          style="
            position: absolute;
            opacity: 0;
            z-index: -2;
            width: 0;
            visibility: visible;
          "
          @change="onChangeFile"
        />
        <div
            :class="gifActive
          ? 'cursor-not-allowed' : ''"
            @click="handlePhoto"
            class="photo-icon d-flex align-items-center mr-5"
            :style="{filter: !photo ? 'grayscale(1)' : '', opacity: !photo ? null : 1}"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="35" viewBox="0 0 26 25" :fill="color">
            <path
                d="M7.50002 8.98333C8.59539 8.98333 9.48335 8.09537 9.48335 7C9.48335 5.90464 8.59539 5.01667 7.50002 5.01667C6.40466 5.01667 5.51669 5.90464 5.51669 7C5.51669 8.09537 6.40466 8.98333 7.50002 8.98333Z"
                :fill="color"/>
            <path
                d="M13.4917 2H6.50835C3.47502 2 1.66669 3.80833 1.66669 6.84167V10.825C1.66669 11.7333 1.82502 12.525 2.13335 13.1917C2.85002 14.775 4.38335 15.6667 6.50835 15.6667H13.4917C16.525 15.6667 18.3334 13.8583 18.3334 10.825V6.84167C18.3334 3.80833 16.525 2 13.4917 2ZM16.975 7.75C16.325 7.19167 15.275 7.19167 14.625 7.75L11.1584 10.725C10.5084 11.2833 9.45835 11.2833 8.80835 10.725L8.52502 10.4917C7.93335 9.975 6.99169 9.925 6.32502 10.375L3.20835 12.4667C3.02502 12 2.91669 11.4583 2.91669 10.825V6.84167C2.91669 4.49167 4.15835 3.25 6.50835 3.25H13.4917C15.8417 3.25 17.0834 4.49167 17.0834 6.84167V7.84167L16.975 7.75Z"
                :fill="color"/>
          </svg>
        </div>
        <div
            :class="gifActive
            ? 'cursor-not-allowed'
            : ''"
            @click="handleGif"
            class="gif-icon d-flex align-items-center"
            :style="{filter: !gif ? 'grayscale(1)' : '', opacity: !gif ? null : 1, marginRight: '2px'}"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="35" viewBox="0 0 26 22" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.50835 2H13.4917C16.525 2 18.3334 3.80833 18.3334 6.84167V10.825C18.3334 13.8583 16.525 15.6667 13.4917 15.6667H6.50835C4.38335 15.6667 2.85002 14.775 2.13335 13.1917C1.82502 12.525 1.66669 11.7333 1.66669 10.825V6.84167C1.66669 3.80833 3.47502 2 6.50835 2ZM17.0834 11.1667C17.0834 12.9616 15.6283 14.4167 13.8334 14.4167H6.16669C4.37176 14.4167 2.91669 12.9616 2.91669 11.1667V6.5C2.91669 6.08747 2.99355 5.69288 3.13373 5.32979C3.43983 4.40013 4.06682 3.77314 4.99648 3.46705C5.35957 3.32686 5.75415 3.25 6.16669 3.25H13.8334C14.2459 3.25 14.6405 3.32686 15.0036 3.46705C15.9332 3.77314 16.5602 4.40013 16.8663 5.32979C17.0065 5.69288 17.0834 6.08747 17.0834 6.5V11.1667Z"
                :fill="color"/>
            <path
                d="M8.31898 8.67208H8.90685V10.5785C8.69456 10.7787 8.44758 10.9323 8.16589 11.0394C7.88421 11.1465 7.59028 11.2 7.2841 11.2C6.85136 11.2 6.4615 11.0929 6.11449 10.8788C5.76749 10.66 5.49397 10.362 5.29393 9.98493C5.09798 9.60318 5 9.17487 5 8.70001C5 8.22515 5.09798 7.79685 5.29393 7.4151C5.49397 7.03335 5.76749 6.73539 6.11449 6.52124C6.46558 6.30709 6.85953 6.20001 7.29634 6.20001C7.63926 6.20001 7.94953 6.26519 8.22713 6.39554C8.50881 6.52124 8.74763 6.70746 8.94359 6.9542L8.56392 7.38716C8.221 7.01007 7.80664 6.82152 7.32084 6.82152C6.99425 6.82152 6.70031 6.90299 6.43904 7.06593C6.18185 7.22422 5.97977 7.44768 5.83281 7.73633C5.68584 8.02031 5.61236 8.34154 5.61236 8.70001C5.61236 9.05383 5.68584 9.37506 5.83281 9.6637C5.97977 9.94768 6.18185 10.1711 6.43904 10.3341C6.70031 10.497 6.99221 10.5785 7.31471 10.5785C7.69846 10.5785 8.03321 10.4738 8.31898 10.2643V8.67208Z"
                :fill="color"/>
            <path d="M10.0793 6.25588H10.6916V11.1441H10.0793V6.25588Z" :fill="color"/>
            <path d="M12.5873 6.86342V8.56733H14.7367V9.16789H12.5873V11.1441H11.975V6.25588H15V6.86342H12.5873Z"
                :fill="color"/>
          </svg>
        </div>
        <audio-recorder
          :installedAudio="!!audio || !!gif || !!photo"
          :timeExpired="audioTimeExpired"
          :get-color="color"
          @start="startRecord"
          @stop="stopRecord"
          @audio-url="setAudioUrl"
          @send-audio="sendAudioFile"
        />
        <div v-if="gifContent" class="gif-content">
          <div class="gif-search">
            <input
                v-model.trim="searchingGif"
                @keyup="advanceSearch($event.target.value, true)"
                ref="gifInput"
                type="text"
                :placeholder="$t('search-gif')"
            />
            <img class="search-icon" src="/svg/search.svg" width="22" height="22" alt=""/>
            <i v-show="searchingGif" @click="onClearGif" class="van-icon van-icon-cross"
               style="color: rgb(124, 124, 124); top: 14px">
            </i>
          </div>
          <div
              v-if="gifs.length || loadingGif"
              ref="gifItems"
              :key="gifsKey" class="gif-items">
            <div
                v-for="gif in gifs"
                :key="gif.id + Math.random() * 10"
                @click="onSetGif(gif.images.original.url)"
                class="gif-item">
              <img :src="gif.images.original.url" alt="Some Gif here :-)"/>
            </div>
            <p ref="loadMore" class="load-more text-center"></p>
          </div>
          <div v-if="loadingGif" class="loading-gif">
            <div class="lds-facebook">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="(gif || photo)" class="gif-photo-content">
        <van-icon @click="removeGifPhoto" size="24" name="close" color="#7c7c7c"/>
        <img :src="gif ? gif : photo" alt="Gif-photo content"/>
      </div>
      <div v-show="showRecordedAudio" class="audio-content">
        <van-icon @click="removeAudio" size="24" name="close" color="#7c7c7c"/>
        <av-line
          v-if="showRecordedAudio"
          :line-width="4"
          :line-color="color"
          :audio-src="audio"
          class="audio-line"
          id="avLine"
        />
      </div>
    </div>
    <div v-if="!store?.['show_rating']" class="text-center">
      Выберите сумму чаевых
    </div>
  </div>
</template>

<script>
import {Icon, Rate} from "vant";
import apiClient from "@/services/axios";
import StickerBlock from "@/components/StickerBlock";
import AudioRecorder from "@/components/AudioRecorder";
import AvLine from "vue-audio-visual/src/components/AvLine";
export default {
  name: "CommentBlock",
  props: {
    store: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
      default: '#1fb7a4',
    },
    comment: {
      type: String,
      default: '',
    },
    rating: {
      type: Number,
      default: 0
    },
  },
  emits: ['rate', 'comment', 'gif', 'photo', 'audio', 'stickers'],
  components: {
    AvLine,
    StickerBlock,
    AudioRecorder,
    "van-rate": Rate,
    "van-icon": Icon,
  },
  data() {
    return {
      gif: '',
      rate: 0,
      gifs: [],
      photo: '',
      audio: '',
      gifsKey: 0,
      stickers: [],
      tooltip: false,
      audioMessage: '',
      searchingGif: '',
      commentModel: '',
      loadingGif: false,
      gifContent: false,
      audioTimeExpired: false,
      showRecordedAudio: false,
    }
  },
  methods: {
    stickerSelect(stickers) {
      this.stickers = stickers
    },
    handlePhoto(e) {
      this.isShowTooltip = false;
      this.isClickedCommentIcons = true;
      if (this.hasMedia) {
        e.preventDefault();
        return;
      }
      document.getElementById("file-input").value = null;
      document.getElementById("file-input").click();
    },
    onLoadMore() {
      const callback = (entries) => {
        if (entries[0].isIntersecting) {
          if (!this.calledObserver && !this.endCheckLimit) {
            this.gifApiPagination += 9;
            this.advanceSearch(this.searchingGif, false);
            this.calledObserver = true;
          }
        }
      };
      const observer = new IntersectionObserver(callback, {rootMargin: "0px", threshold: 1.0});
      if (this.$refs.loadMore) {
        observer.observe(this.$refs.loadMore);
      }
    },
    handleGif(e) {
      this.isShowTooltip = false;
      this.isClickedCommentIcons = true;
      if (this.hasMedia) {
        e.preventDefault();
        return;
      }
      this.searchingGif = "";
      this.gifContent = !this.gifContent;
      this.calledObserver = false;
      if (this.gifContent) this.$nextTick(() => this.$refs.gifInput?.focus());
    },
    onSetGif(gifUrl) {
      this.gif = "";
      this.gif = gifUrl;
      this.gifContent = false;
      this.calledObserver = false;
    },
    onClearGif() {
      const elem = this.$refs.gifItems;
      if (elem) {
        elem.scroll({
          top: 0,
          behavior: "smooth"
        });
      }
      this.searchingGif = '';
      this.calledObserver = false;
      if (this.gifContent) this.$nextTick(() => this.$refs.gifInput?.focus())
    },
    onChangeFile(event) {
      const target = event.target;
      const files = target.files;

      if (FileReader && files && files.length) {
        const formData = new FormData();
        formData.append('file', target.files[0]);
        apiClient.post('/review/upload', formData).then(res => {
          if (res.data) {
            this.photo = res.data.data;
          }
        }).catch(e => console.log(e))
      } else {
        console.log('not supported')
      }
    },
    startRecord() {
      this.audioMessage = "Recording ...";
      this.audioTimeExpired = false;
      this.isShowTooltip = false;
      this.isClickedCommentIcons = true;
      this.audioLimit()
    },
    stopRecord() {
      this.audioMessage = "";
      this.recorded = false;
      this.showRecordedAudio = false;
      this.audio = "";
      this.audioSrc = "";
      this.audioTimeLimit = 120;
    },
    audioLimit() {
      if (this.audioTimeLimit > 0) {
        this.audioTimeTimer = setTimeout(() => {
          this.audioTimeLimit--;
          this.audioLimit();
        }, 2000)
      }
      if (this.audioTimeLimit < 0) {
        this.recorded = false;
        this.audioTimeExpired = true;
        clearTimeout(this.audioTimeTimer)
      }
    },
    setAudioUrl(value) {
      this.audioSrc = value;
      this.showRecordedAudio = true;
      this.audio = value; // need come from backend
    },
    sendAudioFile(fileRecording) {
      let file = new File([fileRecording], 'recording.mp3');

      const formData = new FormData();
      formData.append('file', file);

      apiClient.post('/review/upload', formData, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
        }
      }).then(res => {
        if (res.data) {
          this.showRecordedAudio = true;
          this.audio = res.data.data;
        }
      }).catch(e => console.log(e))
    },
    removeGifPhoto() {
      this.gif = "";
      this.photo = "";
      this.showRecordedAudio = false;
    },
    removeAudio() {
      this.audio = "";
      this.audioMessage = "";
      this.showRecordedAudio = false;
    },
    advanceSearch(value, activeSearch) {
      clearTimeout(this.searchTimeout);
      if (this.searchingGif.length > 2) {
        this.loadingGif = true;
        this.searchTimeout = setTimeout(() => {
          if (activeSearch) {
            this.gifApiPagination = 15;
            this.gifs = [];
          }
          const api = 'https://api.giphy.com/v1/gifs/search?q=';
          const apiKey = '&api_key=AkY7KgOwfyHMbNIqwlDTLDh6lIgZhWK7';
          const lang = '&lang=es';
          const rating = '&rating=g';
          const url = api + value + apiKey + lang + '&limit=' + this.gifApiPagination + rating;
          apiClient.get(url).then(data => {
            if (data.data?.data.length > 0) {
              this.gifs.push(...data.data.data);
              this.calledObserver = false;
              this.$nextTick(() => {
                this.onLoadMore();
              });
            }
          }).catch(e => console.log(e)).finally(() => {
            this.loadingGif = false;
            const elem = this.$refs.gifItems;
            if (activeSearch && elem) {
              elem?.scroll({
                top: 0,
                behavior: "smooth"
              });
              // this.gifsKey++;
            }
          })
        },
        1000
        );
      }
    },
  },
  computed: {
    stickersList() {
      if (this.$route.name === 'ReviewPage') {
        return this.store?.stickers?.filter((sticker) => sticker.type.toString() === '2')
      }
      return this.store?.stickers
    },
    hasMedia() {
      return [
        this.photo,
        this.gif,
        this.audio,
        this.recorded,
        this.audioMessage
      ].some((item) => Boolean(item));
    },
    gifActive() {
      return [
        this.gif,
        this.photo,
        this.audio,
        this.audioMessage,
        this.recorded
      ].some(some => some);
    },
    tooltipBg() {
      let color;
      if (this.color === "#1fb7a4") {
        color = {backgroundImage: 'linear-gradient(to left top, #0faeae, #29bf9b)'}
      } else {
        color = {backgroundColor: this.color}
      }
      return color;
    },
  },
  watch: {
    rate: {
      handler(value) {
        this.$emit('rate', value)
      }
    },
    rating: {
      handler(value) {
        this.rate = value
      }
    },
    commentModel: {
      handler(value) {
        this.$emit('comment', value)
      }
    },
    comment: {
      handler(value) {
        this.commentModel = value
      }
    },
    gif: {
      handler(value) {
        this.$emit('gif', value)
      }
    },
    photo: {
      handler(value) {
        this.$emit('photo', value)
      }
    },
    audio: {
      handler(value) {
        this.$emit('audio', value)
      }
    },
    stickers: {
      handler(value) {
        this.$emit('stickers', value)
      }
    }
  }
}
</script>

<style scoped></style>